import { useApolloClient } from "@apollo/client"
import { useCookie } from "react-use"

export function useDataMasking() {
  const client = useApolloClient()
  const [dataUnmaskCookie, setDataUnmaskCookie] = useCookie("unmask_data")

  const isMasked = !Boolean(Number(dataUnmaskCookie))

  const toggleDataMasking = () => {
    if (!isMasked) {
      setDataUnmaskCookie("0", { sameSite: "Lax", secure: true })
    } else {
      setDataUnmaskCookie("1", { sameSite: "Lax", secure: true })
    }

    client.resetStore()
  }

  return {
    isMasked,
    toggleDataMasking
  }
}
