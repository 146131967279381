import { MutableRefObject, useEffect, useRef, useState } from "react"

export function useHover<T extends HTMLDivElement>(): [MutableRefObject<T>, boolean] {
  const [value, setValue] = useState(false)
  const ref = useRef<T>(null)
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = (e) => e.currentTarget === e.target && setValue(false)
  useEffect(
    () => {
      const node = ref.current
      node?.addEventListener("mouseover", handleMouseOver)
      node?.addEventListener("mouseout", handleMouseOut)
      return () => {
        node?.removeEventListener("mouseover", handleMouseOver)
        node?.removeEventListener("mouseout", handleMouseOut)
      }
    },
    [ref.current] // Recall only if ref changes
  )
  return [ref, value]
}
