import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { PeopleTable, PeopleFilterBar } from "./index"

export function PeopleIndexSection() {
  return (
    <Fragment>
      <Helmet title="All People" />
      <PeopleFilterBar />
      <PeopleTable queryId="active" />
    </Fragment>
  )
}
