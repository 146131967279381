import { formatMessage } from "@formatjs/intl"
import {
  avatarColors,
  Box,
  Button,
  Card,
  CreditCardIcon,
  CreditCardAmexIcon,
  CreditCardMastercardIcon,
  CreditCardVisaIcon,
  EditIcon,
  EmptyState,
  getColorFromHash,
  Heading,
  HStack,
  IconProps,
  Text,
  theme,
  VStack,
} from "@glasscanvas/elephantkit"
import { ReactChild, ReactNode, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router"
import { RmsSectionProps } from "../base"
import { Pledge, useGetPledgesQuery } from "../graphql"
import { Loading, usePersonId } from "../shared"
import { PledgeEditor } from "@pledgeui"

export function PersonPledgesSection(_: RmsSectionProps) {
  const personId = usePersonId()

  const { loading, error, data } = useGetPledgesQuery({
    variables: { fromDate: `${new Date().getFullYear() - 2}-01-01T00:00:00.00Z`, personId, isSubscription: true },
  })

  if (loading) return <Loading />

  if (error) {
    return <p>Error!</p>
  }

  if (!data.pledges.pledges.length) {
    return (
      <EmptyState
        title="No donations yet"
        description="You haven't made any donations yet."
        action={{ title: "Give Now", to: "give-now" }}
      />
    )
  }

  return (
    <VStack css={{ padding: "$6", width: "100%", maxWidth: "800px" }} space="large">
      {data.pledges.pledges.map((pledge) => (
        <PledgeCard key={pledge.id} pledge={pledge} />
      ))}
    </VStack>
  )
}

// MARK: Pledge Card

type PledgeCardProps = {
  pledge: Pledge
}

const CREDIT_CARD_ICONS: Record<string, (props: IconProps) => JSX.Element> = {
  Visa: CreditCardVisaIcon,
  Mastercard: CreditCardMastercardIcon,
  Americanexpress: CreditCardAmexIcon,
  default: CreditCardIcon,
}

const FREQUENCY_LABELS = {
  once: "once",
  week: "weekly",
  month: "monthly",
  quarter: "quarterly",
  halfyear: "semi-annual",
  year: "annual",
}

function PledgeCard({ pledge }: PledgeCardProps) {
  const { formatMessage, formatDate } = useIntl()
  const [showPledgeForm, setShowPledgeForm] = useState<false | "paymentSource" | "paymentAttrs">(false)

  const hashColor: string =
    theme.colors[avatarColors[getColorFromHash(pledge.campaign?.id ?? "static-campaign") % avatarColors.length]]?.value

  const CreditCardBrand = CREDIT_CARD_ICONS[pledge.cardBrand] ?? CREDIT_CARD_ICONS.default
  const paymentStatusStr = pledge.canceled
    ? formatMessage({ defaultMessage: "Cancelled", id: "pledge.cancelled" }, {})
    : formatMessage(
        { defaultMessage: 'Note the last transaction failed "{ccStatus}"', id: "pledge.failed" },
        { ccStatus: pledge.ccStatus }
      )

  return (
    <Card
      key={pledge.id}
      elevation={2}
      css={{
        backgroundColor: "$background",
        padding: "$8",
        position: "relative",
        overflow: "hidden",

        "&::before": {
          content: '""',
          width: "$1",
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          backgroundColor: hashColor,
        },
      }}
    >
      <VStack space="large">
        <VStack space="small">
          <HStack align="center" css={{ justifyContent: "space-between" }}>
            <Heading css={{ fontWeight: "$regular" }}>
              <FormattedMessage
                defaultMessage="<b>{amount, number, ::currency/USD}</b> / {frequency} {donation}"
                id="pledge.transaction"
                values={{
                  donation: pledge.frequency !== "once" && "donation",
                  amount: pledge.transactionCents / 100.0,
                  frequency: FREQUENCY_LABELS[pledge.frequency],
                  b: (t: string) => <Box as="strong" css={{ fontWeight: "$bold" }} children={t} />,
                }}
              />
            </Heading>
          </HStack>

          <Text size="caption1" css={{ color: "$secondary" }}>
            <FormattedMessage
              defaultMessage="Next Payment: {paymentDate}"
              id="pledge.paymentDate"
              values={{
                paymentDate: !pledge.nextScheduledChargeDate
                  ? paymentStatusStr
                  : formatDate(new Date(pledge.nextScheduledChargeDate), { dateStyle: "medium" }),
              }}
            />
            {["valid", "none", null].includes(pledge.ccStatus) ? null : ` (${paymentStatusStr})`}
          </Text>
        </VStack>

        {showPledgeForm ? (
          <PledgeEditor pledge={pledge} initialFieldSet={showPledgeForm} setOpen={() => setShowPledgeForm(false)} />
        ) : (
          <VStack>
            {pledge.campaign && <Heading size="subHeadline">{pledge.campaign.title}</Heading>}
            {!!pledge?.campaign?.brief && <Text>{pledge?.campaign?.brief}</Text>}
            <HStack>
              <CreditCardBrand />
              <Box>
                <Text
                  as={FormattedMessage}
                  id="pledge.cardLast4"
                  defaultMessage="**** **** **** {cardLast4}"
                  values={{ cardLast4: pledge?.cardLast4 ?? "****" }}
                />
              </Box>
            </HStack>
            <HStack>
              <Button onClick={() => setShowPledgeForm("paymentAttrs")} appearance="outline">
                Edit Pledge
              </Button>
              <Button onClick={() => setShowPledgeForm("paymentSource")} appearance="outline">
                Update card on file
              </Button>
            </HStack>
          </VStack>
        )}
      </VStack>
    </Card>
  )
}
