import { Box, EmptyState } from "@glasscanvas/elephantkit"
import { isEmpty, trim } from "lodash-es"
import { useEffect, useRef, useState } from "react"
import { RmsSectionProps } from "../base"
import { usePerson } from "../shared"
import { useMutationObserver, usePersonId } from "../shared"

// During the transition to the new RMS, use this component to display Rails partials we haven't had a chance to re-create in React yet.
// The partials that will display here may be found in vendor/extensions/people/app/views/refinery/people/_person_edit_rms_pieces.html.erb
export function PersonRailsSection({ sectionMeta }: RmsSectionProps) {
  const personId = usePersonId()
  const person = usePerson()

  const [emptyStateTitle, setEmptyStateTitle] = useState(null)
  const [emptyStateDesc, setEmptyStateDesc] = useState(null)
  const [emptyStateActionTitle, setEmptyStateActionTitle] = useState(null)
  const [emptyStateActionTo, setEmptyStateActionTo] = useState(null)
  const [emptyStateActionHref, setEmptyStateActionHref] = useState(null)

  const presentBasedOnAttr = sectionMeta.fallback?.presentCheckAttr ? person[sectionMeta.fallback?.presentCheckAttr] : true

  const [present, setPresent] = useState(presentBasedOnAttr)

  // get a reference to the div with the rails content
  const railsSectionToBeFilled = useRef<HTMLDivElement>(null)
  const presentCallback = () => {
    // If the rails div is empty, show the EmptyState component instead
    // There are two ways to set up an EmptyState component - needed for now as some EmptyState components rely on conditions or links only available in Rails.=

    // 1) by pulling from the fallback param in sectionMeta (rms_sections_data.tsx)
    if (person[sectionMeta.fallback?.presentCheckAttr] === false) {
      if (sectionMeta.fallback?.title) setEmptyStateTitle(sectionMeta.fallback?.title)
      if (sectionMeta.fallback?.description) setEmptyStateDesc(sectionMeta.fallback?.description)
      if (sectionMeta.fallback?.actionTitle) setEmptyStateActionTitle(sectionMeta.fallback?.actionTitle)
      if (sectionMeta.fallback?.actionTo) setEmptyStateActionTo(sectionMeta.fallback?.actionTo)
      if (sectionMeta.fallback?.actionHref) setEmptyStateActionHref(sectionMeta.fallback?.actionHref)
      setPresent(false)
      return
    }

    // 2) by pulling data- attributes from a hidden div in the Rails partial
    if (
      isEmpty(trim(railsSectionToBeFilled.current?.textContent)) &&
      !railsSectionToBeFilled.current?.querySelector(".loader.active")
    ) {
      const emptyStateEl = railsSectionToBeFilled.current?.querySelector(".rms-rails-show-empty-state")
      if (emptyStateEl) {
        if (emptyStateEl.dataset.emptyStateTitle) setEmptyStateTitle(emptyStateEl.dataset.emptyStateTitle)
        if (emptyStateEl.dataset.emptyStateDescription) setEmptyStateDesc(emptyStateEl.dataset.emptyStateDescription)
        if (emptyStateEl.dataset.emptyStateActionTitle) setEmptyStateActionTitle(emptyStateEl.dataset.emptyStateActionTitle)
        if (emptyStateEl.dataset.emptyStateActionTo) setEmptyStateActionTo(emptyStateEl.dataset.emptyStateActionTo)
        if (emptyStateEl.dataset.emptyStateActionHref) setEmptyStateActionHref(emptyStateEl.dataset.emptyStateActionHref)
      }
      setPresent(false)
    } else {
      setPresent(true)
    }
  }
  useMutationObserver(railsSectionToBeFilled, presentCallback)

  useEffect(() => {
    document
      ?.querySelector(`.rails-html-within-react[data-selector="${sectionMeta?.domSelector}"]`)
      ?.dispatchEvent(new Event("content-ready-vanilla", { bubbles: true }))
  }, [present])

  return (
    <>
      <Box
        css={{ visibility: !present ? "hidden" : "visible", width: '100%' }}
        ref={railsSectionToBeFilled}
        className="rails-html-within-react rails-element-root"
        data-ajax-url={sectionMeta?.ajaxUrl?.replace(":person_id", `${personId}`)}
        data-selector={sectionMeta?.domSelector}
        dangerouslySetInnerHTML={{ __html: document.querySelector(".blank-rails-section-to-be-filled").innerHTML }}
      />
      {!present && <EmptyState title={emptyStateTitle} description={emptyStateDesc} action={{title: emptyStateActionTitle, to: emptyStateActionTo, href: emptyStateActionHref}} />}
    </>
  )
}
