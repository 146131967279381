import {
  Box,
  Button,
  FormikCheckboxField,
  FormikForm,
  FormikTextField,
  Heading,
  HStack,
  useToaster,
  VStack,
} from "@glasscanvas/elephantkit"
import { Formik, useField } from "formik"
import { isEmpty, pick } from "lodash-es"
import { DateTime } from "luxon"
import { useMemo } from "react"
import * as yup from "yup"
import { rmsSectionsData, SectionMeta, selectKeys, transformValues } from "../base"
import { useUpdatePersonMutation } from "../graphql"
import {
  AttributeLayout,
  AvatarField,
  DateField,
  FieldsData,
  MultipleChoiceField,
  NumberField,
  StringField,
  TextAreaField,
  useAttributeLayouts,
} from "../shared"
import { usePerson } from "../shared"
import { PersonFieldKeys, PERSON_FIELDS_DATA } from "./fields_data"

// TODO: Use i18n for this @see https://github.com/jquense/yup#using-a-custom-locale-dictionary
yup.setLocale({
  string: {
    email: "Not a valid email address",
  },
})

export function generateSchema(formData: FieldsData<PersonFieldKeys>, hiddenAttributes: string[] = []) {
  return Object.entries(formData).reduce((schema, [key, field]) => {
    if (hiddenAttributes.includes(key)) return schema

    const { validationType, validations = [] } = field
    if (!yup[validationType]) {
      return schema
    }

    let validator = yup[validationType]()
    validations.forEach((validation) => {
      const { params = [], type } = validation
      if (type === "matches") {
        params[0] = new RegExp(params[0] as string)
      }
      if (!validator[type]) {
        return
      }
      validator = validator[type](...(params ?? []))
    })

    schema[key] = validator
    return schema
  }, {})
}

type PersonAttributeFormProps = {
  setOpen: (open: boolean) => void
  sectionMeta: SectionMeta
  slug?: string
}

/** These are the custom layouts per layout grouping */
export const attributeLayouts: Record<string, AttributeLayout> = {
  name: { component: NameFieldSet, title: "Name" },
  contact: { component: EmailPhoneFieldSet },
  address: { component: FlexFieldSet, title: "Address" },
  religion: { component: FlexFieldSet, title: "Religion" },
  marriage: { component: FlexFieldSet, title: "Marriage" },
  wedding_location: { component: FlexFieldSet, title: "Wedding Location" },
  school: { component: FlexFieldSet, title: "School" },
  hidden: { component: null },
}

export const fieldDictionary = {
  string: { component: StringField },
  text: { component: TextAreaField },
  number: { component: NumberField },
  multiple_choice: { component: MultipleChoiceField },
  checkbox: { component: FormikCheckboxField },
  date: { component: DateField },
  image_id: { component: AvatarField },
}

export const attributeGroups: Partial<Record<PersonFieldKeys, keyof typeof attributeLayouts>> = {
  // Name
  firstName: "name",
  lastName: "name",
  memberTitle: "name",
  memberSuffix: "name",

  // Contact
  email: "contact",
  memberDoNotEmail: "contact",
  memberCellPhone: "contact",
  memberHomePhone: "contact",
  memberDoNotCall: "contact",

  // Address
  addressAddress: "address",
  addressAddress2: "address",
  addressCity: "address",
  addressStateProvince: "address",
  addressPostalCode: "address",
  addressCountry: "address",

  // Religion
  memberReligion: "religion",
  memberReligionOther: "religion",

  // Marriage
  memberMaritalStatus: "marriage",
  memberDateMarried: "marriage",

  // Wedding Location
  memberCountryWhereMarried: "wedding_location",
  memberChurchWhereMarried: "wedding_location",
  memberCityWhereMarried: "wedding_location",

  // School
  memberSchoolGrade: "school",
  memberSchoolName: "school",

  // Hidden
  memberHaveChildren: "hidden",
  memberBaptised: "hidden",
  memberConfirmed: "hidden",
  memberFirstCommunion: "hidden",
  inOrgRoles: "hidden",
  globalRoles: "hidden",
}

export function renderFormElements(elements: ReturnType<typeof useAttributeLayouts>) {
  return Object.entries(elements).map(
    ([
      key,
      {
        component: Component,
        label,
        context,
        placeholder,
        name,
        choices,
        validationType,
        validations,
        __grouped,
        ...props
      },
    ]) =>
      Component ? (
        <Component
          key={key}
          slug={key}
          label={label}
          placeholder={placeholder}
          name={name}
          choices={choices}
          validationType={validationType}
          validations={validations}
          context={context}
          {...(__grouped ? props : {})}
        />
      ) : null
  )
}

export function PersonAttributeForm({ setOpen, sectionMeta, slug }: PersonAttributeFormProps) {
  const toaster = useToaster()
  const [updatePerson] = useUpdatePersonMutation()
  const person = usePerson()
  const sectionValueDictionary = transformValues(
    selectKeys(person, sectionMeta.subSections.concat(Object.keys(sectionMeta.hiddenValues ?? ({} as any)))),
    PERSON_FIELDS_DATA,
    false
  )

  const sections = useAttributeLayouts({
    keys: sectionMeta.subSections,
    meta: PERSON_FIELDS_DATA,
    groups: attributeGroups,
    layouts: { ...attributeLayouts, ...fieldDictionary },
    values: sectionValueDictionary,
    hiddenAttributes: Object.keys(sectionMeta.hiddenValues),
    defaultLayout: FormikTextField,
  })

  if (!person) return null

  if (sections["authUserTwoFactorEnabled"] && person.authUserEnforceTwoFactor) {
    sections["authUserTwoFactorEnabled"]["choices"] = sections["authUserTwoFactorEnabled"]["choices"].filter(choice => choice.custom_id !== "disabled")
  }

  const initialValues: Record<string, any> = {}
  Object.entries(PERSON_FIELDS_DATA).forEach(([key, item]) => {
    switch (item.part_type) {
      case "date":
        initialValues[key] = DateTime.fromISO(sectionValueDictionary[key]).toISODate() ?? undefined
        break
      default:
        initialValues[key] = (sectionMeta.hiddenValues ?? {})[key] ?? sectionValueDictionary[key] ?? undefined
        break
    }
  })

  const fields = pick(PERSON_FIELDS_DATA, Object.keys(sectionValueDictionary)) as FieldsData<PersonFieldKeys>
  const validationSchema = useMemo(() => {
    const schema = generateSchema(fields, Object.keys(sectionMeta?.hiddenValues))
    if (slug === "admin-access") {
      schema.email = yup.string().email().required()
    }
    return yup.object().shape(schema)
  }, [fields])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          // Going forward, not all People will have emails or be auth_users. However, if an email is entered, create an auth user account under that email.
          if (values["email"]) {
            values = {...values, isAuthUser: true}
          }
          await updatePerson({ variables: { id: person.id, person: values } })
          setSubmitting(false)
          setOpen(false)
          toaster.add({ title: "Saved", description: `${person.firstName} was saved successfully`, intent: "success" })
        } catch (error) {
          console.error(error)
          toaster.add({
            title: error.message ? "Oops" : "Server Error",
            description: error.message || `There was an issue saving ${person.firstName}`,
            intent: "danger",
          })
        }
      }}
    >
      {({ isSubmitting, resetForm }) => (
        <FormikForm>
          <VStack space="large" css={{ $$space: "$space$3", paddingBlock: "$4" }}>
            {renderFormElements(sections)}
            <HStack space="medium" css={{ paddingTop: "$3" }}>
              <Button type="submit" color="primary" loading={isSubmitting}>
                Save Changes
              </Button>
              <Button
                onPress={() => {
                  resetForm()
                  setOpen(false)
                }}
                appearance="minimal"
              >
                Cancel Changes
              </Button>
            </HStack>
          </VStack>
        </FormikForm>
      )}
    </Formik>
  )
}

// MARK: Custom Layouts
type PersonAttributeCustomFieldsetProps = AttributeLayout & {
  slug: keyof typeof rmsSectionsData
}

function EmailPhoneFieldSet({ slug, meta, values }: PersonAttributeCustomFieldsetProps) {
  const fields = useAttributeLayouts({
    keys: Object.keys(values),
    defaultLayout: FormikTextField,
    values,
    meta,
    layouts: fieldDictionary,
    groups: {},
  })

  const emailFields = pick(fields, ["email", "memberDoNotEmail"])
  const phoneFields = pick(fields, ["memberCellPhone", "memberHomePhone", "memberDoNotCall", "authUserCustom2faNumber"])

  return (
    <VStack
      key={slug}
      space="large"
      css={{
        paddingTop: "$$space",
      }}
    >
      {!isEmpty(emailFields) && (
        <VStack as="fieldset">
          <Heading size="subHeadline">Email</Heading>
          {renderFormElements(emailFields)}
        </VStack>
      )}
      {!isEmpty(phoneFields) && (
        <VStack as="fieldset">
          <Heading size="subHeadline">Phone Number</Heading>
          {renderFormElements(phoneFields)}
        </VStack>
      )}
    </VStack>
  )
}

function NameFieldSet({ slug, title, meta, values }: PersonAttributeCustomFieldsetProps) {
  const fields = useAttributeLayouts({
    keys: Object.keys(values),
    defaultLayout: FormikTextField,
    values,
    meta,
    layouts: fieldDictionary,
    groups: {},
  })

  const [_] = useField(fields.memberTitle?.name)

  return (
    <VStack key={slug}>
      <Heading size="subHeadline">{title}</Heading>
      <Box css={{ display: "grid", gridTemplateColumns: "2fr 5fr 5fr 2fr", gap: "$2" }} as="fieldset">
        {renderFormElements(fields)}
      </Box>
    </VStack>
  )
}

export function FlexFieldSet({ slug, title, meta, values }: PersonAttributeCustomFieldsetProps) {
  const fields = useAttributeLayouts({
    keys: Object.keys(values),
    defaultLayout: FormikTextField,
    values,
    meta,
    layouts: fieldDictionary,
    groups: {},
  })

  return (
    <VStack key={slug} css={{ paddingTop: "$$space" }}>
      <Heading size="subHeadline">{title}</Heading>
      <Box as="fieldset" css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "$$space" }}>
        {renderFormElements(fields)}
      </Box>
    </VStack>
  )
}
