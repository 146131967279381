import { FormikSelectField, FormikTextArea, FormikTextField, FormikNumberField } from "@glasscanvas/elephantkit"
import { useField } from "formik"
import { ComponentProps } from "react"
import { Person, usePersonAvatarQuery } from "../graphql"
import { PersonAvatar } from "../person/person_avatar"
import { nameFormatter } from "./name_formatter"
import { FieldTypeWithContext } from "./types"
import { usePersonId } from "./usePerson"

// MARK: Multiple Choice
export type MultipleChoices = {
  custom_id: number | string
  val: string | number
}[]

interface MultipleChoiceField extends FieldTypeWithContext {
  part_type: "multiple_choice"
  choices: MultipleChoices
}

export function MultipleChoiceField({
  choices,
  ...props
}: ComponentProps<typeof FormikSelectField> & MultipleChoiceField) {
  return (
    <FormikSelectField {...props}>
      {choices?.map(({ custom_id, val }) => (
        <option key={custom_id} value={custom_id} children={val} />
      ))}
    </FormikSelectField>
  )
}

// MARK: Date
interface DateField extends FieldTypeWithContext {
  part_type: "date"
}
export function DateField(props: DateField) {
  return <FormikTextField type="date" {...props} />
}

// MARK: String
interface StringField extends FieldTypeWithContext {
  part_type: "string"
}
export function StringField(props: StringField) {
  return <FormikTextField type="text" {...props} />
}

// MARK: Text
interface TextAreaField extends FieldTypeWithContext {
  part_type: "text"
}
export function TextAreaField(props: TextAreaField) {
  return <FormikTextArea {...props} />
}

// MARK: Number
interface NumberField extends FieldTypeWithContext {
  part_type: "number"
}
export function NumberField(props: StringField) {
  return <FormikNumberField {...props} />
}

// MARK: Avatar
interface AvatarField extends FieldTypeWithContext {
  part_type: "image_id"
  value?: string
}
export function AvatarField(props: AvatarField) {
  const [field, _, helpers] = useField(props)
  const personId = usePersonId()

  const { data: urlData, loading: urlLoading } = usePersonAvatarQuery({
    variables: { id: props?.value, geometry: "50x50" },
    skip: !props?.value,
  })
  const { data: fullUrlData, loading: fullUrlLoading } = usePersonAvatarQuery({
    variables: { id: props.value, crop: false, geometry: "700x" },
    skip: !props?.value,
  })

  const avatarName = nameFormatter(props?.context?.values as unknown as Person, { suffix: false, title: false })

  return (
    <PersonAvatar
      personId={personId}
      loading={urlLoading || fullUrlLoading}
      name={field.name}
      fullName={avatarName}
      previewPhoto={urlData?.person?.authorPhoto}
      fullPhoto={fullUrlData?.person?.authorPhoto}
      size={20}
      onChange={({ id }) => helpers.setValue(id)}
    />
  )
}
