import React, { useContext } from "react";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import RRuleGenerator from "@rrule-generator";

// react-bootstrap
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


// custom
import SetTimeCustomInput from "./set-time-custom-input"
import LanguageCodes from "../service/language-codes"
import SpecialMassTypes from "../service/special-mass-types"
import { ScheduleDispatch } from "../schedule/schedule-reducer"
import AttachedConfession from "./attached-confession"
import Service from "../service/service"

export default (props) => {
  const dispatch = useContext(ScheduleDispatch);

  const { serviceAttrs } = props;

  const handleSubmit = values => {
    dispatch({type: 'update_service', serviceAttrs: values});
    dispatch({type: 'close_editor_modal'});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={new Service(serviceAttrs || {}).attrs}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {formik => (
        <Form className='text-left col-md-12' noValidate onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Form.Label className='font-weight-bold'>Event Name</Form.Label>
          </Form.Row>
          <Form.Row>
            <Col className='col-md-12'>
              <Form.Group controlId='formEventName'>
                <Form.Control
                  placeholder='Add a name for this event'
                  name='eventName'
                  type='eventName'
                  onChange={formik.handleChange}
                  value={formik.values.eventName || ""}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label className='font-weight-bold'>Start Time</Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md='6' controlId='validationstartTime'>
              <DatePicker
                name='startTime'
                type='startTime'
                /* DatePicker always edits in the Browser's timezone, we use UTC (Z) striclty. This converts to the timezone on init, then to UTC on save */
                selected={formik.values.startTime                        + new Date(formik.values.startTime).getTimezoneOffset() * 60 * 1000 }
                onChange={date => formik.setFieldValue("startTime", date - new Date(formik.values.startTime).getTimezoneOffset() * 60 * 1000)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                dateFormat='h:mm aa'
                customInput={<SetTimeCustomInput />}
              />
            </Form.Group>
          </Form.Row>
          {!['Christmas', 'Easter'].includes(props.scheduleType) ? null :
            <Form.Row>
              <Form.Group as={Col} md='12' controlId='validationSpecialMassType'>
                <InputGroup>
                  <Form.Control
                    name='specialMassType'
                    type='specialMassType'
                    as='select'
                    onChange={formik.handleChange}
                    value={formik.values.specialMassType}
                  >
                    <SpecialMassTypes scheduleType={props.scheduleType} />
                  </Form.Control>
                </InputGroup>
              </Form.Group>
            </Form.Row>
          }
          {formik.values.specialMassType && formik.values.specialMassType != 'regular' ? null :
            <Form.Row>
              <Form.Group as={Col} md='12' controlId='validationRecurrence'>
                <RRuleGenerator
                  onChange={rrule => formik.setFieldValue("recurrence", rrule)}
                  config={{
                    repeat: ['Weekly', 'Monthly', 'Yearly'],
                    yearly: 'on',
                    monthly: 'on the',
                    hideEnd: true,
                    hideError: false,
                  }}
                  value={formik.values.recurrence || ""}
                />
              </Form.Group>
            </Form.Row>
          }
          <Form.Row>
            <Form.Label className='font-weight-bold'>Language</Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md='6' controlId='validationLanguage'>
              <InputGroup>
                <Form.Control
                  name='language'
                  type='language'
                  as='select'
                  onChange={formik.handleChange}
                  value={formik.values.language || "en"}
                >
                  <LanguageCodes languageSet="main" />
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <AttachedConfession />
          <Form.Row>
            <Form.Label className='font-weight-bold'>Custom Label</Form.Label>
          </Form.Row>
          <Form.Row>
            <Col className='col-md-12'>
              <Form.Group controlId='formCustomLabel'>
                <Form.Control
                  placeholder='Add a custom description for this Mass'
                  name='description'
                  type='description'
                  onChange={formik.handleChange}
                  value={formik.values.description || ""}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <hr />
            <Button variant='primary' type='submit' className='mr-auto'>
              Save Changes
            </Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
}
