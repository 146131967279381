import { useState } from "react"
import { Card, Box, EmptyState, Heading, VStack, HStack, Text, Button, EditIcon } from "@glasscanvas/elephantkit"
import { RecordForm } from "./record_form"

export function RecordListItemNew() {
  const [open, setOpen] = useState(false)

  return (
    <Card
      border
      elevation={1}
      css={{
        backgroundColor: "$background",
        padding: "$9",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <VStack space="small">
        <HStack align="center" css={{ justifyContent: "space-between" }}>
          <Heading>Add New Record</Heading>
          {!open && (
            <Button onClick={() => setOpen(!open)} appearance="outline">
              Add
            </Button>
          )}
        </HStack>

        {open && <RecordForm setOpen={setOpen} />}
      </VStack>
    </Card>
  )
}

export function RecordListItem({ recordData }) {
  const [open, setOpen] = useState(false)

  return (
    <Card
      border
      elevation={1}
      css={{
        backgroundColor: "$background",
        padding: "$9",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <VStack space="small">
        <HStack align="center" css={{ justifyContent: "space-between" }}>
          <Heading>{recordData.recordType}</Heading>
          {!open && (
            <Button iconBefore={!open && EditIcon} onClick={() => setOpen(!open)} appearance="outline">
              Edit
            </Button>
          )}
        </HStack>

        {open && <RecordForm {...{ setOpen, recordData }} />}
      </VStack>
    </Card>
  )
}
