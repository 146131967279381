import { Utils } from "react-awesome-query-builder"

type FormPart = {
  part_type: string
  choices?: { val: string; custom_id: string }[]
}

function formPartTypeToQbType(form_part: FormPart) {
  // Omit: ['submit_btn', 'heading', 'paragraph', 'hr', 'spacer', 'payment', 'amount', 'credit_card', 'file_upload'] + todo_types,
  // TODO: ['name', 'address'] // don't know how we want to handle these at the moment
  const partTypeToQbType = Object.entries({
    text: ["string", "text", "province", "country"],
    number: [], // string w/ number validation
    datetime: ["datetime"],
    date: ["date"],
    time: ["time"],
    select: ["multiple_choice"],
    multiselect: [],
    treeselect: [],
    treemultiselect: [],
    boolean: [], // multiple_choice w/ single checkbox
  }).reduce((obj, [qb_type, part_types]) => {
    part_types.forEach((part_type) => {
      obj[part_type] = qb_type
    })
    return obj
  }, {})

  var result = partTypeToQbType[form_part["part_type"]]
  if (form_part["part_type"] == "multiple_choice" && form_part["choices"].length == 1) {
    result = "boolean"
  }
  // else if (form_part['part_type'] == 'string' && validation is 'number') {
  //   result = 'number'
  // }
  return result
}

function formPartToQbField(form_part: FormPart, qb_type: string) {
  var result = {
    label: form_part["label"],
    type: qb_type,
    valueSources: ["value"],
  }

  if (qb_type == "select") {
    result["listValues"] = form_part["choices"].map((choice_meta) => {
      var val =
        choice_meta.hasOwnProperty("custom_id") && choice_meta["custom_id"].toString().length > 0
          ? choice_meta["custom_id"]
          : choice_meta["val"]
      return { value: val, title: choice_meta["val"] }
    })
  }
  // fieldSettings: {min: 0, max: 100}
  // preferWidgets: ['number'],
  // operators: ['equal'],
  return result
}

function formbuilderPartsToQbFields(formbuilder_parts: FormPart[]) {
  return formbuilder_parts.reduce((obj, form_part) => {
    var qb_type = formPartTypeToQbType(form_part)
    if (qb_type) {
      obj[form_part["part_id"]] = formPartToQbField(form_part, qb_type)
    }
    return obj
  }, {})
}

function initQueryToRules(initQuery: Record<string, any>): Record<string, any> {
  let rules = {}

  Object.entries(initQuery).forEach(function (kv) {
    rules[Utils.uuid() as string] = {
      type: "rule",
      properties: {
        field: kv[0],
        valueType: [kv[1][0]],
        operator: kv[1][1],
        value: [kv[1][2]],
        valueSrc: ["value"],
      },
    }
  })

  return rules
}

function qbQueryToSearchkick(qb_rule: Record<string, any>): Record<string, any> {
  var sk_rule_new: Record<string, any> = null
  if (!qb_rule) return null
  var [qb_key, qb_val] = Object.entries(qb_rule)?.[0]
  if (["or", "and"].includes(qb_key)) {
    // 'not' ('!') doesn't work cleanly with searchkick rules, left it out of the UI
    sk_rule_new = {}
    sk_rule_new[`_${qb_key}`] = qb_val
      .map((qb_rule_nested: Record<string, any>) => {
        return qbQueryToSearchkick(qb_rule_nested)
      })
      .filter((sk_rule_nested: Record<string, any>) => {
        return sk_rule_nested
      })
  } else {
    var expect = null
    var expect_a: string[] = []
    var attr = null
    if (Array.isArray(qb_val)) {
      qb_val.forEach((arg) => {
        if (arg && typeof arg === "object" && arg["var"]) {
          attr = arg["var"]
        } else {
          expect = arg // For case insensitive search -> typeof arg === 'string' ? arg.toLowerCase() : arg
          expect_a.push(expect)
        }
      })
    } else if (qb_val["var"]) {
      attr = qb_val["var"]
    } else if (qb_key == "!") {
      return { _not: qbQueryToSearchkick(qb_val) }
    } else {
      throw "ERROR: unsupported rule: " + JSON.stringify(qb_rule)
    }

    sk_rule_new = {}

    switch (qb_key) {
      case "==":
        sk_rule_new[attr] = expect
        break
      case "!=":
        sk_rule_new[attr] = { not: expect }
        break
      case "in": // includes? array OR substring
        sk_rule_new[attr] = Array.isArray(expect) ? expect : { like: `%${expect}%` }
        break
      case "!": // val is empty OR (handled above) negate the sub query
        // sk_rule_new[attr] = {exists: false} // -> searchkick doesn't like this for some reason
        sk_rule_new["_not"] = {}
        sk_rule_new["_not"][attr] = { exists: true }
        break
      case "!!": // present
        sk_rule_new[attr] = { exists: true }
        break
      case "<=": // basic or between:       {basic: attr <= #0}   {between:   #0 <= attr <= #1       }
        sk_rule_new[attr] = expect_a.length < 2 ? { lte: expect } : { gte: expect_a[0], lte: expect_a[1] }
        break
      case "<":
        sk_rule_new[attr] = { lt: expect }
        break
      case ">=":
        sk_rule_new[attr] = { gte: expect }
        break
      case ">":
        sk_rule_new[attr] = { gt: expect }
        break
      default:
        console.error(`ERROR: unsupported rule (key): ${qb_key} in rule: ${JSON.stringify(qb_rule)}`)
    }
  }

  return sk_rule_new
}

export { formbuilderPartsToQbFields, formPartTypeToQbType, formPartToQbField, qbQueryToSearchkick, initQueryToRules }
