import { Box, HStack, Text } from "@glasscanvas/elephantkit"

type SeparatorProps = {
  /** Optionally add a title */
  title?: string
}

/**
 * Use this as a section separator. Has an optional title
 */
export function Separator({ title }: SeparatorProps) {
  const HeadingLine = () => <Box as="span" css={{ height: "1px", backgroundColor: "$gray3", width: "100%" }} />

  if (!title) {
    return <HeadingLine />
  }

  return (
    <HStack align="center">
      <HeadingLine />
      <Box css={{ padding: "0 $2", display: "flex", alignItems: "center" }}>
        <Text size="caption1" css={{ color: "$gray1", whiteSpace: "nowrap" }}>
          {title}
        </Text>
      </Box>
      <HeadingLine />
    </HStack>
  )
}
