import { Heading, Button, Card, HStack, VStack } from "@glasscanvas/elephantkit"
import { FormattedMessage } from "react-intl"
import { RmsSectionProps } from "../base"
import { PledgeEditor } from "@pledgeui"

export function PersonGiveNowSection({ slug, sectionMeta, yieldBottom }: RmsSectionProps) {
  return (
    <Card
      border
      elevation={1}
      css={{
        backgroundColor: "$background",
        padding: "$9",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <VStack space="large">
        <PledgeEditor initialFieldSet={"paymentAttrs"} pledge={{ transactionCents: 0, frequency: "once" }} />
      </VStack>
    </Card>
  )
}

