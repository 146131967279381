import ReactOnRails from "react-on-rails"
import { PledgeUi } from "./pledge_ui"
import { AmountScreen } from "./amount_screen"
import { ConfirmationScreen } from "./confirmation_screen"
import { DonationTypeScreen } from "./donation_type_screen"
import { MonthlyDonorPromptScreen } from "./monthly_donor_prompt_screen"
import { ThankYouScreen } from "./thank_you_screen"
import { PledgeEditor } from "./pledge_editor"
import { PledgeFieldAmount } from "./pledge_field_amount"
import { PledgeFieldCampaign } from "./pledge_field_campaign"
import { PledgeFieldCc, tokenizeCard } from "./pledge_field_cc"
import { PledgeFieldEmail } from "./pledge_field_email"
import { PledgeFieldFrequency } from "./pledge_field_frequency"

ReactOnRails.register({
  PledgeUi,
})

export { PledgeUi, AmountScreen, ConfirmationScreen, DonationTypeScreen, MonthlyDonorPromptScreen, ThankYouScreen, PledgeEditor, PledgeFieldAmount, PledgeFieldCampaign, PledgeFieldCc, tokenizeCard, PledgeFieldEmail, PledgeFieldFrequency }
