import { Card, Box, EmptyState, Heading, VStack, HStack, Text, Button } from "@glasscanvas/elephantkit"
import { usePersonId, useGetSacramentalRecordsQuery } from "@people"
import { Loading } from "@shared"
import { RecordListItem, RecordListItemNew } from "./record_list_item"

export function PersonSacramentalRecordsSection() {
  const personId = usePersonId()

  const { loading, error, data } = useGetSacramentalRecordsQuery({
    variables: { personId },
  })

  if (loading) return <Loading />

  if (error) {
    return <EmptyState title="Error: Permission Denied" description={error["graphQLErrors"][0]["message"]} />
  }

  return (
    <VStack align="center" space="large" css={{ padding: "0 $5", width: "100%" }}>
      <Box css={{ paddingBottom: "$5" }}>
        <Heading>Sacramental Records</Heading>
      </Box>

      <RecordListItemNew />

      {data.sacramentalRecords.records.map((record) => (
        <RecordListItem recordData={record} />
      ))}
    </VStack>
  )
}
