import {
  Avatar,
  Box,
  Button,
  Card,
  EditIcon,
  FormikForm,
  FormikTextField,
  Heading,
  HStack,
  Text,
  TrashIcon,
  useConfirm,
  useToaster,
  VStack,
} from "@glasscanvas/elephantkit"
import { Formik } from "formik"
import { pick, startCase } from "lodash-es"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "react-router-dom"
import { useLocation } from "react-use"
import * as Yup from "yup"
import { RmsSectionProps } from "../base"
import {
  GetPeopleDocument,
  GetPeopleQuery,
  GetPeopleQueryResult,
  Person,
  useCreatePersonMutation,
  useDeletePersonMutation,
  useGetPeopleQuery,
} from "../graphql"
import { nameFormatter, useAttributeLayouts, roleFormatter, usePerson } from "../shared"
import { PERSON_FIELDS_DATA } from "./fields_data"
import { fieldDictionary, FlexFieldSet, renderFormElements } from "./person_attribute_form"

function PersonFamilyDisplay({ people }: { people: Person[] }) {
  return (
    <Box css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
      {people?.map((person) => (
        <FamilyMemberProfile key={person.id} person={person} />
      ))}
    </Box>
  )
}

function FamilyMemberProfile({ person }: { person: Person }) {
  const { memberPhoto, firstName, lastName, id, globalRoles } = person
  const [deletePerson] = useDeletePersonMutation()
  const confirmDelete = useConfirm()
  const toaster = useToaster()
  const location = useLocation()

  async function deletePersonFunction() {
    try {
      await confirmDelete({
        children: `Are you sure you want to delete ${firstName}?`,
        intent: "danger",
        confirmLabel: "Delete",
      })
    } catch {
      return
    }

    try {
      await deletePerson({
        variables: { id },
        update: (cache) => {
          const peopleInCurrentFamily = cache.readQuery<GetPeopleQueryResult>({
            query: GetPeopleDocument,
            variables: { familyId: person.memberFamilyId },
          })
          const familyWithoutDeletedMember = peopleInCurrentFamily.data?.people?.people?.filter(
            (member) => member.id !== id
          )
          cache.writeQuery({
            query: GetPeopleDocument,
            variables: { familyId: person.memberFamilyId },
            data: { ...peopleInCurrentFamily.data, people: { people: familyWithoutDeletedMember } },
          })

          toaster.add({
            title: "Deleted",
            intent: "success",
            description: `Deleted ${firstName} ${lastName}`,
          })
        },
      })
    } catch (error) {
      console.error(error)
      toaster.add({ title: "Error", intent: "warning", description: error?.message })
    }
  }

  const fullName = nameFormatter(person, { title: false })
  const roleString = roleFormatter(person, { birthday: true, limit: { globalRoles: 1, inOrgRoles: 1 } })

  return (
    <HStack css={{ padding: "$2" }} align="center">
      <HStack as={Link} to={{ ...location, pathname: `/person/${id}` }} align="center" css={{ color: "$primary" }}>
        <Avatar src={memberPhoto?.src} name={fullName} size={10} />
        <VStack space="small">
          <Text size="caption1" css={{ fontWeight: "$bold" }}>
            {fullName}
          </Text>
          <Text size="caption2" css={{ color: "$secondary" }}>
            {roleString}
          </Text>
        </VStack>
      </HStack>

      {person?.globalRoles?.family?.includes("child") && (
        <Box css={{ marginInlineStart: "auto" }}>
          <Button icon={TrashIcon} onPress={deletePersonFunction} intent="danger" appearance="minimal">
            Delete
          </Button>
        </Box>
      )}
    </HStack>
  )
}

const FamilyValidations = Yup.object().shape({
  firstName: Yup.string().required("Cannot be blank"),
  lastName: Yup.string().required("Cannot be blank"),
  email: Yup.string().email("Email is invalid").notRequired(),
})

const FAMILY_FIELDS_DATA = pick(PERSON_FIELDS_DATA, ["firstName", "lastName", "email", "memberFamilyRole"])

function PersonFamilyForm({
  setOpen,
  familyName,
  familyId,
}: {
  setOpen: React.Dispatch<any>
  familyName: string
  familyId: number
}) {
  const [createPerson] = useCreatePersonMutation()

  const fields = useAttributeLayouts({
    keys: Object.keys(FAMILY_FIELDS_DATA),
    meta: FAMILY_FIELDS_DATA,
    layouts: { ...fieldDictionary, family: { component: FlexFieldSet, title: "Add a child or spouse" } },
    defaultLayout: FormikTextField,
    groups: Object.keys(FAMILY_FIELDS_DATA).reduce((obj, key) => {
      obj[key] = "family"
      return obj
    }, {}),
  })

  return (
    <Formik
      initialValues={{ firstName: "", lastName: familyName, email: "", memberFamilyRole: "Child" }}
      validationSchema={FamilyValidations}
      onSubmit={async (values, { setSubmitting }) => {
        await createPerson({
          // Assuming only children can be added for now
          variables: { person: { isMember: true, memberFamilyRole: "Child", memberFamilyId: familyId, ...values } },
          update: (cache, { data: { createPerson } }) => {
            const cachedData = cache.readQuery<GetPeopleQuery>({
              query: GetPeopleDocument,
              variables: { familyId },
            })
            if (cachedData) {
              const newData = {
                ...cachedData,
                people: { ...cachedData.people, people: cachedData.people.people.concat([createPerson]) },
              }
              cache.writeQuery({
                query: GetPeopleDocument,
                variables: { familyId },
                data: newData,
              })
            }
          },
        })
        setSubmitting(false)
        setOpen(false)
      }}
    >
      {({ isSubmitting, resetForm }) => (
        <FormikForm>
          <VStack space="large" css={{ $$space: "$space$3", paddingBlock: "$4" }}>
            {renderFormElements(fields)}
            <HStack space="medium" css={{ paddingBlockStart: "$3" }}>
              <Button type="submit" color="primary" loading={isSubmitting}>
                Add Family Member
              </Button>
              <Button
                onPress={() => {
                  resetForm()
                  setOpen(false)
                }}
                appearance="minimal"
              >
                Cancel
              </Button>
            </HStack>
          </VStack>
        </FormikForm>
      )}
    </Formik>
  )
}

export function PersonFamilySection() {
  // Get the right family
  const person = usePerson()
  const familyId = person?.memberFamilyId

  const [open, setOpen] = useState(false)
  const { data } = familyId ? useGetPeopleQuery({ variables: { familyId } }) : {}

  return (
    <Card
      border
      elevation={1}
      css={{
        backgroundColor: "$background",
        padding: "$9",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <VStack space="medium">
        <HStack align="center" css={{ justifyContent: "space-between" }}>
          <Heading>
            <FormattedMessage id={`rms.section.family-title`} defaultMessage="Family / Household" />
          </Heading>
          <Button iconBefore={!open && EditIcon} onClick={() => setOpen(!open)} appearance="outline">
            {open ? (
              <FormattedMessage id={`cancel`} defaultMessage="Cancel" />
            ) : (
              <FormattedMessage id={`family.edit_family`} defaultMessage="Add Family Member" />
            )}
          </Button>
        </HStack>
        {open && familyId ? (
          <PersonFamilyForm setOpen={setOpen} familyName={person?.lastName} familyId={familyId} />
        ) : (
          <PersonFamilyDisplay people={data?.people?.people ?? []} />
        )}
      </VStack>
    </Card>
  )
}
