import { FormikNumberField } from "@glasscanvas/elephantkit"
import { Pledge } from "@people"

type FieldProps = {
  pledge: Pledge
}

export function PledgeFieldAmount({ pledge }: FieldProps) {
  return (
    <FormikNumberField
      label="Amount"
      placeholder="Enter donation amount here"
      name="transactionCents"
      formatOptions={{ style: "currency", currency: "CAD" }}
    />
  )
}
