/** This file was generated with `r r script/misc/graphql_generate.rb` */

import type { FieldType } from "../shared"

function freezeFieldData<Key extends PropertyKey>(object: Record<Key, FieldType>): Record<Key, FieldType> {
  return Object.freeze(object)
}
export type PersonFieldKeys = keyof typeof PERSON_FIELDS_DATA

/** In the future, this data will probably come over GraphQL */
export const PERSON_FIELDS_DATA = freezeFieldData({
  "email": {
    "part_id": "email",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "email"
      }
    ],
    "persona": null,
    "attr_name": "email",
    "label": "Email Address",
    "graphql_type": "String"
  },
  "memberDoNotEmail": {
    "part_id": "member_do_not_email",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "do_not_email",
    "label": "Do not email",
    "graphql_type": "Boolean"
  },
  "firstName": {
    "part_id": "first_name",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "required"
      }
    ],
    "persona": null,
    "attr_name": "first_name",
    "label": "First Name",
    "graphql_type": "String"
  },
  "memberMiddleName": {
    "part_id": "member_middle_name",
    "part_type": "string",
    "persona": "member",
    "attr_name": "middle_name",
    "label": "Middle name",
    "graphql_type": "String"
  },
  "lastName": {
    "part_id": "last_name",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "required"
      }
    ],
    "persona": null,
    "attr_name": "last_name",
    "label": "Last Name",
    "graphql_type": "String"
  },
  "memberMaidenName": {
    "part_id": "member_maiden_name",
    "part_type": "string",
    "persona": "member",
    "attr_name": "maiden_name",
    "label": "Maiden name",
    "graphql_type": "String"
  },
  "status": {
    "part_id": "status",
    "part_type": "multiple_choice",
    "search": false,
    "persona": null,
    "attr_name": "status",
    "choices": [
      {
        "custom_id": "active",
        "val": "Active"
      },
      {
        "custom_id": "deceased",
        "val": "Deceased"
      },
      {
        "custom_id": "trash",
        "val": "Archived"
      }
    ],
    "label": "Status of the person",
    "graphql_type": "String"
  },
  "orgAccountStatus": {
    "part_id": "org_account_status",
    "part_type": "multiple_choice",
    "search": false,
    "persona": "org_account",
    "attr_name": "status",
    "choices": [
      {
        "custom_id": "active",
        "val": "Active"
      },
      {
        "custom_id": "inactive",
        "val": "Inactive"
      },
      {
        "custom_id": "moved_away",
        "val": "Moved away"
      }
    ],
    "label": "Status at the parish",
    "graphql_type": "String"
  },
  "orgStatuses": {
    "part_id": "org_statuses",
    "part_type": "multiple_choice",
    "choices": [

    ],
    "persona": null,
    "attr_name": "org_statuses",
    "label": "Status",
    "graphql_type": "String"
  },
  "orgRoleAspects": {
    "part_id": "org_role_aspects",
    "part_type": "multiple_choice",
    "choices": [

    ],
    "graphql_type": "[String]",
    "persona": null,
    "attr_name": "org_role_aspects",
    "label": "Role"
  },
  "isAdmin": {
    "part_id": "is_admin",
    "part_type": "checkbox",
    "search": false,
    "search_index": false,
    "persona": null,
    "attr_name": "is_admin",
    "label": "Grant administrative access",
    "graphql_type": "Boolean"
  },
  "isAuthUser": {
    "part_id": "is_auth_user",
    "part_type": "checkbox",
    "search": false,
    "search_index": false,
    "persona": null,
    "attr_name": "is_auth_user",
    "label": "Is auth user",
    "graphql_type": "Boolean"
  },
  "authUserTwoFactorEnabled": {
    "part_id": "auth_user_two_factor_enabled",
    "part_type": "multiple_choice",
    "search": false,
    "persona": "auth_user",
    "attr_name": "two_factor_enabled",
    "choices": [
      {
        "custom_id": "disabled",
        "val": "2FA Disabled"
      },
      {
        "custom_id": "email",
        "val": "Email"
      },
      {
        "custom_id": "sms",
        "val": "SMS"
      },
      {
        "custom_id": "call",
        "val": "Phone Call"
      }
    ],
    "label": "Two Factor Authentication type",
    "graphql_type": "String"
  },
  "authUserEnforceTwoFactor": {
    "part_id": "auth_user_enforce_two_factor",
    "part_type": "checkbox",
    "search": false,
    "persona": "auth_user",
    "attr_name": "enforce_two_factor",
    "label": "Enforce 2FA (GC support access only)",
    "graphql_type": "Boolean"
  },
  "authUserCustomTwoFactorAuthNumber": {
    "part_id": "auth_user_custom_two_factor_auth_number",
    "part_type": "string",
    "search": false,
    "placeholder": "Leave blank to use contact phone number",
    "persona": "auth_user",
    "attr_name": "custom_two_factor_auth_number",
    "label": "Custom 2FA Number",
    "graphql_type": "String"
  },
  "authUserLastSignInAt": {
    "part_id": "auth_user_last_sign_in_at",
    "part_type": "date",
    "persona": "auth_user",
    "attr_name": "last_sign_in_at",
    "label": "Last Login",
    "graphql_type": "GraphQL::Types::ISO8601Date"
  },
  "isMember": {
    "part_id": "is_member",
    "part_type": "checkbox",
    "search": false,
    "search_index": false,
    "persona": null,
    "attr_name": "is_member",
    "label": "Is member",
    "graphql_type": "Boolean"
  },
  "memberHaveChildren": {
    "part_id": "member_have_children",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "have_children",
    "label": "Has 1 or more children",
    "graphql_type": "Boolean"
  },
  "memberBaptised": {
    "part_id": "member_baptised",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "baptised",
    "label": "Baptised",
    "graphql_type": "Boolean"
  },
  "memberConfirmed": {
    "part_id": "member_confirmed",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "confirmed",
    "label": "Confirmed as Catholic?",
    "graphql_type": "Boolean"
  },
  "memberFirstCommunion": {
    "part_id": "member_first_communion",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "first_communion",
    "label": "First Communion",
    "graphql_type": "Boolean"
  },
  "memberMissionaryDiscipleCommitment": {
    "part_id": "member_missionary_disciple_commitment",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "missionary_disciple_commitment",
    "label": "Commitment to: Be a missionary disciple",
    "graphql_type": "Boolean"
  },
  "memberTrainingCommitment": {
    "part_id": "member_training_commitment",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "training_commitment",
    "label": "Commitment to: Training",
    "graphql_type": "Boolean"
  },
  "memberRunSeriesCommitment": {
    "part_id": "member_run_series_commitment",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "run_series_commitment",
    "label": "Commitment to: Run a faith series",
    "graphql_type": "Boolean"
  },
  "memberPrayerCommitment": {
    "part_id": "member_prayer_commitment",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "prayer_commitment",
    "label": "Commitment to: Pray for the Proclaim movement",
    "graphql_type": "Boolean"
  },
  "memberSiteTraining": {
    "part_id": "member_site_training",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "site_training",
    "label": "Training: General",
    "graphql_type": "Boolean"
  },
  "memberAlphaTraining": {
    "part_id": "member_alpha_training",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "alpha_training",
    "label": "Training: Alpha",
    "graphql_type": "Boolean"
  },
  "memberDiscoveryTraining": {
    "part_id": "member_discovery_training",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "discovery_training",
    "label": "Training: Discovery",
    "graphql_type": "Boolean"
  },
  "memberHostingAlpha": {
    "part_id": "member_hosting_alpha",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "hosting_alpha",
    "label": "Hosting: Alpha",
    "graphql_type": "Boolean"
  },
  "memberHostingDiscovery": {
    "part_id": "member_hosting_discovery",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "hosting_discovery",
    "label": "Hosting: Discovery",
    "graphql_type": "Boolean"
  },
  "memberBirthday": {
    "part_id": "member_birthday",
    "part_type": "date",
    "persona": "member",
    "attr_name": "birthday",
    "label": "Date of Birth",
    "graphql_type": "GraphQL::Types::ISO8601Date"
  },
  "memberBirthplace": {
    "part_id": "member_birthplace",
    "part_type": "string",
    "persona": "member",
    "attr_name": "birthplace",
    "label": "Birthplace",
    "graphql_type": "String"
  },
  "memberDateMarried": {
    "part_id": "member_date_married",
    "part_type": "date",
    "persona": "member",
    "attr_name": "date_married",
    "label": "Date of Wedding",
    "graphql_type": "GraphQL::Types::ISO8601Date"
  },
  "memberReligion": {
    "part_id": "member_religion",
    "part_type": "multiple_choice",
    "persona": "member",
    "attr_name": "religion",
    "choices": [
      {
        "custom_id": "catholic",
        "val": "Catholic"
      },
      {
        "custom_id": "evangelical",
        "val": "Evangelical"
      },
      {
        "custom_id": "orthodox",
        "val": "Orthodox (Eastern, Greek, Russian)"
      },
      {
        "custom_id": "jewish",
        "val": "Jewish"
      },
      {
        "custom_id": "muslim",
        "val": "Muslim"
      },
      {
        "custom_id": "sikh",
        "val": "Sikh"
      },
      {
        "custom_id": "hindu",
        "val": "Hindu"
      },
      {
        "custom_id": "buddhist",
        "val": "Buddhist"
      },
      {
        "custom_id": "mormon",
        "val": "Mormon"
      },
      {
        "custom_id": "agnostic",
        "val": "Atheist/Agnostic"
      },
      {
        "custom_id": "non_religious",
        "val": "Non-religious"
      },
      {
        "custom_id": "other",
        "val": "Religion (Other Option)"
      }
    ],
    "label": "Religion",
    "graphql_type": "String"
  },
  "memberMaritalStatus": {
    "part_id": "member_marital_status",
    "part_type": "multiple_choice",
    "persona": "member",
    "attr_name": "marital_status",
    "choices": [
      {
        "custom_id": "single",
        "val": "Single"
      },
      {
        "custom_id": "married",
        "val": "Married"
      },
      {
        "custom_id": "divorced_with_annulment",
        "val": "Divorced with Annulment"
      },
      {
        "custom_id": "divorced_with_no_annulment",
        "val": "Divorced with no Annulment"
      },
      {
        "custom_id": "separated",
        "val": "Separated"
      },
      {
        "custom_id": "widowed",
        "val": "Widowed"
      }
    ],
    "label": "Marital Status",
    "graphql_type": "String"
  },
  "memberGender": {
    "part_id": "member_gender",
    "part_type": "multiple_choice",
    "persona": "member",
    "attr_name": "gender",
    "choices": [
      {
        "custom_id": "Male",
        "val": "Male"
      },
      {
        "custom_id": "Female",
        "val": "Female"
      }
    ],
    "label": "Gender",
    "graphql_type": "String"
  },
  "memberChurchWhereMarried": {
    "part_id": "member_church_where_married",
    "part_type": "string",
    "persona": "member",
    "attr_name": "church_where_married",
    "label": "Church Name",
    "graphql_type": "String"
  },
  "memberCityWhereMarried": {
    "part_id": "member_city_where_married",
    "part_type": "string",
    "persona": "member",
    "attr_name": "city_where_married",
    "label": "City",
    "graphql_type": "String"
  },
  "memberCountryWhereMarried": {
    "part_id": "member_country_where_married",
    "part_type": "string",
    "persona": "member",
    "attr_name": "country_where_married",
    "label": "Country",
    "graphql_type": "String"
  },
  "memberSchoolName": {
    "part_id": "member_school_name",
    "part_type": "string",
    "persona": "member",
    "attr_name": "school_name",
    "label": "School",
    "graphql_type": "String"
  },
  "memberSchoolGrade": {
    "part_id": "member_school_grade",
    "part_type": "string",
    "validationType": "number",
    "validations": [
      {
        "type": "max",
        "params": [
          13
        ]
      }
    ],
    "persona": "member",
    "attr_name": "school_grade",
    "label": "Grade",
    "graphql_type": "String"
  },
  "memberReligionOther": {
    "part_id": "member_religion_other",
    "part_type": "string",
    "when_blank": "hide",
    "persona": "member",
    "attr_name": "religion_other",
    "label": "Religion (Other Option)",
    "graphql_type": "String"
  },
  "memberHomePhone": {
    "part_id": "member_home_phone",
    "part_type": "string",
    "persona": "member",
    "attr_name": "home_phone",
    "label": "Home Phone",
    "graphql_type": "String"
  },
  "memberCellPhone": {
    "part_id": "member_cell_phone",
    "part_type": "string",
    "persona": "member",
    "attr_name": "cell_phone",
    "label": "Cell Phone",
    "graphql_type": "String"
  },
  "memberDoNotCall": {
    "part_id": "member_do_not_call",
    "part_type": "checkbox",
    "persona": "member",
    "attr_name": "do_not_call",
    "label": "Do not call",
    "graphql_type": "Boolean"
  },
  "memberOccupation": {
    "part_id": "member_occupation",
    "part_type": "string",
    "persona": "member",
    "attr_name": "occupation",
    "label": "Occupation",
    "graphql_type": "String"
  },
  "memberPhotoId": {
    "part_id": "member_photo_id",
    "part_type": "ID",
    "search": false,
    "persona": "member",
    "attr_name": "photo_id",
    "label": "Photo",
    "graphql_type": "ID"
  },
  "memberPhoto": {
    "part_id": "member_photo",
    "part_type": "image",
    "search": false,
    "search_index": false,
    "persona": "member",
    "attr_name": "photo",
    "label": "Photo",
    "graphql_type": "resolver: Resolvers::ImageResolver"
  },
  "memberTitle": {
    "part_id": "member_title",
    "part_type": "multiple_choice",
    "choices": [
      {
        "val": "Mr.",
        "custom_id": "Mr."
      },
      {
        "val": "Mrs.",
        "custom_id": "Mrs."
      },
      {
        "val": "Ms.",
        "custom_id": "Ms."
      },
      {
        "val": "Miss",
        "custom_id": "Miss"
      },
      {
        "val": "Sr.",
        "custom_id": "Sister"
      },
      {
        "val": "Br.",
        "custom_id": "Brother"
      },
      {
        "val": "Deacon",
        "custom_id": "Deacon"
      },
      {
        "val": "Fr.",
        "custom_id": "Father"
      },
      {
        "val": "Msgr.",
        "custom_id": "Monsignor"
      },
      {
        "val": "Rev.",
        "custom_id": "Reverend"
      },
      {
        "val": "Very Reverend",
        "custom_id": "Very Reverend"
      },
      {
        "val": "Bp.",
        "custom_id": "Bishop"
      },
      {
        "val": "Archbp.",
        "custom_id": "Archbishop"
      },
      {
        "val": "Cardinal",
        "custom_id": "Cardinal"
      },
      {
        "val": "Cardinal-designate",
        "custom_id": "Cardinal-designate"
      },
      {
        "val": "Pope",
        "custom_id": "Pope"
      },
      {
        "val": "Dr.",
        "custom_id": "Doctor"
      }
    ],
    "search": false,
    "persona": "member",
    "attr_name": "title",
    "label": "Title",
    "graphql_type": "String"
  },
  "memberSuffix": {
    "part_id": "member_suffix",
    "part_type": "string",
    "search": false,
    "persona": "member",
    "attr_name": "suffix",
    "label": "Suffix",
    "graphql_type": "String"
  },
  "memberWebsiteUrl": {
    "part_id": "member_website_url",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "matches",
        "params": [
          "^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(.[a-zA-Z]+)+((/)[w#]+)*(/w+?[a-zA-Z0-9_]+=w+(&[a-zA-Z0-9_]+=w+)*)?$",
          "Must be a valid URL"
        ]
      }
    ],
    "persona": "member",
    "attr_name": "website_url",
    "label": "Website URL",
    "graphql_type": "String"
  },
  "memberFacebookUrl": {
    "part_id": "member_facebook_url",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "matches",
        "params": [
          "^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(.[a-zA-Z]+)+((/)[w#]+)*(/w+?[a-zA-Z0-9_]+=w+(&[a-zA-Z0-9_]+=w+)*)?$",
          "Must be a valid URL"
        ]
      }
    ],
    "persona": "member",
    "attr_name": "facebook_url",
    "label": "Facebook URL",
    "graphql_type": "String"
  },
  "memberTwitterUrl": {
    "part_id": "member_twitter_url",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "matches",
        "params": [
          "^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(.[a-zA-Z]+)+((/)[w#]+)*(/w+?[a-zA-Z0-9_]+=w+(&[a-zA-Z0-9_]+=w+)*)?$",
          "Must be a valid URL"
        ]
      }
    ],
    "persona": "member",
    "attr_name": "twitter_url",
    "label": "Twitter URL",
    "graphql_type": "String"
  },
  "memberInstagramUrl": {
    "part_id": "member_instagram_url",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "matches",
        "params": [
          "^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(.[a-zA-Z]+)+((/)[w#]+)*(/w+?[a-zA-Z0-9_]+=w+(&[a-zA-Z0-9_]+=w+)*)?$",
          "Must be a valid URL"
        ]
      }
    ],
    "persona": "member",
    "attr_name": "instagram_url",
    "label": "Instagram URL",
    "graphql_type": "String"
  },
  "memberFamilyRole": {
    "part_id": "member_family_role",
    "part_type": "multiple_choice",
    "persona": "member",
    "attr_name": "family_role",
    "choices": [
      {
        "custom_id": "Parent",
        "val": "Spouse"
      },
      {
        "custom_id": "Child",
        "val": "Child"
      }
    ],
    "label": "Family Role",
    "graphql_type": "String"
  },
  "memberFamilyId": {
    "part_id": "member_family_id",
    "part_type": "number",
    "search": false,
    "persona": "member",
    "attr_name": "family_id",
    "label": "Family",
    "graphql_type": "Int"
  },
  "memberNamePreference": {
    "part_id": "member_name_preference",
    "part_type": "multiple_choice",
    "search": false,
    "persona": "member",
    "attr_name": "name_preference",
    "choices": [
      {
        "custom_id": "first_only",
        "val": "First Name Only"
      },
      {
        "custom_id": "last_only",
        "val": "Last Name Only"
      },
      {
        "custom_id": "show_full_name",
        "val": "Show Full Name"
      }
    ],
    "label": "Name Preference",
    "graphql_type": "String"
  },
  "addressAddress": {
    "part_id": "address_address",
    "part_type": "string",
    "persona": "address",
    "attr_name": "address",
    "label": "Street Address",
    "graphql_type": "String"
  },
  "addressAddress2": {
    "part_id": "address_address2",
    "part_type": "string",
    "persona": "address",
    "attr_name": "address2",
    "label": "Address Line 2 (Optional)",
    "graphql_type": "String"
  },
  "addressCity": {
    "part_id": "address_city",
    "part_type": "string",
    "persona": "address",
    "attr_name": "city",
    "label": "City",
    "graphql_type": "String"
  },
  "addressStateProvince": {
    "part_id": "address_state_province",
    "part_type": "string",
    "persona": "address",
    "attr_name": "state_province",
    "label": "State or Province",
    "graphql_type": "String"
  },
  "addressPostalCode": {
    "part_id": "address_postal_code",
    "part_type": "string",
    "persona": "address",
    "attr_name": "postal_code",
    "label": "Postal Code",
    "graphql_type": "String"
  },
  "addressCountry": {
    "part_id": "address_country",
    "part_type": "string",
    "persona": "address",
    "attr_name": "country",
    "label": "Country",
    "graphql_type": "String"
  },
  "financialProfileEnvelopeNumber": {
    "part_id": "financial_profile_envelope_number",
    "part_type": "number",
    "persona": "financial_profile",
    "attr_name": "envelope_number",
    "label": "Envelope Number",
    "graphql_type": "Int"
  },
  "financialProfileCurrentPaymentMethod": {
    "part_id": "financial_profile_current_payment_method",
    "part_type": "multiple_choice",
    "persona": "financial_profile",
    "attr_name": "current_payment_method",
    "choices": [
      {
        "custom_id": "none",
        "val": "I'm giving for the first time"
      },
      {
        "custom_id": "envelope",
        "val": "Cash or cheque (envelope)"
      },
      {
        "custom_id": "irregular",
        "val": "Irregular donations"
      },
      {
        "custom_id": "direct_deposit",
        "val": "Direct deposit"
      }
    ],
    "label": "How did you usually donate in the past?",
    "graphql_type": "String"
  },
  "financialProfileWeeklyDollarsHistoricalEstimate": {
    "part_id": "financial_profile_weekly_dollars_historical_estimate",
    "part_type": "number",
    "persona": "financial_profile",
    "attr_name": "weekly_dollars_historical_estimate",
    "label": "Estimate of what you typically donate weekly",
    "graphql_type": "Int"
  },
  "isTeamMember": {
    "part_id": "is_team_member",
    "part_type": "checkbox",
    "search": false,
    "search_index": false,
    "persona": null,
    "attr_name": "is_team_member",
    "label": "Is team member",
    "graphql_type": "Boolean"
  },
  "teamMemberJobTitle": {
    "part_id": "team_member_job_title",
    "part_type": "multiple_choice",
    "persona": "team_member",
    "attr_name": "job_title",
    "choices": [
      {
        "custom_id": "pastor",
        "val": "Pastor"
      },
      {
        "custom_id": "assistant_pastor",
        "val": "Assistant Pastor"
      },
      {
        "custom_id": "deacon",
        "val": "Deacon"
      },
      {
        "custom_id": "secretary",
        "val": "Secretary"
      },
      {
        "custom_id": "leadership_team",
        "val": "Leadership Team"
      },
      {
        "custom_id": "administrator",
        "val": "Administrator"
      },
      {
        "custom_id": "in_residence",
        "val": "In Residence"
      }
    ],
    "label": "Job Title",
    "graphql_type": "String"
  },
  "teamMemberCustomJobTitle": {
    "part_id": "team_member_custom_job_title",
    "part_type": "string",
    "persona": "team_member",
    "attr_name": "custom_job_title",
    "label": "Custom job title",
    "graphql_type": "String"
  },
  "teamMemberDisplayEmail": {
    "part_id": "team_member_display_email",
    "part_type": "checkbox",
    "search": false,
    "persona": "team_member",
    "attr_name": "display_email",
    "label": "Display email address on contact page",
    "graphql_type": "Boolean"
  },
  "teamMemberBio": {
    "part_id": "team_member_bio",
    "part_type": "text",
    "search": false,
    "persona": "team_member",
    "attr_name": "bio",
    "label": "Bio",
    "graphql_type": "String"
  },
  "teamMemberDisplayMember": {
    "part_id": "team_member_display_member",
    "part_type": "checkbox",
    "search": false,
    "persona": "team_member",
    "attr_name": "display_member",
    "label": "Display member under 'Our Team' on contact page",
    "graphql_type": "Boolean"
  },
  "isAuthor": {
    "part_id": "is_author",
    "part_type": "checkbox",
    "search": false,
    "search_index": false,
    "persona": null,
    "attr_name": "is_author",
    "label": "Is author",
    "graphql_type": "Boolean"
  },
  "authorBio": {
    "part_id": "author_bio",
    "part_type": "text",
    "search": false,
    "persona": "author",
    "attr_name": "bio",
    "label": "Bio",
    "graphql_type": "String"
  },
  "authorTwitterHandle": {
    "part_id": "author_twitter_handle",
    "part_type": "string",
    "persona": "author",
    "attr_name": "twitter_handle",
    "label": "Twitter Handle",
    "graphql_type": "String"
  },
  "authorWebsiteUrl": {
    "part_id": "author_website_url",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "url"
      }
    ],
    "persona": "author",
    "attr_name": "website_url",
    "label": "Website URL",
    "graphql_type": "String"
  },
  "authorFacebookUrl": {
    "part_id": "author_facebook_url",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "url"
      }
    ],
    "persona": "author",
    "attr_name": "facebook_url",
    "label": "Facebook URL",
    "graphql_type": "String"
  },
  "authorInstagramUrl": {
    "part_id": "author_instagram_url",
    "part_type": "string",
    "validationType": "string",
    "validations": [
      {
        "type": "url"
      }
    ],
    "persona": "author",
    "attr_name": "instagram_url",
    "label": "Instagram URL",
    "graphql_type": "String"
  },
  "authorIsContentProvider": {
    "part_id": "author_is_content_provider",
    "part_type": "checkbox",
    "persona": "author",
    "attr_name": "is_content_provider",
    "label": "This is a Content Provider (an organization, maybe with multiple authors)",
    "graphql_type": "Boolean"
  },
  "authorVoiceIndexVisibility": {
    "part_id": "author_voice_index_visibility",
    "part_type": "multiple_choice",
    "persona": "author",
    "attr_name": "voice_index_visibility",
    "choices": [
      {
        "custom_id": "auto",
        "val": "Voice index visibility auto"
      },
      {
        "custom_id": "show",
        "val": "Voice index visibility show"
      },
      {
        "custom_id": "hide",
        "val": "Voice index visibility hide"
      }
    ],
    "label": "Author visibilty on Voices index",
    "graphql_type": "String"
  },
  "authorPhotoId": {
    "part_id": "author_photo_id",
    "part_type": "image_id",
    "search": false,
    "graphql_type": "ID",
    "persona": "author",
    "attr_name": "photo_id",
    "label": "Photo"
  },
  "authorPhoto": {
    "part_id": "author_photo",
    "part_type": "image",
    "search": false,
    "search_index": false,
    "persona": "author",
    "attr_name": "photo",
    "label": "Photo",
    "graphql_type": "resolver: Resolvers::ImageResolver"
  },
  "authorAnonymous": {
    "part_id": "author_anonymous",
    "part_type": "checkbox",
    "persona": "author",
    "attr_name": "anonymous",
    "label": "Anonymous",
    "graphql_type": "Boolean"
  },
  "isSubscriber": {
    "part_id": "is_subscriber",
    "part_type": "checkbox",
    "search": false,
    "search_index": false,
    "persona": null,
    "attr_name": "is_subscriber",
    "label": "Is subscriber",
    "graphql_type": "Boolean"
  }
})
