export * from "./graphql"
export * from "./people_index"
export * from "./base/apollo_client"
export * from "./base/utils"
export * from "./shared"
import { fieldDictionary, FlexFieldSet, renderFormElements } from "./person/person_attribute_form"
import { selectKeys } from "./base/utils"
import { RmsApp } from "./base/rms_app"

import ReactOnRails from "react-on-rails"

ReactOnRails.register({
  RmsApp,
})

export { fieldDictionary, FlexFieldSet, renderFormElements, selectKeys }
