import React, { Fragment } from "react";

// custom components
import Service from "../service/service";
import ServiceModRow from "./service-mod-row";

export default ({ serviceMods }) => {
  return (
    <Fragment>
      <h2>Cancellations and changes</h2>
      {serviceMods.map((mod) =>
        <ServiceModRow key={mod.uid} serviceMod={mod} />
      )}
    </Fragment>
  )
};

