import { startCase } from "lodash-es"
import { DateTime } from "luxon"
import { useMemo } from "react"
import warning from "warning"
import { FieldType } from "./types"

type Formatter = (value: string | number | boolean) => string
const defaultFormatter: Formatter = (value: string) => value
const defaultDateFormatter = (date: string) => (date ? DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL) : "")

type AttributeLayoutHookParams<AttributesObject = {}> = {
  keys: string[]
  meta: Record<keyof AttributesObject, FieldType>
  groups?: Record<string, string>
  layouts: { [key: string]: Pick<AttributeLayout, "title" | "component"> }
  hiddenAttributes?: string[]
  /** Adds formatting for specific `part_type`s */
  formatters?: Partial<Record<FieldType["part_type"], Formatter>>
  values?: AttributesObject
  defaultLayout: ({}: any) => JSX.Element
}
export type AttributeLayout = {
  title?: string
  name?: string
  value?: any
  values?: {}
  meta?: Record<string, FieldType> | FieldType
  context?: { meta: Record<string, FieldType> | FieldType; values: {} }
  __grouped?: boolean
  component: ({}: any) => JSX.Element
} & Partial<FieldType>

export function useAttributeLayouts({
  keys = [],
  meta = {},
  groups = {},
  layouts = {},
  values = {},
  hiddenAttributes = [],
  formatters = { date: defaultDateFormatter },
  defaultLayout,
}: AttributeLayoutHookParams): { [key: string]: AttributeLayout } {
  return useMemo(() => {
    const groupLayouts: { [group: string]: AttributeLayout } = {}
    // Loop over each key
    for (const key of keys) {
      if (hiddenAttributes.includes(key)) continue
      // Look for a potential group
      const group = groups[key]

      const formatter = formatters[meta?.[key]?.["part_type"]] ?? defaultFormatter
      const fieldMeta = meta?.[key] as FieldType

      // If this key belongs to a group let's group it together with its sister keys
      if (group) {
        const groupLayout = layouts[group]
        warning(!!groupLayout, `No group layout found for group: ${group}`)

        if (groupLayouts[group]) {
          groupLayouts[group] = {
            ...groupLayouts[group],
            values: { ...groupLayouts[group].values, [key]: formatter(values[key]) },
            meta: { ...groupLayouts[group].meta, [key]: meta[key] },
          }
        } else {
          groupLayouts[group] = {
            ...groupLayout,
            __grouped: true,
            values: { [key]: values[key] },
            meta: { [key]: fieldMeta },
          }
        }
      } else {
        const layout = layouts[meta?.[key]?.["part_type"]]?.component ?? defaultLayout
        const fieldMeta = meta?.[key] as FieldType
        groupLayouts[key] = {
          component: layout,
          placeholder: fieldMeta?.placeholder || fieldMeta?.label,
          meta: fieldMeta,
          ...fieldMeta,
          name: key,
          title: startCase(key),
          value: formatter(values[key]),
          context: { meta, values },
        }
      }
    }

    return groupLayouts
  }, [keys, values])
}
