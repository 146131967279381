import React from "react";

export const themes = {
  editor_default: {
    theme: 'editor',
  },

  viewer_default: {
    theme: 'viewer'
  }
};

export default React.createContext(themes.editor_default);
