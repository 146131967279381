import ReactOnRails from "react-on-rails";

const _file = 'GlassLoadReactOnAjax';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassLoadReactOnAjax = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    if ($(element).find('.js-react-on-rails-component').length > 0) {
      ReactOnRails.reactOnRailsPageLoaded()
    }
  });

  // Return API for other modules
  return {
  };
})(jQuery);
