export type TableColumn = {
  key: string,
  header: string
}

export type PeopleIndexState = {
  searchStr: string
  queryId: string
  pageNum: number
  rowsPerPage: number
  sortAscending: boolean
  sortBy: string
  columns: TableColumn[]
}

export type PeopleAction =
  | { type: "nextPage" }
  | { type: "prevPage" }
  | { type: "setPageNum"; page: PeopleIndexState["pageNum"] }
  | { type: "setRowsPerPage"; rows: PeopleIndexState["rowsPerPage"] }
  | { type: "doSearch"; searchStr: PeopleIndexState["searchStr"] }
  | { type: "setQuery"; query: PeopleIndexState["queryId"] }
  | { type: "clearQuery" }
  | { type: "setSortBy"; columnName: PeopleIndexState["columns"][0]["key"] }
  | { type: "toggleSortDirection" }
  | { type: "addColumns"; newColumns: PeopleIndexState["columns"] }
  | { type: "removeColumns"; columnsToRemove: PeopleIndexState["columns"] }
  | { type: "setColumns"; columns: PeopleIndexState["columns"] }

export const peopleIndexReducer = (state: PeopleIndexState, action: PeopleAction) => {
  switch (action.type) {
    case "nextPage":
      return { ...state, pageNum: state.pageNum + 1 }
    case "prevPage":
      return { ...state, pageNum: state.pageNum - 1 }
    case "setPageNum":
      return { ...state, pageNum: action.page }
    case "setRowsPerPage":
      return { ...state, rowsPerPage: action.rows }
    case "doSearch":
      return { ...state, searchStr: action.searchStr }
    case "setQuery":
      return { ...state, queryId: action.query }
    case "clearQuery":
      return { ...state, queryId: 0 }
    case "setSortBy":
      return { ...state, sortBy: action.columnName }
    case "toggleSortDirection":
      return { ...state, sortAscending: !state.sortAscending }
    case "addColumns":
      return { ...state, columns: state.columns.concat(action.newColumns) }
    case "removeColumns":
      return { ...state, columns: state.columns.concat(action.columnsToRemove) }
    case "setColumns":
      return { ...state, columns: action.columns }
  }
}
