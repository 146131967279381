import {
  Button,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  HStack,
  Select,
  Text,
} from "@glasscanvas/elephantkit"
import { ComponentProps } from "react"
import { useHistory, useLocation } from "react-router-dom"

const PaginationButton = (props: ComponentProps<typeof Button>) => (
  <Button appearance="minimal" {...props} css={{ backgroundColor: "transparent", size: "$7", padding: "0" }} />
)

type PaginationProps = {
  totalCount: number
}

// Use the totalCount from any GraphQL query (e.g. for people, charges, ...)
export function Pagination({ totalCount }: PaginationProps) {
  const { search, ...location } = useLocation()
  const { replace } = useHistory()
  const urlParams = new URLSearchParams(search)

  const perPage = Number(urlParams.get("per_page") ?? 20)
  const pageNum = Number(urlParams.get("page") ?? 1)

  const totalPages = Math.ceil(totalCount / perPage)
  const startAt = Math.max(1, Math.min(pageNum - 2, totalPages - 4))
  const endAt = Math.min(totalPages, Math.max(pageNum + 2, 5))
  const pageNumberButtons = Array(endAt - startAt + 1)
    .fill(undefined)
    .map((_, index) => index + startAt)

  function toPage(page: number) {
    urlParams.set("page", String(page))
    replace({ ...location, search: urlParams.toString() })
  }

  function setRowsPerPage(rows: number) {
    urlParams.set("per_page", String(rows))
    replace({ ...location, search: urlParams.toString() })
  }

  return (
    <HStack
      space="extraSmall"
      align="center"
      css={{
        background: "$gray6",
        color: "$secondary",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "$4",

        a: {
          color: "$secondary",
        },
      }}
    >
      <PaginationButton icon={ChevronDoubleLeftIcon} onClick={() => toPage(1)} />
      <PaginationButton icon={ChevronLeftIcon} onClick={() => toPage(pageNum - 1)} />

      {pageNumberButtons.map((i) => (
        <PaginationButton
          key={`pagination_button_${i}`}
          appearance={pageNum === i ? "outline" : "minimal"}
          onClick={() => toPage(i)}
          children={i}
        />
      ))}

      <PaginationButton icon={ChevronRightIcon} onClick={() => toPage(pageNum + 1)} />
      <PaginationButton icon={ChevronDoubleRightIcon} onClick={() => toPage(totalPages)} />
      <HStack>
        <Select css={{ minWidth: "$14" }} value={perPage} onChange={(e) => setRowsPerPage(Number(e.target.value))}>
          {[5, 10, 20, 50].map((perPage) => (
            <option key={perPage} value={perPage}>
              {perPage}
            </option>
          ))}
        </Select>
        <Text
          css={{
            margin: "$2",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {` ${1 + (pageNum - 1) * perPage} - ${Math.min(pageNum * perPage, totalCount)} of ${
            totalCount
          }`}
        </Text>
      </HStack>
    </HStack>
  )
}
