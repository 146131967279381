import { StackingOrder, StackingProvider, Tab, TabList, TabRoot } from "@glasscanvas/elephantkit"
import { startCase } from "lodash-es"
import { FormattedMessage } from "react-intl"
import { matchPath, NavLink, useLocation, useRouteMatch } from "react-router-dom"
import { getSectionMeta } from "../base"
import { usePerson } from "../shared"

export function PersonNav({ subSections }: { subSections: string[] }) {
  const { url } = useRouteMatch()
  const { pathname, ...location } = useLocation()
  const person = usePerson()

  return (
    <StackingProvider value={StackingOrder.DEFAULT}>
      {(zIndex) => (
        <TabRoot
          css={{
            backgroundColor: "$background",
            paddingInline: "$6",
            position: "sticky",
            top: 0,
            borderBottom: "1px solid $gray5",
            zIndex,
          }}
        >
          <TabList>
            {subSections.map((subSlug) => {
              const to = `${url}/${subSlug}`
              const navTitle = getSectionMeta(subSlug, person).navTitle
              return (
                <NavLink to={{ ...location, pathname: to }} key={subSlug}>
                  <Tab className={matchPath(pathname, { path: to }) ? "active" : ""} value={to}>
                    <FormattedMessage id={`rms_nav.${subSlug}`} defaultMessage={navTitle || startCase(subSlug)} />
                  </Tab>
                </NavLink>
              )
            })}
          </TabList>
        </TabRoot>
      )}
    </StackingProvider>
  )
}
