import { ApolloClient, FieldReadFunction, HttpLink, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { isEmpty } from "lodash-es"
import { GetDomainOrgDocument } from "../graphql"
import { handleGenericError } from "@shared"

// Reference: https://www.apollographql.com/docs/react/caching/cache-field-behavior/
// This one is here for backwards compatibility.
function roleAspectRead(_: any, { fieldName, readField, cache }: Parameters<FieldReadFunction>[1]) {
  const domainOrgInfo = cache.readQuery({ query: GetDomainOrgDocument })
  if (!domainOrgInfo) {
    return false
  }
  const { domainOrg } = domainOrgInfo
  const regexToFind = String(domainOrg.id) + "-"
  const theRoles = (readField("orgRoleAspects") as string[])
    ?.filter((str) => str.startsWith(regexToFind) || str.startsWith("0-"))
    .map((role) => role.replace(regexToFind, ""))

  if (isEmpty(theRoles)) {
    return false
  }

  const roleKey = {
    isAuthor: "author",
    isAdmin: "admin",
    isSubscriber: "subscriber",
    isTeamMember: "team-member",
    isOnlineMember: "online-member",
    isMemberRegistered: "member-registered",
    isNonPrimaryParish: "non-primary-parish",
  }[fieldName]
  return theRoles.includes(roleKey)
}

const DEFAULT_ROLES: Record<string, string[]> = {
  parish: [],
  monetary: [],
  staff: [],
  admin: [],
  family: [],
  inactiveStatus: null,
}

// The new function that extracts all roles in this organization from the GraphQL output into an array like ["author", "admin"] ...
function findInOrgRoles(_: Parameters<FieldReadFunction>[0], { readField, cache }: Parameters<FieldReadFunction>[1]) {
  const domainOrgInfo = cache.readQuery({ query: GetDomainOrgDocument })
  if (!domainOrgInfo) {
    return DEFAULT_ROLES
  }
  const { domainOrg } = domainOrgInfo
  const regexToFind = String(domainOrg.id) + "-"
  const inOrgRoles =
    (readField("orgRoleAspects") as string[])
      ?.filter((str) => str.startsWith(regexToFind))
      .map((role) => role.replace(regexToFind, "")) || []

  if (isEmpty(inOrgRoles)) {
    return DEFAULT_ROLES
  }

  return {
    ...DEFAULT_ROLES,
    parish: sortRoles(inOrgRoles, "parish") ?? [],
    monetary: inOrgRoles.includes("donor") ? ["donor"] : [],
    staff: sortRoles(inOrgRoles, "staff") ?? [],
    admin: inOrgRoles.includes("admin") ? ["admin"] : [],
  }
}

function findGlobalRoles(_: Parameters<FieldReadFunction>[0], { readField }: Parameters<FieldReadFunction>[1]) {
  const regexToFind = "0-"
  const globalRoles = (readField("orgRoleAspects") as string[])
    ?.filter((str) => str.startsWith(regexToFind))
    .map((role) => role.replace(regexToFind, ""))

  if (isEmpty(globalRoles)) {
    return DEFAULT_ROLES
  }

  if (globalRoles.includes("deceased")) {
    return { ...DEFAULT_ROLES, inactiveStatus: "Deceased" }
  } else if (globalRoles.includes("trash")) {
    return { ...DEFAULT_ROLES, inactiveStatus: "Archived" }
  } else {
    return {
      ...DEFAULT_ROLES,
      family: sortRoles(globalRoles, "family"),
    }
  }
}

function sortRoles(roles: string[] = [], typeToSort: string) {
  // Role priority is in order from lowest to highest priority. This is because indexOf returns -1 if an element is not found, so adding new, unknown role aspects
  // will not break the priority.
  const ROLE_PRIORITY = {
    parish: [
      "subscriber",
      "online-member",
      "member-registered",
      "non-primary-parish",
      "parishioner",
      "inactive",
      "moved_away",
    ],
    staff: ["author", "team-member"],
    family: ["child", "single", "divorced", "married", "parent"],
  }
  return roles
    .filter((role) => ROLE_PRIORITY[typeToSort]?.includes(role))
    .sort((a, b) => ROLE_PRIORITY[typeToSort]?.indexOf(b) - ROLE_PRIORITY[typeToSort].indexOf(a))
}

/**
 * Configures the behaviour to perform on generic apollo errors
 */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  try {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        handleGenericError({ title: "Communication error", message, locations, path, operation })
      })
    } else if (networkError) {
      handleGenericError({ title: "Network error", details: networkError })
    } else {
      handleGenericError({ title: "Apollo error", message: "Unknown apollo error occurred" })
    }
  } catch (e) {
    handleGenericError({ message: "A JS exception occurred when handling an apollo error :(", details: e })
  }
})

export const createClient = ({ csrf }: { csrf?: string } = {}) => {
  return new ApolloClient({
    uri: "/graphql",
    name: "rms-web-app",
    connectToDevTools: __DEV__,
    link: errorLink.concat(new HttpLink({ headers: { Authentication: csrf } })),
    credentials: "include",
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            person(_, { args, toReference }) {
              return toReference({ __typename: "Person", id: args.id })
            },
          },
        },
        People: {
          fields: {
            globalRoles: { read: findGlobalRoles },
            inOrgRoles: { read: findInOrgRoles },
          },
        },
        Person: {
          fields: {
            globalRoles: { read: findGlobalRoles },
            inOrgRoles: { read: findInOrgRoles },
            isAuthor: { read: roleAspectRead },
            isAdmin: { read: roleAspectRead },
            isSubscriber: { read: roleAspectRead },
            isTeamMember: { read: roleAspectRead },
            isOnlineMember: { read: roleAspectRead },
            isMemberRegistered: { read: roleAspectRead },
            isNonPrimaryParish: { read: roleAspectRead },
          },
        },
      },
    }),
  })
}
