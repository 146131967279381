import React from 'react';

class CheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (e) => {
    var checked = [],
        options = this.props.options;

    for (var i=0; i<options.length; i++) {
      if (document.getElementById(this.props.html_id + '-' + options[i].value).checked) { //e.target.checked && e.target.value == options[i].value) {
        checked.push(options[i].value);
      } else {
        if (checked.indexOf(options[i].value) >= 0) {
          checked.splice(checked.indexOf(options[i].value));
        }
      }
    }

    if (checked.length <= 0 && this.props.default_value.length) {
      checked = [this.props.default_value];
    }

    this.props.setSetting(this.props.id, checked);
  }
  render() {
    const props = this.props;
    const checked_options = props.val ? props.val : (props.default_value ? [props.default_value] : ['text']);
    const dummy_options = [{value: 1, text: 'Option 1'}, {value: 2, text: 'Another option'}];
    const options = props.mode && props.mode == "dummy" ? dummy_options : props.options
    const format_class = props.inline && props.inline == true ? '' : 'flex-column';

    return (
      <div
        className={"form-row {format_class} px-4 " + (props.in_group ? '' : 'py-2 border-bottom')}
        data-file={"------------------- checkbox-group.jsx -------------------"}
      >
        <p className="my-2 bold">{props.label}</p>
        {options.map((option) => (
          <div key={option.value} className="pl-0">
            <label className="custom-control custom-checkbox">
              <input
                id={this.props.html_id + '-' + option.value}
                type="checkbox"
                className="custom-control-input"
                checked={checked_options.indexOf(option.value) >= 0}
                value={option.value}
                onChange={this.onChange}
              />
              <span className="custom-control-label">{option.text}</span>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

export default CheckboxGroup;
