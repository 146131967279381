import {
  Avatar,
  Box,
  Button,
  Checkbox,
  ChevronRightIcon,
  Dialog,
  HStack,
  Menu,
  MenuGroup,
  MenuItem,
  MoreHorizontalBoldIcon,
  Popover,
  styled,
  Text,
  Tooltip,
  TrashIcon,
  useToaster,
} from "@glasscanvas/elephantkit"
import { startCase } from "lodash-es"
import { useState } from "react"
import { Link, LinkProps, NavLink, useLocation } from "react-router-dom"
import { Person, useDeletePersonMutation } from "../graphql"
import { roleFormatter } from "../shared/role_formatter"

type PersonRowProps = {
  person: Person
  columns: string[]
  gridTemplateColumns?: string
}

// MARK: STYLES
const TableRow = styled("div", {
  display: "grid",
  alignItems: "center",
  paddingBlock: "$1",
  paddingInline: "$7",
  borderBottom: "1px solid $colors$gray4",
  cursor: "pointer",
  position: "relative",

  "&:hover": {
    backgroundColor: "$gray5",
  },
})

export function PersonRow({ columns, gridTemplateColumns, ...props }: PersonRowProps) {
  const { person } = props
  const { search } = useLocation()
  const to: LinkProps["to"] = {
    state: { fromIndex: true },
    pathname: `/person/${person?.id}`,
    search,
  }

  return (
    <TableRow css={{ gridTemplateColumns }}>
      <Box as={Link} to={to} css={{ position: "absolute", top: 0, width: "100%", height: "100%" }} />
      {columns.map((column) => (
        <Link key={column} to={to}>
          <Column column={column} {...props} />
        </Link>
      ))}
      <PersonContextMenu person={person} to={to} />
    </TableRow>
  )
}

/** SPECIAL ROWS */

function DefaultColumn({ person, column }: Partial<PersonRowProps> & { column: string }) {
  return (
    <HStack align="center">
      <Text
        size="caption1"
        css={{
          color: "$secondary",
          padding: "$1 0",
          paddingRight: "$2",
          overflowX: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {person[attributeMap[column]] || person[column]}
      </Text>
    </HStack>
  )
}

function FamilyColumn({ person }: Partial<PersonRowProps>) {
  return (
    <HStack align="center">
      <Text size="body" css={{ color: "$label", fontWeight: "$bold" }}>
        {person?.lastName}
      </Text>
    </HStack>
  )
}

function NameColumn({ person, compact }: Partial<PersonRowProps> & { compact?: boolean }) {
  const { firstName, lastName } = person
  const fullName = `${firstName.trim()} ${lastName.trim()}`

  return (
    <HStack align="center" css={{ paddingRight: "$2" }}>
      <Avatar src={person.memberPhoto?.src} name={fullName} size={9} />
      <Box css={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", paddingBlock: "$4" }}>
        <Text size="body" css={{ color: "$label" }}>
          <Box as="span" css={{ fontWeight: "$bold" }}>
            {lastName}
            {", "}
          </Box>
          {firstName}
        </Text>
      </Box>
      {compact && (
        <Box css={{ marginLeft: "auto !important", paddingLeft: "$1" }}>
          <ChevronRightIcon size="$3" css={{ color: "$gray2" }} />
        </Box>
      )}
    </HStack>
  )
}

function RoleColumn({ person }: Partial<PersonRowProps>) {
  return (
    <Tooltip content={roleFormatter(person, { include: { globalRoles: [] } })}>
      <Box css={{ padding: "$1 0", paddingRight: "$2" }}>
        <Text size="caption1" css={{ color: "$secondary" }}>
          {roleFormatter(person, { limit: 1 })}
        </Text>
      </Box>
    </Tooltip>
  )
}

const attributeMap: Record<string, keyof Person> = {
  "primary contact": "firstName",
  city: "addressCity",
  address: "addressAddress",
}

const columnMap = {
  name: NameColumn,
  role: RoleColumn,
  family: FamilyColumn,
}

function Column(props: Partial<PersonRowProps> & { column: string }) {
  const Child = columnMap[props.column] ?? DefaultColumn

  return <Child {...props} css={{ justifySelf: "center" }} />
}

/** This is used in the side nav */
export function PersonRowItem(props: PersonRowProps) {
  const { search } = useLocation()
  const { person } = props
  if (!person) return null

  const to: LinkProps["to"] = { state: { fromIndex: true }, pathname: `/person/${person?.id}`, search }

  return (
    <Box
      as={NavLink}
      to={to}
      css={{
        display: "block",
        padding: "calc($1 / 2) $$paddingX",
        scrollSnapAlign: "start",

        "&.active": {
          backgroundColor: "$gray5",
        },

        "&:hover": {
          backgroundColor: "$gray4",
        },
      }}
    >
      <NameColumn compact {...props} />
    </Box>
  )
}

function PersonContextMenu({ person, to }: { person: Person; to: any }) {
  const [deletePerson] = useDeletePersonMutation()
  const toaster = useToaster()

  const [deleteModalIsShown, setDeleteModalIsShown] = useState(false)

  async function deletePersonFunction() {
    await deletePerson({
      variables: { id: person?.id },
      update: (cache, { data }) => {
        if (data) {
          cache.evict({ id: `Person:${person?.id}` })
        }
      },
    })
    toaster.add({ intent: "success", title: `Deleted ${person.firstName}` })
  }

  return (
    <Box css={{ marginLeft: "auto" }}>
      <Popover
        placement="bottom-start"
        content={
          <>
            <Menu>
              {/* <MenuGroup>
              <MenuItem>Edit</MenuItem>
              <MenuItem>Email</MenuItem>
            </MenuGroup>
            <MenuDivider /> */}
              <MenuGroup>
                <MenuItem intent="danger" icon={TrashIcon} onClick={() => setDeleteModalIsShown(true)}>
                  Delete
                </MenuItem>
              </MenuGroup>
            </Menu>
            <Dialog
              intent="danger"
              title="Delete Person"
              isShown={deleteModalIsShown}
              onConfirm={deletePersonFunction}
              onCancel={() => setDeleteModalIsShown(false)}
              confirmLabel="Delete"
            >
              Are you sure you want to delete {person.firstName}?
            </Dialog>
          </>
        }
      >
        <Button
          css={{ borderRadius: "$round", color: "$gray3" }}
          size="large"
          icon={MoreHorizontalBoldIcon}
          appearance="minimal"
        />
      </Popover>
    </Box>
  )
}
