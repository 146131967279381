import { Person } from "../graphql"

type NameFormatterOptions = {
  title?: boolean
  firstName?: boolean
  lastName?: boolean
  suffix?: boolean
  order?: "first, last" | "last, first"
}
export function nameFormatter(
  { memberTitle: title, firstName, lastName, memberSuffix: suffix }: Person,
  { order = "first, last", ...options }: NameFormatterOptions = {}
): string {
  var name = order === "first, last" ? { title, firstName, lastName, suffix } : { title, lastName, firstName, suffix }

  Object.keys(options).forEach((key) => {
    if (options[key] === false) delete name[key]
  })

  return Object.values(name).join(" ").trim()
}
