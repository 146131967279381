import { FormikSelectField } from "@glasscanvas/elephantkit"
import { startCase } from "lodash-es"
import { Pledge } from "@people"

enum Frequency {
  ONCE = "once",
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  HALFYEAR = "halfyear",
  YEAR = "year",
}

type FieldProps = {
  pledge: Pledge
}

function frequencyToHumanReadable(frequency) {
  const mapping = {
    once: "One-time",
    week: "Weekly",
    month: "Monthly",
    quarter: "Quarterly",
    halfyear: "Semi-annually",
    year: "Annually",
  }
  return mapping[frequency]
}

export function PledgeFieldFrequency({ pledge }: FieldProps) {
  let frequencies = Object.values(Frequency)

  if (pledge.id) {
    // don't allow switch between recurring & one-time, only between different period lengths
    frequencies = pledge.frequency == "once" ? ["once"] : frequencies.filter((frequency) => frequency != "once")
  }

  return (
    <FormikSelectField label="Frequency" name="frequency" css={{ minWidth: "$20" }}>
      {frequencies.map((frequency) => (
        <option value={frequency}>{frequencyToHumanReadable(frequency)}</option>
      ))}
    </FormikSelectField>
  )
}
