import { Box, styled } from "@glasscanvas/elephantkit"
import { motion, useElementScroll, useTransform, useViewportScroll } from "framer-motion"
import { useContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { HeroColor, HeroSectionProps } from "../sections/HeroSection"
import { SectionContext } from "../section_context"

export const ColoredWrapper = styled("div", {
  color: "$background",
  paddingBlock: "$5",

  "@tabletPortrait": {
    paddingBlock: "$20",
  },

  variants: {
    contrastType: {
      dark: {
        color: "$label",
      },
      light: {
        color: "$white",
      },
      custom: {
        color: "var(--org-theme-primary-contrast-color, $white)",
      },
    },
  },
})

export function getContrastType(color: string): "light" | "dark" | "custom" {
  if (!Object.values(HeroColor).includes(color as HeroColor)) {
    return "custom"
  }

  if (["lightBlue", "cream"].includes(color)) {
    return "dark"
  }

  return "light"
}

// Mark: BackgroundScrollEffect
function BackgroundPortal({ children }) {
  const [container] = useState(() => {
    const el = document.createElement("div")
    el.setAttribute("data-hero-background", "")
    return el
  })

  useEffect(() => {
    document.querySelector("#page").prepend(container)
    return () => {
      document.querySelector("#page").removeChild(container)
    }
  }, [])

  return createPortal(children, container)
}

export function ColoredBackgroundScrollEffect({ color }: Partial<HeroSectionProps>) {
  const scrollHeight = window?.innerHeight * 0.8 ?? 600
  const { editing } = useContext(SectionContext)
  const { scrollY } = useViewportScroll()

  const scrollingRef = useRef<HTMLElement>(document.querySelector("#page-preview") ?? document.querySelector("body"))
  const { scrollY: previewY } = useElementScroll(scrollingRef)

  const opacity = useTransform(editing ? previewY : scrollY, [0, scrollHeight], [1, 0])

  return (
    <BackgroundPortal>
      <Box
        as={motion.div}
        css={{
          background: "var(--org-theme-color)",
          backgroundColor: `$${color};`,
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 0,
          transform: editing ? `translateX(calc(100% - 80px))` : "",
        }}
        style={{ opacity }}
      />
    </BackgroundPortal>
  )
}
