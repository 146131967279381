import Service from '../service/service'
import ServiceMod from '../service-mod/service-mod'

class ScheduleNinja {
  constructor(args) {
    this._rawOrgData = args.orgData
    this._themeAttrs = args.themeAttrs
    this._data = {}
    this._uid_to_schContext = {}
    this._schContext = 'current'
    this.initContext('regular') // other context's mods reference services in here, this needs to be first
    this.initContext(this._schContext)
  }

  initContext(schContext, customScheduleIndex = null) {
    const scheduleData = customScheduleIndex != null ? this._rawOrgData.custom_schedules[customScheduleIndex] : this._rawOrgData[schContext]
    if (!this._data[schContext] && scheduleData) {
      var data = this.schContextData(schContext)
      Object.assign(data.attrs, scheduleData.attrs)

      Object.keys(scheduleData.data.services || {}).forEach(uid => {
        this.initService(uid, scheduleData.data.services[uid], schContext)
      })

      Object.keys(scheduleData.data.service_mods || {}).forEach(uid => {
        this.initServiceMod(uid, scheduleData.data.service_mods[uid], schContext)
      })
    }
  }

  componentProps(schContext) {
    return {key: schContext, scheduleNinja: this, scheduleContext: schContext}
  }

  scheduleActive(schContext) {
    return !!this._data[schContext]
  }

  setContext(props) {
    this._schContext = props.scheduleContext
    this.initContext(this._schContext)
    return this
  }

  schContextData(schContext = null) {
    schContext = schContext || this._schContext
    if (!this._data[schContext])              { this._data[schContext] = {} }
    if (!this._data[schContext].services)     { this._data[schContext].services = {} }
    if (!this._data[schContext].service_mods) { this._data[schContext].service_mods = {} }
    if (!this._data[schContext].attrs)        { this._data[schContext].attrs = {} }
    return this._data[schContext]
  }

  getAttr(attr) {
    return this.schContextData().attrs[attr]
  }

  customScheduleContexts() {
    var result = [];
    (this._rawOrgData.custom_schedules || []).forEach((scheduleData, i) => {
      result.push(`custom_${i}`) // TODO: this could be 'trump' if it is an active custom schedule to be shown at the top
      this.initContext(result.slice(-1)[0], i)
    })
    return result
  }

  initServiceMod(uid, serviceModAttrs, schContext = null) {
    const mod = new ServiceMod(serviceModAttrs)
    schContext = schContext || this._schContext
    this.schContextData(schContext).service_mods[uid] = mod
    this._uid_to_schContext[uid] = schContext
    mod.attachToService(this.getService(mod.attrs.service_uid))
    return mod
  }

  initService(uid, serviceAttrs, schContext = null) {
    const service = new Service(serviceAttrs, this._themeAttrs)
    schContext = schContext || this._schContext
    this.schContextData(schContext).services[uid] = service
    this._uid_to_schContext[uid] = schContext
    return service
  }

  getService(uid) {
    return this.schContextData(this._uid_to_schContext[uid]).services[uid]
  }

  getServiceMod(uid) {
    return this.schContextData(this._uid_to_schContext[uid]).service_mods[uid]
  }

  serviceMods(filter = 'all') {
    return Object.values(this.schContextData().service_mods)
  }

  services(filter = 'all', include_regular_schedule = false) {
    var result = Object.values(this.schContextData().services)

    if (include_regular_schedule && this.getAttr('schedule_type') != 'Regular') {
      result = result.concat(Object.values(this.schContextData('regular').services))
    }
    result = Service.sort(result)

    if (filter == 'special') {
      result = result.filter(s => !s.rrMeta['weekly'])
    }
    else if (filter != 'all') {
      result = result.filter(s => s.rrMeta['weekly'] == filter)
    }

    return result
  }

  serviceOccurrences(args) {
    return Service.occurrences(this.services('all', true), args)
  }
}

export default ScheduleNinja
