import { Box, Heading, Spacer } from "@glasscanvas/elephantkit"
import { FormattedMessage } from 'react-intl'
import {
  ColoredBackgroundScrollEffect,
  ColoredWrapper,
  DEFAULTS,
  getContrastType,
  HeroSectionProps,
  OutlineButton,
} from "../../components"
import { adjustFontSize } from "../../hooks/adjustFontSize"

export function TextLayout({ title, color, buttonPath = "/welcome", buttonTextKey = "nav.im_new" }: Partial<HeroSectionProps>) {
  const contrastType = getContrastType(color)

  const titleText = title || DEFAULTS.TITLE
  const textWrapperWidth = 700
  const titleSize = adjustFontSize(titleText, {
    textWrapperWidth,
    textWrapperLines: 6,
    maxSize: 40,
    defaultSize: "$extraLargeTitle",
  })

  return (
    <ColoredWrapper css={{ padding: "clamp(120px, 10vw, 190px) $7" }} contrastType={contrastType}>
      <ColoredBackgroundScrollEffect color={color} />
      <Box css={{ textAlign: "center", maxWidth: 700, marginInline: "auto" }} role="doc-subtitle">
        <Heading
          as="h1"
          css={{
            fontFamily: "$serif",
            fontWeight: "normal",
            lineHeight: "1.35",
            fontSize: "clamp(18px, 15vw, 36px)",

            "@tabletPortrait": {
              fontSize: "$extraLargeTitle",
            },
          }}
        >
          {titleText}
        </Heading>
        <Spacer size="$10" />
        <OutlineButton href={buttonPath} contrastType={contrastType}>
          <FormattedMessage id={buttonTextKey} defaultMessage="I'm New" />
        </OutlineButton>
      </Box>
    </ColoredWrapper>
  )
}
