const _file = 'ConditionalFields';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var ConditionalFields = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    function onSelectChange($field) {
      if (!$field.attr("id")) {
        return true;
      }

      var part_id      = $field.is("select") ? $field.attr("id") : $field.attr("id").replace("_" + $field.val().replace(" ", "_"), ""),
          $other_field = $("#" + part_id + "_other_option") || "",
          value        = $field.multiple_choice_val();

      if (!value) {
        return true;
      }

      if ($other_field && (value.indexOf("other") >= 0 || value.indexOf("Other") >= 0)) {
        $other_field.parent().show();
        $other_field.focus();
      } else {
        $other_field.parent().hide();
      }
    }

    $(element).find(".multiple-choice").each(function() {
      var $select_elem = $(this);
      $select_elem.change(function() {onSelectChange($select_elem)});
      onSelectChange($select_elem);
    });
  });

  // 2021 review/refactor by SS: I'm confused. What's this doing?
  $.fn.multiple_choice_val = function() {
    var $field  = $(this),
        part_id = $field.is("select") ? $field.attr("id") : $field.attr("id").replace("_" + $field.val().replace(" ", "_"), ""),
        $fields = $("[id*='" + part_id + "']"),
        value   = $field.prop("checked") || $field.is("select") ? $field.val() : "";

    if (!$field.is("select")) {
      if (!$field.is(":radio")) {
        value = [];
      }
      $fields.each(function(){
        if ($(this).prop("checked")) {
          if (!$field.is(":radio")) {
            value.push($(this).val());
          } else {
            value = $(this).val();
          }
        }
      });
    }

    return value;
  }
})(jQuery);