import { startCase } from "lodash-es"
import { Person, Roles } from "../graphql"
import { getAge } from "./get_age"

type RoleFormatterRoles = Omit<Roles, "__typename">
type IncludeExcludeFilter = Array<keyof RoleFormatterRoles>
type IncludeExcludeFilterDictionary = Partial<Record<"inOrgRoles" | "globalRoles", IncludeExcludeFilter>>

type RoleFormatterOptions = {
  birthday?: boolean
  id?: boolean
  include?: IncludeExcludeFilterDictionary
  exclude?: IncludeExcludeFilterDictionary
  limit?: Partial<Record<"inOrgRoles" | "globalRoles", number>> | number
}

type CollateRolesOptions = {
  include?: IncludeExcludeFilter
  exclude?: IncludeExcludeFilter
  limit?: number
}

function collateRoles(roles: RoleFormatterRoles, { include = [], exclude = [], limit }: CollateRolesOptions) {
  let count = 0
  const rolesArray: Array<keyof RoleFormatterRoles | (string & {})> = []

  for (const [role, value] of Object.entries(roles)) {
    if (
      (include.length > 0 && !include.includes(role as keyof RoleFormatterRoles)) ||
      exclude.includes(role as keyof RoleFormatterRoles) ||
      !value
    ) {
      continue
    }

    for (const roleValue of value) {
      if (limit && count === limit) break
      count++
      rolesArray.push(roleValue)
    }
  }

  return rolesArray
}

export function roleFormatter(
  { inOrgRoles = {}, globalRoles = {}, memberBirthday, id }: Person,
  { birthday, id: includeId, include = {}, exclude = {}, limit = {} }: RoleFormatterOptions = {}
): string {
  if (typeof limit === "number") {
    var totalLimit = limit
    limit = { inOrgRoles: limit, globalRoles: limit }
  }

  if (!!globalRoles?.inactiveStatus) {
    globalRoles = { inactiveStatus: globalRoles.inactiveStatus }
    inOrgRoles = {}
    birthday = false
  }

  const roles = Object.entries({ inOrgRoles, globalRoles })
    .flatMap(([key, value]) => collateRoles(value, { include: include[key], exclude: exclude[key], limit: limit[key] }))
    .splice(0, totalLimit ? totalLimit : Infinity)
    .concat([birthday && memberBirthday ? `Age ${getAge(memberBirthday)}` : ""])
    .map(startCase)
    .concat([includeId && id ? `ID: ${id}` : ""])
    .filter((value) => value.length > 0)
    .join(" / ")

  return roles
}
