import { useEffect, useRef } from "react"

const defaultValues = {
  string: "",
  checkbox: false,
  multiple_choice: 0,
}

// Create a copy of an object with only selected key-value pairs included
export function selectKeys<ObjectType = {}>(obj: ObjectType, keys: string[]): ObjectType {
  return keys.reduce((result_obj, key) => {
    result_obj[key] = obj[key] || undefined
    return result_obj
  }, {} as ObjectType)
}

export function transformValues<ObjectType = {}>(obj: ObjectType, dataSource: {}, humanReadable?: Boolean): ObjectType {
  const result_obj: ObjectType = {}
  Object.keys(obj).forEach((key) => {
    // Get info on fields from a data source generated by a Ruby script or GraphQL e.g. person/fields_data.ts
    if (dataSource?.[key]) {
      switch (dataSource[key]["part_type"]) {
        case "checkbox":
          result_obj[key] = obj[key] || false
          break
        case "multiple_choice":
          const myChoice = dataSource[key].choices.find((choice) => choice.custom_id == obj[key])
          result_obj[key] = humanReadable ? myChoice?.val || "" : myChoice?.custom_id
          break
        default:
          result_obj[key] = obj[key] || undefined
      }
    } else {
      // For other forms (e.g. pledges) that don't have a front-end datasource yet
      result_obj[key] = obj[key]
    }
  })
  return result_obj
}

export function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
