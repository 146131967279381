import React, { Fragment, useContext } from 'react';
import { PledgeUiDispatch } from "./reducer"

export const QuantitySelect = props => {
  const dispatch = useContext(PledgeUiDispatch);

  return (
    <div className="form-group form-row">
      <div className="flex-1 input-group input-group-simple align-items-center bg-white rounded">
        <span className="input-group-prepend pl-3 text-gray-500">Qty:</span>
        <input
          className="form-control no-placeholder number bg-transparent"
          defaultValue={1}
          onChange={(e) =>
            dispatch({ type: "update_quantity", value: e.currentTarget.value })
          }
          value={props.quantity}
          onBlur={(e) =>
            e.currentTarget.value <= 0 &&
            dispatch({ type: "update_quantity", value: 1 })
          }
        />
        <span className="border-element"></span>
      </div>
    </div>
  );
}