import {
  Box,
  Checkbox,
  Button,
  DataTransferVerticalIcon,
  EmptyState,
  ExpandIcon,
  Heading,
  HStack,
  Menu,
  MenuGroup,
  MenuItem,
  MoreHorizontalBoldIcon,
  Pane,
  Popover,
  StackingOrder,
  StackingProvider,
  styled,
  ViewIcon,
  ViewOffIcon,
  VStack,
} from "@glasscanvas/elephantkit"
import { useReducer } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { Person, useGetPeopleQuery } from "../graphql"
import { PeopleFilterBar } from "../people_filter"
import { Loading, useDataMasking, useCanToggleDataMasking } from "../shared"
import { Pagination } from "./pagination"
import { peopleIndexReducer, PeopleIndexState, TableColumn } from "./people_index_reducer"
import { PersonRow, PersonRowItem } from "./person_row"

export type PeopleData = {
  people: NestedPeopleData
}

export type NestedPeopleData = {
  people: Person[]
  totalCount: number
}

type PeopleTableProps = Partial<Pick<PeopleIndexState, "queryId" | "columns">> & {
  compact?: boolean
  addColumns?: TableColumn[]
}

function getBooleanFromURLString(string: string): boolean {
  return string === "true" ? true : false
}

export function PeopleTable({
  compact,
  queryId,
  columns = [{key: "name", header: "Name"}, 
  {key: "role", header: "Role"},
  {key: "email", header: "Email"}, 
  {key: "address", header: "Address"},
  {key: "city", header: "City"}],
  addColumns,
}: PeopleTableProps) {
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)

  queryId = urlParams.has("query_id") ? urlParams.get("query_id") : queryId

  const defaultState = {
    searchStr: urlParams.get("search") || "",
    queryId,
    pageNum: Number(urlParams.get("page")),
    rowsPerPage: Number(urlParams.get("per_page")) || 20,
    sortAscending: true,
    sortBy: "name",
    columns: [...columns, ...(addColumns ?? [])],
  }

  const [state, dispatch] = useReducer(peopleIndexReducer, defaultState)

  const { loading, data, error } = useGetPeopleQuery({
    variables: {
      page: urlParams.has("page") ? Number(urlParams.get("page")) : 1,
      perPage: urlParams.has("per_page") ? Number(urlParams.get("per_page")) : 20,
      searchStr: urlParams.get("search"),
      sortBy: urlParams.get("sort_by") || "name",
      asc: urlParams.has("sort") ? getBooleanFromURLString(urlParams.get("sort")) : true,
      queryId,
    },
  })

  if (loading) {
    return (
      <Box css={{ width: compact && "360px" }}>
        <Loading />
      </Box>
    )
  }

  if (error) {
    return (
      <Box css={{ display: "flex", align: "center", height: "400vh" }}>
        <EmptyState title="Something went wrong" description={error.message} />
      </Box>
    )
  }

  const gridTemplateColumns = `minmax(17em, 1fr) repeat(${state.columns.length - 1}, minmax(10em, 1fr)) auto`
  
  return compact ? (
    <VStack
      css={{
        $$headerHeight: "70px",
        $$paddingX: "$sizes$6",

        display: "flex",
        maxWidth: "360px",
        width: "100%",
        flexDirection: "column",
        height: `calc(100vh - $$headerHeight)`,
        backgroundColor: "$background",
        overflowY: "scroll",
        borderRight: "1px solid $gray5",
        position: "sticky",
        top: "$$headerHeight",
        left: 0,
        // scrollSnapType: "y proximity",
        // scrollPaddingTop: "calc($$headerHeight * 1.5)",
      }}
    >
      <VStack
        css={{
          borderBottom: "1px solid $gray5",
          padding: "$5 $$paddingX",
          backgroundColor: "$background",
          // position: "sticky",
          // top: 0,
          // left: 0,
        }}
        space="small"
      >
        <HStack align="center">
          <Heading size="subHeadline" css={{ fontWeight: "800" }}>
            Expand Table
          </Heading>
          <Box css={{ marginLeft: "auto !important" }}>
            <Button
              as={Link}
              to={{ pathname: "/index", search }}
              icon={ExpandIcon}
              round
              appearance="outline"
              css={{ color: "$gray2", $$outlineColor: "$colors$hsl_gray2" }}
            />
          </Box>
        </HStack>
        <PeopleFilterBar compact />
      </VStack>

      <Box css={{ paddingBlock: "$1" }}>
        {data.people.people.map((person) => (
          <PersonRowItem key={person.id} columns={state.columns.map(c => c.key)} person={person} />
        ))}
      </Box>
    </VStack>
  ) : (
    <Box css={{ overflowX: "scroll" }}>
      <Pane css={{ $$headerHeight: "68px", backgroundColor: "$background", width: "fit-content", minWidth: "100%" }}>
        <TableHeader
          sortBy={state.sortBy}
          dispatch={dispatch}
          columns={state.columns}
          sortableColumns={["name", "email"]}
          gridTemplateColumns={gridTemplateColumns}
          showCheckbox={false}
          headingSize="groupHeadline"
        />
        {data.people.people.map((person) => (
          <PersonRow
            key={person.id}
            gridTemplateColumns={gridTemplateColumns}
            columns={state.columns.map(c => c.key)}
            person={person}
          />
        ))}
        <Pagination totalCount={data?.people?.totalCount} />
      </Pane>
    </Box>
  )
}

const SortableButton = ({ columnKey, header }: { columnKey: string, header: string }) => {
  const { search, ...location } = useLocation()
  const { replace } = useHistory()
  const urlParams = new URLSearchParams(search)
  const sortBy = urlParams.get("sort_by") || "name"
  const sortAscending = getBooleanFromURLString(urlParams.get("sort")) ?? false

  const isCurrentColumn = columnKey === sortBy

  const colors = isCurrentColumn
    ? sortAscending
      ? ["$primary", "$gray3"]
      : ["$gray3", "$primary"]
    : ["$gray3", "$gray3"]

  return (
    <Button
      size="small"
      iconAfter={() => <DataTransferVerticalIcon multicolored={colors} />}
      appearance="minimal"
      round
      onClick={() => {
        if (isCurrentColumn) {
          urlParams.set("sort", String(!sortAscending))
          replace({ ...location, search: urlParams.toString() })
        } else {
          urlParams.set("sort_by", columnKey)
          replace({ ...location, search: urlParams.toString() })
        }
      }}
      css={{ marginLeft: "-$3", svg: { width: "$3" } }}
    >
      <Heading
        size="groupHeadline"
        css={{ color: isCurrentColumn ? "$black" : "$gray1", fontWeight: isCurrentColumn && "bold" }}
      >
        {header}
      </Heading>
    </Button>
  )
}

// MARK: TableHeader
type TableHeaderProps = {
  gridTemplateColumns: string
  columns: PeopleIndexState["columns"]
  sortBy: PeopleIndexState["sortBy"]
  dispatch: React.Dispatch<any>
  showCheckbox: boolean
  headingSize: string
  sortableColumns: String[]
}

const TableHeaderGrid = styled("div", {
  display: "grid",
  border: "1px solid $colors$gray4",
  borderWidth: "1px 0",
  alignItems: "center",
  paddingBlock: "$1",
  paddingInline: "$7",
  position: "sticky",
  top: 0,
  left: 0,
  minHeight: "$12",
  backgroundColor: "$background",
})

// S4K Note: This function is now exported for use by Charges Index Table. Changes to this will affect both tables.
export function TableHeader({ showCheckbox, columns, gridTemplateColumns, headingSize, sortableColumns }: TableHeaderProps) {

  return (
    <StackingProvider value={StackingOrder.DEFAULT}>
      {(zIndex) => (
        <TableHeaderGrid css={{ gridTemplateColumns, zIndex }}>
          {showCheckbox && 
          <Box css={{ paddingRight: "$3" }}>
            <Checkbox />
          </Box>}
          {columns.map((column) => (
            <HStack key={column.key} align="center">
              {sortableColumns.includes(column.key) ? (
                // Note use of "columnKey" since "key" is a reserved keyword
                <SortableButton key={column.key} columnKey={column.key} header={column.header} />
              ) : (
                <Heading size={headingSize} css={{ color: "$gray1" }}>
                  {column.header}
                </Heading>
              )}
            </HStack>
          ))}
          <HStack align="center" justify="end">
            <TableHeaderDropdownMenu />
          </HStack>
        </TableHeaderGrid>
      )}
    </StackingProvider>
  )
}

function TableHeaderDropdownMenu() {
  const isAgent = useCanToggleDataMasking()
  const { isMasked, toggleDataMasking } = useDataMasking()

  // TODO: Remove this when we have non agent menu items in here
  if (!isAgent) return null

  return (
    <Popover
      placement="bottom-start"
      content={
        <Menu>
          {isAgent && (
            <MenuGroup title="Admin">
              <MenuItem icon={isMasked ? ViewOffIcon : ViewIcon} onSelect={toggleDataMasking}>
                {isMasked ? "Unmask Data" : "Mask Data"}
              </MenuItem>
            </MenuGroup>
          )}
        </Menu>
      }
    >
      <Button icon={MoreHorizontalBoldIcon} size="large" appearance="minimal" round color="secondary" />
    </Popover>
  )
}
