import React from 'react';

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (e) => {
    this.props.toggleSetting(this.props.id);
  }

  render() {
    var props = this.props;
    return (
      <div className={"px-4 " + (props.in_group ? '' : 'py-2 border-bottom')}
        data-file={"------------------- toggle-switch.jsx -------------------"}>
        <div className="form-row d-flex align-items-center">
          <i className={'mr-1 icon ' + (props.icon && props.icon.length ? props.icon : 'd-none')}></i>
          <span className="small" onClick={this.onChange}>{props.label}</span>
          <div className="toggle ml-auto">
            <input type="checkbox" name="toggle" className="toggle-checkbox" id={props.html_id} onChange={this.onChange} checked={!!props.val} />
            <label className="toggle-label" htmlFor={props.html_id}>
              <span className="toggle-inner"></span>
              <span className="toggle-switch"></span>
            </label>
          </div>
        </div>
        {props.help_text ?
          <small className="form-text text-muted">{props.help_text}</small> : null
        }
      </div>
    );
  }
}

export default ToggleSwitch;
