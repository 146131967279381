import React from "react"
import ReactOnRails from "react-on-rails"

import ScheduleEditor from "./schedule/schedule-editor"
import ScheduleViewer from "./schedule/schedule-viewer"

const MassScheduleApp = (props) => {
  const appTarget = document?.querySelector("#masstimes-schedule-viewer, #schedule-editor-container")
  const ScheduleComponent = props.editing ? ScheduleEditor : ScheduleViewer
  return <ScheduleComponent appTarget={appTarget} {...props} />
}

document.addEventListener("re-init-mass-finder", ReactOnRails.reactOnRailsPageLoaded)

export default MassScheduleApp
