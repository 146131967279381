import React, { useContext, Fragment } from "react";
// bootstrap
import Card from "react-bootstrap/Card";
import { ScheduleDispatch } from "../schedule/schedule-reducer";
import ThemeContext from "../theme";
// custom
import LanguageCodes from "./language-codes";
import ServiceRowActionBtn from "./service-row-action-btn";

export default function ServiceRow({ service, occurrenceDate }) {
  const dispatch = useContext(ScheduleDispatch);
  const serviceAttrs = service.attrs;

  var rowActions = [];

  function serviceModActionClick(actionType) {
    var serviceModAttrs = {
      service_uid: service.attrs.uid,
      occurrence_date: occurrenceDate,
    };
    dispatch({ type: actionType, serviceModAttrs: serviceModAttrs });
  }

  function serviceActionClick(actionType) {
    dispatch({
      type: actionType,
      serviceUid: service.uid,
      occurrenceDate: occurrenceDate,
    });
  }

  if (occurrenceDate) {
    rowActions.push({
      btnText: "Modify Service",
      btnIconClasses: "fa fa-edit",
      actionType: "add_service_mod",
      onClick: serviceModActionClick,
    });
    rowActions.push({
      btnText: "Cancel Service",
      btnIconClasses: "fa fa-edit",
      actionType: "cancel_service",
      onClick: serviceModActionClick,
    });
  } else {
    rowActions.push({
      btnText: "Edit",
      btnIconClasses: "fa fa-edit",
      actionType: "edit_service",
      onClick: serviceActionClick,
    });
    rowActions.push({
      btnText: "Delete",
      btnIconClasses: "fa fa-trash",
      actionType: "delete_service",
      onClick: serviceActionClick,
    });
  }

  const serviceRowActionButtons = rowActions.map((actionMeta) => (
    <ServiceRowActionBtn {...actionMeta} key={actionMeta.actionType} />
  ));
  const theme = useContext(ThemeContext);

  return theme.theme === "viewer" ? (
    <ViewerCard service={service} serviceAttrs={serviceAttrs} />
  ) : (
    <EditorCard service={service} serviceAttrs={serviceAttrs} serviceRowActionButtons={serviceRowActionButtons} />
  );
}

// MARK - Viewer Card
function ViewerCard({ service, serviceAttrs }) {
  const serviceTypeAttrs = {
    mass: {
      iconClass: "icon icon-eucharist",
      colorClass: "bg-gold",
      hideLang: false,
    },
    confession: {
      iconClass: "icon icon-confession",
      colorClass: "bg-steel-blue-dark",
      hideLang: true,
    },
    devotion: {
      iconClass: "icon icon-cross",
      colorClass: "bg-burgundy",
      hideLang: true,
    },
  }[serviceAttrs.type];

  return (
    <Fragment>
      <Card className="flex-row mb-3 overflow-hidden rounded mass-viewer-card medium" border="0">
        <div
          className={`flex-shrink-1 px-2 text-white m-0 font-weight-bold d-flex align-items-center ${serviceTypeAttrs.colorClass}`}
        >
          <span className="text-white font-size-15">
            <i className={serviceTypeAttrs.iconClass}></i>
          </span>
        </div>

        <div className="d-flex align-items-center p-2 pl-3 flex-grow-1">
          <span className="steel-blue bold">{service.startTimeFormatted()}</span>
          {serviceAttrs.type != "mass" ? null : <span className="ml-2 black text-uppercase font-weight-light">Mass</span>}
        </div>

        {/* <div className="d-flex align-items-center ml-auto pr-3">
          <span className="light-brown font-size-15">
            <i className="icon icon-right"></i>
          </span>
        </div> */}

        <div className="small bg-language d-flex align-items-center">
          {!serviceTypeAttrs.hideLang && serviceAttrs.language.match(/en/i) ? null : ( // TODO: Detect user default language and use that
            <span className="p-2 white bold">{serviceAttrs.language}</span>
          )}
        </div>
      </Card>
      {serviceAttrs.description && serviceAttrs.description.length > 0 ? <p>* {serviceAttrs.description}</p> : null}
    </Fragment>
  );
}

// MARK - Editor Card
function EditorCard({ serviceAttrs, service, serviceRowActionButtons }) {
  const serviceTypeAttrs = {
    mass: {
      iconClass: "icon icon-eucharist",
      colorClass: "bg-steel-blue-dark",
      hideLang: false,
    },
    confession: {
      iconClass: "icon icon-confession",
      colorClass: "bg-steel-blue-dark",
      hideLang: true,
    },
    devotion: {
      iconClass: "icon icon-cross",
      colorClass: "bg-burgundy",
      hideLang: true,
    },
  }[serviceAttrs.type];

  return (
    <Card className="flex-row mb-3">
      <div
        className={
          "p-3 rounded-left text-white h5 m-0 font-weight-bold d-flex align-items-center " + serviceTypeAttrs.colorClass
        }
        style={{ minWidth: "125px" }}
      >
        <span className="circle-icon circle-icon-sm text-white border border-white mr-2">
          <i className={serviceTypeAttrs.iconClass}></i>
        </span>
        <div>
          <span>{service.startTimeFormatted()}</span>
          {serviceAttrs.type != "mass" ? null : <span className="d-block small">Mass</span>}
        </div>
      </div>
      <div className="p-3 d-flex align-items-center">
        {serviceTypeAttrs.hideLang ? null : (
          <span className="badge badge-default badge-pill badge-btn-size mr-2">
            <LanguageCodes languageNameFor={serviceAttrs.language || "en"} />
          </span>
        )}
        {/* <span className="badge badge-default badge-pill badge-btn-size mr-2">
  <i className="icon icon-confession icon-lg mr-1"></i>
  Confession 1 hour before Mass
  </span>
  <span className="badge badge-default badge-pill badge-btn-size mr-2">
  <i className="icon icon-cross icon-lg mr-1"></i>
  Adoration 1 hr before Mass
</span> */}
        {serviceAttrs.description}
      </div>

      <div className="d-flex align-items-center ml-auto pr-3">
        <div className="dropdown">
          <div className="dropdown-arrow independent-arrow"></div>
          <button name="button" type="button" className="btn btn-outline-default circle-icon shrink-0 border-0">
            <i className="fa fa-dots fa-sm" aria-hidden="true"></i>
          </button>
          <div className="dropdown-menu dropdown-menu-right border-0-last">{serviceRowActionButtons}</div>
        </div>
      </div>
    </Card>
  );
}
