import { ApolloProvider } from "@apollo/client"
import { ThemeProvider } from "@glasscanvas/elephantkit"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { RailsContext } from "react-on-rails/node_package/lib/types"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
// TODO: Remove me when we've remove rails sections
import "reactshims.scss"
import { ErrorBoundary, translations, globalStyles } from "@shared"
import { Families } from "../families"
import { useGetDomainOrgQuery } from "../graphql"
import { PeopleIndexSection } from "../people_index"
import { PersonBaseSection } from "../person"
import { PersonNewSection } from "../person/person_new_section"
import { Staff } from "../staff"
import { Admin } from "../admin"
import { createClient } from "./apollo_client"
import { FourOhFour } from "./four_oh_four"
import { TopNav } from "./index"

export const RmsApp = ({ csrf }: any, context: RailsContext) => {
  return () => (
    <ThemeProvider
      toaster={{ position: "right", offset: { top: "$15", side: "$2" } }}
      i18n={{
        locale: context.i18nLocale,
        defaultLocale: context.i18nDefaultLocale,
        messages: translations[context.i18nLocale],
      }}
    >
      <ErrorBoundary>
        <ApolloProvider client={createClient({ csrf })}>
          <AppRoot context={context} />
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

type AppRootProps = {
  context: RailsContext
}
export function AppRoot({ context }: AppRootProps) {
  globalStyles()

  const { data } = useGetDomainOrgQuery()

  return !data ? null : (
    <HelmetProvider>
      <Helmet defaultTitle="People" titleTemplate="People | %s" />
      <Router basename="/admin/people">
        <TopNav />
        <Switch>
          {/* PEOPLE */}
          <Route path="/index" exact component={PeopleIndexSection} />
          <Route path="/person/new" exact component={PersonNewSection} />
          <Route path="/person/:personId" component={PersonBaseSection} />
          <Route path={["/myself", "/profile"]} component={PersonBaseSection} />
          {/* FAMILIES */}
          <Route path="/families" exact component={Families} />
          <Route path="/families/person/:personId" component={PersonBaseSection} />
          {/* STAFF */}
          <Route path="/staff" exact component={Staff} />
          <Route path="/staff/person/:personId" component={PersonBaseSection} />
          {/* ADMIN */}
          <Route path="/admin" exact component={Admin} />
          <Route path="/admin/person/:personId" component={PersonBaseSection} />
          <Route path="*" component={FourOhFour} />
        </Switch>
      </Router>
    </HelmetProvider>
  )
}
