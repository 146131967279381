import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { PeopleTable, PeopleFilterBar } from "../people_index/index"

export function Admin() {
  return (
    <Fragment>
      <Helmet title="Admin" />
      <PeopleFilterBar />
      <PeopleTable queryId="admin" addColumns={[{key: "authUserLastSignInAt", header: "Last Login"}]} />
    </Fragment>
  )
}
