import { RmsSectionProps } from "../base"
import { PersonAttrsSection, PersonRailsSection, SplitNavigationSection, TeamMemberSection } from "./index"

export function PersonPublicProfileSection(props: RmsSectionProps) {
  return (
    <SplitNavigationSection
      {...props}
      subComponents={{
        "ministry-contact": PersonRailsSection,
        "author-info": PersonAttrsSection,
        "team-member-info": TeamMemberSection,
      }}
    />
  )
}
