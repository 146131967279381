import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { PeopleTable, PeopleFilterBar } from "../people_index/index"

export function Staff() {
  return (
    <Fragment>
      <Helmet title="Staff" />
      <PeopleFilterBar />
      <PeopleTable queryId="team_members" />
    </Fragment>
  )
}
