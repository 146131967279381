var CrozierFormPart = (function ($) {
  $(document).on('content-ready', function (e, element) {

    $(element).find(".amount_options").change(function(){
      var $select = $(this),
          value = $select.val();
      if (value == "Other") {
        value = "";
        $($(this).data("target")).focus();
      }
      $($(this).data("target")).val(value);
    });

    $(element).find(".parish_options").change(function(){
      var $option = $(this).find("option[value='" + $(this).val() + "']");
      $(this).siblings('.parish_email').val($option.data('email'));
      $(this).siblings('.parish_name').val($option.data('name'));
    });

    $(element).find(".sponsor_priest").change(function(){
      var $fields = $($(this).data("target")).find(":input");
      if ($(this).prop("checked")) {
        $fields.addClass("required");
        return true;
      }
      $fields
        .removeClass("required")
        .removeClass("has-error");
    });

    $(".annual_pledge").change(function(){
      var amount = parseInt($(".annual_pledge").val());
      if (amount > 0 && amount < 2000) {
        $(this).val(2000);
      }
    });

    $(element).find(".crozier-form-part :input").on("keyup change", function(){
      var total = 0;
          payments = $(".frequency").val() == "quarter" ? 4 : 1,
          instalment_total = "",
          $total_field = $(".total_field_show"),
          $instalment_field = $(".instalment_field_show");

      total+= parseInt($(".annual_pledge").val()) || 0;
      total+= parseInt($(".semenarian_education_pledge").val()) || 0;
      total+= ($(".sponsor_priest").prop("checked") ? 500 : 0);

      payment_amount = total/payments;

      $(".payments_field").val(payments);

      $(".amount_options").each(function(){
        if ($(this).val() == $($(this).data('target')).val()) {
          return true;
        } else if ($($(this).data('target')).val() == "") {
          $(this).val("Select an Amount...");
          return true;
        }
        $(this).val("Other");
      });

      $total_field.text("$" + total); // Math.floor(total.toFixed(2))
      $instalment_field.text("$" + (payment_amount % 1 == 0 ? payment_amount : payment_amount.toFixed(2))); // Math.floor(total.toFixed(2))
      $(".amount_field").val(payment_amount*100);

      if ($total_field.text().length > 0) {
        $total_field.parent().show();
        if (payments > 1) {
          $instalment_field.parent().show();
        } else {
          $instalment_field.parent().hide();
        }
        return true;
      }
      $total_field.parent().hide();
    });
  });
})(jQuery);