import { useState } from "react"
import { Box, Button, Card, EditIcon, Heading, HStack, VStack } from "@glasscanvas/elephantkit"
import { getSectionMeta, RmsSectionProps } from "../base"
import { PersonAttributeDisplay } from "./person_attribute_display"
import { PersonAttributeForm } from "./person_attribute_form"
import { FormattedMessage } from "react-intl"
import { startCase } from "lodash-es"
import { useLocation } from "react-router-dom"

export function PersonAttrsSection({ slug, sectionMeta, yieldBottom }: RmsSectionProps) {
  const { state } = useLocation()
  const [open, setOpen] = useState(state?.fromNewPerson || false)

  const { props } = getSectionMeta(slug)
  const Wrapper = props?.layout === "inline" ? Box : Card

  return (
    <Wrapper
      border
      elevation={1}
      key={slug}
      css={{
        backgroundColor: "$background",
        padding: "$9",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <VStack space="small">
        <HStack align="center" css={{ justifyContent: "space-between" }}>
          <Heading>
            <FormattedMessage id={`rms.section.${slug}`} defaultMessage={startCase(slug)} />
          </Heading>
          {!open && (
            <Button iconBefore={EditIcon} onClick={() => setOpen(!open)} appearance="outline">
              {props?.editBtnText || "Edit"}
            </Button>
          )}
        </HStack>
        {open ? (
          <PersonAttributeForm setOpen={setOpen} slug={slug} sectionMeta={sectionMeta} />
        ) : (
          <PersonAttributeDisplay setOpen={setOpen} sectionMeta={sectionMeta} />
        )}
      </VStack>
      {yieldBottom ? yieldBottom : null}
    </Wrapper>
  )
}
