const _file = 'GlassSelectTwo';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassSelectTwo = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    let $selectEl = $(element).find('.select-two:not(.select2-hidden-accessible)')
    if ($selectEl.data('dropdownparent')) {
      $selectEl.select2({
        dropdownParent: $(element).closest($selectEl.data('dropdownparent'))
      })
    } else {
      $selectEl.select2()
    }
  });

  // Return API for other modules
  return {
  };
})(jQuery);
