import React, { Fragment } from "react";
import Nav from "react-bootstrap/Nav";

const navLabels = {
  settings:    'Settings',
  schedule:    'Schedule',
  occurrences: 'Occurrences'
}

export default ({activeKey, onChange, itemKeys}) => (
  <Fragment>
    <Nav variant='pills' className='ml-auto' activeKey={activeKey}>
      {
        itemKeys.map(itemKey =>
          <Nav.Item key={itemKey}>
            <Nav.Link variant='light' eventKey={itemKey} onClick={() => onChange(itemKey)}>
              {navLabels[itemKey]}
            </Nav.Link>
          </Nav.Item>
        )
      }
    </Nav>
  </Fragment>
)
