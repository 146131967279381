import { Button, StackPage, VStack } from "@glasscanvas/elephantkit"
import { Link } from "react-router-dom"

export const ConfirmationScreen = (props) => {
  return (
    <StackPage>
      <VStack>
        <Button round as={Link} to="/thank-you">
          Continue
        </Button>
      </VStack>
    </StackPage>
  )
}
