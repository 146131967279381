import { HeroSectionProps } from "../sections/HeroSection"
import { Box } from "@glasscanvas/elephantkit"
import { getImage } from "./ImageCollage"

export function ImageCollage2({
  image0 = "static/hero1.jpg",
  image1 = "static/hero2.jpg",
  image2 = "static/hero3.jpg",
  image3 = "static/hero4.jpg",
  image0Position,
  image1Position,
  image2Position,
  image3Position,
}: Partial<HeroSectionProps>) {
  return (
    <Box css={{ width: "100%", marginLeft: "auto" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 1335 1137"
      >
        <g transform="scale(1.00798 .88032)">
          <path fill="none" d="M0 0H1324V1291H0z"></path>
          <g transform="matrix(.93717 0 0 .99413 0 -175.512)">
            <use
              width="854"
              height="428"
              transform="matrix(-.98783 0 0 -1.06627 1129.68 789.176)"
              xlinkHref="#_Image1"
            ></use>
            <g transform="matrix(1.00796 0 0 1.088 498.05 192.084)">
              <clipPath id="_clip2">
                <path d="M456 42.009C456 18.824 437.176 0 413.991 0H42.009C18.824 0 0 18.824 0 42.009v489.982C0 555.176 18.824 574 42.009 574h371.982C437.176 574 456 555.176 456 531.991V42.009z"></path>
              </clipPath>
              <g clipPath="url(#_clip2)">
                <use width="456" height="574" xlinkHref="#_Image3"></use>
              </g>
            </g>
            <g transform="matrix(1.00796 0 0 1.088 996.099 434.269)">
              <clipPath id="_clip4">
                <path d="M410 42.009C410 18.824 391.176 0 367.991 0H42.009C18.824 0 0 18.824 0 42.009v489.982C0 555.176 18.824 574 42.009 574h325.982C391.176 574 410 555.176 410 531.991V42.009z"></path>
              </clipPath>
              <g clipPath="url(#_clip4)">
                <use width="410" height="574" xlinkHref="#_Image5"></use>
              </g>
            </g>
            <g transform="matrix(2.50993 0 0 -2.70924 -1845.33 2772.99)">
              <g>
                <g>
                  <path
                    fill="none"
                    stroke="#E0A961"
                    strokeWidth="2"
                    d="M1089.75 739.75c57.16 0 103.5-46.339 103.5-103.5s-46.34-103.5-103.5-103.5v207z"
                  ></path>
                </g>
              </g>
            </g>
            <g transform="matrix(1.00796 0 0 1.088 497.421 850.658)">
              <clipPath id="_clip6">
                <path d="M456 42.009C456 18.824 437.176 0 413.991 0H42.009C18.824 0 0 18.824 0 42.009v489.982C0 555.176 18.824 574 42.009 574h371.982C437.176 574 456 555.176 456 531.991V42.009z"></path>
              </clipPath>
              <g clipPath="url(#_clip6)">
                <use width="456" height="574" xlinkHref="#_Image7"></use>
              </g>
            </g>
            <g transform="matrix(1.00796 0 0 1.088 0 606.346)">
              <clipPath id="_clip8">
                <path d="M456 42.009C456 18.824 437.176 0 413.991 0H42.009C18.824 0 0 18.824 0 42.009v489.982C0 555.176 18.824 574 42.009 574h371.982C437.176 574 456 555.176 456 531.991V42.009z"></path>
              </clipPath>
              <g clipPath="url(#_clip8)">
                <use width="456" height="574" xlinkHref="#_Image9"></use>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <image id="_Image1" width="854" height="428" href={getImage("static/hero_oval.png", "854x428#")}></image>

          <image
            id="_Image3"
            width="456"
            height="574"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image0, "456x574#", { position: image0Position })}
          ></image>
          <image
            id="_Image5"
            width="410"
            height="574"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image3, "382x547#", { position: image3Position })}
          ></image>
          <image
            id="_Image7"
            width="456"
            height="574"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image1, "456x574#", { position: image1Position })}
          ></image>
          <image
            id="_Image9"
            width="456"
            height="574"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image2, "456x574#", { position: image2Position })}
          ></image>
        </defs>
      </svg>
    </Box>
  )
}
