import { FormikTextField, VStack } from "@glasscanvas/elephantkit"
import { Pledge } from "@people"

type FieldProps = {
  pledge: Pledge
}

export function PledgeFieldEmail({ pledge }: FieldProps) {
  return (
    <VStack space="small" css={{ width: "clamp(150px, 300px, 100%)", paddingTop: "$3" }}>
      <FormikTextField label="Email Address" type="string" name="contactEmail" />
    </VStack>
  )
}
