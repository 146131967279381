import { Box, EmptyState, VStack } from "@glasscanvas/elephantkit"
import { ReactNode } from "react"
import { useIntl } from "react-intl"
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom"
import { PersonNav } from "../person"
import { usePerson } from "../shared"
import { getSectionMeta, SectionMeta } from "./index"
import { rmsSectionsData } from "./rms_sections_data"
import { FourOhFour } from "./four_oh_four"

export type RmsSectionProps = {
  /**
   * The unique ID for this section, also used to form the URL path
   */
  slug: string
  /**
   * Definition of the section, all attributes
   */
  sectionMeta?: SectionMeta
  /**
   * Available components for sub sections, with a default
   */
  subComponents?: Partial<Record<keyof typeof rmsSectionsData, ReactNode>>
  /**
   * Used to inject or override part of the section (e.g. an extra "Delete" button)
   */
  yieldBottom?: ReactNode
  context?: "split-navigation"
}

export function NavigatableSubsections({ context, slug, sectionMeta, subComponents }: RmsSectionProps) {

  const person = usePerson()
  const { path, url } = useRouteMatch()
  const { state, search } = useLocation()
  sectionMeta ||= getSectionMeta(slug, person)
  subComponents ||= {}

  return (
    <Switch>
      {sectionMeta.subSections[0] && (
        <Redirect exact from={url} to={{ pathname: `${url}/${sectionMeta.subSections[0]}`, state, search }} />
      )}
      {sectionMeta.subSections.map((subsectionPath) => {
        const SubComponent = subComponents[subsectionPath] || BasicSection
        return (
          <Route key={subsectionPath} path={`${path}/${subsectionPath}`}>
            <SubComponent
              context={context}
              slug={subsectionPath}
              sectionMeta={getSectionMeta(subsectionPath, person)}
              subComponents={subComponents}
            />
          </Route>
        )
      })}
      <Route path="*" component={FourOhFour} />
    </Switch>
  )
}

export function BasicSection(props: RmsSectionProps) {
  const { formatMessage } = useIntl()
  const descContextId = `rms.section.emptystate.${props.slug}`
  const descContextTr = formatMessage({ id: descContextId })
  const descContext = descContextTr == descContextId ? "" : descContextTr
  const person = usePerson()
  const sectionMeta: SectionMeta = props.sectionMeta || getSectionMeta(props.slug, person)

  if (!sectionMeta.subSections.length) {
    return <EmptyState descriptionContext={descContext} />
  }

  return (
    <Box css={{ backgroundColor: "$groupedBackground" }}>
      <PersonNav subSections={sectionMeta.subSections} />
      <VStack align="center" space="large" css={{ padding: "$15" }}>
        <NavigatableSubsections {...props} />
      </VStack>
    </Box>
  )
}
