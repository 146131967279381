import React, { Fragment, useState, useEffect, useContext } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { PledgeUiDispatch } from "./reducer"

export const AmountField = props => {
  const dispatch = useContext(PledgeUiDispatch);

  return (
    <div className={`form-group mb-0 ${props.errorStr ? 'has-error' : ''}`}>
      <div className="flex-1 form-group m-0 input-group input-group-simple align-items-center bg-white rounded">
        <span className="input-group-prepend pl-3 text-gray-500">$</span>
        <CurrencyInput
          id="input-example"
          name="input-name"
          className="transaction-amount-visible form-control no-placeholder bg-transparent"
          placeholder="Amount"
          value={props.externalValueCents ? props.externalValueCents / 100.0 : undefined}
          defaultValue={props.initialValue}
          decimalsLimit={2}
          step={1}
          allowNegativeValue={false}
          onValueChange={props.onValueChange}
          onValueChange={(value, name) => {
            dispatch({type: 'update_amount', value: value})
          }}
        />
        <span className="border-element"></span>
      </div>

      <label className="placeholder-label">
        <span></span>
        <div id="submission_response_1530143376088594_parish-error" className="error-text">
          {props.errorStr}
        </div>
      </label>
    </div>
  )
}
