import React, { PureComponent, ReactChild } from 'react'
import { cloneDeep, set } from 'lodash'

import Start from './Start'
import Repeat from './Repeat'
import End from './End'
import computeRRuleToString from '../utils/computeRRule/toString/computeRRule'
import computeRRuleFromString from '../utils/computeRRule/fromString/computeRRule'
import configureInitialState from '../utils/configureInitialState'
import translateLabel from '../utils/translateLabel'
import translations from '../translations'
// import '../styles/index.css';

interface ReactRRuleGeneratorProps {
  id?: string
  config?: {
    frequency: 'Yearly' | 'Monthly' | 'Weekly' | 'Daily' | 'Hourly'[]
    yearly: 'on' | 'on the'
    monthly: 'on' | 'on the'
    end: 'Never' | 'After' | 'On date'[]
    hideStart?: boolean
    hideEnd?: boolean
    hideError?: boolean
    weekStartsOnSunday?: boolean
  }
  value?: string
  onChange: (rrule?: string) => void
  calendarComponent: ReactChild
  translations: () => {} | {}
}

class ReactRRuleGenerator extends PureComponent<ReactRRuleGeneratorProps, {}> {
  // compute default view based on user's config
  state = configureInitialState(
    this.props.config,
    this.props.calendarComponent,
    this.props.id
  )

  static defaultProps = {
    id: null,
    value: '',
    config: {},
    onChange() {},
    calendarComponent: null,
    translations: translations.english,
  }

  componentWillMount() {
    if (this.props.onChange === ReactRRuleGenerator.defaultProps.onChange) {
      // no onChange() was provided
      throw new Error(
        'No onChange() function has been passed to RRuleGenerator. \n' +
          "Please provide one, it's needed to handle generated value."
      )
    }

    if (this.props.value) {
      // if value is provided to RRuleGenerator, it's used to compute state of component's forms
      const data = computeRRuleFromString(this.state.data, this.props.value)
      this.setState({ data })
    }
  }

  parseForInterval(rule: string) {
    return Number(rule.split("=").pop());
  }

  componentDidUpdate(prevProps: ReactRRuleGeneratorProps) {
    if (this.parseForInterval(this.props.value) === 0) {
      this.state.errorMessage = "Recurrence interval cannot be 0";
    } else {
      this.state.errorMessage = ""
    }
    if (this.props.value !== prevProps.value) {
      const data = computeRRuleFromString(this.state.data, this.props.value);
      this.setState({ data });
    }
  }


  handleChange = ({ target }) => {
    const newData = cloneDeep(this.state.data)
    set(newData, target.name, target.value)
    const rrule = computeRRuleToString(newData)
    this.setState({ data: newData })
    this.props.onChange(rrule)
  }

  render() {
    const {
      id,
      data: { start, repeat, end, options, error },
    } = this.state

    return (
      <div>
        {!options.hideError && error && (
          <div className="alert alert-danger">
            {translateLabel(this.props.translations, 'invalid_rrule', {
              value: error.value,
            })}
          </div>
        )}
        <div className="px-0 pt-3 border rounded">
          {!options.hideStart && (
            <div>
              <Start
                id={`${id}-start`}
                start={start}
                handleChange={this.handleChange}
                translations={this.props.translations}
              />
              <hr />
            </div>
          )}

          <div>
            <Repeat
              id={`${id}-repeat`}
              repeat={repeat}
              handleChange={this.handleChange}
              translations={this.props.translations}
            />
            {this.state.errorMessage && (
              <span style={{paddingLeft: 1 + "rem", color: "red"}}>{this.state.errorMessage}</span>
            )}

          </div>

          {!options.hideEnd && (
            <div>
              <hr />
              <End
                id={`${id}-end`}
                end={end}
                handleChange={this.handleChange}
                translations={this.props.translations}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ReactRRuleGenerator
