import moment from 'moment'
import Calendar from 'tui-calendar'

const _file = 'GlassTUICalendar';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassTUICalendar = (function ($) {
  var doNotHide = false;
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    if ($(element).find('.tui-calendar').length > 0) {
      var default_options = {
        usageStatistics:      false,
        isReadOnly:           true,
        defaultView:          isMobile() ? 'day' : 'month',
        taskView:             false,
        scheduleView:         ['time', 'allday'],
        useDetailPopup:       false,
        month: {
          visibleScheduleCount: 4,
        },
      };

      $(element).find('.tui-calendar').each(function() {
        var self = this;
        var custom_options = $(this).data('options');
        var options = $.extend({}, default_options, custom_options);
        var events_data = $(this).data('events');
        var recurrence_options = $(this).data('recurrence-options') || {};
        var date_range_loaded_recurring_events;

        window.addEventListener('load', () => {
          const calendar = new Calendar(this, options);
          if (events_data) {
            calendar.createSchedules(events_data);
          }

          calendar.on('clickSchedule', function(e) {
            doNotHide = true;
            var schedule = e.schedule;

            // Open popup detail view
            var schedule_popup_selector = '[data-tui-event-popup-id=' + schedule.id + ']';

            hidePopover();
            $(e.event.target).popover({
              trigger: 'click',
              html: true,
              content: $(self).find(schedule_popup_selector + ' .glass-tui-calendar-popover-container').prop('outerHTML'),
            });
          });

          $(this).find('button').click(function(e) {
            doNotHide = true;
            var $button = $(e.target);
            var action = $button.data('action');
            var current_options = calendar.getOptions();

            hidePopover();
            switch (action) {
              case 'move-prev':
                calendar.prev();
                loadMoreRecurringEvents();
                break;
              case 'move-next':
                calendar.next();
                loadMoreRecurringEvents();
                break;
              case 'move-today':
                calendar.today();
                break;
              case 'move-month':
                calendar.changeView('month');
                toggleActiveView();
                break;
              case 'move-week':
                calendar.changeView('week');
                toggleActiveView();
                break;
              default:
                return;
            }

            // Update calendar range text
            var month_config = { month: 'long', year: 'numeric' };
            var calendar_range = calendar.getDate().toDate().toLocaleString('default', month_config);
            $(self).find('#renderRange').text(calendar_range);
          });

          function toggleActiveView() {
            $(self).find('.tui-schedule-view-toggle button').toggleClass('active');
          }

          function loadMoreRecurringEvents() {
            var new_from_time = moment(calendar.getDateRangeStart().toUTCString());
            var new_to_time = moment(calendar.getDateRangeEnd().toUTCString());
            var load_from_time, load_to_time;
            var load_more_events = false;

            if (new_from_time.isBefore(recurrence_options.from_time)) {
              load_more_events = true;
              load_to_time = recurrence_options.from_time;
              recurrence_options.from_time = load_from_time = new_from_time.toISOString();
            }

            if (new_to_time.isAfter(recurrence_options.to_time)) {
              load_more_events = true;
              load_from_time = recurrence_options.to_time;
              recurrence_options.to_time = load_to_time = new_to_time.toISOString();
            }

            if (load_more_events) {
              $.ajax({
                url: recurrence_options.more_url || (document.URL + ".json"),
                method: 'GET',
                data: { from_time: load_from_time, to_time: load_to_time },
                success: function(data) {
                  var newEvents = [];
                  if (data) {
                    newEvents = data.filter(function(event) {
                      return !calendar.getSchedule(event.id, '1');
                    });
                    if (newEvents.length > 0) {
                      calendar.createSchedules(newEvents);
                    }
                  }
                }
              });
            }
          }
        });
      });
    }
  });

  $(document).on('click', function(e) {
    if (!doNotHide) {
      hidePopover();
    }
    doNotHide = false;
  });

  function hidePopover() {
    $('.tui-full-calendar-weekday-schedule-title, .tui-full-calendar-time-schedule-content').popover('dispose');
  }

  function isMobile() {
    return window.matchMedia("only screen and (max-width: 760px)").matches;
  }

  // Return API for other modules
  return {
  };
})(jQuery);

export default GlassTUICalendar
