import React, { forwardRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

// create custom element for react datepicker and wrap in forwardRef()s
export default forwardRef(({ value, onClick, onChange }, _ref) => (
  <InputGroup>
    <InputGroup.Prepend>
      <InputGroup.Text id='inputGroupPrepend'>
        <i className='fa fa-clock'></i>
      </InputGroup.Text>
    </InputGroup.Prepend>
    <Form.Control
      type='text'
      placeholder='Start Time'
      aria-describedby='inputGroupPrepend'
      required
      value={value}
      onClick={onClick}
      onChange={onChange}
      ref={_ref}
    />
    <Form.Control.Feedback type='invalid'>
      Please choose a valid start time.
    </Form.Control.Feedback>
  </InputGroup>
));
