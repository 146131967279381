import React from 'react';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (e) => {
    this.props.setSetting(this.props.id, e.target.value);
  }
  render() {
    var props = this.props;
    var inputProps = {
      id: props.html_id,
      className: "form-control custom-select",
      onChange: this.onChange,
      value: props.val || ''
    };
    const dummy_options = [{value: 1, text: 'Option 1'}, {value: 2, text: 'Another option'}];
    const options = (props.mode && props.mode == "dummy") ? dummy_options : props.options;
    return (
      <div className={"form-row " + (props.row_classes || '') + (props.in_group === false ? 'border-b' : '')} data-file={"------------------- dropdown.jsx -------------------"}>
        <label htmlFor={props.html_id}>{props.label}</label>
        <select {...inputProps}>
          {options.map((option) => (<option key={option.value} value={option.value}>{option.text}</option>))}
        </select>
        {props.help_text ?
          <small className="form-text text-muted">{props.help_text}</small> : null
        }
      </div>
    );
  }
}

export default Dropdown;
