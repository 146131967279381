import React from "react";

// custom
import { dup } from '../utils'

export default (state = {}, action) => {
  var new_state;

  switch (action.type) {
    case 'delete_service':
      new_state = dup(state);
      delete new_state[action.serviceUid];
      break;
    case 'update_service':
      if (!action.serviceAttrs.uid) {throw new Error("'update_service' action needs a 'uid' to update") }
      new_state = dup(state);
      new_state[action.serviceAttrs.uid] = dup(action.serviceAttrs);
      break;
    default:
      return state;
  }
  return new_state;
}
