import React, { useContext } from "react";
import { Formik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// react-bootstrap
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

// custom
import SetTimeCustomInput from "../service-editor/set-time-custom-input"
import { ScheduleDispatch } from "../schedule/schedule-reducer"
import LanguageCodes from "../service/language-codes"

export default (props) => {
  const dispatch = useContext(ScheduleDispatch);

  const { serviceModAttrs } = props; // TODO: use serviceAttrs to fill in defaults, but this serviceMod should only track changes I think

  const handleSubmit = values => {
    dispatch({type: 'update_service_mod', serviceModAttrs: values});
    dispatch({type: 'close_editor_modal'});
  };

  return (
    <Formik
      enableReinitialize
      initialValues={serviceModAttrs || {}}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        // actions.setSubmitting(false);
      }}
    >
      {formik => (
        <Form className='text-left col-md-12' noValidate onSubmit={formik.handleSubmit}>
          <Form.Row>
            <Form.Label className='font-weight-bold'>Cancelation</Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="canceledCheckbox">
              <Form.Check
                label="This Mass is cancelled"
                name='is_cancelled'
                type="checkbox"
                onChange={is_cancelled => formik.setFieldValue("is_cancelled", !formik.values.is_cancelled)}
                checked={formik.values.is_cancelled || false}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Label className='font-weight-bold'>Add a note to display</Form.Label>
          </Form.Row>
          <Form.Row>
            <Col className='col-md-12'>
              <Form.Group controlId='formCustomLabel'>
                <Form.Control
                  placeholder='Note'
                  name='note'
                  type='text'
                  onChange={formik.handleChange}
                  value={formik.values.note || ""}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Label className='font-weight-bold'>Customize when to show this modification</Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md='6' controlId='daysNoticeOverride'>
              <InputGroup>
                <Form.Control
                  name='daysNotice'
                  type='daysNotice'
                  as='select'
                  onChange={formik.handleChange}
                  value={formik.values.daysNotice}
                >
                  {[null,1,2,3,4,5,6,7,10,14,24,32].map(days => <option key={days} value={days}>{days == null ? 'Use the default (7 days)' : `${days} days before`}</option>)}
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Label className='font-weight-bold'>Modify the Start Time</Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md='6' controlId='validationstartTime'>
              <DatePicker
                name='startTime'
                type='startTime'
                selected={formik.values.startTime}
                onChange={date => formik.setFieldValue("startTime", date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption='Time'
                dateFormat='h:mm aa'
                customInput={<SetTimeCustomInput />}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Label className='font-weight-bold'>Modify the Language</Form.Label>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md='6' controlId='validationLanguage'>
              <InputGroup>
                <Form.Control
                  name='language'
                  type='language'
                  as='select'
                  onChange={formik.handleChange}
                  value={formik.values.language || "en"}
                >
                  <LanguageCodes languageSet="main" />
                </Form.Control>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <hr />
            <Button variant='primary' type='submit' className='mr-auto'>
              Save Changes
            </Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
}
