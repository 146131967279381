import { Box, EmptyState, Heading, VStack, Text, Button } from "@glasscanvas/elephantkit"
import { groupBy, isEmpty } from "lodash"
import { useIntl } from "react-intl"
import { Charge, useGetChargesQuery } from "../graphql"
import { DebugValues, ListCard, Loading, Separator, usePersonId } from "../shared"
import { Link, useRouteMatch } from "react-router-dom"

export function PersonChargesSection() {
  const personId = usePersonId()

  const { loading, error, data } = useGetChargesQuery({
    variables: { fromDate: `${new Date().getFullYear() - 2}-01-01T00:00:00.00Z`, personId },
  })

  if (loading) return <Loading />

  if (error) {
    return <EmptyState title="Error: Permission Denied" description={error["graphQLErrors"][0]["message"]} />
  }

  const chargesByMonth = groupBy(
    data.charges.charges,
    (charge) => String(new Date(charge.createdAt).getFullYear()) + "_" + String(new Date(charge.createdAt).getMonth())
  )

  if (isEmpty(chargesByMonth)) {
    return <EmptyState title="No charges yet" description="You don't have any charges on your account yet." />
  }

  return (
    <VStack css={{ maxWidth: "800px", width: "100%" }}>
      <Box css={{ paddingBottom: "$5" }}>
        <Heading>Transaction History</Heading>
      </Box>
      {Object.entries(chargesByMonth).map(([key, value]) => (
        <ChargesForOneMonth key={key} charges={value} />
      ))}
    </VStack>
  )
}

// Note to future devs: Zero-based indexing being used. Month 0 is January, month 1 is Feb, and so on.
function ChargesForOneMonth({ charges }: { charges: Charge[] }) {
  const { formatMessage, formatDate } = useIntl()
  const { url } = useRouteMatch()

  return (
    <VStack>
      <Separator title={formatDate(new Date(charges[0].createdAt), { month: "long", year: "numeric" })} />
      <VStack>
        {charges.map((charge) => {
          const amt_str = formatMessage(
            { defaultMessage: "{amount, number, ::currency/USD}", id: "charge.transaction_amount" },
            { amount: charge.transactionCents / 100.0 }
          )
          let charge_card_title = ""
          switch (charge.processingStatus) {
            case "success":
              charge_card_title = formatMessage(
                { defaultMessage: "Donated {amountStr}", id: "charge.donated" },
                { amountStr: amt_str }
              )
              break
            case "inprogress":
              charge_card_title = formatMessage(
                { defaultMessage: "{amountStr} currently being processed", id: "charge.inprogress" },
                { amountStr: amt_str }
              )
              break
            default:
              charge_card_title = formatMessage(
                { defaultMessage: 'Attempted {amountStr}, status is "{processingStatus}"', id: "charge.error" },
                { amountStr: amt_str, processingStatus: charge.processingStatus }
              )
              break
          }

          return (
            <ListCard
              key={charge.id}
              title={charge_card_title}
              description={[formatDate(new Date(charge.createdAt), { dateStyle: "medium" }), charge?.campaign?.title]}
            >
              <Text size="caption1" css={{ color: "$secondary" }}>
                {charge.pledge.frequency == "once" ? null : charge.pledge.canceled ? (
                  "Recurring donation has been cancelled"
                ) : (
                  <Button appearance="minimal">
                    <Link to={url.replace("/charges", "/pledges")}>Edit recurring donation</Link>
                  </Button>
                )}
              </Text>
              <DebugValues values={charge} />
            </ListCard>
          )
        })}
      </VStack>
    </VStack>
  )
}
