import { ApolloProvider } from "@apollo/client"
import { Box, FilterIcon, Sheet, Spacer, Text, ThemeProvider } from "@glasscanvas/elephantkit"
import { PeopleQuery, usePeopleQueryQuery } from "@people"
import { Loading, translations } from "@shared"
import { truncate } from "lodash-es"
import { ReactNode, Suspense, useEffect, useState } from "react"
import { RailsContext } from "react-on-rails/node_package/lib/types"
import { BrowserRouter as Router } from "react-router-dom"
import { createClient } from "../../../rmsui/src/base/apollo_client"
import PeopleQueryBuilder, { FilterButton } from "./people_query_builder"

type QueryBuilderComponentProps = {
  queryId?: string
  emailListCount?: number
  countURL?: string
  csrf: string
}

const QueryBuilderComponent = (props: QueryBuilderComponentProps, railsContext: RailsContext) => () => {
  return (
    <AppProvider csrf={props.csrf}>
      <StandaloneQueryBuilder {...props} />
    </AppProvider>
  )
}

function StandaloneQueryBuilder({ queryId, emailListCount, countURL }: QueryBuilderComponentProps) {
  const [query, setQuery] = useState<PeopleQuery>({ id: queryId })

  const [queryBuilderIsOpen, setQueryBuilderIsOpen] = useState(false)

  const closeQueryBuilder = () => setQueryBuilderIsOpen(false)
  const openQueryBuilder = () => setQueryBuilderIsOpen(true)

  const { data: existingQueryData } = usePeopleQueryQuery({ variables: { id: query?.id }, skip: !query?.id })
  const exitingFilterIsApplied = !!existingQueryData?.query?.id

  console.log({ query })

  const filteredCount = useFilteredCount({
    countURL,
    searchKickWhere: query?.searchkickWhere,
  })

  return (
    <>
      <Sheet
        hideHeader
        hideClose
        css={{ width: "1000px" }}
        overlayProps={{
          containerCSS: {
            $$zIndex: "9998",
            zIndex: "9998",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
          },
        }}
        title="Filters"
        isShown={queryBuilderIsOpen}
        onClose={closeQueryBuilder}
      >
        {(close) => (
          <Suspense fallback={<Loading />}>
            <PeopleQueryBuilder
              queryId={query?.id}
              useParams={false}
              onApply={(query) => setQuery(query)}
              onClear={() => setQuery(null)}
              close={close}
            />
          </Suspense>
        )}
      </Sheet>
      <Box css={{ paddingBlock: "$3" }}>
        <FilterButton
          css={{ "&, &&&&:hover, &&&&:active": { color: "$onPrimary !important" } }}
          iconBefore={FilterIcon}
          onPress={openQueryBuilder}
        >
          {exitingFilterIsApplied
            ? truncate(existingQueryData?.query?.descriptor || existingQueryData?.query?.humanReadable, { length: 35 })
            : "Filters"}
        </FilterButton>
        <input type="hidden" name="email[query_id]" id="email_query_id" value={query?.id ?? null} />
        {filteredCount && (
          <>
            <Spacer size={5} />
            <Text size="caption1">Filtered to {`${0} / ${emailListCount ?? 0}`}</Text>
          </>
        )}
      </Box>
    </>
  )
}

function useFilteredCount({
  countURL,
  searchKickWhere,
}: Pick<QueryBuilderComponentProps, "countURL"> & { searchKickWhere?: string }): number | undefined {
  const [count, setCount] = useState<number>()

  console.log(searchKickWhere)

  useEffect(() => {
    async function fetchCountFromURL() {
      const response = await fetch(`${countURL}?searchkickwhere=${searchKickWhere}`).catch(console.log)
      console.log(response)
    }

    if (searchKickWhere && countURL) fetchCountFromURL()
  }, [countURL, searchKickWhere])

  if (!count || count < 0) return undefined

  return undefined
}

function AppProvider({ children, csrf }: { children: ReactNode } & Pick<QueryBuilderComponentProps, "csrf">) {
  return (
    <Router>
      <ThemeProvider i18n={{ defaultLocale: "en", messages: translations.en, locale: "en" }}>
        <ApolloProvider client={createClient({ csrf })}>{children}</ApolloProvider>
      </ThemeProvider>
    </Router>
  )
}

export default QueryBuilderComponent
