import {
  Card,
  ChevronRightIcon,
  FilterIcon,
  Heading,
  HStack,
  Link,
  Pane,
  Spacer,
  StarIcon,
  Tab,
  TabList,
  TabPanel,
  TabRoot,
  Text,
  VStack,
} from "@glasscanvas/elephantkit"
import { useActor } from "@xstate/react"
import { motion } from "framer-motion"
import { isEmpty, startCase } from "lodash-es"
import { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { PeopleQueriesQuery, PeopleQueriesQueryVariables, PeopleQuery, usePeopleQueriesQuery } from "@people"
import { useHover } from "@shared"
import { QueryBuilderChildProps, sectionVariants } from "./people_query_builder"
import { useDeletePersonQueryHook } from "./people_query_hooks"

export function QueryCard({ service, query }: QueryBuilderChildProps & { query: PeopleQuery }) {
  const [_, send] = useActor(service)
  const [hoverRef, isHovered] = useHover()
  const deletePersonQuery = useDeletePersonQueryHook(query)

  return (
    <Card
      ref={hoverRef}
      border
      hoverElevation={1}
      onClick={() => send({ type: "APPLY", id: query.id, query })}
      css={{ padding: "$4", userSelect: "none", cursor: "pointer" }}
    >
      <HStack align="center">
        <VStack as={motion.div} space="small">
          <HStack align="center" space="extraSmall">
            {query?.isFavorite && <StarIcon css={{ color: "$yellow" }} size="$3" />}
            <Heading size="subHeadline">{query.descriptor || query.humanReadable}</Heading>
          </HStack>
          <HStack space="extraSmall" align="center">
            {isHovered ? (
              <>
                <Link size="caption2" underline onClick={() => send({ type: "EDIT", query })}>
                  <FormattedMessage id="filter.edit_filter" defaultMessage="Edit Filter" />
                </Link>
                {!query.preset && (
                  <Link
                    size="caption2"
                    underline
                    onClick={async () => {
                      await deletePersonQuery()
                      send("DELETE")
                    }}
                  >
                    <FormattedMessage id="filter.delete_filter" defaultMessage="Delete Filter" />
                  </Link>
                )}
              </>
            ) : (
              <>
                <FilterIcon size="$2" />
                {Number(query.id) > 0 && (
                  <Text css={{ color: "$gray1" }} size="caption2">
                    <FormattedMessage
                      id="rms.query.id"
                      defaultMessage="#{id, number, :: 000}"
                      values={{ id: query.id }}
                    />
                  </Text>
                )}
                {!!query.preset && (
                  <Text css={{ color: "$gray1" }} size="caption2">
                    <FormattedMessage id="rms.query.preset" defaultMessage="Preset" />
                  </Text>
                )}
                {!isEmpty(query.humanReadable) && !isEmpty(query.descriptor) && (
                  <Text css={{ color: "$gray1" }} size="caption2">
                    • {startCase(query.humanReadable)}
                  </Text>
                )}
              </>
            )}
          </HStack>
        </VStack>
        <ChevronRightIcon size="$3" css={{ marginInlineStart: "auto" }} />
      </HStack>
    </Card>
  )
}

type QueryListProps = {
  variables?: PeopleQueriesQueryVariables
  data?: PeopleQueriesQuery
} & Partial<QueryBuilderChildProps>

export function QueriesList({ variables, data, service, close }: QueryListProps) {
  const { data: fetched_data, loading } = usePeopleQueriesQuery({ variables, skip: !!data })

  if (loading) return null

  return (
    <VStack space="small">
      {(data || fetched_data)?.queries?.queries?.map((query) => (
        <QueryCard key={query.preset || query.id} service={service} query={query} close={close} />
      ))}
    </VStack>
  )
}

export function QueryIndex({ service, close }: QueryBuilderChildProps) {
  return (
    <VStack
      as={motion.div}
      variants={sectionVariants}
      initial="enter"
      animate="center"
      exit="exit"
      custom={-1}
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
    >
      <Spacer size="medium" />

      <TabRoot defaultValue="all" css={{ gap: "$4" }}>
        <Pane borderBottom>
          <TabList>
            <Tab value="all">
              <FormattedMessage id="filter.list_all" defaultMessage="All Filters" />
            </Tab>
            <Tab value="presets">
              <FormattedMessage id="filter.list_presets" defaultMessage="General Filters" />
            </Tab>
          </TabList>
        </Pane>

        <TabPanel value="all">
          <QueriesList variables={{ isPreset: false }} service={service} close={close} />
        </TabPanel>
        <TabPanel value="presets">
          <QueriesList variables={{ isPreset: true }} service={service} close={close} />
        </TabPanel>
      </TabRoot>
    </VStack>
  )
}
