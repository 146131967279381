import { useParams } from "react-router"
import { useCurrentPersonQuery } from "../graphql"
import { useGetPersonQuery } from "../graphql"

export function usePersonId({ id }: { id?: string } = {}) {
  const params = useParams<{ personId?: string }>()
  const { data } = useCurrentPersonQuery()

  const personIdFromParam = params?.personId
  const currentPersonId = data?.currentPerson?.id

  return id || personIdFromParam || currentPersonId
}

export function usePerson() {
  const personId = usePersonId()
  const { data } = useGetPersonQuery({ variables: { id: personId } })
  return data.person
}