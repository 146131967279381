import { Card, Paragraph, Pre } from "@glasscanvas/elephantkit"

export function DebugValues({values, ...props}) {
  if (production) return null

  return (
    <details>
      <Paragraph as="summary" css={{ outline: "none", paddingBottom: "$2", cursor: "pointer" }}>
        (SHOW DEBUG VALUES){" "}
        <Paragraph as="span" size="caption2">
          This is only shown in development
        </Paragraph>
      </Paragraph>
      <Card
        css={{
          backgroundColor: "$gray5",
          padding: "$3",
          color: "$quartenaryLabel",
          borderColor: "$primaryTint",
        }}
        border="solid"
      >
        <Pre>{JSON.stringify({ values }, null, 2)}</Pre>
      </Card>
    </details>
  )
}
