import { AvatarEditor, useToaster } from "@glasscanvas/elephantkit"
import { Position2D } from "@glasscanvas/elephantkit/dist/typings"
import { ComponentProps, useState } from "react"
import { Image, Person, useUpdatePersonMutation } from "../graphql"

type AvatarEditorProps = ComponentProps<typeof AvatarEditor>

export function PersonAvatar({
  personId,
  previewPhoto,
  fullPhoto,
  fullName,
  size = 34,
  name,
  onChange,
}: {
  personId: Person["id"]
  previewPhoto: Image
  fullPhoto: Image
  fullName?: string
  name: string
  onChange?: (avatar: Image) => void
} & Partial<AvatarEditorProps>) {
  const [position, setPosition] = useState<Position2D>({
    x: (previewPhoto?.position?.x ?? 50) / 100,
    y: (previewPhoto?.position?.y ?? 50) / 100,
  })
  const [updatePerson, { loading }] = useUpdatePersonMutation()
  const [imageId, setImageId] = useState(fullPhoto?.id || previewPhoto?.id)
  const toaster = useToaster()

  return (
    <AvatarEditor
      key={personId}
      position={position}
      onPositionChange={setPosition}
      avatarProps={{ name: fullName }}
      image={{ full: imageId ? `/image?id=${imageId}&crop=false` : undefined, url: previewPhoto?.src, id: imageId }}
      size={size}
      loading={loading}
      onRemove={async () => {
        await updatePerson({ variables: { id: personId, person: { [name]: "" } } })
        onChange?.({ id: null })
      }}
      onSave={async ({ position }) => {
        const id = previewPhoto?.id || imageId
        if (!id) return

        try {
          const formData = new FormData()
          formData.append("image[x]", String(position.x))
          formData.append("image[y]", String(position.y))

          await fetch(`/admin/images/${id}`, {
            method: "PATCH",
            body: formData,
          })

          await updatePerson({
            variables: { id: personId, person: { [name]: String(imageId) } },
          })

          onChange?.({ id: String(imageId) })
        } catch (error) {
          console.error(error)
          toaster.add({ intent: "danger", title: "Couldn't set position on Image" })
        }
      }}
      config={{
        upload: async (file) => {
          try {
            const formData = new FormData()
            formData.append("image[image]", file, file.name)

            const result = await fetch("/admin/images", { method: "POST", body: formData }).then((res) => res.json())
            const imageId = result.image_id

            setImageId(imageId)

            onChange?.({ id: String(imageId) })
          } catch (error) {
            toaster.add({ intent: "danger", title: "Couldn't upload Image" })
            console.error(error)
          }
        },
      }}
    />
  )
}
