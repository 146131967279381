import { useConfirm } from "@glasscanvas/elephantkit"
import { useHistory, useLocation } from "react-router-dom"
import { PeopleQuery, useDeletePersonQueryMutation } from "@people"

export function useDeletePersonQueryHook({ id, descriptor, humanReadable }: Partial<PeopleQuery> = {}) {
  const { search, ...location } = useLocation()
  const { replace } = useHistory()
  const urlParams = new URLSearchParams(search)
  const queryId = id ?? urlParams.get("query_id")
  const confirm = useConfirm()

  const [deletePersonQuery] = useDeletePersonQueryMutation()

  return async () => {
    try {
      await confirm({
        confirmLabel: "Delete",
        intent: "danger",
        children: `Are you sure you want to delete ${descriptor || humanReadable || "this filter?"}`,
      })
    } catch {
      return
    }

    try {
      await deletePersonQuery({
        variables: { id: queryId },
        update: (cache, { data }) => {
          if (queryId === urlParams.get("query_id")) {
            urlParams.delete("query_id")
            replace({ ...location, search: urlParams.toString() })
          }
          cache.evict({ id: `PeopleQuery:${data?.deleteQuery?.id}` })
          cache.gc()
        },
      })
    } catch (error) {}
  }
}
