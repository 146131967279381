import React from "react"

// custom
import { dup } from '../utils'
import ServiceMod from "../service-mod/service-mod";

export default (state = {}, action) => {
  var new_state

  switch (action.type) {
    case 'cancel_service':
      new_state = dup(state)
      const serviceMod = new ServiceMod(Object.assign(action.serviceModAttrs, {is_cancelled: true}))
      new_state[serviceMod.uid] = dup(serviceMod.attrs)
      break
    case 'update_service_mod':
      if (!action.serviceModAttrs.uid) {throw new Error("'update_service_mod' action needs a 'uid' to update") }
      new_state = dup(state)
      new_state[action.serviceModAttrs.uid] = dup(action.serviceModAttrs)
      break
    case 'delete_service_mod':
      new_state = dup(state)
      delete new_state[action.serviceModUid]
      break
    default:
      return state
  }
  return new_state
}
