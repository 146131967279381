import { Box, Card, Heading, Tab, TabList, TabRoot, VStack } from "@glasscanvas/elephantkit"
import { FormattedMessage } from "react-intl"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { NavLink } from "react-router-dom"
import { getSectionMeta, NavigatableSubsections, RmsSectionProps, SectionMeta } from "../base"

export function SplitNavigationSection(props: RmsSectionProps) {
  const { url } = useRouteMatch()
  const { pathname, state } = useLocation()
  const { push } = useHistory()

  const sectionMeta: SectionMeta = props.sectionMeta || getSectionMeta(props.slug)

  const padding = "$9"
  return (
    <Box css={{ padding: "$10 $4", display: "flex", width: "100%", justifyContent: "center" }}>
      <Card elevation={1} border css={{ maxWidth: "1000px", width: "100%", backgroundColor: "$background" }}>
        <TabRoot
          value={pathname}
          onValueChange={(pathname: string) => push({ pathname, state })}
          orientation="vertical"
        >
          <Box css={{ padding, flexBasis: "30%", minWidth: "300px" }}>
            <VStack>
              <Heading>{sectionMeta?.navTitle || ""}</Heading>
              <TabList>
                {sectionMeta.subSections.map((subSlug) => {
                  const pathname = `${url}/${subSlug}`
                  const navTitle = getSectionMeta(subSlug)?.navTitle
                  return (
                    <Tab value={pathname} to={{ pathname, state }} key={subSlug}>
                      <FormattedMessage id={`rms_nav.${subSlug}`} defaultMessage={navTitle || subSlug} />
                    </Tab>
                  )
                })}
              </TabList>
            </VStack>
          </Box>

          <Box
            css={{
              padding,
              borderLeft: "1px solid $gray4",
              display: "flex",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <NavigatableSubsections {...props} subComponents={props.subComponents} />
          </Box>
        </TabRoot>
      </Card>
    </Box>
  )
}
