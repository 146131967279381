const _file = 'WorksWithAmount';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var WorksWithAmount = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    $(element).find(".kind-transaction_amount, .transaction-multiplier").on('change', function() {
      $(".kind-transaction_amount").each(function() {
        var dollars_amount = $(this).prop('checked') || $(this).is("select") || $(this).hasClass("wordcount-amount") ? $(this).val() : 0
        $(this).data('calculated-cents', dollars_amount * 100)
      })

      var cent_vals = {
        set_amount_cents: 0,
        add_amount_cents: 0,
        multiply_amount_cents: 1,
      }
      var $form = $(this).parents('form')

      // transaction calculation: 'works with' fields that add to the transaction
      $form.find(".kind-transaction_amount").each(function() {
        cent_vals[$(this).hasClass("kind-adds_to_amount") ? 'add_amount_cents' : 'set_amount_cents'] += $(this).data('calculated-cents') || 0
      });

      $form.find(".transaction-multiplier").each(function() {
        cent_vals['multiply_amount_cents'] *= parseInt($(this).val());
      });

      $form.find(".kind-transaction_amount").parents("label").removeClass('active')
      $form.find(".kind-transaction_amount:checked").parents("label").addClass('active')

      const pledge_ui_params_elem = $form.find('.pledge-ui-params-in').get(0)

      if (pledge_ui_params_elem) {
        if (cent_vals['set_amount_cents'] == 0) {
          delete cent_vals['set_amount_cents']
        }
        pledge_ui_params_elem.value = JSON.stringify(Object.assign(JSON.parse(pledge_ui_params_elem.value || '{}'), cent_vals))
        pledge_ui_params_elem.onchange({target: pledge_ui_params_elem})
      }
    }) // .trigger('change');
  });
})(jQuery);