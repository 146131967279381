// DEPRECATED / TODO: to be removed after cathstan classified form is upgraded
// - replaced by refinerycms-forms/.../wordcount-amount-form-part.js

var BASE_WORDS = 30;
var BASE_COST  = 25;

const _file = 'cathstanClassifieds';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var cathstanClassifieds = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $amount_field = $(element).find('.cathstan-classifieds-wordcount-amount');
    if ($amount_field.length > 0) {
      var $form       = $amount_field.parents('form');
      var $body       = $form.find('textarea').first();
      var $week_count = $form.find('input[type="number"]').first();
      var $works_with = $form.find('.kind-transaction_amount');

      function updateAmount(e) {
        var extra_words = $body.val().replace(/^\s+|\s+$/g,"").split(/\s+/).length - BASE_WORDS;
        var subtotal    = 0;

        if ($works_with.is('select')) {
          var value = $works_with.val().replace('$', '');
          if ($works_with.val() != null) {
            var value = $works_with.val().replace('$', '');
            if (!isNaN(value)) {
              subtotal+= parseFloat(value);
            }
          }
        } else {
          $form.find(".kind-transaction_amount").each(function(){
            var value = $(this).val().replace('$', '');
            if ($(this).prop('checked') && !isNaN(value)) {
              subtotal+= parseFloat(value);
            }
          });
        }

        if (extra_words < 0) {
          extra_words = 0;
        }

        var amt = ((subtotal + BASE_COST + extra_words) * (parseInt($week_count.val()) || 1));
        $amount_field.val(amt * 100);
        $form.find('.display-classified-amount').html(amt);
      }

      $body.change(      updateAmount);
      $body.keyup(       updateAmount);
      $works_with.change(updateAmount);
      $week_count.change(updateAmount);
      $week_count.keyup( updateAmount);
    }
  });
})(jQuery);
