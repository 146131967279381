import React, { Fragment, useContext } from 'react';
import { PledgeUiDispatch } from "./reducer"
import { Dropdown } from "@glassets"

export const PaymentsSelect = props => {
  const dispatch = useContext(PledgeUiDispatch);
  const min = 2;

  return (
    <div className="form-group">
      <label className="placeholder-label">Payments</label>
      <Dropdown id="frequency-choice-dropdown" val={props.payments}
        options={Array.from({length: props.maxPayments - min + 1}, (v, k) => k + min).map((paymentsVal) => {return {value: paymentsVal, text: paymentsVal}})}
        setSetting={(id, val) => { dispatch({type: 'update_payments', value: val}) }}>
      </Dropdown>
    </div>
  )
}