export function adjustFontSize(
  text: string,
  {
    textWrapperWidth = 700,
    textWrapperLines = 4,
    minSize = 9,
    maxSize = 24,
    defaultSize = "$title1",
  } = {}
): number | string {
  if (text.length <= 70 || typeof window === "undefined") {
    return defaultSize
  }

  const fontSize = Math.max(
    minSize,
    Math.min((textWrapperWidth * textWrapperLines) / text.length),
    maxSize
  )

  return fontSize
}
