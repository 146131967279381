import React, { Fragment } from "react";

// custom components
import ServicesList from "../service/services-list";
import { useContext } from "react";
import ThemeContext from "../theme";

export default function ScheduleContainer(props) {
  const theme = useContext(ThemeContext);
  const viewer = theme.theme === "viewer";
  const scheduleNinja = props.scheduleNinja.setContext(props);
  const sectionTitles = {
    weekend: "Weekend Masses",
    weekday: "Weekday Masses",
    special: "Special Masses",
  };

  return (
    <Fragment>
      <h1>{scheduleNinja.getAttr("label") || `${scheduleNinja.getAttr("schedule_type") || "Custom"} Schedule`}</h1>
      {["weekend", "weekday", "special"].map((section) => {
        const services = scheduleNinja.services(section);
        return services.length == 0 ? null : (
          <div key={section} className={`pb-5 ${viewer && "white"}`}>
            <h2>{sectionTitles[section]}</h2>
            <ServicesList services={services} />
          </div>
        );
      })}
    </Fragment>
  );
}
