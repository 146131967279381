import React, { useState, useEffect } from 'react';

// custom components
import ThemeContext, { themes } from "../theme"
import ScheduleContainer from "../schedule/schedule-container"
import ScheduleNinja from "../schedule/schedule-ninja"

const useFetch = (url) => {
  const [data, updateData] = useState(undefined);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(url);
      const json = await response.json();
      updateData(json);
    }
    fetchData();
  }, []);

  return data;
};

export default function ScheduleViewer ({ appTarget }) {
  var orgData = useFetch(appTarget.dataset.url)

  if (!orgData) {
    return null
  }

  const themeAttrs  = Object.assign({}, themes['viewer_default'], {global_vars: orgData.global_vars})
  var scheduleNinja = new ScheduleNinja({orgData: orgData, themeAttrs: themeAttrs})

  return (
    <ThemeContext.Provider value={themeAttrs}>
      <div className='p-4 w-100'>
        <div>
          {!scheduleNinja.scheduleActive('trump') ? null :
            <ScheduleContainer {...scheduleNinja.componentProps('trump')} />
          }
          <ScheduleContainer   {...scheduleNinja.componentProps('regular')} />
          {scheduleNinja.customScheduleContexts().map((ctx) =>
            <ScheduleContainer {...scheduleNinja.componentProps(ctx)} />
          )}
        </div>
      </div>
    </ThemeContext.Provider>
  );
}
