import { Button, FormikForm, FormikTextField, Heading, HStack, useToaster, VStack } from "@glasscanvas/elephantkit"
import { Formik } from "formik"
import { useEffect } from "react"
import { selectKeys, Pledge, useUpdatePledgeMutation, useGetDomainOrgQuery } from "@people"

type PaymentSourceEditorProps = {
  hidden: boolean
  pledge: Pledge
  setTokenizeContext: (ctx: any) => void
}

export async function tokenizeCard(formikValues, tokenizeContext) {
  const cardAttrs = { ...tokenizeContext }
  const resultValues = {}
  for (const [k, v] of Object.entries(formikValues)) {
    if (["card_name", "card_number", "expire_month", "expire_year", "card_ccv"].includes(k)) {
      cardAttrs[k] = v
    } else {
      resultValues[k] = v
    }
  }

  return new Promise((resolve, reject) => {
    if (Object.keys(cardAttrs).length < 4) {
      resolve(resultValues)
    } else {
      Paydock.createToken(
        cardAttrs,
        (token, status) => {
          resolve({ ...resultValues, paydockToken: token })
        },
        function (res, status) {
          resolve(null)
        }
      )
    }
  })
}

export function PledgeFieldCc({ hidden, pledge, setTokenizeContext }: PaymentSourceEditorProps) {
  Paydock.setSandbox(!production)
  var { data } = useGetDomainOrgQuery()
  var domainOrg = data?.domainOrg

  const toaster = useToaster()

  useEffect(() => {
    if (domainOrg) {
      if (!domainOrg.paydockGatewayId || !domainOrg.paydockPublicKey) {
        toaster.add({
          intent: "danger",
          title: "Error",
          description: `This form won't work, ${
            domainOrg.paydockGatewayId ? "Gateway ID" : "public key"
          } has not been set`,
        })
      } else {
        Paydock.setPublicKey(domainOrg.paydockPublicKey)
        setTokenizeContext({ gateway_id: domainOrg.paydockGatewayId })
      }
    }
  }, [domainOrg])

  if (hidden) {
    return null
  } else {
    return (
      <>
        <VStack space="small" css={{ width: "clamp(150px, 300px, 100%)", paddingTop: "$3" }}>
          <FormikTextField label="Name on card" placeholder="Name on card" type="string" name="card_name" />
          <FormikTextField label="Card number" placeholder="Card number" type="string" name="card_number" />
        </VStack>
        <HStack space="medium" css={{ paddingTop: "$3" }}>
          <FormikTextField label="Expiry Month" placeholder="MM" type="string" name="expire_month" />
          <FormikTextField label="Expiry Year" placeholder="YYYY" type="string" name="expire_year" />
          <FormikTextField label="Expiry CVC" placeholder="000" type="string" name="card_ccv" />
        </HStack>
      </>
    )
  }
}
