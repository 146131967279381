import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Card, FormikForm, Heading, HStack, useToaster, VStack, Paragraph, Pre } from "@glasscanvas/elephantkit"
import { Formik } from "formik"
import * as Yup from "yup"
import { selectKeys, Pledge, useUpdatePledgeMutation, useCreatePledgeMutation, useGetPledgesQuery, usePerson } from "@people"
import { PledgeFieldAmount, PledgeFieldCampaign, PledgeFieldCc, PledgeFieldEmail, PledgeFieldFrequency, tokenizeCard } from "."

const fieldSets = {
  paymentSource: ["cc"],
  paymentAttrs: ["amount", "frequency", "campaign"],
  all: ["amount", "frequency", "campaign", "cc"],
}

type PledgeEditorProps = {
  pledge: Pledge
  setOpen: (open: boolean) => void
  initialFieldSet: "paymentSource" | "paymentAttrs"
}

// const PledgeValidations = Yup.object().shape({
//   transactionCents: Yup.number().required("Cannot be blank"),
// })

enum CreditCardStatus {
  NONE = "none",
  VALID = "valid",
  INVALID = "invalid",
  EXPIRED = "expired",
}

export function PledgeEditor({ pledge, setOpen, initialFieldSet }: PledgeEditorProps) {
  const [updatePledge] = useUpdatePledgeMutation()
  const [createPledge] = useCreatePledgeMutation()
  const intl = useIntl()
  const toaster = useToaster()
  const person = usePerson()
  const [showFields, setShowFields] = useState(fieldSets[initialFieldSet || "all"])
  const [tokenizeContext, setTokenizeContext] = useState({})

  // In the future, this should be taken from graphql, or a rails script that generates fields data on the front end
  const fields = ["transactionCents", "frequency", "contactEmail"]
  const cc_statuses = Object.values(CreditCardStatus)
  const showCc = showFields.includes("cc")

  const { data: pledgesData } = useGetPledgesQuery({
    variables: { fromDate: `2018-01-01T00:00:00.00Z`, personId: person.id, isSubscription: true },
  })

  const pledges = pledgesData?.pledges?.pledges
  useEffect(() => {
    let addShowFields = []
    if (pledges) {
      if (pledges.filter((pl) => pl.ccStatus == "valid").length == 0) {
        addShowFields.push("cc")
      }
      if (!person.email) {
        addShowFields.push("email")
      }
    }
    if (addShowFields.length > 0) {
      setShowFields([...new Set(showFields.concat(addShowFields))]) // .uniq
    }
  }, [pledges])

  return (
    <VStack>
      <Heading>{pledge.id ? "Update Recurring" : "Add"} Donation</Heading>
      <Formik
        initialValues={selectKeys(
          { frequency: "once", ...pledge, transactionCents: pledge.transactionCents / 100, contactEmail: person.email },
          fields
        )}
        // validationSchema={PledgeValidations}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            var newValues = await tokenizeCard(values, tokenizeContext)
            if (!newValues) {
              toaster.add({
                intent: "danger",
                title: "Error",
                description: "There was an error attempting to verify and use your card",
              })
            } else {
              const contactEmail = newValues.contactEmail
              delete newValues.contactEmail
              const paydockToken = newValues.paydockToken
              delete newValues.paydockToken

              const gqlVars = {
                variables: { paydockToken, contactEmail, pledge: { ...newValues } },
                refetchQueries: () => ["GetCharges", "GetPledges"],
              }

              if (gqlVars.variables.pledge.transactionCents) {
                gqlVars.variables.pledge.transactionCents *= 100
              }
              if (pledge.id) {
                gqlVars.variables.id = pledge.id
                await updatePledge(gqlVars)

                toaster.add({
                  intent: "success",
                  title: intl.formatMessage({ id: "pledge.pledge_updated", defaultMessage: "Pledge Updated" }),
                  description: intl.formatMessage({
                    id: "pledge.pledge_updated_description",
                    defaultMessage: "Changes saved successfully",
                  }),
                })
              } else {
                gqlVars.variables.personId = person.id
                await createPledge(gqlVars)

                toaster.add({
                  intent: "success",
                  title: intl.formatMessage({ id: "pledge.pledge_created", defaultMessage: "Pledge Created" }),
                  description: intl.formatMessage({
                    id: "pledge.pledge_created_description",
                    defaultMessage: "Processed payment successfully",
                  }),
                })
                resetForm({ values: { transactionCents: 0 } })
              }
            }
          } catch (error) {
            toaster.add({ intent: "danger", title: "Error", description: error?.message })
          }
          setSubmitting(false)
        }}
      >
        {({ values, errors, touched, resetForm, submitForm, isSubmitting }) => (
          <FormikForm>
            <HStack align="center" css={{ width: "clamp(250px, 350px, 100%)" }}>
              {showFields.includes("amount") ? PledgeFieldAmount({ pledge }) : null}
              {showFields.includes("frequency") ? PledgeFieldFrequency({ pledge }) : null}
              {showFields.includes("campaign") ? PledgeFieldCampaign({ pledge }) : null}
            </HStack>

            {showFields.includes("email") ? PledgeFieldEmail({ pledge }) : null}

            <PledgeFieldCc hidden={!showCc} pledge={pledge} setTokenizeContext={setTokenizeContext} />

            {!showFields.includes("amount") ? null : ( // null when ONLY updating card on file
              <HStack css={{ paddingTop: "$3" }}>
                <Button
                  onClick={() =>
                    setShowFields(showCc ? showFields.filter((field) => field !== "cc") : showFields.concat(["cc"]))
                  }
                  appearance="outline"
                >
                  {showCc ? "Use" : "Update"} card on file
                </Button>
              </HStack>
            )}

            <HStack space="medium" css={{ paddingTop: "$3" }}>
              <Button type="submit" color="primary" loading={isSubmitting}>
                {pledge.id ? "Save Changes" : "Process payment"}
              </Button>
              {!pledge.id ? null : (
                <Button
                  onPress={() => {
                    resetForm()
                    if (setOpen) {
                      setOpen(false)
                    }
                  }}
                  appearance="minimal"
                  disabled={isSubmitting}
                >
                  Cancel Changes
                </Button>
              )}
            </HStack>
            {!pledge.id || pledge.canceled ? null : (
              <HStack space="medium" css={{ paddingTop: "$3" }}>
                <Button
                  onPress={() => {
                    resetForm({ values: { transactionCents: 0 } })
                    submitForm()
                  }}
                  intent="danger"
                >
                  Cancel Recurring Donation
                </Button>
              </HStack>
            )}
          </FormikForm>
        )}
      </Formik>
    </VStack>
  )
}
