import { HelmetProvider } from "react-helmet-async"
import { RailsContext } from "react-on-rails/node_package/lib/types"
import { ReactNode } from "react-router/node_modules/@types/react"
import { ApolloProvider } from "@apollo/client"
import { ThemeProvider } from "@glasscanvas/elephantkit"
import { createClient, useGetDomainOrgQuery } from "@people"
import { ErrorBoundary, translations } from "@shared"

type ModuleBaseProps = {
  context: RailsContext
  children: ReactNode
}
export function ModuleBase({ context, children }: ModuleBaseProps) {
  return (
    <ThemeProvider
      toaster={{ position: "right", offset: { top: "$15", side: "$2" } }}
      i18n={{
        locale: context.i18nLocale,
        defaultLocale: context.i18nDefaultLocale,
        messages: translations[context.i18nLocale],
      }}
    >
      <ErrorBoundary>
        <ApolloProvider client={createClient()}>
          <ModuleBaseInner>
            {children}
          </ModuleBaseInner>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export function ModuleBaseInner({ children }) {
  const { data } = useGetDomainOrgQuery()

  return !data ? null : (
    <HelmetProvider>
      {children}
    </HelmetProvider>
  )
}