import { Box, Heading, Spacer } from "@glasscanvas/elephantkit"
import {
  ColoredBackgroundScrollEffect,
  ColoredWrapper,
  DEFAULTS,
  getContrastType,
  HeroLayout,
  HeroSectionProps,
  ImageCollage,
  ImageCollage2,
  OutlineButton,
} from "../../components"
import { adjustFontSize } from "../../hooks/adjustFontSize"

export function CollageLayout({ color, title, ...props }: HeroSectionProps) {
  const contrastType = getContrastType(color)

  const titleText = title || DEFAULTS.TITLE
  const titleSize = adjustFontSize(titleText, {
    textWrapperLines: 8,
    defaultSize: "$largeTitle",
  })

  return (
    <ColoredWrapper contrastType={contrastType}>
      <ColoredBackgroundScrollEffect color={color} />
      <Box
        css={{
          width: "100%",
          maxWidth: 1300,
          padding: "0 $5",
          marginInline: "auto",
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: "$5",
          rowGap: "$15",
          alignItems: "center",

          "@tabletPortrait": {
            gridTemplateColumns: "repeat(auto-fit, minmax(500px, 1fr))",
          },
        }}
      >
        <Box
          css={{
            gridRow: "2",

            "@tabletPortrait": {
              gridRow: "auto",
            },
          }}
        >
          <Heading
            as="h1"
            css={{
              fontFamily: "$serif",
              fontWeight: "normal",
              lineHeight: "1.35",
              fontSize: "$title1",

              "@tabletPortrait": {
                fontSize: "$largeTitle",
              },
            }}
          >
            {titleText}
          </Heading>
          <Spacer size="$10" />
          <OutlineButton href="/welcome" contrastType={contrastType}>
            I'm New
          </OutlineButton>
        </Box>
        {props.layout === HeroLayout.Collage ? <ImageCollage {...props} /> : <ImageCollage2 {...props} />}
      </Box>
    </ColoredWrapper>
  )
}
