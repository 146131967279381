import { useState, useEffect, useReducer, useCallback } from "react"
import { IntlProvider, FormattedMessage } from "react-intl"
import { translations } from "@shared"
import { pledgeUiReducer, PledgeUiDispatch } from "./reducer"
import { AmountField, FrequencySelect, PaymentsSelect, QuantitySelect, PledgeDescriptorSummary } from "."
import { RailsContext } from "react-on-rails/node_package/lib/types"

var validateAmount = (pledgeAttrs, pledgeUiParams) => {
  if ((pledgeAttrs.transaction_cents || 0) == 0) {
    return null
  }
  if (pledgeAttrs.transaction_cents / 100 < pledgeUiParams.min_transaction) {
    return `Transaction amount must be greater than $${pledgeUiParams.min_transaction}`
  }
  if (pledgeAttrs.pledge_cents / 100 < pledgeUiParams.min_pledge) {
    return `Pledge amount can't be less than $${pledgeUiParams.min_pledge}`
  }
  return null
}

export type PledgeUiParamsType = {
  is_donation?: boolean
  has_custom_total?: boolean
  has_custom_payments?: boolean
  frequency_options?: string[]
  frequency_slugs?: object
}

export type PledgeAttrsType = {
  frequency: string
  is_donation?: boolean
  has_custom_total?: boolean
  has_custom_payments?: boolean
  item_price?: number
  add_amount_cents?: number
  max_payments?: number
  external_cents: number
  multiply_amount_cents?: number
  campaign_length_months?: number
  pledge_cents?: number
  transaction_cents?: number
}

// Pledge UI component
export const PledgeUiLegacy = ({}: any, context: RailsContext) => {
  // Yes, we need the {} part. React on Rails passes props in the first argument and context in the second argument.
  // Also note that a valid React functional component can only take one argument (props), so this function is not a React component and you cannot add a hook here.
  // In order to access the Rails context, we need to return a React functional component here (instead of just returning a JSX element)
  // We can now use hooks within the returned functional component.
  console.log("Context is ", context)
  const { i18nLocale, i18nDefaultLocale } = context
  const [pledgeUiParams, setPledgeUiParams] = useState<PledgeUiParamsType>({})
  const [pledgeUiStore, dispatch] = useReducer(pledgeUiReducer, { pledgeAttrs: {} })
  const pledgeAttrs: PledgeAttrsType = pledgeUiStore.pledgeAttrs

  var monthDiff = (d1: Date, d2: Date) => {
    var months
    months = (d2.getFullYear() - d1.getFullYear()) * 12
    months -= d1.getMonth()
    months += d2.getMonth()
    if (d2.getDay() > d1.getDay()) {
      months -= 1 // subtract a partial month
    }
    return months <= 0 ? 0 : months
  }

  var _setPledgeUiParams: PledgeUiParamsType = (dataInElem: { value: string }) => {
    var newParams = JSON.parse(dataInElem.value || "{}")
    newParams = Object.assign(newParams, {
      has_custom_total: newParams.is_donation ? newParams.has_custom_total : true,
      is_donation: newParams.is_donation,
      item_price: newParams.is_donation ? null : newParams.item_price,
    })

    var pledgeContextAttrs = {
      has_custom_total: newParams.has_custom_total,
      has_custom_payments: newParams.has_custom_payments,
      is_donation: newParams.is_donation,
      item_price: newParams.item_price,
      add_amount_cents: newParams.add_amount_cents || 0,
      multiply_amount_cents: newParams.multiply_amount_cents || 1,
      campaign_length_months: null,
    }
    if (newParams.has_custom_payments) {
      pledgeContextAttrs.campaign_length_months = 36
    }
    if (newParams.has_max_payment_months) {
      pledgeContextAttrs.campaign_length_months = newParams.max_payment_months
    }
    if (newParams.has_end_date) {
      pledgeContextAttrs.campaign_length_months = monthDiff(new Date(), new Date(newParams.end_date))
    }
    dispatch({ type: "set_ui_context_attrs", value: pledgeContextAttrs })

    if (newParams.set_amount_cents && newParams.set_amount_cents != pledgeUiParams.set_amount_cents) {
      dispatch({ type: "update_amount", value: newParams.set_amount_cents / 100.0 }) // update state as if user typed in the AmountField
      dispatch({ type: "set_external_cents", value: newParams.set_amount_cents }) // set the AmountField to this value
    }

    setPledgeUiParams(newParams)
    return newParams
  }

  const handleParamsChange = useCallback((e) => {
    _setPledgeUiParams(e.target)
  }, [])

  useEffect(() => {
    var dataInElem = document.getElementsByClassName("pledge-ui-params-in")[0]
    dataInElem.onchange = handleParamsChange

    const newParams = _setPledgeUiParams(dataInElem)
    if (!pledgeAttrs.frequency) {
      dispatch({ type: "update_frequency", value: newParams.frequency_options[0] })
    }
  }, [handleParamsChange])

  useEffect(() => {
    var dataOutElem = document.getElementsByClassName("pledge-ui-attrs-out")[0]
    dataOutElem.value = JSON.stringify(pledgeUiStore.pledgeAttrs) // quantity, payments, payments_choice, transaction_cents, pledge_cents, frequency, etc.
    // dataOutElem.onchange();
  }, [pledgeUiStore])

  if (!pledgeUiParams.frequency_options) {
    return "loading..."
  } else {
    const frequencySelect =
      pledgeUiParams.frequency_options.length < 2 ? null : (
        <div className="mb-2">
          <FrequencySelect
            frequencyOptions={pledgeUiParams.frequency_options}
            frequencySlugs={pledgeUiParams.frequency_slugs}
            frequency={pledgeAttrs.frequency}
          />
        </div>
      )

    return (
      <IntlProvider
        locale={i18nLocale}
        defaultLocale={i18nDefaultLocale}
        messages={translations[i18nLocale]}
        onError={(e) => console.log(e)}
      >
        <PledgeUiDispatch.Provider value={dispatch}>
          <div className="py-1">
            {pledgeAttrs.has_custom_total ? null : frequencySelect}
            {!pledgeUiParams.is_donation ? null : (
              <div className="mb-2">
                <AmountField
                  errorStr={validateAmount(pledgeAttrs, pledgeUiParams)}
                  externalValueCents={pledgeAttrs.external_cents}
                />
              </div>
            )}
            {pledgeAttrs.has_custom_total ? frequencySelect : null}
            {pledgeUiParams.has_custom_payments && pledgeAttrs.max_payments > 1 && pledgeAttrs.frequency != "once" ? (
              <div className="mb-2">
                <PaymentsSelect maxPayments={pledgeAttrs.max_payments} payments={pledgeAttrs.payments_choice} />
              </div>
            ) : null}

            {!pledgeUiParams.is_donation && pledgeUiParams.has_quantity ? (
              <div className="mb-2">
                <QuantitySelect quantity={pledgeAttrs.quantity} />
              </div>
            ) : null}
            <PledgeDescriptorSummary pledgeParams={pledgeUiParams} pledgeAttrs={pledgeAttrs} />
          </div>
        </PledgeUiDispatch.Provider>
      </IntlProvider>
    )
  }
}

export const TranslatedFrequencyText = ({ id }: { id: string }) => {
  if (id) {
    return <FormattedMessage id={id} />
  } else {
    return <></>
  }
}
