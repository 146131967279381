import { global, globalStyles as elephantStyles, themeFonts } from "@glasscanvas/elephantkit"

const cmsOverrides = global({
  ".d-none, .upload-form, .modal, .auto-save-state": {
    display: "none",
  },

  a: {
    textDecoration: "none",
  },

  ".environment-indicator": {
    backgroundColor: "$primary",
    position: "fixed",
    bottom: "0",
    right: "0",
    color: "$onPrimary",
    padding: "$1 $2",
    borderRadius: "$1 0 0 0",
  },
})

export function globalStyles() {
  elephantStyles()
  themeFonts({ basePath: "/assets/" })
  cmsOverrides()
}
