import { Box, Heading, Spacer } from "@glasscanvas/elephantkit"
import { useContext } from "react"
import { ColoredWrapper, DEFAULTS, HeroSectionProps, OutlineButton } from "../../components"
import { SectionContext } from "../../section_context"

const ALIGNMENT_CONFIG = {
  left: {
    alignItems: "flex-end",
    justifyContent: "flex-start",
    textAlign: "left",
    padding: "0",

    "@tabletPortrait": {
      padding: "$12 $20",
    },
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}

export function Overlay({ title, align, color }: Partial<HeroSectionProps> & { align: "center" | "left" }) {
  const { featuredImage, featuredVideo } = useContext(SectionContext)

  const titleText = title || DEFAULTS.TITLE
  const textWrapperWidth = 1000
  let contrastType = ["lightBlue", "cream"].includes(color) ? "dark" : "light"
  if (featuredImage || featuredVideo) {
    contrastType = "light"
  }

  return (
    <ColoredWrapper
      contrastType={contrastType as "light" | "dark"}
      css={{
        background: `$${color}`,
        position: "relative",
        width: "100%",
        height: "75vh",
        minHeight: 400,
        display: "flex",
        alignItems: "center",
        marginBottom: "$15",
      }}
    >
      {featuredVideo ? (
        <Box
          css={{
            position: "absolute",
            objectFit: "cover",
            width: "100%",
            height: "100%",
            display: "block",
          }}
          as="video"
          autoPlay
          loop
          muted
          playsInline
          poster={featuredImage}
        >
          <source src={featuredVideo} type="video/mp4" />
        </Box>
      ) : (
        <Box
          css={{
            position: "absolute",
            objectFit: "cover",
            width: "100%",
            height: "100%",
          }}
          as="img"
          src={featuredImage}
        />
      )}
      <Box
        css={{
          width: "100%",
          height: "100%",
          display: "flex",

          ...(ALIGNMENT_CONFIG[align] as any),
        }}
      >
        <Box
          css={{
            maxWidth: textWrapperWidth,
            padding: "$10",
            "h1, span, btn, a": { filter: "drop-shadow(0 0 10px rgba(0,0,0,0.5))" },
          }}
        >
          <Heading
            as="h1"
            css={{
              fontFamily: "$serif",
              fontWeight: "normal",
              lineHeight: "1.35",
              fontSize: "$title1",

              "@tabletPortrait": {
                fontSize: "$extraLargeTitle",
              },
            }}
          >
            {titleText}
          </Heading>
          <Spacer size="$10" />
          <OutlineButton contrastType={contrastType} href="/welcome">
            I'm New
          </OutlineButton>
        </Box>
      </Box>
    </ColoredWrapper>
  )
}
