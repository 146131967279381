import { Box, Button, FormikForm, FormikTextField, Heading, HStack, useToaster, VStack } from "@glasscanvas/elephantkit"
import { Formik } from "formik"
import {
  useCreateSacramentalRecordMutation,
  usePerson,
  fieldDictionary,
  FlexFieldSet,
  renderFormElements,
  useAttributeLayouts,
  FieldType,
  useUpdateSacramentalRecordMutation,
  selectKeys,
} from "@people"

function freezeFieldData<Key extends PropertyKey>(object: Record<Key, FieldType>): Record<Key, FieldType> {
  return Object.freeze(object)
}

export const SACRAMENT_RECORD_FIELDS_DATA = freezeFieldData({
  recordType: {
    part_id: "recordType",
    attr_name: "recordType",
    label: "Sacrament Record Type",
    part_type: "multiple_choice",
    choices: [
      {
        custom_id: "baptism",
        val: "Baptism",
      },
      {
        custom_id: "first_communion",
        val: "First Communion",
      },
      {
        custom_id: "confirmation",
        val: "Confirmation",
      },
      {
        custom_id: "marriage",
        val: "Marriage",
      },
      {
        custom_id: "death",
        val: "Death",
      },
    ],
  },
  occurredAt: {
    part_id: "occurredAt",
    attr_name: "occurredAt",
    label: "Date",
    part_type: "date",
  },
  isThisParish: {
    part_id: "isThisParish",
    attr_name: "isThisParish",
    label: "This Parish",
    part_type: "checkbox",
  },
  parishName: {
    part_id: "parishName",
    attr_name: "parishName",
    label: "Parish Name",
    part_type: "string",
    validationType: "string",
  },
  recordLocationNotes: {
    part_id: "recordLocationNotes",
    attr_name: "recordLocationNotes",
    label: "Record Location Notes",
    part_type: "string",
    validationType: "string",
  },
  notations: {
    part_id: "notations",
    attr_name: "notations",
    label: "Notations",
    part_type: "string",
    validationType: "string",
  },
  professionOfFaith: {
    part_id: "professionOfFaith",
    attr_name: "professionOfFaith",
    label: "Profession Of Faith",
    part_type: "checkbox",
  },
  sacramentalName: {
    part_id: "sacramentalName",
    attr_name: "sacramentalName",
    label: "Confirmation Name",
    part_type: "string",
    validationType: "string",
  },
  currentMarriageStatus: {
    part_id: "currentMarriageStatus",
    attr_name: "currentMarriageStatus",
    label: "Current Marriage Status",
    part_type: "multiple_choice",
    choices: [
      {
        custom_id: "married",
        val: "Married",
      },
      {
        custom_id: "divorced_with_annulment",
        val: "Divorced with Annulment",
      },
      {
        custom_id: "divorced_with_no_annulment",
        val: "Divorced with no Annulment",
      },
      {
        custom_id: "separated",
        val: "Separated",
      },
      {
        custom_id: "widowed",
        val: "Widowed",
      },
    ],
  },
  funeralDate: {
    part_id: "funeralDate",
    attr_name: "funeralDate",
    label: "Funeral Date",
    part_type: "date",
  },
  burialDate: {
    part_id: "burialDate",
    attr_name: "burialDate",
    label: "Burial Date",
    part_type: "date",
  },
  burialPlace: {
    part_id: "burialPlace",
    attr_name: "burialPlace",
    label: "Burial Place",
    part_type: "string",
    validationType: "string",
  },
  cremated: {
    part_id: "cremated",
    attr_name: "cremated",
    label: "Cremated",
    part_type: "checkbox",
  },
})

export function SacramentPersonFields({ personKey }) {
  return (
    <VStack key={personKey} css={{ paddingTop: "$$space" }}>
      <Heading size="subHeadline">{personKey}</Heading>
      <Box as="fieldset" css={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gridGap: "$$space" }}>
        {/* <FormikTextField name={`${personKey}.title`} label="Title" placeholder="Title" /> */}
        <FormikTextField name={`${personKey}.firstName`} label="First Name" placeholder="First Name" />
        <FormikTextField name={`${personKey}.lastName`} label="Last Name" placeholder="Last Name" />
      </Box>
    </VStack>
  )
}

export function RecordForm({ setOpen, recordData }) {
  const toaster = useToaster()
  const isNewRecord = !recordData || !recordData.id
  const [createSacramentalRecord] = useCreateSacramentalRecordMutation()
  const [updateSacramentalRecord] = useUpdateSacramentalRecordMutation()
  const person = usePerson()
  const peopleKeys = ["minister", "sponsor1", "sponsor2", "proxy1", "proxy2"]
  let initialValues = selectKeys(recordData || {}, Object.keys(SACRAMENT_RECORD_FIELDS_DATA))

  if (!isNewRecord) {
    peopleKeys.forEach((personKey) => {
      initialValues[personKey] = selectKeys(recordData[personKey] || {}, ["title", "firstName", "lastName"])
    })
  }

  const fields = useAttributeLayouts({
    keys: Object.keys(SACRAMENT_RECORD_FIELDS_DATA),
    meta: SACRAMENT_RECORD_FIELDS_DATA,
    layouts: fieldDictionary,
    defaultLayout: FormikTextField,
  })

  return (
    <Formik
      initialValues={initialValues}
      // validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (isNewRecord) {
            await createSacramentalRecord({ variables: { record: { ...values, ...{ personId: person.id } } } })
          } else {
            await updateSacramentalRecord({
              variables: { id: recordData.id, record: { ...values, ...{ personId: person.id } } },
            })
          }
          setSubmitting(false)
          toaster.add({ title: "Saved", description: `Saved successfully`, intent: "success" })
        } catch (error) {
          console.error(error)
          toaster.add({
            title: error.message ? "Oops" : "Server Error",
            description: error.message || `There was an issue saving`,
            intent: "danger",
          })
        }
      }}
    >
      {({ isSubmitting, resetForm }) => (
        <FormikForm>
          <VStack space="large" css={{ $$space: "$space$3", paddingBlock: "$4" }}>
            {renderFormElements(fields)}
            {peopleKeys.map((personKey) => (
              <SacramentPersonFields key={personKey} personKey={personKey} />
            ))}
            <HStack space="medium" css={{ paddingTop: "$3" }}>
              <Button type="submit" color="primary" loading={isSubmitting}>
                Save Changes
              </Button>
              <Button
                onPress={() => {
                  resetForm()
                  setOpen(false)
                }}
                appearance="minimal"
              >
                Cancel Changes
              </Button>
            </HStack>
          </VStack>
        </FormikForm>
      )}
    </Formik>
  )
}
