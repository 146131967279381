import {
  Box,
  Button,
  Card,
  CloseIcon,
  CogIcon,
  Heading,
  Label,
  SelectField,
  TextInput,
  VStack,
} from "@glasscanvas/elephantkit"
import { ColorPicker, safelyParseJSON, translations } from "@shared"
import { AnimatePresence, motion } from "framer-motion"
import { startCase } from "lodash-es"
import { useEffect, useRef, useState } from "react"
import { IntlProvider } from 'react-intl'
import * as yup from 'yup'
import {
  DEFAULTS,
  HeroColor,
  HeroLayout,
  HeroLayoutNames,
  HeroLayoutOptions,
  HeroSection,
  HeroSectionProps,
  ImageUploader,
} from './components'
import { useSectionEditor } from './hooks/useSectionEditor'
import { SectionContext } from './section_context'

type HeroEditorProps = {
  editing: boolean
  title?: string
  featuredImage: string
  featuredVideo?: string
  templateDocument: string
  buttonTextKey?: string
  buttonPath?: string
  defaultImages?: string[]
  locale: string
  defaultLocale: string
  color?: HeroColor
}

const DEFAULT_DOCUMENT = '{"type":"element","tagName":"hero","properties":{"id":"hero"},"children":[]}'

const heroPropsSchema = yup.object().shape({
  id: yup.string().required(),
  color: yup.string().default('slate'),
  title: yup.string().default(DEFAULTS.TITLE).nullable(),
  layout: yup.string().oneOf(Object.values(HeroLayout)),
  buttonTextKey: yup.string(),
  buttonPath: yup.string(),
  image0: yup.string(),
  image1: yup.string(),
  image2: yup.string(),
  image3: yup.string(),
  image0Position: yup.string(),
  image1Position: yup.string(),
  image2Position: yup.string(),
  image3Position: yup.string(),
})

export function HeroEditor({
  editing,
  featuredImage,
  featuredVideo,
  locale,
  defaultLocale,
  title = DEFAULTS.TITLE,
  color = HeroColor.Slate,
  buttonPath,
  buttonTextKey,
  templateDocument = DEFAULT_DOCUMENT,
}: HeroEditorProps) {
  const pageTemplateFormInput = useRef<HTMLInputElement>(document?.querySelector('#page_template_document'))
  const orgColorFormInput = useRef<HTMLInputElement>(document?.querySelector('#page_org_color'))
  const [optionsIsShown, setOptionsIsShown] = useState(false)

  const templateDoc = safelyParseJSON(templateDocument, DEFAULT_DOCUMENT)
  const section = useSectionEditor<HeroSectionProps>(templateDoc, {
    id: 'hero',
    componentMap: {
      hero: HeroSection,
    },
    overrideProps: { color: color ?? undefined },
    defaultProps: {
      color,
      layout: HeroLayout.Collage2,
      title,
      buttonPath,
      buttonTextKey,
    },
    schema: heroPropsSchema,
  })

  const context = {
    editing,
    color,
    featuredImage,
    featuredVideo,
  }

  useEffect(() => {
    if (!editing || !pageTemplateFormInput.current || !orgColorFormInput.current) return
    try {
      pageTemplateFormInput.current.value = section.save()
      orgColorFormInput.current.value = section.getProp('hero', 'color')
    } catch (error) {
      console.error(error)
    }
  }, [section.save()])

  return (
    <IntlProvider locale={locale} defaultLocale={defaultLocale} messages={translations[locale]}>
      <SectionContext.Provider value={context}>{section.stringify()}</SectionContext.Provider>
      {editing && (
        <Box
          className="cms-ui"
          css={{
            position: 'absolute',
            top: 'clamp(10px, 5vw, 100px)',
            right: 'clamp(10px, 5vw, 100px)',
            zIndex: 980,
          }}
        >
          <AnimatePresence>
            {optionsIsShown ? (
              <Card
                as={motion.div}
                initial={{ opacity: 0, height: 10 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0 }}
                elevation={4}
                border
                css={{
                  overflow: 'hidden',
                  backgroundColor: '$background',
                  padding: '$6',
                  borderRadius: '$3',
                  width: '500px',
                  display: 'inline-flex',
                  flexDirection: 'column',
                  transformOrigin: 'top',
                  gap: '$2',
                }}
              >
                <Box
                  css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Heading css={{ paddingBottom: '$2', fontFamily: '$sans' }}>Edit Section</Heading>
                  <Button
                    color="tertiary"
                    appearance="minimal"
                    icon={CloseIcon}
                    onClick={() => setOptionsIsShown(false)}
                  />
                </Box>
                <SelectField
                  label="Layout"
                  css={{ maxWidth: '100%' }}
                  value={section.getProp('hero', 'layout')}
                  onChange={e =>
                    section.setProp('hero', {
                      layout: e.currentTarget.value as HeroLayout,
                    })
                  }
                >
                  {Object.values(HeroLayout).map(layout => (
                    <option key={layout} value={layout}>
                      {HeroLayoutNames[layout] ?? startCase(layout)}
                    </option>
                  ))}
                </SelectField>
                {HeroLayoutOptions[section.getProp('hero', 'layout')]?.color && (
                  <VStack css={{ marginTop: '$2' }}>
                    <Label>Theme Color</Label>
                    <ColorPicker
                      swatchSize={12}
                      colors={Object.values(HeroColor)}
                      value={section.getProp('hero', 'color')}
                      onChange={color => section.setProp('hero', { color: color as HeroColor })}
                    />
                  </VStack>
                )}
                <TextInput
                  label="Title"
                  placeholder={DEFAULTS.TITLE}
                  value={section.getProp('hero', 'title')}
                  onChange={title => {
                    section.setProp('hero', {
                      title,
                    })
                  }}
                  css={{ maxWidth: '100%' }}
                />
                {HeroLayoutOptions[section.getProp('hero', 'layout')]?.images && (
                  <>
                    <Label>Images</Label>
                    <Box
                      as={motion.div}
                      className="relative-exempt"
                      css={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        gap: '$1',
                      }}
                    >
                      {['image0', 'image1', 'image2', 'image3'].map(index => (
                        <ImageUploader key={index} index={index} section={section} />
                      ))}
                    </Box>
                  </>
                )}
                {/* {section.debug()} */}
              </Card>
            ) : (
              <Box as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Button
                  iconBefore={CogIcon}
                  size="large"
                  color="tertiary"
                  onClick={() => setOptionsIsShown(!optionsIsShown)}
                >
                  Edit Section
                </Button>
              </Box>
            )}
          </AnimatePresence>
        </Box>
      )}
    </IntlProvider>
  )
}
