import React, { useState, Fragment } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";

export default (props) => {
  return (
    <Fragment>
      <Form.Row>
        <Form.Label className='font-weight-bold'>Confession</Form.Label>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md='6' controlId='validationConfessionOffsetTime'>
          <InputGroup>
            <Form.Control as='select' style={{ height: "38px" }}>
              <option value='no'>None</option>
              <option value='30'>30 Mins</option>
              <option value='45'>45 Mins</option>
              <option value='60'>60 Mins</option>
            </Form.Control>
          </InputGroup>
        </Form.Group>
        <Form.Group
          as={Col}
          md='6'
          controlId='validationConfessionOffsetPosition'
        >
          <InputGroup>
            <Form.Control as='select' style={{ height: "38px" }}>
              <option value='before'>Before Mass</option>
              <option value='after'>After Mass</option>
            </Form.Control>
          </InputGroup>
        </Form.Group>
      </Form.Row>
    </Fragment>
  );
}
