import { styled } from "@glasscanvas/elephantkit"

export const OutlineButton = styled("a", {
  backgroundColor: "transparent",
  border: "3px solid $white",
  borderRadius: "$round",
  fontSize: "$5",
  padding: "$2 $5",
  transition: "$normal",
  color: "$white",

  "&:hover": {
    color: "$label",
    backgroundColor: "$white",
  },

  variants: {
    contrastType: {
      dark: {
        color: "$label",
        border: "3px solid $label",

        "&:hover": {
          color: "$white",
          backgroundColor: "$label",
        },
      },
      custom: {
        color: "var(--org-theme-primary-contrast-color, $white)",
        border: "3px solid var(--org-theme-primary-contrast-color, $white)",

        "&:hover": {
          color: "var(--org-theme-color, $white)",
          backgroundColor: "var(--org-theme-primary-contrast-color, $label)",
        },
      },
      light: {},
    },
  },
})

OutlineButton.defaultProps = {
  className: "btn-round",
}