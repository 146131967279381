import React from 'react';

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (e) => {
    if (e.target.checked) {
      this.props.setSetting(this.props.id, e.target.value);
    }
  }
  render() {
    const props = this.props;
    const current_value = (props.val ? props.val : 'text');
    const dummy_options = [{value: 1, text: 'Option 1'}, {value: 2, text: 'Another option'}];
    const options = props.mode && props.mode == "dummy" ? dummy_options : props.options
    const format_class = props.inline && props.inline == true ? '' : 'flex-column';
    return (
      <div
        className={"form-row {format_class} px-4 " + (props.in_group ? '' : 'py-2 border-bottom')}
        data-file={"------------------- radio-group.jsx -------------------"}
      >
        <p className="my-2 bold">{props.label}</p>
        {options.map((option) => (
          <div key={option.value}>
            <label className="custom-control custom-radio">
              <input
                id={this.props.html_id + '-' + option.value}
                type="radio"
                className="custom-control-input"
                checked={current_value == option.value}
                value={option.value}
                onChange={this.onChange}
              />
              <span className="custom-control-label">{option.text}</span>
            </label>
          </div>
        ))}
      </div>
    );
  }
}

export default RadioGroup;
