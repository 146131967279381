import { RRule, RRuleSet, rrulestr } from "rrule"
import dayjs from "dayjs"; // , { utc }
import utc from "dayjs/plugin/utc"
import uuid from "react-uuid"

import { specialMassTypeToStr } from "../service/special-mass-types"

dayjs.extend(utc)

class Service {
  constructor(serviceAttrs, themeAttrs = {}) {
    this._attrs = serviceAttrs
    this._themeAttrs = themeAttrs
    this._mods  = []

    if (!this._attrs.uid) {
      this._attrs.uid = uuid()
    }
  }

  static processIncomingData(incomingData) {
    console.log(incomingData);

    // TODO: there's got to be a better way...
    [incomingData.services || {}, incomingData.service_mods || {}].forEach((attrSet) => {
      Object.values(attrSet).forEach((attrs) => {
        if (attrs.startTime && !Number.isInteger(attrs.startTime)) {
          attrs.startTime = Date.parse(attrs.startTime)
        }
      })
    })

    return incomingData
  }

  static occurrences(services, options) {
    var result = [];
    services.forEach(service => {
      var rrule = service.rrule;
      if (rrule) {
        rrule.between(options.from, options.to, true).forEach((date) => {
          result.push({date: dayjs(date).utc(), service: service});
        })
      }
    });

    return result.sort((a, b) => a.date - b.date);
  }

  static sort(services) {
    return services.sort((a, b) => {
      return a.sortVal - b.sortVal
    })
  }

  recurrenceStr() {
    const specialMassType = specialMassTypeToStr(this._attrs.specialMassType, this._themeAttrs)

    if (specialMassType) {
      return specialMassType
    }

    if (!this._attrs || !this._attrs.recurrence) {
      return "Recurrence not set"
    }

    var rrule = rrulestr(this._attrs.recurrence)

    const weekOfMonth = ['', 'first', 'second', 'third', 'fourth', 'last']
    const monthly = rrule.options.freq == RRule.MONTHLY
    const repl = {
      every: monthly ? 'Every' : ' ',
      week: ' ',
      month: ' ',
      'on the': ' ',
      on: monthly ? weekOfMonth[rrule.options.bysetpos || 0] : ' ',
    }

    return rrule.toText((word) => {
      return repl[word] || word
    })
  }

  get sortVal() {
    var result = ((this.rrule && this.rrule.options.byweekday) ? this.rrule.options.byweekday[0] : 0) * 24 * 60
    var t = this._attrs.startTime ? dayjs(this._attrs.startTime).utc() : null
    result += t ? ((t.hour() * 60) + t.minute()) : 0
    return result
  }

  get rrule() {
    return !this.specialMassType && this._attrs && this._attrs.recurrence ? RRule.fromString(this._attrs.recurrence) : null;
  }

  get specialMassType() {
    return this._attrs.specialMassType && this._attrs.specialMassType != 'regular'
  }

  get rrMeta() {
    const rr = this.rrule
    var result = {}

    if (!this.specialMassType && rr && rr.options.byweekday && rr.options.byweekday.length > 0) {
      result['weekly'] = (rr.options.byweekday[0] >= 5 ? 'weekend' : 'weekday')
    }

    return result
  }

  get uid() {
    return this._attrs.uid
  }

  get attrs() {
    return this._attrs
  }

  startTimeFormatted() {
    if (!this._attrs.startTime) {
      return "TBD"
    }

    const startTime = dayjs(this._attrs.startTime).utc()
    return startTime.format(startTime.minute() != 0 ? "h:mm A" : "h A")
  }

  attachMod(mod) {
    this._mods.push(mod)
  }

  mods(filter = 'all') {
    return this._mods.filter(mod => mod.matchesFilter(filter))
  }
}

export default Service
