import React, { useContext, Fragment } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

// custom components
import { ScheduleDispatch } from "../schedule/schedule-reducer"

export default () => {
  const dispatch = useContext(ScheduleDispatch);

  function getClickHandler(type) {
    return function(e) {
      dispatch({type: 'add_service', serviceAttrs: {type: type}})
    }
  }

  return (
    <Fragment>
      <DropdownButton
        id='event-type-select'
        title='New Service'
        className='ml-auto'
        style={{ position: "relative", top: "-120px" }} // offset button up into top bar area
        size="xl"
      >
        <Dropdown.Item onClick={getClickHandler('mass')}>
          Mass
        </Dropdown.Item>
        <Dropdown.Item onClick={getClickHandler('confession')}>
          Confession
        </Dropdown.Item>
        <Dropdown.Item onClick={getClickHandler('devotion')}>
          Adoration
        </Dropdown.Item>
      </DropdownButton>
    </Fragment>
  );
}
