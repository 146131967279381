import uuid from "react-uuid"
import dayjs from "dayjs"

class ServiceMod {
  constructor(serviceModAttrs) {
    this._attrs = serviceModAttrs
    this._service = null

    if (!this._attrs.uid) {
      this._attrs.uid = uuid()
    }
  }

  noteText(themeMode = 'viewer') {
    const viewer = (themeMode == 'viewer')
    const serviceDescriptor = `${viewer ? '' : (this._service ? this._service.startTimeFormatted() : 'A deleted')} Mass`
    const dateFormat = "dddd MMMM D" + (viewer ? '' : ' (YYYY)')
    var mod_str      = "has changed"
    var add_note     = ''

    if (this._attrs.is_cancelled) {
      mod_str = "will be cancelled"
    }
    else if (this._attrs.startTime) {
      mod_str = `will be at ${dayjs(this._attrs.startTime).format("h:mm A")}`
    }

    if (this._attrs.note) {
      add_note = this._attrs.note
    }

    if (this._attrs.language) {
      add_note = `It will be in "${this._attrs.language}"`
    }

    return `* Notice: ${serviceDescriptor} ${mod_str} on ${dayjs(this._attrs.occurrence_date).format(dateFormat)}. ${add_note}`
  }

  attachToService(service) {
    if (service) {
      this._service = service
      this._service.attachMod(this)
    }
  }

  matchesFilter(filter) {
    const today = dayjs(this._service._themeAttrs.global_vars.date_of_today).utc()
    const occurrence_date = dayjs(this._attrs.occurrence_date)
    return filter == 'active' ? today.isAfter(occurrence_date.subtract(this._attrs.daysNotice || 7, 'day')) && today.isBefore(occurrence_date.add(1, 'day')) : true
  }

  get uid() {
    return this._attrs.uid
  }

  get attrs() {
    return this._attrs
  }
}

export default ServiceMod
