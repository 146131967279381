import { createElement, Fragment, ReactChild, useEffect, useState } from "react"
import rehypeToReact from "rehype-react"
import unified from "unified"
import type { Node } from "unist"
import find from "unist-util-find"
import map from "unist-util-map"
import type { ObjectSchema } from "yup"

// MARK: Types
export type IdentifiableSection = {
  readonly id: string
}

export type SectionEditorProps<Props = {}> = {
  /**
   * The id of the section
   */
  id?: string
  /**
   * Maps certain custom nodes to render react components
   */
  componentMap?: { [key: string]: React.FC<Props> }

  defaultProps?: Partial<Props>

  /** Override props in the document with these props */
  overrideProps?: Partial<Props>

  schema?: ObjectSchema<{}>
}

export type SectionEditorReturnType<Props = {}> = {
  /**
   * Outputs the document as a string that can be rendered by React.
   * @see `save()` for getting a version of the document that is ready
   */
  stringify: () => ReactChild

  /**
   * Outputs the sanitized document as a string for saving into the database
   */
  save: () => string

  /**
   * Outputs a debug object of the current document
   */
  debug: () => ReactChild

  /**
   * Finds a node and sets one or more property values
   * @property {id} - The unique id of the node
   */
  setProp: (id: string, prop: Partial<Props>) => void

  /**
   * Finds a node and gets a property value
   * @property {id} - The unique id of the node
   * @property {prop} - The prop you want back
   */
  getProp: (id: string, prop: Partial<{}>) => any

  /**
   * Finds a node and gets one or more property values
   * @property {id} - The unique id of the node
   * @property {props} - The props you want back
   */
  getProps: (id: string, props: string[]) => Record<string, any>[]

  setMetadata: (key: string, value: string) => void
  getMetadata: (key: string) => any
}

// MARK: Hook

/**
 * A react hook for creating and editing page sections
 * @note For rendering a section: use the `useSectionRenderer` hook for speed.
 */
export function useSectionEditor<SectionProps extends IdentifiableSection>(
  initialValue: Node = null,
  { id, componentMap, defaultProps = {}, overrideProps = {}, schema }: SectionEditorProps<SectionProps> = {}
): SectionEditorReturnType<SectionProps> {
  const [internalDocument, setInternalDocument] = useState<Node>(initialValue)
  const [metadata, setInternalMetadata] = useState<Record<string, any>>({})

  useEffect(() => {
    setDefaults()
  }, [])

  type NodeWithProperties = Node & { properties: SectionProps }

  /** @see This is the base of the unist processor, see: https://unifiedjs.com/ */
  const processor = unified().use(rehypeToReact, {
    createElement,
    Fragment,
    components: componentMap,
  })

  function setDefaults(): void {
    const newTree = map(internalDocument, function (node: NodeWithProperties) {
      if (node?.properties.id === id) {
        node.properties = Object.assign({}, defaultProps, node.properties, overrideProps)
      }
      return node
    })
    setInternalDocument(newTree)
  }

  function stringify(): string {
    const processedDocument = processor.stringify(internalDocument)
    return processedDocument
  }

  function save(): string {
    try {
      const strippedDocument = map(internalDocument, function (node: NodeWithProperties) {
        if (node?.properties.id === id) {
          node.properties = schema.cast(Object.assign({}, defaultProps, node.properties), {
            stripUnknown: true,
          }) as SectionProps
        }
        return node
      })
      // console.log(strippedDocument)

      return JSON.stringify(internalDocument)
    } catch (error) {
      console.error(error)
      return ""
    }
  }

  function debug(): ReactChild {
    return <pre>{JSON.stringify(internalDocument, null, 3)}</pre>
  }

  function setProp(id: string, propsToChange: Partial<SectionProps>): void {
    const newTree = map(internalDocument, function (node: NodeWithProperties) {
      if (node?.properties.id === id) {
        node.properties = Object.assign({}, node.properties, propsToChange)
      }
      return node
    })
    setInternalDocument(newTree)
  }

  function getProps(id: string, props: string[]): Record<string, any>[] {
    const node: NodeWithProperties = find(internalDocument, {
      properties: { id },
    })

    if (Array.isArray(props)) {
      return Object.entries(node?.properties)
        .filter(([key, value]) => props.includes(key))
        .map(([key, value]) => ({ key, value }))
    } else {
      console.warn("getProp arg: props expects a string")
      return null
    }
  }

  function getProp(id: string, prop: string): any {
    const node: NodeWithProperties = find(internalDocument, {
      properties: { id },
    })

    if (typeof prop === "string" && node?.properties) {
      return node.properties[prop] ?? undefined
    } else {
      console.warn("getProp arg: props expects a string")
      return null
    }
  }

  function setMetadata(key: string, value: string): void {
    setInternalMetadata({ ...metadata, [key]: value })
  }

  function getMetadata(key: string): any {
    return metadata[key] ?? undefined
  }

  return { stringify, save, debug, setProp, getProp, getProps, setMetadata, getMetadata }
}
