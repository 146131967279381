import { Button, Heading, Paragraph, StackPage, VStack } from "@glasscanvas/elephantkit"
import { Link } from "react-router-dom"

export const ThankYouScreen = (props) => {
  return (
    <StackPage>
      <VStack>
        <Heading size="subHeadline">Thank You Adeline!</Heading>
        <Paragraph size="caption1">
          Thank you for your generous support. We couldn't do this without you.
        </Paragraph>
        <Button round as={Link} to="/">
          Continue
        </Button>
      </VStack>
    </StackPage>
  )
}
