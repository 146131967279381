import { HStack, TextInput, FilterIcon, InputGroup, InputGroupFactory, VStack, Sheet, styled, Box, SearchIcon } from '@glasscanvas/elephantkit'
import { useDebounce } from "react-use"
import { FilterButton } from "@querybuilder"
import { lazy, ComponentProps, useState, Suspense, ReactChild } from 'react'
import { useLocation } from 'react-router-dom'
import { Loading } from './loading'
import { truncate } from 'lodash-es'
import { usePeopleQueryQuery } from '@people'

const PeopleQueryBuilder = lazy(() => import('@querybuilder/people_filter/people_query_builder'))

type FilterBarProps = {
  compact?: boolean
  onChange: (searchStr: string) => void
  initialSearchString?: string
  searchPlaceholder?: string
  tableActions: () => JSX.Element
  hideQueryBuilder: boolean
}

export function FilterBar({
  compact,
  hideQueryBuilder,
  onChange,
  initialSearchString,
  searchPlaceholder,
  tableActions: TableActionsComponent,
}: FilterBarProps) {
  return (
    <HStack
      align="center"
      css={{
        padding: compact ? '' : '0 $7',
        [`${TextInput}, ${InputGroupFactory}`]: { width: '100%', flexGrow: '1' },
      }}
    >
      <FilterBarWrapper
        compact={compact}
        as={hideQueryBuilder ? HStack : compact ? VStack : undefined}
        css={{ width: '100%' }}
      >
        {!hideQueryBuilder && <QueryBuilderSheet compact={compact} />}
        <SearchInput
          compact={compact}
          placeholder={searchPlaceholder}
          enterKeyHint="search"
          initialSearchString={initialSearchString}
          onChange={onChange}
        />
      </FilterBarWrapper>
      {!compact && !!TableActionsComponent && <TableActionsComponent />}
    </HStack>
  )
}

const FilterBarWrapper = styled(InputGroup, {
  flexGrow: 1,
  maxWidth: '100%',
  width: '100%',

  variants: {
    compact: {
      true: {
        flexWrap: 'wrap',
        border: 'none',
        flexDirection: 'column',
        gap: '$2',
      },
    },
  },
})

// MARK: SearchInput
const SEARCH_INPUT_DELAY = 500

type SearchInputProps = Omit<ComponentProps<typeof TextInput>, 'onChange'> & {
  onChange: (value: string) => void
  initialSearchString?: string
  compact?: boolean
  placeholder: string
}
export function SearchInput({ initialSearchString, compact, onChange, ...props }: SearchInputProps) {
  const [searchString, setSearchString] = useState(initialSearchString)

  useDebounce(() => onChange(searchString), SEARCH_INPUT_DELAY, [searchString])

  return (
    <Box css={{ "&, > div": { width: "100%" } }}>
      <TextInput
        iconBefore={SearchIcon}
        css={{
          width: "100%",

          "::-webkit-search-cancel-button": { display: "none" },
        }}
        type="search"
        value={searchString}
        onChange={setSearchString}
        placeholder={props.placeholder}
        {...props}
      />
    </Box>
  )
}

// MARK: Query Filter

function QueryBuilderSheet({ compact }: { compact?: boolean }) {
  const [queryBuilderIsOpen, setQueryBuilderIsOpen] = useState(false)

  const closeQueryBuilder = () => setQueryBuilderIsOpen(false)
  const openQueryBuilder = () => setQueryBuilderIsOpen(true)

  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const queryId = urlParams.get('query_id')

  const { data: existingQueryData } = usePeopleQueryQuery({ variables: { id: queryId }, skip: !queryId })
  const exitingFilterIsApplied = !!existingQueryData?.query?.id

  return (
    <>
      <Sheet
        hideHeader
        hideClose
        css={{ width: '1000px' }}
        title="Filters"
        isShown={queryBuilderIsOpen}
        onClose={closeQueryBuilder}
      >
        {close => (
          <Suspense fallback={<Loading />}>
            <PeopleQueryBuilder close={close} />
          </Suspense>
        )}
      </Sheet>
      <FilterButton
        compact={compact}
        hasFilter={exitingFilterIsApplied}
        iconBefore={FilterIcon}
        onClick={openQueryBuilder}
      >
        {exitingFilterIsApplied
          ? truncate(existingQueryData?.query?.descriptor || existingQueryData?.query?.humanReadable, { length: 35 })
          : 'Filters'}
      </FilterButton>
    </>
  )
}