import { FormikSelectField } from "@glasscanvas/elephantkit"
import { Campaign, useGetCampaignsQuery } from "@people"
import { Pledge } from "@people"

type FieldProps = {
  pledge: Pledge
}

export function PledgeFieldCampaign({ pledge }: FieldProps) {
  const { data } = useGetCampaignsQuery()
  const campaigns = data?.campaigns?.campaigns

  if (!campaigns || campaigns.length == 0) {
    return null
  }

  return (
    <FormikSelectField label="Campaign" name="campaignId" css={{ minWidth: "$20" }}>
      {campaigns.map((campaign) => (
        <option value={campaign.id}>{campaign.title}</option>
      ))}
    </FormikSelectField>
  )
}
