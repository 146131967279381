import {
  Box,
  Button,
  Heading,
  HStack,
  Pane,
  StackingOrder,
  StackingProvider,
  Tab,
  TabList,
  TabRoot,
  Text,
  Tooltip,
  UploadCircleIcon,
} from "@glasscanvas/elephantkit"
import { FormattedMessage } from "react-intl"
import { Link, matchPath, NavLink, useLocation } from "react-router-dom"

const topNavItems = [
  { title: "All People", to: "/index" },
  // { title: "Families", to: "/families" }, temporarily hidden until we get the families index working
  { title: "Staff", to: "/staff" },
  { title: "Admin", to: "/admin" },
]

export function TopNav() {
  const { pathname, search } = useLocation()

  return (
    <StackingProvider value={StackingOrder.DEFAULT + 1}>
      {(zIndex) => (
        <Box
          as="nav"
          css={{
            backgroundColor: "$background",
            borderBottom: "1px solid $muted",
            display: "flex",
            alignItems: "center",
            paddingInline: "$5",
            position: "sticky",
            top: 0,
            left: 0,
            zIndex,
          }}
        >
          <HStack align="center">
            <Pane
              border
              css={{
                borderWidth: "0 2px 0 0",
                display: "flex",
                alignSelf: "stretch",
                margin: "$3 0",
                paddingRight: "$7",
                alignItems: "center",
              }}
            >
              <Heading size="subHeadline" css={{ fontWeight: "$bold", position: "relative" }}>
                <FormattedMessage id="app.title" defaultMessage="People">
                  {(title) => (
                    <>
                      {title}
                      <Text as="sup" size="footnote">
                        Beta
                      </Text>
                    </>
                  )}
                </FormattedMessage>
              </Heading>
            </Pane>
            <TabRoot>
              <TabList>
                {topNavItems.map(({ title, to, urlParams }) => {
                  const combinedSearchParams = new URLSearchParams({
                    ...new URLSearchParams(search),
                    ...Object.fromEntries(urlParams ?? []),
                  })

                  return (
                    <NavLink exact to={{ pathname: to, search: combinedSearchParams.toString() }} key={to + title}>
                      <Tab className={matchPath(to, { path: pathname }) ? "active" : ""} value={to}>
                        {title}
                      </Tab>
                    </NavLink>
                  )
                })}
              </TabList>
            </TabRoot>
          </HStack>
          <HStack align="center" css={{ justifySelf: "flex-end", marginLeft: "auto" }}>
            <Tooltip content="Import People" placement="bottom-start">
              <Button
                size="large"
                icon={UploadCircleIcon}
                appearance="minimal"
                onPress={() =>
                  document
                    ?.querySelector(".cms-secondary-sidebar")
                    .closest(".sidebar-wrapper")
                    .classList.toggle("cms-secondary-sidebar-active")
                }
              />
            </Tooltip>
            <Button as={Link} to="/person/new">
              New Person
            </Button>
          </HStack>
        </Box>
      )}
    </StackingProvider>
  )
}
