import { useEffect } from "react"
import { CollageLayout, Overlay, TextLayout, WideImageLayout } from "../layouts"

export enum HeroLayout {
  Collage2 = "collage2",
  Collage = "collage",
  WideImage = "wide_image",
  LargeTitle = "large_title",
  OverlayCenter = "overlay_center",
  OverlayLeft = "overlay_left",
}

// Yeah this is confusing but we needed to switch the order of these
export const HeroLayoutNames: Record<HeroLayout[0], string> = {
  [HeroLayout.Collage]: "Collage 2",
  [HeroLayout.Collage2]: "Collage 1",
}

// Define extra options for layouts
export const HeroLayoutOptions = {
  [HeroLayout.Collage]: {
    images: true,
    color: true,
  },
  [HeroLayout.Collage2]: {
    images: true,
    color: true,
  },
  [HeroLayout.LargeTitle]: {
    color: true,
  },
}

export enum HeroColor {
  Slate = "slate",
  LightBlue = "lightBlue",
  Jade = "jade",
  Steel = "steel",
  Cream = "cream",
  Rose = "rose",
}

export type HeroSectionProps = {
  readonly id: string
  editing: boolean
  layout: HeroLayout
  color: HeroColor
  title: string
  featuredImage: string
  featuredVideo?: string
  buttonTextKey?: string
  buttonPath?: string
  image0?: string
  image1?: string
  image2?: string
  image3?: string
  image0Position?: string
  image1Position?: string
  image2Position?: string
  image3Position?: string
}

export const DEFAULTS = {
  TITLE: "A community that brings God’s love to everyone.",
}

export function HeroSection(props: HeroSectionProps) {
  useEffect(() => {
    document.documentElement.style.setProperty?.(
      "--hero-color",
      ["lightBlue", "cream"].includes(props.color) ? "" : `var(--gc-colors-${props.color})`
    )

    if (
      [HeroLayout.Collage, HeroLayout.Collage2, HeroLayout.LargeTitle].includes(props.layout) &&
      !["lightBlue", "cream"].includes(props.color)
    ) {
      document.documentElement.classList.add("hero-contrast-light")
    } else {
      document.documentElement.classList.remove("hero-contrast-light")
    }
  }, [props.color, props.layout])

  switch (props.layout) {
    case HeroLayout.WideImage:
      return <WideImageLayout {...props} />
    case HeroLayout.LargeTitle:
      return <TextLayout {...props} />
    case HeroLayout.OverlayCenter:
      return <Overlay align="center" {...props} />
    case HeroLayout.OverlayLeft:
      return <Overlay align="left" {...props} />
    case HeroLayout.Collage:
    case HeroLayout.Collage2:
    default:
      return <CollageLayout {...props} />
  }
}
