import { RmsSectionProps, BasicSection } from "../base"
import { PersonChargesSection, PersonPledgesSection, PersonGiveNowSection } from "./index"
import { PersonAttrsSection } from "../person"

export function PersonGivingSection(props: RmsSectionProps) {
  return (
    <BasicSection {...props} subComponents={{
      "pledges":        PersonPledgesSection,
      "charges":        PersonChargesSection,
      "give-now":       PersonGiveNowSection,
    }} />
  )
}
