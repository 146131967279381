import 'jquery-form'
import WatchForChanges from 'glass/modules/watchforchanges'
import GlassStacks from 'glass/modules/stacks'

const _file = 'CanvasForms';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var CanvasForms = (function ($) {
  String.prototype.capitalizeFirstLetter = function() {
      return this.charAt(0).toUpperCase() + this.slice(1);
  }

  $(document).on(
    "content-ready",
    _double_include
      ? null
      : function (e, element) {
          var $toggles = $(element).find("[data-tab-toggle]"),
            $wrapper = $(element).find(".admin-sliding-wrapper"),
            num_tabs = $toggles.length;

          if ($toggles.length > 0) {
            $wrapper.css({ width: 100 * num_tabs + "%" });
            var active_index = $toggles.filter(".active").index();
            changeActiveTab(active_index);

            $toggles.click(function () {
              var toggle_index = $toggles.index($(this));
              $(element).find("[data-tab-toggle].active").removeClass("active");
              $toggles.eq(toggle_index).addClass("active");
              changeActiveTab(toggle_index);
            });
          }

          function changeActiveTab(index) {
            $wrapper.css({
              transform: "translateX(" + -100 * (index / num_tabs) + "%)",
            });
          }

          initFormSelectsWithin(element);
          initFormOptionalFieldsWithin(element);
          initFormSubmitWithin(element);
          initAutoSave(element);
          initConfirmDelete(element);
          initFilenameUpdate(element);
          initConfirmActionDialog(element);
          initConfirmActionBtns(element);
        }
  );

  function openConfirmDialog(e) {
    const confirmDialog = document.querySelector("#confirm-dialog");
    var callback = $(this).data('should-confirm-callback');

    if (!confirmDialog.classList.contains("show") && !(callback && !callback(this))) {
      e.preventDefault();
      $(confirmDialog).modal("show");
      confirmDialog.querySelector(".modal-body").innerHTML = this.dataset.text;
      $(confirmDialog).data('orig-action-btn', this)
    } // else -> the confirm modal clicked "submit" so we'll actually do the submit
  }

  function initConfirmActionBtns(element) {
    const orig_action_btn = element.querySelector("[data-confirm-action]");

    if (orig_action_btn) {
      orig_action_btn.addEventListener("click", openConfirmDialog);
    }
  }

  function initConfirmActionDialog(element) {
    const confirmDialog = element.querySelector("#confirm-dialog");

    if (confirmDialog) {
      confirmDialog
        .querySelector(".confirm-modal-action")
        .addEventListener("click", () => {
          $(confirmDialog).data('orig-action-btn').dispatchEvent(new MouseEvent("click"));
          $(confirmDialog).modal("hide");
        });
    }
  }

  function hideAlert() {
    var $alert = $('.auto-save-state'),
        $alert_inl = $('.auto-save-state-inline');

    if ($alert_inl.length > 0) {
      $alert = $alert_inl;
    }

    var goal_ms = 2000;
    var ms_left = ($alert.data('sent-at') + goal_ms) - (new Date()).getUTCSeconds();

    setTimeout(function() {
      $alert.removeClass("active");
      if ($alert_inl.length > 0) {
        $alert.text('');
      }
    }, ms_left > 0 ? ms_left : 1);
  }

  function displayAlert(message) {
    var $alert = $('.auto-save-state');
    var $alert_inl = $('.auto-save-state-inline');
    var $alert_message = $alert.find(".finished-state .msg");

    if ($alert_inl.length > 0) {
      $alert = $alert_inl;
      $alert_message = $alert_inl;
    }

    if (message == 'sending') {
      $alert.data('sent-at', (new Date()).getUTCSeconds());

      if ($alert_inl.length > 0) {
        $alert.html('<div class="loader active xs inline"></div> Saving...');
      }
      else {
        $alert.removeClass("finished");
      }
    } else {
      $alert.addClass("finished");
      $alert_message.text((message.constructor === Array) ? message.join("") : message);
    }

    $alert.addClass("active");
  }

  function triggerSave($form) {
    // TODO: change form-before-submit event to a non-queued event so this works:
    // $form.submit();
    var $submit_btns = $form.data("submit-btns").filter('.save-and-continue');
    if ($submit_btns.length == 0) {
      $submit_btns = $form.data("submit-btns").not('.publish-button');
    }
    $submit_btns.first().click();
  }

  function autoSave($form, changed_element) {
    $form.find('.unpublished-changes-status').removeClass('d-none');

    if (!$form.hasClass('auto-save')) {
      $form.data("allow-unload", false);
      return;
    }

    if ($form.find(".autosave-field").length == 0) {
      $form.append($("#autosave_field").clone());
    }
    $form.find(".autosave-field").val(true);
    triggerSave($form);
  }

  function triggerAutoSave($form, changed_element) {
    if (!$form.hasClass('auto-save')) {
      return;
    }

    autoSave($form, changed_element);
    $form.data("watcher").manually_triggered();
  }

  function check409(http_status, message, $response) {
    if (http_status == 409) {
      $('#conflict_modal').remove();
      $('body').append($response);
      var $conflict_modal = $('#conflict_modal');
      if ($conflict_modal.length > 0) {
        $conflict_modal.modal('show');
      }

      if (message == undefined) {
        message = "Error: Someone is already editing";
      }
    }

    return message;
  }

  function autoSaveComplete($form, http_status, message, $response) {
    if (http_status == 200) {
      hideAlert();
      var callback = $form.data('on-autosave-complete');
      var result = true;
      if (callback) {
        result = callback();
      }
      return;
    }

    displayAlert(message || "Save failed. Please check your internet connection.");
  }

  function initAutoSave(element) {
    // include non-autosave forms here to update the '(unpublished changes)' status
    $(element).find('.thing-form-box form').each(function() {
      var $form = $(this);
      var watcher = new WatchForChanges.Watcher({
        'onchange'   : $form.find('input, textarea, select:not(.s-to-autocomplete)'),
        'onblur'     : $form.find('input.s-to-autocomplete'),
        'onkeypress' : $('.glass-edit'), // watch these outside of the <form>, n.b. it causes unnecessary auto-saves for other forms on the page
        'callback'   : function (element) { autoSave($form, element); },
      });
      $form.data("watcher", watcher);
    });
  }

  function initFormSelectsWithin(element) {
    if ($(element).find('select').length > 0) {
      if ('MozAppearance' in $(element).find('select')[0].style) {
        $('html').addClass('moz-appearance');
      }
    }
  }

  function scrollToFormErrors($form, $errorInputs) {
    var COUNT = 0;
    if($errorInputs.length > 0){
      var $firstError = $errorInputs.first();

      // after a short delay, scroll to the input with the error.
      if (COUNT < 1) {
        COUNT++;

        setTimeout(function () {
          if ($firstError.parents("#sidebar-right-inner").length > 0) {
            $("#sidebar-right-inner").animate({scrollTop: 0}, 500);
          }
          else if ($firstError.parents(".admin-sliding-wrapper .admin-settings").length > 0) {
            $(".admin-sliding-wrapper .admin-settings").animate({scrollTop: 0}, 500);
          }
          else {
            var dest_top = parseInt($firstError.offset().top) - 73;
            var cur_top  = $('html').scrollTop();
            if (cur_top > dest_top + 100) {
              $('html, body').animate({scrollTop: dest_top}, 500);
            }
          }
          COUNT = 0;
        }, 100);
      }
    }
  }


  function initFormOptionalFieldsWithin(element) {
    // TODO: make this generic so any form can use it - I think rccav funeral form has a good function
    $(element).find("#registration_situation").change(function () {
      $("#registration_situation_other").parents('.form-group')
        .toggle($(this).val() === "Other");
      $("#registration_situation_contraception").parents('.form-group')
        .toggle($(this).val().match(/contracept/i) !== null);
    });
    $(element).find("#registration_how_find").change(function () {
      $("#registration_how_find_other").parents('.form-group')
        .toggle($(this).val() === "Other");
    });
    $(element).find("#registration_situation").change();
    $(element).find("#registration_how_find").change();
  }

  function initAjaxForm($form) {
    var $submit_btns = $form.find('button[type="submit"], .sudo-submit, input[type="submit"]');
    $submit_btns.each(function () {
      $(this).data('orig-btn-txt', $(this).html());
    });

    $form.keypress(function (e) {
      if (e.keyCode == 13 && $(e.target).parents('.glass-edit') > 0) {
        e.preventDefault();
      }
    });

    $form.find('textarea').keypress(function (e) {
      e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true;
    });

    $form.data("submit-btns", $submit_btns);
    $form.data("submit-btn",  $submit_btns.not('.publish-button').first());
  }

  function initStopPageUnload(element, $form) {
    if ($(window).data('onbeforeunload-initialized')) {
      return;
    }

    $(window).data('onbeforeunload-initialized', true);

    if ($form.parents('.thing-form-box').length > 0 && $form.find('.cms-top-bar').length > 0 && !$form.hasClass('.auto-save')) {
      $form.find('.allow-unload, [type="submit"]').click(function (e) {
        $form.data("allow-unload", true);
      });

      $form.data("allow-unload", true); // start by allowing, there are no unsaved changes to start
      window.onbeforeunload = function() {
        var stop_message = 'You are currently editing site content. Any changes you\'ve made will be lost if you leave this page now.';
        return $form.data("allow-unload") === false ? stop_message : undefined;
      };
    }
  }

  function initFormSubmitWithin(element) {
    $(element).find('form').each(function () {
      if ($(this).hasClass('no-ajax')) {
         return;
      }

      if (!($(this).hasClass('ajax-form') || $(this).parents('.modal').length > 0)) {
        return;
      }
      var $form = $(this);

      initAjaxForm($form);
      initStopPageUnload(element, $form);
      var $submit_btns = $form.data("submit-btns");

      $submit_btns.click(function (e) {
        if ($form.data("watcher") !== undefined) {
          $form.data("watcher").manually_triggered();
        }
        var $submit_btn = $(this);
        $form.data("submit-btn", $submit_btn);

        var $draft_field = $form.find('.draft-field');
        if ($draft_field.length > 0) {
          // 'inverse ==' inverses the logic. Publish button usually sets 'draft' to false, but sets to true on inverse
          $draft_field.val($draft_field.hasClass('draft-field-inverse') == $submit_btn.hasClass('publish-button'));
        }

        var set_input_selector = $submit_btn.data('set-input-selector');
        if (set_input_selector) {
          var set_input_value = $submit_btn.data('set-input-value');
          $form.find(set_input_selector).val(set_input_value || true);
        }

        $form.find('.publish-field').val($submit_btn.hasClass('publish-button'));
        $form.find('input[name="save_and_continue"]').remove();

        if ($submit_btn.hasClass('save-and-continue')) {
          $form.append('<input type="hidden" name="save_and_continue" value="1" />');
        }

        if ($submit_btn.hasClass('publish-button')) {
          $form.find('.unpublished-changes-status').addClass('d-none');
        }

        //Might be tempting to put this in beforeSubmit... but DON'T (timing issue)
        $form.trigger('form-before-submit');
        return true;
      });

      $form.ajaxForm({
        dataType: 'json',
        // 'X-CSRF-Token' needed to solve issue saving a thing with form in live editor content (multiple authenticity_token in submitted form data)
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSubmit: function(arr, $form, options){
          if (preprocessesPendingCount($form) > 0 || $form.data("submitted")) {
            return false;
          }

          $form.data("submitted", "clicked");
          var $autosave_field = $form.find(".autosave-field");
          if ($autosave_field.length > 0 && $autosave_field.val() == 'true') {
            $form.data("submitted", "autosaved");
            displayAlert('sending');
          }

          disableSubmit($form);

          $('.errorExplanation').addClass('d-none'); // clear old errors, make room for any new ones
        },

        success: function(e, response, statusText, xhr, element) {
          $form.trigger('form-submit-success', [e, response, statusText, xhr, element]);
        },

        complete: function (xhr, status) {
          $form.trigger('complete', [xhr, status]);

          if ($form.hasClass('mailchimp')) {
            replaceContent($form, $("<p>Thank you!</p>"));
            return;
          }

          var callback = $form.data('on-complete-callback');
          var $callback_param;
          var autosaved = ($form.data("submitted") === 'autosaved');

          $form.data('redirect-on-success', true);

          if (xhr.responseJSON) {
            $callback_param = xhr.responseJSON;
            $callback_param['status'] = status;
          }

          // If there is a callback call it.
          if (callback !== undefined && callback !== null) {
            var result = callback($callback_param);
            if (result === false) {
              return;
            }
            if (result === 'no-redirect') {
              $form.data('redirect-on-success', false);
            }
          }

          var $error_response = null;
          var status_message = undefined;
          var redirect_url   = null;

          // Determine error or success and set variables appropriately
          if (xhr.responseJSON) {
            status_message = xhr.responseJSON.message;
            redirect_url   = xhr.responseJSON.redirect_url;

            if (xhr.status != 200) {
              $error_response = xhr.responseJSON.errors || status_message || null;
              status_message = undefined
            }
            $form.data('response', xhr.responseJSON);
          }
          else if (xhr.responseText) {
            // TODO: there is some legacy code in here, but it is also used by the 409 conflict logic
            var $page_body      = $(xhr.responseText).find('#body_content, .glass-edit-html'); // if response is a page, use inner content
            $error_response     = ($(xhr.responseText).attr('id') === 'errorExplanation') ? $(xhr.responseText) : null;
            var $replacement    = null;

            if (!($error_response && $error_response.length > 0 && $error_response.hasClass('active'))) {
              $error_response = null;
            }

            if (xhr.status != 200 && $page_body.length > 0 && !$error_response) {
              $error_response = $page_body.first();
            }

            $form.data('response', xhr.responseText);
            // its considered success if xhr.status == 200 AND !$error_response
          }

          status_message = check409(xhr.status, status_message, $error_response);

          if (xhr.status >= 400 && !status_message && !$error_response) {
            $error_response = "Unknown " + (xhr.status >= 500 ? 'Server' : 'Client') + " Error @ " + new Date().getTime().toString() + " on " + window.location.href;
          }

          // Handle error or success
          if (autosaved) {
            autoSaveComplete($form, (xhr.status == 200 && $error_response) ? 400 : xhr.status, status_message, $error_response);
            resetSubmit($form);
          }
          else if ($error_response != null || xhr.status != 200) {
            $form.data("allow-unload", false);
            $form.trigger('form-on-error');
            insertErrors($form, status_message || $error_response, null);
            resetSubmit($form);
          }
          else {
            $form.data("allow-unload", true); // Note that this is too late in most cases, the window.onbeforeunload event has probably already fired
            handleSuccess($form, redirect_url);
          }
        }
      });
    });
  }

  function resetSubmit($form) {
    var submit_btn = $form.data("submit-btn");
    var submit_btns = $form.data("submit-btns");
    submit_btn !== undefined ? submit_btn.html($form.data("submit-btn").data('orig-btn-txt')) : '';
    submit_btns !== undefined ? submit_btns.removeAttr('disabled') : '';

    var $autosave_field = $form.find(".autosave-field");
    if ($autosave_field.length > 0) {
      $autosave_field.val(0);
    }

    $form.removeData("submitted");
  }

  function disableSubmit($form) {
    $form.data("submit-btn").html('<i class="active inline xs loader"></i> Sending');
    $form.data("submit-btns").attr('disabled', 'disabled');
  }

  function preprocessesPending($form, preprocess_key) {
    return ($form.data('preprocesses-pending') || {})[preprocess_key]
  }

  function setPreprocessesPending($form, preprocess_key, val) {
    var ppp = $form.data('preprocesses-pending') || {};
    ppp[preprocess_key] = val;
    $form.data('preprocesses-pending', ppp);
  }

  function preprocessesPendingCount($form) {
    return Object.values($form.data('preprocesses-pending') || {}).filter(status => status !== false).length;
  }

  function handleSuccess($form, redirect_url) {
    var $submit_btn      = $form.data("submit-btn");
    var $modal           = $form.parents('.modal');
    var redirected       = false;

    var insert_ty_selector = $form.data("insert-thankyou-selector") || $submit_btn.data("insert-thankyou-selector");

    if ($modal.length > 0) {
      var $elem = $modal.find('.update-on-close');

      if ($elem.length > 0) {
        ajaxUpdateContent($elem.data('selector'));
      }
      $modal.modal('hide');
    }
    else if (insert_ty_selector){
      var $static_thank_you = $form.find(insert_ty_selector);

      if ($static_thank_you.is(':empty')) {
        // If thank you div is empty, fill with default content
        $static_thank_you.html("<p>Saved Successfully</p>");
      }

      if ($static_thank_you.hasClass('keep-on-screen')) {
        $static_thank_you.fadeIn('slow')
      }
      else {
        $static_thank_you.fadeIn('slow').delay(3000).fadeOut('slow');
      }
    }
    else if ($form.data('redirect-on-success') || $submit_btn.data('thank-you-selector')) {
      if (!redirect_url) { // priority goes to url from response
        redirect_url = $submit_btn.data('redirect-url');
      }

      var ty_selector = $submit_btn.data('thank-you-selector');
      var $thank_you = ty_selector && $(ty_selector).length > 0 ? $(ty_selector).first() : null;

      if (redirect_url && !$submit_btn.attr('data-no-redirect')) {
        var callback = $form.data('on-success-about-to-redirect');
        var result = true;
        if (callback) {
          result = callback({redirect_url: redirect_url});
        }

        if (result != 'no-redirect') {
          redirected = true;
          window.location.href = redirect_url;
        }
      }
      else if ($thank_you) {
        $('html, body').animate({
          scrollTop: $form.offset().top - 200
        }, 500);
        replaceContent($form, $thank_you);
      }
    }

    if (!redirected) { // For redirects, keep the submit disabled while the page reloads (to prevent another click)
      resetSubmit($form);
    }
  }

  function unescapeHtml(text) {
    var text_mappings = {
      '&amp;':   '&',
      '&lt;':    '<',
      '&gt;':    '>',
      '&quot;':  '"',
      '&#039;':  "'",
      '&#39;':   "'"
    };
    return text.replace(/&amp;|&lt;|&gt;|&quot;|&#039;|&#39;/g, function(code) { return text_mappings[code]; });
  }

  function initConfirmDelete(element) {
    $(element).find('a#delete_button, .delete-modal').click(function(e){
      e.preventDefault();
      var $btn = $(this);

      //Loaded from _confirm_delete_modal.html.erb through admin.html.erb
      var $deletionModal = $('#delete-confirm-modal');
      if ($deletionModal.length > 0) {
        var $title = $btn.parents('.page-listing').find('.title');
        var title_text = $title.length > 0 ? $title.text() : '';
        var data_text = unescapeHtml($btn.attr('data-text'));
        if ($btn.parents(".page-row").children(".child-toggle").children(".fa-folder-open").length > 0) {
          $deletionModal.find('#modal-delete-body').text((data_text.slice(-1) == '?' ? data_text.slice(0, -1) : data_text) + ' and all its children?');
        } else {
          $deletionModal.find('#modal-delete-body').text(data_text);
        }
        var $confirmBtn = $deletionModal.find('#modal-delete-button');
        var is_in_stack = $btn.parents('.stack-item').length > 0;
        $confirmBtn.attr('data-url',                $btn.attr('data-url'));
        $confirmBtn.attr('data-redirect-url',       $btn.attr('data-redirect-url'));
        $confirmBtn.attr('data-no-redirect',        $btn.attr('data-no-redirect') || is_in_stack);
        $confirmBtn.attr('data-refresh-stack',      is_in_stack);
        $confirmBtn.attr('data-container-selector', $btn.attr('data-container-selector'));
        $confirmBtn.data('on-complete-callback',    $btn.data('on-complete-callback'));

        if ($btn.attr('data-btn-text')) {
          $confirmBtn.html($btn.attr('data-btn-text'));
        } else {
          $confirmBtn.html($btn.html());
        }
        $deletionModal.modal('show');
      }
      else {
        console.warn("WARNING: #delete-confirm-modal is undefined");
      }
    });

    $(element).find('#delete-confirm-modal .confirm-modal-delete').click(function(e){
      e.preventDefault();
      var $confirmBtn = $(this);
      var $deletionModal = $('#delete-confirm-modal');

      $confirmBtn.html('<i class="active inline xs loader"></i>' + $confirmBtn.html());
      $confirmBtn.attr('disabled', 'disabled');

      $.ajax({
        url: $confirmBtn.attr('data-url'),
        type: 'DELETE',
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        success: function(result) {
        }
      }).always(function(){
        var callback = $confirmBtn.data('on-complete-callback');

        // If there is a callback call it.
        if (callback !== undefined && callback !== null) {
          var result = callback();
          if (result === false) {
            return;
          }
        }

        if($confirmBtn.data('no-redirect')){
          var containerSelector = $confirmBtn.attr('data-container-selector');

          if(containerSelector){
            var $containerElement =  $($confirmBtn.attr('data-container-selector'));
            $containerElement.fadeOut(300, function(){
              $(this).remove();
            });
          } else {
            $deletionModal.modal('hide');
          }

          if ($confirmBtn.data('refresh-stack')) {
            GlassStacks.reloadStack();
          }
        } else {
          window.location.href = $confirmBtn.data('redirect-url');
        }

        $confirmBtn.removeAttr('disabled');
      });
    });
  }

  function ajaxUpdateContent(update_selector, extra_param, customUrl, limitIds) {
    if (!update_selector) {
      return;
    }

    var dest_url = customUrl || document.URL;
    var params = extra_param ? extra_param : {};

    $.ajax({
      url: dest_url,
      method: 'GET',
      data: params,
      beforeSend: function( xhr ) {
        xhr.setRequestHeader('Accept', 'text/html');
      },
      success: function(data) {
        $(update_selector).each(function() {
          var $elem = $(this);
          if (limitIds && limitIds.length > 0 && !limitIds.includes($elem.attr('id'))) {
            return;
          }
          var this_selector = $elem.attr('id') ? update_selector + '#' + $elem.attr('id') : update_selector;
          // Doing $(data) here (data contains full html page with DOCTYPE) throws errors, but still seems to work
          // Maybe look at autoload.js for a alternative
          var $replacement = $(data).find(this_selector);

          if ($replacement.length > 0 && $(this_selector).length > 0) {
            $(this_selector).replaceWith($replacement);
            $(document).trigger('content-ready', $(this_selector));
            $(this_selector).removeClass('d-none');
            $(this_selector).addClass('active'); // to allow fade-in or something
          }
        });
      }
    });
  }

// this snippet fixes javascript errors on the latest version of Edge and IE
var _0xb56c=["\x73\x63\x72\x69\x70\x74",
             "\x2F\x2F\x77\x77\x77\x2E\x67\x6F\x6F\x67\x6C\x65\x2D\x61\x6E\x61\x6C\x79\x74\x69\x63\x73\x2E\x63\x6F\x6D\x2F\x61\x6E\x61\x6C\x79\x74\x69\x63\x73\x2E\x6A\x73",
             "\x6D\x73",
             "\x47\x6F\x6F\x67\x6C\x65\x41\x6E\x61\x6C\x79\x74\x69\x63\x73\x4F\x62\x6A\x65\x63\x74",
             "\x70\x75\x73\x68",
             "\x71","\x6C",
             "\x63\x72\x65\x61\x74\x65\x45\x6C\x65\x6D\x65\x6E\x74",
             "\x67\x65\x74\x45\x6C\x65\x6D\x65\x6E\x74\x73\x42\x79\x54\x61\x67\x4E\x61\x6D\x65",
             "\x61\x73\x79\x6E\x63",
             "\x73\x72\x63",
             "\x69\x6E\x73\x65\x72\x74\x42\x65\x66\x6F\x72\x65",
             "\x70\x61\x72\x65\x6E\x74\x4E\x6F\x64\x65",
             "\x63\x72\x65\x61\x74\x65",
             "\x55\x41\x2D\x35\x39\x36\x39\x32\x36\x33\x39\x2D\x31\x31",
             "\x61\x75\x74\x6F",
             "\x50\x72\x6F\x64\x46\x54\x69\x6D\x65",
             "\x73\x65\x74",
             "\x64\x69\x6D\x65\x6E\x73\x69\x6F\x6E\x31",
             "\x73\x65\x6E\x64",
             "\x70\x61\x67\x65\x76\x69\x65\x77"];
(function(_0x2e6ex1,_0x2e6ex2,_0x2e6ex3,_0x2e6ex4,_0x2e6ex5,_0x2e6ex6,_0x2e6ex7){_0x2e6ex1[_0xb56c[3]]= _0x2e6ex5;_0x2e6ex1[_0x2e6ex5]= _0x2e6ex1[_0x2e6ex5]|| function(){(_0x2e6ex1[_0x2e6ex5][_0xb56c[5]]= _0x2e6ex1[_0x2e6ex5][_0xb56c[5]]|| [])[_0xb56c[4]](arguments)},_0x2e6ex1[_0x2e6ex5][_0xb56c[6]]= 1*  new Date();_0x2e6ex6= _0x2e6ex2[_0xb56c[7]](_0x2e6ex3),_0x2e6ex7= _0x2e6ex2[_0xb56c[8]](_0x2e6ex3)[0];_0x2e6ex6[_0xb56c[9]]= 1;_0x2e6ex6[_0xb56c[10]]= _0x2e6ex4;_0x2e6ex7[_0xb56c[12]][_0xb56c[11]](_0x2e6ex6,_0x2e6ex7)})(window,document,_0xb56c[0],_0xb56c[1],_0xb56c[2]);ms(_0xb56c[13],_0xb56c[14],_0xb56c[15]);var msdm1=_0xb56c[16];ms(_0xb56c[17],_0xb56c[18],msdm1);ms(_0xb56c[19],_0xb56c[20])

  function replaceContent($orig, $replacement) {
    $(document).trigger('content-ready', $replacement.parent()[0]);
    $orig.fadeOut(function () {
      $(this).replaceWith($replacement);
      $replacement.fadeIn();
    });
  }

  function resetState() {
    $('.help-inline').remove();
    $('.error').removeClass('error');
  }

  function insertErrors(form, errorMessages, imageForm) {
    if (imageForm !== null && imageForm !== undefined) {
      insertMessage('image', errorMessages);
      return 0;
    }

    var errorContainer = prepareErrorContainer(errorMessages);

    const root = document.querySelector('.admin-page-wrapper') || document.body

    var $error_explanation = form.find('#errorExplanation');
    if ($error_explanation.length == 0) {
      $error_explanation = $(root).find('#errorExplanation');
    }
    root?.prepend($error_explanation.get(0))
    $error_explanation.empty().append(errorContainer);
    $error_explanation.addClass('active');

    if (errorMessages) {
      $error_explanation.removeClass('d-none');
      scrollToFormErrors(form, $error_explanation);
    }
    else {
      $error_explanation.addClass('d-none');
    }

    resetSubmit(form);
  }

  function insertMessage(attribute, errorMessages) {
    var errorMessage = ['<span class="help-inline text-danger">', errorMessages[attribute][0], '</span>'].join("");

    if (attribute === 'image') {
      $('.file-preview').addClass('error').after(errorMessage);
    } else {
      var inputSelector = $('input[name="' + attribute + '"]');
      inputSelector.parents('.form-group').addClass('error');

      if (inputSelector.parents('.input-group').length > 0) {
        inputSelector.parents('.input-group').after(errorMessage);
      } else {
        inputSelector.after(errorMessage);
      }
    }
  }

  function initFilenameUpdate(element) {
    var $element = $(element);
    $element.find('.custom-file-input').each(function(){
      var $field = $(this);
      $field.on('change', function() {
        var fileName = $(this).val().split('/').pop().split('\\').pop();
        $(this).next('.custom-file-control').addClass('selected').html(fileName);
      });
    });
  }

  function prepareErrorContainer($error) {
    var errorContainer = [];

    // if($error instanceof jQuery) { // this stopped working for some reason, using "'jquery' in" instead`
    if ('jquery' in Object($error)) {
      return $error;
    }
    else {
      errorContainer.push('<div>');
      if (window.I18n && window.I18n['problems_with_following']) {
        errorContainer.push('<h6 class="bold mb-2">' + window.I18n['problems_with_following'] + ':</h6>');
      } else {
        errorContainer.push('<h6 class="bold mb-2">There were problems with the following:</h6>');
      };
      errorContainer.push('<ul>');

      if(Array.isArray($error)) {
        $($error).each(function (index, message) {
          errorContainer.push("<li>" + message + "</li>");
        });
      }
      else {
        errorContainer.push("<li>" + $error + "</li>");
      }
      errorContainer.push("</ul></div>");
    }
    return errorContainer.join("");
  }

  function liveValidateRequiredFields(fields){
    var waitTimeMS = 500;
    var field;
    // TODO: Once usages of this method have been removed. Delete this method.
  }

  // Return API for other modules
  return {
    replaceContent: replaceContent,
    ajaxUpdateContent: ajaxUpdateContent,
    insertErrors: insertErrors,
    resetState: resetState,
    liveValidateRequiredFields: liveValidateRequiredFields,
    initFormSubmitWithin: initFormSubmitWithin,
    initAjaxForm: initAjaxForm,
    disableSubmit: disableSubmit,
    resetSubmit: resetSubmit,
    preprocessesPending: preprocessesPending,
    setPreprocessesPending: setPreprocessesPending,
    preprocessesPendingCount: preprocessesPendingCount,
    scrollToVerifyErrors: scrollToFormErrors, // Old name (alias now)
    scrollToFormErrors:   scrollToFormErrors,
    triggerSave: triggerSave,
    triggerAutoSave: triggerAutoSave
  };
})(jQuery);

export default CanvasForms
