import React, { Fragment } from "react";
// custom components
import ServiceRow from "./service-row";

export default function ServicesList({ services }) {
  var lastRecurrenceStr = "";

  return services.map((service) => (
    <Fragment key={service.uid}>
      {lastRecurrenceStr == service.recurrenceStr() ? null : (
        <h4 className="pt-4 font-weight-normal">{(lastRecurrenceStr = service.recurrenceStr())}</h4>
      )}
      <ServiceRow service={service} />
    </Fragment>
  ));
}
