import React, { Fragment } from "react";
import dayjs from "dayjs";

// bootstrap
import Card from "react-bootstrap/Card";

// custom components
import ServiceRow from "./service-row";
import Service from "../service/service";

export default ({ scheduleNinja }) => {
  return (
    <Fragment>
      {
        scheduleNinja.serviceOccurrences({from: dayjs().toDate(), to: dayjs().add(90, 'day').toDate()}).map((occurrence) =>
          <Fragment key={occurrence.date.format("YYYY-MM-DD-") + occurrence.service.uid}>
            <h4>{occurrence.date.format("MMMM D, YYYY - dddd")}</h4>
            <ServiceRow service={occurrence.service} occurrenceDate={occurrence.date} />
          </Fragment>
        )
      }
    </Fragment>
  );
};
