import { Box, HStack, Paragraph, styled, Text, VStack } from "@glasscanvas/elephantkit"
import { PropsWithChildren, useEffect, useState } from "react"
import ReactOnRails from "react-on-rails"

const TypographySwatch = styled("div", {
  display: "flex",
  flexDirection: "column",

  width: "265px",
  height: "285px",
  borderRadius: "$2",
  borderWidth: "0",
  fontSize: "$caption2",
  transition: "$normal",
  userSelect: "none",
  cursor: "pointer",
  minWidth: "$15",
  backgroundColor: "$background",
  padding: "0",
  overflow: "hidden",
  boxShadow: "$shadows$1, 0 0 0 1px $colors$gray5",

  "&:hover": { boxShadow: "$shadows$3, 0 0 0 1px $colors$gray5" },

  "> div": {
    padding: "$4",
  },

  "&, *": {
    userSelect: "none",
  },

  variants: {
    active: {
      true: {
        "&, &:hover": { boxShadow: "$shadows$3, 0 0 0 2px $colors$primary" },
      },
    },
  },
})

const Header = styled("div", VStack, {
  backgroundColor: "$gray6",
  borderBottom: "1px solid $gray4",
})

const styleDictionary: Record<string, { name: string; description: string }> = {
  classic: {
    name: "Classic",
    description: "Recoleta Alt / GT Walsheim Pro",
  },
  modern: {
    name: "Modern",
    description: "Work Sans",
  },
  bold: {
    name: "Bold",
    description: "Inter / Roboto Condensed",
  },
  contemporary: {
    name: "Contemporary",
    description: "Oswald / Roboto",
  },
}

type TypographyPickerProps = PropsWithChildren<{
  value?: string
  name?: string
  styles: string[]
  org: {
    name?: string
    statement?: string
  }
  onChange?: (value: string) => void
}>

export function TypographyPicker({
  name: inputName,
  value: initialValue,
  onChange,
  org = {},
  styles = [],
}: TypographyPickerProps) {
  const [value, setValue] = useState(initialValue ?? styles[0])

  useEffect(() => {
    onChange?.(value)
  }, [value])

  useEffect(() => {
    document.body.classList.forEach((className) => {
      if (className.startsWith("theme-style-")) {
        document.body.classList.remove(className)
      }
    })
  })

  return (
    <VStack space="large" className="cms-ui">
      <Text size="caption1">Choose a visual style to personalize your website.</Text>
      <HStack align="center" wrap>
        {styles.map((style) => {
          const { description, name } = styleDictionary[style] ?? {}

          return (
            <TypographySwatch key={style} active={value === style} onClick={() => setValue(style)}>
              <Header space="small">
                <Text>{name}</Text>
                <Text size="extraSmall">{description}</Text>
              </Header>

              <Box className={`theme-style-${style} org-theme`} css={{ pointerEvents: "none", flex: 1 }}>
                <Box className="preview" css={{ height: "100%" }}>
                  <VStack
                    space="extraSmall"
                    className="section-heading"
                    justify="start"
                    align="start"
                    css={{ height: "100%" }}
                  >
                    <Box
                      as="h1"
                      className="site-ui"
                      css={{ fontSize: "20px !important", fontFamily: "var(--theme-style-primary-font)", margin: 0 }}
                    >
                      {org.name ? `Welcome to ${org.name}` : "Get Involved"}
                    </Box>
                    <Paragraph
                      className="site-ui"
                      css={{ fontSize: "14px !important", fontFamily: "var(--theme-style-secondary-font)" }}
                    >
                      {org.statement}
                    </Paragraph>
                    <HStack css={{ marginTop: "auto", paddingBottom: "$1" }} space="small">
                      <Box className="site-ui btn btn-primary btn-round">Button</Box>
                      <Box className="site-ui btn btn-round btn-outline-black" css={{ lineHeight: "1 !important" }}>
                        Button
                      </Box>
                    </HStack>
                  </VStack>
                </Box>
              </Box>
            </TypographySwatch>
          )
        })}
        <input type="hidden" name={inputName} value={value} />
      </HStack>
    </VStack>
  )
}

ReactOnRails.register({ TypographyPicker })
