
import { Button, HStack, StackPage, VStack } from "@glasscanvas/elephantkit"
import { Link } from "react-router-dom"

export const AmountScreen = (props) => {
  return (
    <StackPage>
      <VStack>
        <HStack justify="between" space="extraSmall">
          <Button>$30</Button>
          <Button>$50</Button>
          <Button>$100</Button>
          <Button>$250</Button>
        </HStack>
        <Button round as={Link} to="/donation-type">
          Continue
        </Button>
      </VStack>
    </StackPage>
  )
}
