import GlassUploader   from 'glass/modules/glass-uploader'
import CanvasForms     from 'glass/modules/forms'

const _file = 'GlassImageUploader';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var GlassImageUploader = (function ($) {
  $(document).on('content-ready', _double_include ? null : function (e, element) {
    function enable_dropzone() {
      var $form = $("#img-upload-form");
      if ($("div#dropzone").length > 0) {
        var myDropzone = new Dropzone("div#dropzone", {
          url: $form.attr('action'),
          paramName: "image[image]",
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        });
        myDropzone.on("success", function(image, response) {
          var $container = $("#upload_image");
          var $upload_btn = $container.find('.image-upload-btn');
          var $delete_btn = $container.find('.image-delete-btn');
          $upload_btn.text('Replace Image');

          var $preview_divs = $('[data-image-bg-id="' + $upload_btn.data('image-input-id') + '"]')
          $preview_divs.show();

          $preview_divs.css('background-image', 'url(' + response.url + ')');

          $delete_btn.show();

          var $img_field    = $('#item_image_id');
          $img_field.val(response.image_id).trigger('change');
          CanvasForms.triggerAutoSave($img_field.parents('form'), $img_field);

          $("div#dropzone").slideUp();
        });
      }
    }
    enable_dropzone();


    var $upload_btns = $(element).find('.image-upload-btn');
    if ($upload_btns.length > 0) {
      $upload_btns.each(function () {
        // ##### 3 similar ideas for different uses: #####
        // $box wraps the preview img & form when there are multiple forms on the page
        // $container wraps the preview for progress, errors & adjusting the upload btn
        // $wrapper wraps the image preview as well, for hiding & changing the overlay
        var $upload_btn   = $(this);
        var box_selector  = $(this).data('box-selector');
        var $box          = $(this).parents(box_selector || '.thing-form-box, body').first();
        var $img_field    = $box.find('#' + $upload_btn.data('image-input-id'));
        var $container    = $(this).parents('.upload-preview-container');
        var $progress_bar = ($container.length > 0 ? $container : $upload_btn               ).find('.progress');
        var $error_div    = ($container.length > 0 ? $container : $img_field.parents('form')).find(".errorExplanation").first();

        $upload_btn.on('click', function (e) {
          e.preventDefault();
          GlassUploader.openFileInput('#img-upload-form', $upload_btn);
          $('#sidebar-right').addClass('active');
          if ($progress_bar.length > 0) {
            $progress_bar.val(0);
          }
        });

        GlassUploader.handleProgressUpdates($upload_btn, $progress_bar);

        $upload_btn.data('on-success', function(response) {
          $box.find('.image-upload-btn[data-image-input-id="' + $upload_btn.data('image-input-id') + '"]').each(function () {
            if (this.hasAttribute('data-allows-editing')) {
              setupEditButton(response.url, this)
            } else if ($(this).text().replace(/\W+/g, ' ').length > 3) {
              this.innerHTML = "Replace Image"
            }
          });
          var $preview_divs = $box.find('[data-image-bg-id="' + $upload_btn.data('image-input-id') + '"]');
          $preview_divs.show();
          $preview_divs.each(function() {
            $(this).is('img') ?
              $(this).attr('src', response.url) :
              $(this).css('background-image', 'url(' + response.url + ')');
            var wrapper_selector = $(this).data('wrapper-selector');
            var $wrapper = wrapper_selector ? $(this).parents(wrapper_selector) : $(this);
            $wrapper.show();
            var $overlay = $wrapper.find('.upload-overlay');
            if ($overlay.length > 0) {
              $overlay.addClass('d-none');
            }
          });

          if ($progress_bar.length > 0) {
            $progress_bar.addClass('d-none');
          }

          $delete_btns.show()

          $img_field.val(response.image_id).trigger('change');
          CanvasForms.triggerAutoSave($img_field.parents('form'), $img_field);

          $error_div.removeClass('active');
          $error_div.html('');
        });

        $upload_btn.data('on-error', function(response_text) {
          $error_div.html("<p>" + response_text + "</p>")
          $error_div.addClass('active');
        });
      });
    }

    var $delete_btns = $(element).find('.image-delete-btn');
    $delete_btns.on('click', function () {
      var $container  = $(this).parents('.upload-preview-container');
      var $upload_btn = $container.find('[data-image-input-id]').first();
      var $img_field  = $('#' + $upload_btn.data('image-input-id'));
      $img_field.val('').trigger('change');

      var $preview_divs = $('[data-image-bg-id="' + $upload_btn.data('image-input-id') + '"]')
      $preview_divs.each(function() {
        $(this).is('img') ?
          $(this).removeAttr('src') :
          $(this).css('background-image', '');
      });
    });

    var $delete_editing_btns = $(element).find('.image-delete-editing-btn');
    $delete_editing_btns.each(function(){
      var $input_field = $('#' + $(this).data('image-input-id'));
      if ($input_field.val().length <= 0) {
        $(this).hide();
      }
      $input_field.change(function(){
        if ($(this).val().length > 0) {
          $('.image-delete-editing-btn[data-image-input-id="' + $(this).attr('id') + '"]').show();
        }
      });
      $(this).on('click', function(){
        var $preview_divs = $(element).find('[data-image-bg-id="' + $(this).data('image-input-id') + '"]');

        $(this).hide();

        $('#' + $(this).data('image-input-id')).val('');

        $preview_divs.each(function() {
          var default_image_url = $(this).data('default-image') || '';
          $(this).is('img') ?
            $(this).attr('src', default_image_url) :
            $(this).css('background-image', 'url(' + default_image_url + ')');
        });
      });
    });
  });

  // Return API for other modules
  return {
  };
})(jQuery);
