/** This file was generated with `r r script/misc/graphql_generate.rb` */
import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An ISO 8601-encoded date */
  ISO8601Date: any
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string
}

export type Action = {
  __typename?: "Action"
  action?: Maybe<Scalars["String"]>
  category?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  label?: Maybe<Scalars["String"]>
  occurredAt?: Maybe<Scalars["ISO8601DateTime"]>
  personId?: Maybe<Scalars["ID"]>
}

export type ActionsResult = {
  __typename?: "ActionsResult"
  actions?: Maybe<Array<Action>>
  metrics?: Maybe<Array<Metric>>
  totalCount: Scalars["Int"]
  totalSum?: Maybe<Scalars["Int"]>
}

export type Address = {
  __typename?: "Address"
  address?: Maybe<Scalars["String"]>
  address2?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  country?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  postalCode?: Maybe<Scalars["String"]>
  stateProvince?: Maybe<Scalars["String"]>
}

/** Attributes for creating or updating an address */
export type AddressAttributes = {
  /** ID */
  id?: Maybe<Scalars["ID"]>
  address?: Maybe<Scalars["String"]>
  address2?: Maybe<Scalars["String"]>
  city?: Maybe<Scalars["String"]>
  stateProvince?: Maybe<Scalars["String"]>
  postalCode?: Maybe<Scalars["String"]>
  country?: Maybe<Scalars["String"]>
}

export type Campaign = {
  __typename?: "Campaign"
  brief?: Maybe<Scalars["String"]>
  fund?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  image?: Maybe<Image>
  imageId?: Maybe<Scalars["String"]>
  title?: Maybe<Scalars["String"]>
}

export type CampaignImageArgs = {
  id?: Maybe<Scalars["ID"]>
  geometry?: Maybe<Scalars["String"]>
  crop?: Maybe<Scalars["Boolean"]>
}

export type CampaignsResult = {
  __typename?: "CampaignsResult"
  campaigns: Array<Campaign>
  totalCount: Scalars["Int"]
}

export type Charge = {
  __typename?: "Charge"
  campaign?: Maybe<Campaign>
  chargeType?: Maybe<Scalars["String"]>
  createdAt?: Maybe<Scalars["ISO8601DateTime"]>
  financialProfile?: Maybe<FinancialProfile>
  id: Scalars["ID"]
  person?: Maybe<ReportActionPerson>
  pledge?: Maybe<Pledge>
  processedAt?: Maybe<Scalars["ISO8601Date"]>
  processingStatus?: Maybe<Scalars["String"]>
  reason?: Maybe<Scalars["String"]>
  recurrenceType?: Maybe<Scalars["String"]>
  transactionCents?: Maybe<Scalars["Int"]>
}

export type ChargesResult = {
  __typename?: "ChargesResult"
  charges: Array<Charge>
  oneTimeTransactionCents: Scalars["Int"]
  recurringTransactionCents: Scalars["Int"]
  totalCount: Scalars["Int"]
  totalTransactionCents: Scalars["Int"]
}

export type CurrentPerson = {
  __typename?: "CurrentPerson"
  addressAddress?: Maybe<Scalars["String"]>
  addressAddress2?: Maybe<Scalars["String"]>
  addressCity?: Maybe<Scalars["String"]>
  addressCountry?: Maybe<Scalars["String"]>
  addressPostalCode?: Maybe<Scalars["String"]>
  addressStateProvince?: Maybe<Scalars["String"]>
  authUserCustomTwoFactorAuthNumber?: Maybe<Scalars["String"]>
  authUserEnforceTwoFactor?: Maybe<Scalars["Boolean"]>
  authUserLastSignInAt?: Maybe<Scalars["ISO8601Date"]>
  authUserTwoFactorEnabled?: Maybe<Scalars["String"]>
  authorAnonymous?: Maybe<Scalars["Boolean"]>
  authorBio?: Maybe<Scalars["String"]>
  authorFacebookUrl?: Maybe<Scalars["String"]>
  authorInstagramUrl?: Maybe<Scalars["String"]>
  authorIsContentProvider?: Maybe<Scalars["Boolean"]>
  authorPhoto?: Maybe<Image>
  authorPhotoId?: Maybe<Scalars["ID"]>
  authorTwitterHandle?: Maybe<Scalars["String"]>
  authorVoiceIndexVisibility?: Maybe<Scalars["String"]>
  authorWebsiteUrl?: Maybe<Scalars["String"]>
  canToggleDataMasking?: Maybe<Scalars["Boolean"]>
  email?: Maybe<Scalars["String"]>
  financialProfileCurrentPaymentMethod?: Maybe<Scalars["String"]>
  financialProfileEnvelopeNumber?: Maybe<Scalars["Int"]>
  financialProfileWeeklyDollarsHistoricalEstimate?: Maybe<Scalars["Int"]>
  firstName?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  isAdmin?: Maybe<Scalars["Boolean"]>
  isAgent?: Maybe<Scalars["Boolean"]>
  isAuthUser?: Maybe<Scalars["Boolean"]>
  isAuthor?: Maybe<Scalars["Boolean"]>
  isMember?: Maybe<Scalars["Boolean"]>
  isMemberRegistered?: Maybe<Scalars["Boolean"]>
  isNonPrimaryParish?: Maybe<Scalars["Boolean"]>
  isOnlineMember?: Maybe<Scalars["Boolean"]>
  isSubscriber?: Maybe<Scalars["Boolean"]>
  isTeamMember?: Maybe<Scalars["Boolean"]>
  lastName?: Maybe<Scalars["String"]>
  memberAlphaTraining?: Maybe<Scalars["Boolean"]>
  memberBaptised?: Maybe<Scalars["Boolean"]>
  memberBirthday?: Maybe<Scalars["ISO8601Date"]>
  memberBirthplace?: Maybe<Scalars["String"]>
  memberCellPhone?: Maybe<Scalars["String"]>
  memberChurchWhereMarried?: Maybe<Scalars["String"]>
  memberCityWhereMarried?: Maybe<Scalars["String"]>
  memberConfirmed?: Maybe<Scalars["Boolean"]>
  memberCountryWhereMarried?: Maybe<Scalars["String"]>
  memberDateMarried?: Maybe<Scalars["ISO8601Date"]>
  memberDiscoveryTraining?: Maybe<Scalars["Boolean"]>
  memberDoNotCall?: Maybe<Scalars["Boolean"]>
  memberDoNotEmail?: Maybe<Scalars["Boolean"]>
  memberFacebookUrl?: Maybe<Scalars["String"]>
  memberFamilyId?: Maybe<Scalars["Int"]>
  memberFamilyRole?: Maybe<Scalars["String"]>
  memberFirstCommunion?: Maybe<Scalars["Boolean"]>
  memberGender?: Maybe<Scalars["String"]>
  memberHaveChildren?: Maybe<Scalars["Boolean"]>
  memberHomePhone?: Maybe<Scalars["String"]>
  memberHostingAlpha?: Maybe<Scalars["Boolean"]>
  memberHostingDiscovery?: Maybe<Scalars["Boolean"]>
  memberInstagramUrl?: Maybe<Scalars["String"]>
  memberMaidenName?: Maybe<Scalars["String"]>
  memberMaritalStatus?: Maybe<Scalars["String"]>
  memberMiddleName?: Maybe<Scalars["String"]>
  memberMissionaryDiscipleCommitment?: Maybe<Scalars["Boolean"]>
  memberNamePreference?: Maybe<Scalars["String"]>
  memberOccupation?: Maybe<Scalars["String"]>
  memberPhoto?: Maybe<Image>
  memberPhotoId?: Maybe<Scalars["ID"]>
  memberPrayerCommitment?: Maybe<Scalars["Boolean"]>
  memberReligion?: Maybe<Scalars["String"]>
  memberReligionOther?: Maybe<Scalars["String"]>
  memberRunSeriesCommitment?: Maybe<Scalars["Boolean"]>
  memberSchoolGrade?: Maybe<Scalars["String"]>
  memberSchoolName?: Maybe<Scalars["String"]>
  memberSiteTraining?: Maybe<Scalars["Boolean"]>
  memberSuffix?: Maybe<Scalars["String"]>
  memberTitle?: Maybe<Scalars["String"]>
  memberTrainingCommitment?: Maybe<Scalars["Boolean"]>
  memberTwitterUrl?: Maybe<Scalars["String"]>
  memberWebsiteUrl?: Maybe<Scalars["String"]>
  orgAccountStatus?: Maybe<Scalars["String"]>
  orgRoleAspects?: Maybe<Array<Scalars["String"]>>
  orgStatuses?: Maybe<Scalars["String"]>
  rmsSectionsData?: Maybe<Scalars["String"]>
  status?: Maybe<Scalars["String"]>
  teamMemberBio?: Maybe<Scalars["String"]>
  teamMemberCustomJobTitle?: Maybe<Scalars["String"]>
  teamMemberDisplayEmail?: Maybe<Scalars["Boolean"]>
  teamMemberDisplayMember?: Maybe<Scalars["Boolean"]>
  teamMemberJobTitle?: Maybe<Scalars["String"]>
}

export type CurrentPersonAuthorPhotoArgs = {
  id?: Maybe<Scalars["ID"]>
  geometry?: Maybe<Scalars["String"]>
  crop?: Maybe<Scalars["Boolean"]>
}

export type CurrentPersonMemberPhotoArgs = {
  id?: Maybe<Scalars["ID"]>
  geometry?: Maybe<Scalars["String"]>
  crop?: Maybe<Scalars["Boolean"]>
}

export type FinancialProfile = {
  __typename?: "FinancialProfile"
  envelopeNumber?: Maybe<Scalars["Int"]>
  id: Scalars["ID"]
}

export type Image = {
  __typename?: "Image"
  alt?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  position?: Maybe<ImagePosition>
  src?: Maybe<Scalars["String"]>
  srcset?: Maybe<Scalars["String"]>
}

export type ImagePosition = {
  __typename?: "ImagePosition"
  x?: Maybe<Scalars["Int"]>
  y?: Maybe<Scalars["Int"]>
}

export enum InactiveStatus {
  Deceased = "deceased",
  Trash = "trash",
}

export type Metric = {
  __typename?: "Metric"
  count: Scalars["Int"]
  fields: Array<MetricField>
  sum?: Maybe<Scalars["Int"]>
}

export type MetricField = {
  __typename?: "MetricField"
  field: Scalars["String"]
  rawVal?: Maybe<Scalars["String"]>
  value?: Maybe<Scalars["String"]>
}

export type Mutation = {
  __typename?: "Mutation"
  /** update a charge */
  charge: Charge
  /** Create a person record */
  createPerson: Person
  /** Create a new pledge */
  createPledge: Pledge
  /** Create a query */
  createQuery: PeopleQuery
  /** Create a record */
  createSacramentalRecord: Record
  /** Delete a charge (transaction) - Warning: Do not do for online transactions, that's illegal */
  deleteCharge: Charge
  /** Delete a person record */
  deletePerson: Person
  /** Delete a query */
  deleteQuery: PeopleQuery
  /** Delete a record */
  deleteSacramentalRecord: Record
  /** Update a person */
  person: Person
  /** Update a pledge */
  pledge: Pledge
  /** Update a record */
  sacramentalRecord: Record
  /** Update a query */
  updateQuery: PeopleQuery
}

export type MutationChargeArgs = {
  id: Scalars["ID"]
  transactionCents?: Maybe<Scalars["Int"]>
  processedAt?: Maybe<Scalars["String"]>
  campaignId: Scalars["ID"]
}

export type MutationCreatePersonArgs = {
  person: PersonAttributes
}

export type MutationCreatePledgeArgs = {
  personId: Scalars["ID"]
  pledge: PledgeAttributes
  paydockToken?: Maybe<Scalars["String"]>
  contactEmail?: Maybe<Scalars["String"]>
}

export type MutationCreateQueryArgs = {
  query: QueryAttributes
}

export type MutationCreateSacramentalRecordArgs = {
  record: RecordAttributes
}

export type MutationDeleteChargeArgs = {
  id: Scalars["ID"]
}

export type MutationDeletePersonArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteQueryArgs = {
  id: Scalars["ID"]
}

export type MutationDeleteSacramentalRecordArgs = {
  id: Scalars["ID"]
}

export type MutationPersonArgs = {
  id: Scalars["ID"]
  person: PersonAttributes
}

export type MutationPledgeArgs = {
  id: Scalars["ID"]
  pledge: PledgeAttributes
  paydockToken?: Maybe<Scalars["String"]>
  contactEmail?: Maybe<Scalars["String"]>
}

export type MutationSacramentalRecordArgs = {
  id: Scalars["ID"]
  record: RecordAttributes
}

export type MutationUpdateQueryArgs = {
  id: Scalars["ID"]
  query: QueryAttributes
}

export type Org = {
  __typename?: "Org"
  id: Scalars["ID"]
  name?: Maybe<Scalars["String"]>
  paydockGatewayId?: Maybe<Scalars["String"]>
  paydockPublicKey?: Maybe<Scalars["String"]>
}

export type PeopleQuery = {
  __typename?: "PeopleQuery"
  descriptor?: Maybe<Scalars["String"]>
  humanReadable?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  initQuery?: Maybe<Scalars["String"]>
  isFavorite?: Maybe<Scalars["Boolean"]>
  isOneTime?: Maybe<Scalars["Boolean"]>
  jsonTree?: Maybe<Scalars["String"]>
  lastQueriedAt?: Maybe<Scalars["ISO8601DateTime"]>
  preset?: Maybe<Scalars["String"]>
  searchkickWhere?: Maybe<Scalars["String"]>
}

export type PeopleResult = {
  __typename?: "PeopleResult"
  people: Array<Person>
  totalCount: Scalars["Int"]
}

export type Person = {
  __typename?: "Person"
  addressAddress?: Maybe<Scalars["String"]>
  addressAddress2?: Maybe<Scalars["String"]>
  addressCity?: Maybe<Scalars["String"]>
  addressCountry?: Maybe<Scalars["String"]>
  addressPostalCode?: Maybe<Scalars["String"]>
  addressStateProvince?: Maybe<Scalars["String"]>
  authUserCustomTwoFactorAuthNumber?: Maybe<Scalars["String"]>
  authUserEnforceTwoFactor?: Maybe<Scalars["Boolean"]>
  authUserLastSignInAt?: Maybe<Scalars["ISO8601Date"]>
  authUserTwoFactorEnabled?: Maybe<Scalars["String"]>
  authorAnonymous?: Maybe<Scalars["Boolean"]>
  authorBio?: Maybe<Scalars["String"]>
  authorFacebookUrl?: Maybe<Scalars["String"]>
  authorInstagramUrl?: Maybe<Scalars["String"]>
  authorIsContentProvider?: Maybe<Scalars["Boolean"]>
  authorPhoto?: Maybe<Image>
  authorPhotoId?: Maybe<Scalars["ID"]>
  authorTwitterHandle?: Maybe<Scalars["String"]>
  authorVoiceIndexVisibility?: Maybe<Scalars["String"]>
  authorWebsiteUrl?: Maybe<Scalars["String"]>
  email?: Maybe<Scalars["String"]>
  financialProfileCurrentPaymentMethod?: Maybe<Scalars["String"]>
  financialProfileEnvelopeNumber?: Maybe<Scalars["Int"]>
  financialProfileWeeklyDollarsHistoricalEstimate?: Maybe<Scalars["Int"]>
  firstName?: Maybe<Scalars["String"]>
  globalRoles?: Maybe<Roles>
  id: Scalars["ID"]
  inOrgRoles?: Maybe<Roles>
  isAdmin?: Maybe<Scalars["Boolean"]>
  isAuthUser?: Maybe<Scalars["Boolean"]>
  isAuthor?: Maybe<Scalars["Boolean"]>
  isMember?: Maybe<Scalars["Boolean"]>
  isMemberRegistered?: Maybe<Scalars["Boolean"]>
  isNonPrimaryParish?: Maybe<Scalars["Boolean"]>
  isOnlineMember?: Maybe<Scalars["Boolean"]>
  isSubscriber?: Maybe<Scalars["Boolean"]>
  isTeamMember?: Maybe<Scalars["Boolean"]>
  lastName?: Maybe<Scalars["String"]>
  memberAlphaTraining?: Maybe<Scalars["Boolean"]>
  memberBaptised?: Maybe<Scalars["Boolean"]>
  memberBirthday?: Maybe<Scalars["ISO8601Date"]>
  memberBirthplace?: Maybe<Scalars["String"]>
  memberCellPhone?: Maybe<Scalars["String"]>
  memberChurchWhereMarried?: Maybe<Scalars["String"]>
  memberCityWhereMarried?: Maybe<Scalars["String"]>
  memberConfirmed?: Maybe<Scalars["Boolean"]>
  memberCountryWhereMarried?: Maybe<Scalars["String"]>
  memberDateMarried?: Maybe<Scalars["ISO8601Date"]>
  memberDiscoveryTraining?: Maybe<Scalars["Boolean"]>
  memberDoNotCall?: Maybe<Scalars["Boolean"]>
  memberDoNotEmail?: Maybe<Scalars["Boolean"]>
  memberFacebookUrl?: Maybe<Scalars["String"]>
  memberFamilyId?: Maybe<Scalars["Int"]>
  memberFamilyRole?: Maybe<Scalars["String"]>
  memberFirstCommunion?: Maybe<Scalars["Boolean"]>
  memberGender?: Maybe<Scalars["String"]>
  memberHaveChildren?: Maybe<Scalars["Boolean"]>
  memberHomePhone?: Maybe<Scalars["String"]>
  memberHostingAlpha?: Maybe<Scalars["Boolean"]>
  memberHostingDiscovery?: Maybe<Scalars["Boolean"]>
  memberInstagramUrl?: Maybe<Scalars["String"]>
  memberMaidenName?: Maybe<Scalars["String"]>
  memberMaritalStatus?: Maybe<Scalars["String"]>
  memberMiddleName?: Maybe<Scalars["String"]>
  memberMissionaryDiscipleCommitment?: Maybe<Scalars["Boolean"]>
  memberNamePreference?: Maybe<Scalars["String"]>
  memberOccupation?: Maybe<Scalars["String"]>
  memberPhoto?: Maybe<Image>
  memberPhotoId?: Maybe<Scalars["ID"]>
  memberPrayerCommitment?: Maybe<Scalars["Boolean"]>
  memberReligion?: Maybe<Scalars["String"]>
  memberReligionOther?: Maybe<Scalars["String"]>
  memberRunSeriesCommitment?: Maybe<Scalars["Boolean"]>
  memberSchoolGrade?: Maybe<Scalars["String"]>
  memberSchoolName?: Maybe<Scalars["String"]>
  memberSiteTraining?: Maybe<Scalars["Boolean"]>
  memberSuffix?: Maybe<Scalars["String"]>
  memberTitle?: Maybe<Scalars["String"]>
  memberTrainingCommitment?: Maybe<Scalars["Boolean"]>
  memberTwitterUrl?: Maybe<Scalars["String"]>
  memberWebsiteUrl?: Maybe<Scalars["String"]>
  orgAccountStatus?: Maybe<Scalars["String"]>
  orgRoleAspects?: Maybe<Array<Scalars["String"]>>
  orgStatuses?: Maybe<Scalars["String"]>
  rmsSectionsData?: Maybe<Scalars["String"]>
  status?: Maybe<Scalars["String"]>
  teamMemberBio?: Maybe<Scalars["String"]>
  teamMemberCustomJobTitle?: Maybe<Scalars["String"]>
  teamMemberDisplayEmail?: Maybe<Scalars["Boolean"]>
  teamMemberDisplayMember?: Maybe<Scalars["Boolean"]>
  teamMemberJobTitle?: Maybe<Scalars["String"]>
}

export type PersonAuthorPhotoArgs = {
  id?: Maybe<Scalars["ID"]>
  geometry?: Maybe<Scalars["String"]>
  crop?: Maybe<Scalars["Boolean"]>
}

export type PersonMemberPhotoArgs = {
  id?: Maybe<Scalars["ID"]>
  geometry?: Maybe<Scalars["String"]>
  crop?: Maybe<Scalars["Boolean"]>
}

/** Attributes for creating or updating a person */
export type PersonAttributes = {
  /** ID */
  id?: Maybe<Scalars["ID"]>
  /** Email Address */
  email?: Maybe<Scalars["String"]>
  /** Do not email */
  memberDoNotEmail?: Maybe<Scalars["Boolean"]>
  /** First Name */
  firstName?: Maybe<Scalars["String"]>
  /** Middle name */
  memberMiddleName?: Maybe<Scalars["String"]>
  /** Last Name */
  lastName?: Maybe<Scalars["String"]>
  /** Maiden name */
  memberMaidenName?: Maybe<Scalars["String"]>
  /** Status of the person */
  status?: Maybe<Scalars["String"]>
  /** Status at the parish */
  orgAccountStatus?: Maybe<Scalars["String"]>
  /** Status */
  orgStatuses?: Maybe<Scalars["String"]>
  /** Role */
  orgRoleAspects?: Maybe<Array<Scalars["String"]>>
  /** Grant administrative access */
  isAdmin?: Maybe<Scalars["Boolean"]>
  /** Is auth user */
  isAuthUser?: Maybe<Scalars["Boolean"]>
  /** Two Factor Authentication type */
  authUserTwoFactorEnabled?: Maybe<Scalars["String"]>
  /** Enforce 2FA (GC support access only) */
  authUserEnforceTwoFactor?: Maybe<Scalars["Boolean"]>
  /** Custom 2FA Number */
  authUserCustomTwoFactorAuthNumber?: Maybe<Scalars["String"]>
  /** Last Login */
  authUserLastSignInAt?: Maybe<Scalars["ISO8601Date"]>
  /** Is parish member */
  isMember?: Maybe<Scalars["Boolean"]>
  /** Has 1 or more children */
  memberHaveChildren?: Maybe<Scalars["Boolean"]>
  /** Baptised */
  memberBaptised?: Maybe<Scalars["Boolean"]>
  /** Confirmed as Catholic? */
  memberConfirmed?: Maybe<Scalars["Boolean"]>
  /** First Communion */
  memberFirstCommunion?: Maybe<Scalars["Boolean"]>
  /** Commitment to: Be a missionary disciple */
  memberMissionaryDiscipleCommitment?: Maybe<Scalars["Boolean"]>
  /** Commitment to: Training */
  memberTrainingCommitment?: Maybe<Scalars["Boolean"]>
  /** Commitment to: Run a faith series */
  memberRunSeriesCommitment?: Maybe<Scalars["Boolean"]>
  /** Commitment to: Pray for the Proclaim movement */
  memberPrayerCommitment?: Maybe<Scalars["Boolean"]>
  /** Training: General */
  memberSiteTraining?: Maybe<Scalars["Boolean"]>
  /** Training: Alpha */
  memberAlphaTraining?: Maybe<Scalars["Boolean"]>
  /** Training: Discovery */
  memberDiscoveryTraining?: Maybe<Scalars["Boolean"]>
  /** Hosting: Alpha */
  memberHostingAlpha?: Maybe<Scalars["Boolean"]>
  /** Hosting: Discovery */
  memberHostingDiscovery?: Maybe<Scalars["Boolean"]>
  /** Date of Birth */
  memberBirthday?: Maybe<Scalars["ISO8601Date"]>
  /** Birthplace */
  memberBirthplace?: Maybe<Scalars["String"]>
  /** Date of Wedding */
  memberDateMarried?: Maybe<Scalars["ISO8601Date"]>
  /** Religion */
  memberReligion?: Maybe<Scalars["String"]>
  /** Marital Status */
  memberMaritalStatus?: Maybe<Scalars["String"]>
  /** Gender */
  memberGender?: Maybe<Scalars["String"]>
  /** Church Name */
  memberChurchWhereMarried?: Maybe<Scalars["String"]>
  /** City */
  memberCityWhereMarried?: Maybe<Scalars["String"]>
  /** Country */
  memberCountryWhereMarried?: Maybe<Scalars["String"]>
  /** School */
  memberSchoolName?: Maybe<Scalars["String"]>
  /** Grade */
  memberSchoolGrade?: Maybe<Scalars["String"]>
  /** Religion (Other Option) */
  memberReligionOther?: Maybe<Scalars["String"]>
  /** Home Phone */
  memberHomePhone?: Maybe<Scalars["String"]>
  /** Cell Phone */
  memberCellPhone?: Maybe<Scalars["String"]>
  /** Do not call */
  memberDoNotCall?: Maybe<Scalars["Boolean"]>
  /** Occupation */
  memberOccupation?: Maybe<Scalars["String"]>
  /** Photo */
  memberPhotoId?: Maybe<Scalars["ID"]>
  /** Title */
  memberTitle?: Maybe<Scalars["String"]>
  /** Suffix */
  memberSuffix?: Maybe<Scalars["String"]>
  /** Website URL */
  memberWebsiteUrl?: Maybe<Scalars["String"]>
  /** Facebook URL */
  memberFacebookUrl?: Maybe<Scalars["String"]>
  /** Twitter URL */
  memberTwitterUrl?: Maybe<Scalars["String"]>
  /** Instagram URL */
  memberInstagramUrl?: Maybe<Scalars["String"]>
  /** Family Role */
  memberFamilyRole?: Maybe<Scalars["String"]>
  /** Family */
  memberFamilyId?: Maybe<Scalars["Int"]>
  /** Name Preference */
  memberNamePreference?: Maybe<Scalars["String"]>
  /** Street Address */
  addressAddress?: Maybe<Scalars["String"]>
  /** Address Line 2 (Optional) */
  addressAddress2?: Maybe<Scalars["String"]>
  /** City */
  addressCity?: Maybe<Scalars["String"]>
  /** State or Province */
  addressStateProvince?: Maybe<Scalars["String"]>
  /** Postal Code */
  addressPostalCode?: Maybe<Scalars["String"]>
  /** Country */
  addressCountry?: Maybe<Scalars["String"]>
  /** Envelope Number */
  financialProfileEnvelopeNumber?: Maybe<Scalars["Int"]>
  /** How did you usually donate in the past? */
  financialProfileCurrentPaymentMethod?: Maybe<Scalars["String"]>
  /** Estimate of what you typically donate weekly */
  financialProfileWeeklyDollarsHistoricalEstimate?: Maybe<Scalars["Int"]>
  /** Is team member */
  isTeamMember?: Maybe<Scalars["Boolean"]>
  /** Job Title */
  teamMemberJobTitle?: Maybe<Scalars["String"]>
  /** Custom job title */
  teamMemberCustomJobTitle?: Maybe<Scalars["String"]>
  /** Display email address on contact page */
  teamMemberDisplayEmail?: Maybe<Scalars["Boolean"]>
  /** Bio */
  teamMemberBio?: Maybe<Scalars["String"]>
  /** Display member under 'Our Team' on contact page */
  teamMemberDisplayMember?: Maybe<Scalars["Boolean"]>
  /** Is author */
  isAuthor?: Maybe<Scalars["Boolean"]>
  /** Bio */
  authorBio?: Maybe<Scalars["String"]>
  /** Twitter Handle */
  authorTwitterHandle?: Maybe<Scalars["String"]>
  /** Website URL */
  authorWebsiteUrl?: Maybe<Scalars["String"]>
  /** Facebook URL */
  authorFacebookUrl?: Maybe<Scalars["String"]>
  /** Instagram URL */
  authorInstagramUrl?: Maybe<Scalars["String"]>
  /** This is a Content Provider (an organization, maybe with multiple authors) */
  authorIsContentProvider?: Maybe<Scalars["Boolean"]>
  /** Author visibilty on Voices index */
  authorVoiceIndexVisibility?: Maybe<Scalars["String"]>
  /** Photo */
  authorPhotoId?: Maybe<Scalars["ID"]>
  /** Anonymous */
  authorAnonymous?: Maybe<Scalars["Boolean"]>
  /** Is subscriber */
  isSubscriber?: Maybe<Scalars["Boolean"]>
  /** Is parish owner */
  isParishOwner?: Maybe<Scalars["Boolean"]>
}

export type Pledge = {
  __typename?: "Pledge"
  campaign?: Maybe<Campaign>
  campaignId?: Maybe<Scalars["ID"]>
  canceled?: Maybe<Scalars["Boolean"]>
  cardBrand?: Maybe<Scalars["String"]>
  cardLast4?: Maybe<Scalars["String"]>
  ccStatus?: Maybe<Scalars["String"]>
  createdAt?: Maybe<Scalars["String"]>
  frequency?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  nextScheduledChargeDate?: Maybe<Scalars["ISO8601DateTime"]>
  paymentMethod?: Maybe<Scalars["String"]>
  payments?: Maybe<Scalars["Int"]>
  quantity?: Maybe<Scalars["Int"]>
  transactionCents?: Maybe<Scalars["Int"]>
}

/** Attributes for creating or updating a pledge */
export type PledgeAttributes = {
  /** ID */
  id?: Maybe<Scalars["ID"]>
  /** Transaction Cents */
  transactionCents?: Maybe<Scalars["Int"]>
  /** Recurrence frequency */
  frequency?: Maybe<Scalars["String"]>
  /** Method of payment */
  paymentMethod?: Maybe<Scalars["String"]>
  /** ID of the campaign */
  campaignId?: Maybe<Scalars["ID"]>
}

export type PledgesResult = {
  __typename?: "PledgesResult"
  pledges: Array<Pledge>
  totalCount: Scalars["Int"]
}

export type QueriesResult = {
  __typename?: "QueriesResult"
  queries: Array<PeopleQuery>
  totalCount: Scalars["Int"]
}

/** The query root of this schema */
export type Query = {
  __typename?: "Query"
  /** Get giving campaign by ID */
  campaign: Campaign
  /** Get list of all campaigns */
  campaigns: CampaignsResult
  /** Get charges (transactions, recurring and one-time) */
  charges: ChargesResult
  /** Get some data about the current logged in person */
  currentPerson: CurrentPerson
  /** Get the attributes of the current org (@domain_org) context */
  domainOrg: Org
  /** Get form parts for building queries */
  formParts: Scalars["String"]
  image?: Maybe<Image>
  /** Filter people by a query */
  people: PeopleResult
  /** Get a person by id */
  person: Person
  /** Get pledges (recurring and one-time) */
  pledges: PledgesResult
  /** Index query objects (used for filtering people) */
  queries: QueriesResult
  /** Get a people query */
  query: PeopleQuery
  /** Query and filter report actions of all kinds */
  reportActions: ActionsResult
  /** Get a record */
  sacramentalRecord: Record
  /** Index of records */
  sacramentalRecords: RecordsResult
}

/** The query root of this schema */
export type QueryCampaignArgs = {
  id: Scalars["ID"]
}

/** The query root of this schema */
export type QueryChargesArgs = {
  fromDate: Scalars["String"]
  toDate?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
  pledgeId?: Maybe<Scalars["ID"]>
  campaignId?: Maybe<Scalars["String"]>
  page?: Maybe<Scalars["Int"]>
  perPage?: Maybe<Scalars["Int"]>
  sortBy?: Maybe<Scalars["String"]>
  asc?: Maybe<Scalars["Boolean"]>
}

/** The query root of this schema */
export type QueryImageArgs = {
  id?: Maybe<Scalars["ID"]>
  geometry?: Maybe<Scalars["String"]>
  crop?: Maybe<Scalars["Boolean"]>
}

/** The query root of this schema */
export type QueryPeopleArgs = {
  searchStr?: Maybe<Scalars["String"]>
  queryId?: Maybe<Scalars["ID"]>
  queryPreset?: Maybe<Scalars["String"]>
  familyId?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
  perPage?: Maybe<Scalars["Int"]>
  sortBy?: Maybe<Scalars["String"]>
  asc?: Maybe<Scalars["Boolean"]>
}

/** The query root of this schema */
export type QueryPersonArgs = {
  id: Scalars["ID"]
}

/** The query root of this schema */
export type QueryPledgesArgs = {
  fromDate: Scalars["String"]
  toDate?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
  campaignId?: Maybe<Scalars["ID"]>
  isSubscription?: Maybe<Scalars["Boolean"]>
}

/** The query root of this schema */
export type QueryQueriesArgs = {
  isFavorite?: Maybe<Scalars["Boolean"]>
  isPreset?: Maybe<Scalars["Boolean"]>
}

/** The query root of this schema */
export type QueryQueryArgs = {
  id: Scalars["ID"]
}

/** The query root of this schema */
export type QueryReportActionsArgs = {
  fromDate: Scalars["String"]
  metricsFor?: Maybe<Scalars["String"]>
  toDate?: Maybe<Scalars["String"]>
  category?: Maybe<Scalars["String"]>
  action?: Maybe<Scalars["String"]>
  label?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
}

/** The query root of this schema */
export type QuerySacramentalRecordArgs = {
  id: Scalars["ID"]
}

/** The query root of this schema */
export type QuerySacramentalRecordsArgs = {
  recordType?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
}

/** Attributes for creating or updating a people query */
export type QueryAttributes = {
  /** ID */
  id?: Maybe<Scalars["ID"]>
  /** Descriptior */
  descriptor?: Maybe<Scalars["String"]>
  humanReadable?: Maybe<Scalars["String"]>
  /** Json metadata from awesome query builder */
  jsonTree?: Maybe<Scalars["String"]>
  /** Favorite query */
  isFavorite?: Maybe<Scalars["Boolean"]>
  /** Just a one-time query */
  isOneTime?: Maybe<Scalars["Boolean"]>
  searchkickWhere?: Maybe<Scalars["String"]>
}

export type Record = {
  __typename?: "Record"
  burialDate?: Maybe<Scalars["ISO8601DateTime"]>
  burialPlace?: Maybe<Scalars["String"]>
  cremated?: Maybe<Scalars["Boolean"]>
  currentMarriageStatus?: Maybe<Scalars["String"]>
  funeralDate?: Maybe<Scalars["ISO8601DateTime"]>
  husband?: Maybe<SacramentalRecordsPersonType>
  id: Scalars["ID"]
  isThisParish?: Maybe<Scalars["Boolean"]>
  minister?: Maybe<SacramentalRecordsPersonType>
  notations?: Maybe<Scalars["String"]>
  occurredAt?: Maybe<Scalars["ISO8601Date"]>
  parishAddress?: Maybe<Address>
  parishName?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["Int"]>
  professionOfFaith?: Maybe<Scalars["Boolean"]>
  proxy1?: Maybe<SacramentalRecordsPersonType>
  proxy2?: Maybe<SacramentalRecordsPersonType>
  recordLocationNotes?: Maybe<Scalars["String"]>
  recordType?: Maybe<Scalars["String"]>
  sacramentalName?: Maybe<Scalars["String"]>
  sponsor1?: Maybe<SacramentalRecordsPersonType>
  sponsor2?: Maybe<SacramentalRecordsPersonType>
  wife?: Maybe<SacramentalRecordsPersonType>
}

/** Attributes for creating or updating a record */
export type RecordAttributes = {
  /** ID */
  id?: Maybe<Scalars["ID"]>
  /** Record type */
  recordType?: Maybe<Scalars["String"]>
  /** Person */
  personId?: Maybe<Scalars["ID"]>
  /** Wife */
  wifeId?: Maybe<Scalars["ID"]>
  /** Husband */
  husbandId?: Maybe<Scalars["ID"]>
  /** Occurred at */
  occurredAt?: Maybe<Scalars["ISO8601Date"]>
  /** Is this parish */
  isThisParish?: Maybe<Scalars["Boolean"]>
  /** Parish name */
  parishName?: Maybe<Scalars["String"]>
  /** Parish address */
  parishAddress?: Maybe<AddressAttributes>
  /** Minister */
  minister?: Maybe<SacramentalRecordsPersonAttributes>
  /** Sponsor1 */
  sponsor1?: Maybe<SacramentalRecordsPersonAttributes>
  /** Sponsor2 */
  sponsor2?: Maybe<SacramentalRecordsPersonAttributes>
  /** Proxy1 */
  proxy1?: Maybe<SacramentalRecordsPersonAttributes>
  /** Proxy2 */
  proxy2?: Maybe<SacramentalRecordsPersonAttributes>
  /** Record location notes */
  recordLocationNotes?: Maybe<Scalars["String"]>
  /** Notations */
  notations?: Maybe<Scalars["String"]>
  /** Profession of faith */
  professionOfFaith?: Maybe<Scalars["Boolean"]>
  /** Confirmation name */
  sacramentalName?: Maybe<Scalars["String"]>
  /** Current marriage status */
  currentMarriageStatus?: Maybe<Scalars["String"]>
  /** Funeral date */
  funeralDate?: Maybe<Scalars["ISO8601DateTime"]>
  /** Burial date */
  burialDate?: Maybe<Scalars["ISO8601DateTime"]>
  /** Burial place */
  burialPlace?: Maybe<Scalars["String"]>
  /** Cremated */
  cremated?: Maybe<Scalars["Boolean"]>
}

export type RecordsResult = {
  __typename?: "RecordsResult"
  records: Array<Record>
  totalCount: Scalars["Int"]
}

export type ReportActionPerson = {
  __typename?: "ReportActionPerson"
  fullName?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
}

export type Roles = {
  __typename?: "Roles"
  parish?: Maybe<Array<Maybe<Scalars["String"]>>>
  monetary?: Maybe<Array<Maybe<Scalars["String"]>>>
  staff?: Maybe<Array<Maybe<Scalars["String"]>>>
  admin?: Maybe<Array<Maybe<Scalars["String"]>>>
  family?: Maybe<Array<Maybe<Scalars["String"]>>>
  inactiveStatus?: Maybe<InactiveStatus>
}

/** Attributes for creating or updating a person */
export type SacramentalRecordsPersonAttributes = {
  /** ID */
  id?: Maybe<Scalars["ID"]>
  /** First name */
  firstName?: Maybe<Scalars["String"]>
  /** Last name */
  lastName?: Maybe<Scalars["String"]>
  /** Title */
  title?: Maybe<Scalars["String"]>
  /** Person */
  personId?: Maybe<Scalars["ID"]>
}

export type SacramentalRecordsPersonType = {
  __typename?: "SacramentalRecordsPersonType"
  firstName?: Maybe<Scalars["String"]>
  id: Scalars["ID"]
  lastName?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
  title?: Maybe<Scalars["String"]>
}

export type GetDomainOrgQueryVariables = Exact<{ [key: string]: never }>

export type GetDomainOrgQuery = { __typename?: "Query" } & {
  domainOrg: { __typename?: "Org" } & Pick<Org, "id" | "name" | "paydockGatewayId" | "paydockPublicKey">
}

export type GetPledgesQueryVariables = Exact<{
  fromDate: Scalars["String"]
  toDate?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
  campaignId?: Maybe<Scalars["ID"]>
  isSubscription?: Maybe<Scalars["Boolean"]>
}>

export type GetPledgesQuery = { __typename?: "Query" } & {
  pledges: { __typename?: "PledgesResult" } & Pick<PledgesResult, "totalCount"> & {
      pledges: Array<{ __typename?: "Pledge" } & PledgeFragmentFragment>
    }
}

export type CreatePledgeMutationVariables = Exact<{
  personId: Scalars["ID"]
  pledge: PledgeAttributes
  paydockToken?: Maybe<Scalars["String"]>
  contactEmail?: Maybe<Scalars["String"]>
}>

export type CreatePledgeMutation = { __typename?: "Mutation" } & {
  createPledge: { __typename?: "Pledge" } & PledgeFragmentFragment
}

export type UpdatePledgeMutationVariables = Exact<{
  id: Scalars["ID"]
  pledge: PledgeAttributes
  paydockToken?: Maybe<Scalars["String"]>
  contactEmail?: Maybe<Scalars["String"]>
}>

export type UpdatePledgeMutation = { __typename?: "Mutation" } & {
  pledge: { __typename?: "Pledge" } & PledgeFragmentFragment
}

export type PledgeFragmentFragment = { __typename?: "Pledge" } & Pick<
  Pledge,
  | "id"
  | "transactionCents"
  | "createdAt"
  | "frequency"
  | "ccStatus"
  | "cardLast4"
  | "cardBrand"
  | "quantity"
  | "payments"
  | "canceled"
  | "nextScheduledChargeDate"
> & { campaign?: Maybe<{ __typename?: "Campaign" } & Pick<Campaign, "id" | "brief" | "title">> }

export type ChargeFragmentFragment = { __typename?: "Charge" } & Pick<
  Charge,
  "id" | "transactionCents" | "createdAt" | "processedAt" | "chargeType" | "recurrenceType" | "processingStatus"
> & {
    person?: Maybe<{ __typename?: "ReportActionPerson" } & Pick<ReportActionPerson, "fullName" | "id">>
    campaign?: Maybe<{ __typename?: "Campaign" } & Pick<Campaign, "id" | "brief" | "fund" | "title">>
    pledge?: Maybe<{ __typename?: "Pledge" } & Pick<Pledge, "id" | "frequency" | "paymentMethod">>
    financialProfile?: Maybe<{ __typename?: "FinancialProfile" } & Pick<FinancialProfile, "envelopeNumber">>
  }

export type GetChargesQueryVariables = Exact<{
  fromDate: Scalars["String"]
  toDate?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
  pledgeId?: Maybe<Scalars["ID"]>
  campaignId?: Maybe<Scalars["String"]>
  page?: Maybe<Scalars["Int"]>
  perPage?: Maybe<Scalars["Int"]>
  sortBy?: Maybe<Scalars["String"]>
  asc?: Maybe<Scalars["Boolean"]>
}>

export type GetChargesQuery = { __typename?: "Query" } & {
  charges: { __typename?: "ChargesResult" } & Pick<
    ChargesResult,
    "totalTransactionCents" | "recurringTransactionCents" | "oneTimeTransactionCents" | "totalCount"
  > & { charges: Array<{ __typename?: "Charge" } & ChargeFragmentFragment> }
}

export type GetCampaignsQueryVariables = Exact<{ [key: string]: never }>

export type GetCampaignsQuery = { __typename?: "Query" } & {
  campaigns: { __typename?: "CampaignsResult" } & Pick<CampaignsResult, "totalCount"> & {
      campaigns: Array<{ __typename?: "Campaign" } & Pick<Campaign, "id" | "title">>
    }
}

export type DeleteChargeMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type DeleteChargeMutation = { __typename?: "Mutation" } & {
  deleteCharge: { __typename?: "Charge" } & Pick<Charge, "id">
}

export type UpdateChargeMutationVariables = Exact<{
  id: Scalars["ID"]
  transactionCents?: Maybe<Scalars["Int"]>
  processedAt?: Maybe<Scalars["String"]>
  campaignId: Scalars["ID"]
}>

export type UpdateChargeMutation = { __typename?: "Mutation" } & {
  charge: { __typename?: "Charge" } & ChargeFragmentFragment
}

export type QueryFragmentFragment = { __typename?: "PeopleQuery" } & Pick<
  PeopleQuery,
  "id" | "descriptor" | "humanReadable" | "isFavorite" | "isOneTime" | "jsonTree" | "preset" | "initQuery"
>

export type PeopleQueriesQueryVariables = Exact<{
  isFavorite?: Maybe<Scalars["Boolean"]>
  isPreset?: Maybe<Scalars["Boolean"]>
}>

export type PeopleQueriesQuery = { __typename?: "Query" } & {
  queries: { __typename?: "QueriesResult" } & Pick<QueriesResult, "totalCount"> & {
      queries: Array<{ __typename?: "PeopleQuery" } & QueryFragmentFragment>
    }
}

export type FormPartsQueryQueryVariables = Exact<{ [key: string]: never }>

export type FormPartsQueryQuery = { __typename?: "Query" } & Pick<Query, "formParts">

export type PeopleQueryQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type PeopleQueryQuery = { __typename?: "Query" } & {
  query: { __typename?: "PeopleQuery" } & QueryFragmentFragment
}

export type CreatePersonQueryMutationVariables = Exact<{
  query: QueryAttributes
}>

export type CreatePersonQueryMutation = { __typename?: "Mutation" } & {
  createQuery: { __typename?: "PeopleQuery" } & Pick<PeopleQuery, "id">
}

export type UpdatePersonQueryMutationVariables = Exact<{
  id: Scalars["ID"]
  query: QueryAttributes
}>

export type UpdatePersonQueryMutation = { __typename?: "Mutation" } & {
  updateQuery: { __typename?: "PeopleQuery" } & QueryFragmentFragment
}

export type DeletePersonQueryMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type DeletePersonQueryMutation = { __typename?: "Mutation" } & {
  deleteQuery: { __typename?: "PeopleQuery" } & Pick<PeopleQuery, "id">
}

export type CurrentPersonQueryVariables = Exact<{ [key: string]: never }>

export type CurrentPersonQuery = { __typename?: "Query" } & {
  currentPerson: { __typename?: "CurrentPerson" } & Pick<CurrentPerson, "id" | "isAgent" | "canToggleDataMasking">
}

export type GetPeopleQueryVariables = Exact<{
  searchStr?: Maybe<Scalars["String"]>
  queryId?: Maybe<Scalars["ID"]>
  queryPreset?: Maybe<Scalars["String"]>
  familyId?: Maybe<Scalars["Int"]>
  page?: Maybe<Scalars["Int"]>
  perPage?: Maybe<Scalars["Int"]>
  sortBy?: Maybe<Scalars["String"]>
  asc?: Maybe<Scalars["Boolean"]>
}>

export type GetPeopleQuery = { __typename?: "Query" } & {
  people: { __typename?: "PeopleResult" } & Pick<PeopleResult, "totalCount"> & {
      people: Array<
        { __typename?: "Person" } & {
          globalRoles?: Maybe<
            { __typename?: "Roles" } & Pick<
              Roles,
              "parish" | "monetary" | "staff" | "admin" | "inactiveStatus" | "family"
            >
          >
          inOrgRoles?: Maybe<
            { __typename?: "Roles" } & Pick<
              Roles,
              "parish" | "monetary" | "staff" | "admin" | "inactiveStatus" | "family"
            >
          >
        } & PersonFragmentFragment
      >
    }
}

export type GetPersonQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GetPersonQuery = { __typename?: "Query" } & {
  person: { __typename?: "Person" } & Pick<
    Person,
    | "isAuthor"
    | "isAdmin"
    | "isSubscriber"
    | "isTeamMember"
    | "isOnlineMember"
    | "isMemberRegistered"
    | "isNonPrimaryParish"
  > & {
      globalRoles?: Maybe<
        { __typename?: "Roles" } & Pick<Roles, "parish" | "monetary" | "staff" | "admin" | "inactiveStatus" | "family">
      >
      inOrgRoles?: Maybe<
        { __typename?: "Roles" } & Pick<Roles, "parish" | "monetary" | "staff" | "admin" | "inactiveStatus" | "family">
      >
    } & PersonFragmentFragment
}

export type DeletePersonMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type DeletePersonMutation = { __typename?: "Mutation" } & {
  deletePerson: { __typename?: "Person" } & Pick<Person, "id">
}

export type CreatePersonMutationVariables = Exact<{
  person: PersonAttributes
}>

export type CreatePersonMutation = { __typename?: "Mutation" } & {
  createPerson: { __typename?: "Person" } & PersonFragmentFragment
}

export type UpdatePersonMutationVariables = Exact<{
  id: Scalars["ID"]
  person: PersonAttributes
}>

export type UpdatePersonMutation = { __typename?: "Mutation" } & {
  person: { __typename?: "Person" } & PersonFragmentFragment
}

export type PersonAvatarQueryVariables = Exact<{
  id: Scalars["ID"]
  geometry?: Maybe<Scalars["String"]>
  crop?: Maybe<Scalars["Boolean"]>
}>

export type PersonAvatarQuery = { __typename?: "Query" } & {
  person: { __typename?: "Person" } & Pick<Person, "id"> & {
      avatarPhoto?: Maybe<
        { __typename?: "Image" } & Pick<Image, "id" | "src"> & {
            position?: Maybe<{ __typename?: "ImagePosition" } & Pick<ImagePosition, "x" | "y">>
          }
      >
      authorPhoto?: Maybe<
        { __typename?: "Image" } & Pick<Image, "id" | "src"> & {
            position?: Maybe<{ __typename?: "ImagePosition" } & Pick<ImagePosition, "x" | "y">>
          }
      >
    }
}

export type PersonFragmentFragment = { __typename?: "Person" } & Pick<
  Person,
  | "id"
  | "rmsSectionsData"
  | "email"
  | "memberDoNotEmail"
  | "firstName"
  | "memberMiddleName"
  | "lastName"
  | "memberMaidenName"
  | "status"
  | "orgAccountStatus"
  | "orgStatuses"
  | "orgRoleAspects"
  | "isAdmin"
  | "isAuthUser"
  | "authUserTwoFactorEnabled"
  | "authUserEnforceTwoFactor"
  | "authUserCustomTwoFactorAuthNumber"
  | "authUserLastSignInAt"
  | "isMember"
  | "memberHaveChildren"
  | "memberBaptised"
  | "memberConfirmed"
  | "memberFirstCommunion"
  | "memberMissionaryDiscipleCommitment"
  | "memberTrainingCommitment"
  | "memberRunSeriesCommitment"
  | "memberPrayerCommitment"
  | "memberSiteTraining"
  | "memberAlphaTraining"
  | "memberDiscoveryTraining"
  | "memberHostingAlpha"
  | "memberHostingDiscovery"
  | "memberBirthday"
  | "memberBirthplace"
  | "memberDateMarried"
  | "memberReligion"
  | "memberMaritalStatus"
  | "memberGender"
  | "memberChurchWhereMarried"
  | "memberCityWhereMarried"
  | "memberCountryWhereMarried"
  | "memberSchoolName"
  | "memberSchoolGrade"
  | "memberReligionOther"
  | "memberHomePhone"
  | "memberCellPhone"
  | "memberDoNotCall"
  | "memberOccupation"
  | "memberPhotoId"
  | "memberTitle"
  | "memberSuffix"
  | "memberWebsiteUrl"
  | "memberFacebookUrl"
  | "memberTwitterUrl"
  | "memberInstagramUrl"
  | "memberFamilyRole"
  | "memberFamilyId"
  | "memberNamePreference"
  | "addressAddress"
  | "addressAddress2"
  | "addressCity"
  | "addressStateProvince"
  | "addressPostalCode"
  | "addressCountry"
  | "financialProfileEnvelopeNumber"
  | "financialProfileCurrentPaymentMethod"
  | "financialProfileWeeklyDollarsHistoricalEstimate"
  | "isTeamMember"
  | "teamMemberJobTitle"
  | "teamMemberCustomJobTitle"
  | "teamMemberDisplayEmail"
  | "teamMemberBio"
  | "teamMemberDisplayMember"
  | "isAuthor"
  | "authorBio"
  | "authorTwitterHandle"
  | "authorWebsiteUrl"
  | "authorFacebookUrl"
  | "authorInstagramUrl"
  | "authorIsContentProvider"
  | "authorVoiceIndexVisibility"
  | "authorPhotoId"
  | "authorAnonymous"
  | "isSubscriber"
> & {
    memberPhoto?: Maybe<{ __typename?: "Image" } & Pick<Image, "id" | "src" | "srcset">>
    authorPhoto?: Maybe<{ __typename?: "Image" } & Pick<Image, "id" | "src" | "srcset">>
  }

export type GetReportActionsQueryVariables = Exact<{
  fromDate: Scalars["String"]
  toDate?: Maybe<Scalars["String"]>
  metricsFor?: Maybe<Scalars["String"]>
  category?: Maybe<Scalars["String"]>
  action?: Maybe<Scalars["String"]>
  label?: Maybe<Scalars["String"]>
  personId?: Maybe<Scalars["ID"]>
}>

export type GetReportActionsQuery = { __typename?: "Query" } & {
  reportActions: { __typename?: "ActionsResult" } & Pick<ActionsResult, "totalCount" | "totalSum"> & {
      metrics?: Maybe<
        Array<
          { __typename?: "Metric" } & Pick<Metric, "count" | "sum"> & {
              fields: Array<{ __typename?: "MetricField" } & Pick<MetricField, "field" | "value" | "rawVal">>
            }
        >
      >
    }
}

export type RecordFragmentFragment = { __typename?: "Record" } & Pick<
  Record,
  | "id"
  | "recordType"
  | "personId"
  | "occurredAt"
  | "isThisParish"
  | "parishName"
  | "recordLocationNotes"
  | "notations"
  | "professionOfFaith"
  | "sacramentalName"
  | "currentMarriageStatus"
  | "funeralDate"
  | "burialDate"
  | "burialPlace"
  | "cremated"
> & {
    parishAddress?: Maybe<
      { __typename?: "Address" } & Pick<
        Address,
        "city" | "address" | "address2" | "stateProvince" | "postalCode" | "country"
      >
    >
    minister?: Maybe<
      { __typename?: "SacramentalRecordsPersonType" } & Pick<
        SacramentalRecordsPersonType,
        "title" | "firstName" | "lastName" | "personId"
      >
    >
    sponsor1?: Maybe<
      { __typename?: "SacramentalRecordsPersonType" } & Pick<
        SacramentalRecordsPersonType,
        "title" | "firstName" | "lastName" | "personId"
      >
    >
    sponsor2?: Maybe<
      { __typename?: "SacramentalRecordsPersonType" } & Pick<
        SacramentalRecordsPersonType,
        "title" | "firstName" | "lastName" | "personId"
      >
    >
    proxy1?: Maybe<
      { __typename?: "SacramentalRecordsPersonType" } & Pick<
        SacramentalRecordsPersonType,
        "title" | "firstName" | "lastName" | "personId"
      >
    >
    proxy2?: Maybe<
      { __typename?: "SacramentalRecordsPersonType" } & Pick<
        SacramentalRecordsPersonType,
        "title" | "firstName" | "lastName" | "personId"
      >
    >
    wife?: Maybe<
      { __typename?: "SacramentalRecordsPersonType" } & Pick<
        SacramentalRecordsPersonType,
        "title" | "firstName" | "lastName" | "personId"
      >
    >
    husband?: Maybe<
      { __typename?: "SacramentalRecordsPersonType" } & Pick<
        SacramentalRecordsPersonType,
        "title" | "firstName" | "lastName" | "personId"
      >
    >
  }

export type GetSacramentalRecordsQueryVariables = Exact<{
  personId?: Maybe<Scalars["ID"]>
  recordType?: Maybe<Scalars["String"]>
}>

export type GetSacramentalRecordsQuery = { __typename?: "Query" } & {
  sacramentalRecords: { __typename?: "RecordsResult" } & Pick<RecordsResult, "totalCount"> & {
      records: Array<{ __typename?: "Record" } & RecordFragmentFragment>
    }
}

export type GetSacramentalRecordQueryVariables = Exact<{
  id: Scalars["ID"]
}>

export type GetSacramentalRecordQuery = { __typename?: "Query" } & {
  sacramentalRecord: { __typename?: "Record" } & RecordFragmentFragment
}

export type CreateSacramentalRecordMutationVariables = Exact<{
  record: RecordAttributes
}>

export type CreateSacramentalRecordMutation = { __typename?: "Mutation" } & {
  createSacramentalRecord: { __typename?: "Record" } & RecordFragmentFragment
}

export type UpdateSacramentalRecordMutationVariables = Exact<{
  id: Scalars["ID"]
  record: RecordAttributes
}>

export type UpdateSacramentalRecordMutation = { __typename?: "Mutation" } & {
  sacramentalRecord: { __typename?: "Record" } & RecordFragmentFragment
}

export type DeleteSacramentalRecordMutationVariables = Exact<{
  id: Scalars["ID"]
}>

export type DeleteSacramentalRecordMutation = { __typename?: "Mutation" } & {
  deleteSacramentalRecord: { __typename?: "Record" } & Pick<Record, "id">
}

export const PledgeFragmentFragmentDoc = gql`
  fragment PledgeFragment on Pledge {
    id
    transactionCents
    createdAt
    frequency
    ccStatus
    cardLast4
    cardBrand
    quantity
    payments
    canceled
    nextScheduledChargeDate
    campaign {
      id
      brief
      title
    }
  }
`
export const ChargeFragmentFragmentDoc = gql`
  fragment ChargeFragment on Charge {
    id
    transactionCents
    createdAt
    processedAt
    chargeType
    recurrenceType
    processingStatus
    person {
      fullName
      id
    }
    campaign {
      id
      brief
      fund
      title
    }
    pledge {
      id
      frequency
      paymentMethod
    }
    financialProfile {
      envelopeNumber
    }
  }
`
export const QueryFragmentFragmentDoc = gql`
  fragment QueryFragment on PeopleQuery {
    id
    descriptor
    humanReadable
    isFavorite
    isOneTime
    jsonTree
    preset
    initQuery
  }
`
export const PersonFragmentFragmentDoc = gql`
  fragment PersonFragment on Person {
    id
    rmsSectionsData
    email
    memberDoNotEmail
    firstName
    memberMiddleName
    lastName
    memberMaidenName
    status
    orgAccountStatus
    orgStatuses
    orgRoleAspects
    isAdmin
    isAuthUser
    authUserTwoFactorEnabled
    authUserEnforceTwoFactor
    authUserCustomTwoFactorAuthNumber
    authUserLastSignInAt
    isMember
    memberHaveChildren
    memberBaptised
    memberConfirmed
    memberFirstCommunion
    memberMissionaryDiscipleCommitment
    memberTrainingCommitment
    memberRunSeriesCommitment
    memberPrayerCommitment
    memberSiteTraining
    memberAlphaTraining
    memberDiscoveryTraining
    memberHostingAlpha
    memberHostingDiscovery
    memberBirthday
    memberBirthplace
    memberDateMarried
    memberReligion
    memberMaritalStatus
    memberGender
    memberChurchWhereMarried
    memberCityWhereMarried
    memberCountryWhereMarried
    memberSchoolName
    memberSchoolGrade
    memberReligionOther
    memberHomePhone
    memberCellPhone
    memberDoNotCall
    memberOccupation
    memberPhotoId
    memberTitle
    memberSuffix
    memberWebsiteUrl
    memberFacebookUrl
    memberTwitterUrl
    memberInstagramUrl
    memberFamilyRole
    memberFamilyId
    memberNamePreference
    addressAddress
    addressAddress2
    addressCity
    addressStateProvince
    addressPostalCode
    addressCountry
    financialProfileEnvelopeNumber
    financialProfileCurrentPaymentMethod
    financialProfileWeeklyDollarsHistoricalEstimate
    isTeamMember
    teamMemberJobTitle
    teamMemberCustomJobTitle
    teamMemberDisplayEmail
    teamMemberBio
    teamMemberDisplayMember
    isAuthor
    authorBio
    authorTwitterHandle
    authorWebsiteUrl
    authorFacebookUrl
    authorInstagramUrl
    authorIsContentProvider
    authorVoiceIndexVisibility
    authorPhotoId
    authorAnonymous
    isSubscriber
    memberPhoto(geometry: "500x500") {
      id
      src
      srcset
    }
    authorPhoto(geometry: "500x500") {
      id
      src
      srcset
    }
  }
`
export const RecordFragmentFragmentDoc = gql`
  fragment RecordFragment on Record {
    id
    recordType
    personId
    occurredAt
    isThisParish
    parishName
    recordLocationNotes
    notations
    professionOfFaith
    sacramentalName
    currentMarriageStatus
    funeralDate
    burialDate
    burialPlace
    cremated
    parishAddress {
      city
      address
      address2
      city
      stateProvince
      postalCode
      country
    }
    minister {
      title
      firstName
      lastName
      personId
    }
    sponsor1 {
      title
      firstName
      lastName
      personId
    }
    sponsor2 {
      title
      firstName
      lastName
      personId
    }
    proxy1 {
      title
      firstName
      lastName
      personId
    }
    proxy2 {
      title
      firstName
      lastName
      personId
    }
    wife {
      title
      firstName
      lastName
      personId
    }
    husband {
      title
      firstName
      lastName
      personId
    }
  }
`
export const GetDomainOrgDocument = gql`
  query GetDomainOrg {
    domainOrg {
      id
      name
      paydockGatewayId
      paydockPublicKey
    }
  }
`

/**
 * __useGetDomainOrgQuery__
 *
 * To run a query within a React component, call `useGetDomainOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDomainOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDomainOrgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDomainOrgQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDomainOrgQuery, GetDomainOrgQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDomainOrgQuery, GetDomainOrgQueryVariables>(GetDomainOrgDocument, options)
}
export function useGetDomainOrgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDomainOrgQuery, GetDomainOrgQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDomainOrgQuery, GetDomainOrgQueryVariables>(GetDomainOrgDocument, options)
}
export type GetDomainOrgQueryHookResult = ReturnType<typeof useGetDomainOrgQuery>
export type GetDomainOrgLazyQueryHookResult = ReturnType<typeof useGetDomainOrgLazyQuery>
export type GetDomainOrgQueryResult = Apollo.QueryResult<GetDomainOrgQuery, GetDomainOrgQueryVariables>
export const GetPledgesDocument = gql`
  query GetPledges($fromDate: String!, $toDate: String, $personId: ID, $campaignId: ID, $isSubscription: Boolean) {
    pledges(
      fromDate: $fromDate
      toDate: $toDate
      personId: $personId
      campaignId: $campaignId
      isSubscription: $isSubscription
    ) {
      pledges {
        ...PledgeFragment
      }
      totalCount
    }
  }
  ${PledgeFragmentFragmentDoc}
`

/**
 * __useGetPledgesQuery__
 *
 * To run a query within a React component, call `useGetPledgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgesQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      personId: // value for 'personId'
 *      campaignId: // value for 'campaignId'
 *      isSubscription: // value for 'isSubscription'
 *   },
 * });
 */
export function useGetPledgesQuery(baseOptions: Apollo.QueryHookOptions<GetPledgesQuery, GetPledgesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPledgesQuery, GetPledgesQueryVariables>(GetPledgesDocument, options)
}
export function useGetPledgesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPledgesQuery, GetPledgesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPledgesQuery, GetPledgesQueryVariables>(GetPledgesDocument, options)
}
export type GetPledgesQueryHookResult = ReturnType<typeof useGetPledgesQuery>
export type GetPledgesLazyQueryHookResult = ReturnType<typeof useGetPledgesLazyQuery>
export type GetPledgesQueryResult = Apollo.QueryResult<GetPledgesQuery, GetPledgesQueryVariables>
export const CreatePledgeDocument = gql`
  mutation CreatePledge($personId: ID!, $pledge: PledgeAttributes!, $paydockToken: String, $contactEmail: String) {
    createPledge(personId: $personId, pledge: $pledge, paydockToken: $paydockToken, contactEmail: $contactEmail) {
      ...PledgeFragment
    }
  }
  ${PledgeFragmentFragmentDoc}
`
export type CreatePledgeMutationFn = Apollo.MutationFunction<CreatePledgeMutation, CreatePledgeMutationVariables>

/**
 * __useCreatePledgeMutation__
 *
 * To run a mutation, you first call `useCreatePledgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePledgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPledgeMutation, { data, loading, error }] = useCreatePledgeMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      pledge: // value for 'pledge'
 *      paydockToken: // value for 'paydockToken'
 *      contactEmail: // value for 'contactEmail'
 *   },
 * });
 */
export function useCreatePledgeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePledgeMutation, CreatePledgeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePledgeMutation, CreatePledgeMutationVariables>(CreatePledgeDocument, options)
}
export type CreatePledgeMutationHookResult = ReturnType<typeof useCreatePledgeMutation>
export type CreatePledgeMutationResult = Apollo.MutationResult<CreatePledgeMutation>
export type CreatePledgeMutationOptions = Apollo.BaseMutationOptions<
  CreatePledgeMutation,
  CreatePledgeMutationVariables
>
export const UpdatePledgeDocument = gql`
  mutation UpdatePledge($id: ID!, $pledge: PledgeAttributes!, $paydockToken: String, $contactEmail: String) {
    pledge(id: $id, pledge: $pledge, paydockToken: $paydockToken, contactEmail: $contactEmail) {
      ...PledgeFragment
    }
  }
  ${PledgeFragmentFragmentDoc}
`
export type UpdatePledgeMutationFn = Apollo.MutationFunction<UpdatePledgeMutation, UpdatePledgeMutationVariables>

/**
 * __useUpdatePledgeMutation__
 *
 * To run a mutation, you first call `useUpdatePledgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePledgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePledgeMutation, { data, loading, error }] = useUpdatePledgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      pledge: // value for 'pledge'
 *      paydockToken: // value for 'paydockToken'
 *      contactEmail: // value for 'contactEmail'
 *   },
 * });
 */
export function useUpdatePledgeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePledgeMutation, UpdatePledgeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePledgeMutation, UpdatePledgeMutationVariables>(UpdatePledgeDocument, options)
}
export type UpdatePledgeMutationHookResult = ReturnType<typeof useUpdatePledgeMutation>
export type UpdatePledgeMutationResult = Apollo.MutationResult<UpdatePledgeMutation>
export type UpdatePledgeMutationOptions = Apollo.BaseMutationOptions<
  UpdatePledgeMutation,
  UpdatePledgeMutationVariables
>
export const GetChargesDocument = gql`
  query GetCharges(
    $fromDate: String!
    $toDate: String
    $personId: ID
    $pledgeId: ID
    $campaignId: String
    $page: Int
    $perPage: Int
    $sortBy: String
    $asc: Boolean
  ) {
    charges(
      fromDate: $fromDate
      toDate: $toDate
      personId: $personId
      pledgeId: $pledgeId
      campaignId: $campaignId
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      asc: $asc
    ) {
      charges {
        ...ChargeFragment
      }
      totalTransactionCents
      recurringTransactionCents
      oneTimeTransactionCents
      totalCount
    }
  }
  ${ChargeFragmentFragmentDoc}
`

/**
 * __useGetChargesQuery__
 *
 * To run a query within a React component, call `useGetChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargesQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      personId: // value for 'personId'
 *      pledgeId: // value for 'pledgeId'
 *      campaignId: // value for 'campaignId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortBy: // value for 'sortBy'
 *      asc: // value for 'asc'
 *   },
 * });
 */
export function useGetChargesQuery(baseOptions: Apollo.QueryHookOptions<GetChargesQuery, GetChargesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetChargesQuery, GetChargesQueryVariables>(GetChargesDocument, options)
}
export function useGetChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargesQuery, GetChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetChargesQuery, GetChargesQueryVariables>(GetChargesDocument, options)
}
export type GetChargesQueryHookResult = ReturnType<typeof useGetChargesQuery>
export type GetChargesLazyQueryHookResult = ReturnType<typeof useGetChargesLazyQuery>
export type GetChargesQueryResult = Apollo.QueryResult<GetChargesQuery, GetChargesQueryVariables>
export const GetCampaignsDocument = gql`
  query GetCampaigns {
    campaigns {
      campaigns {
        id
        title
      }
      totalCount
    }
  }
`

/**
 * __useGetCampaignsQuery__
 *
 * To run a query within a React component, call `useGetCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options)
}
export function useGetCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignsQuery, GetCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCampaignsQuery, GetCampaignsQueryVariables>(GetCampaignsDocument, options)
}
export type GetCampaignsQueryHookResult = ReturnType<typeof useGetCampaignsQuery>
export type GetCampaignsLazyQueryHookResult = ReturnType<typeof useGetCampaignsLazyQuery>
export type GetCampaignsQueryResult = Apollo.QueryResult<GetCampaignsQuery, GetCampaignsQueryVariables>
export const DeleteChargeDocument = gql`
  mutation DeleteCharge($id: ID!) {
    deleteCharge(id: $id) {
      id
    }
  }
`
export type DeleteChargeMutationFn = Apollo.MutationFunction<DeleteChargeMutation, DeleteChargeMutationVariables>

/**
 * __useDeleteChargeMutation__
 *
 * To run a mutation, you first call `useDeleteChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChargeMutation, { data, loading, error }] = useDeleteChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteChargeMutation, DeleteChargeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteChargeMutation, DeleteChargeMutationVariables>(DeleteChargeDocument, options)
}
export type DeleteChargeMutationHookResult = ReturnType<typeof useDeleteChargeMutation>
export type DeleteChargeMutationResult = Apollo.MutationResult<DeleteChargeMutation>
export type DeleteChargeMutationOptions = Apollo.BaseMutationOptions<
  DeleteChargeMutation,
  DeleteChargeMutationVariables
>
export const UpdateChargeDocument = gql`
  mutation UpdateCharge($id: ID!, $transactionCents: Int, $processedAt: String, $campaignId: ID!) {
    charge(id: $id, transactionCents: $transactionCents, processedAt: $processedAt, campaignId: $campaignId) {
      ...ChargeFragment
    }
  }
  ${ChargeFragmentFragmentDoc}
`
export type UpdateChargeMutationFn = Apollo.MutationFunction<UpdateChargeMutation, UpdateChargeMutationVariables>

/**
 * __useUpdateChargeMutation__
 *
 * To run a mutation, you first call `useUpdateChargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChargeMutation, { data, loading, error }] = useUpdateChargeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      transactionCents: // value for 'transactionCents'
 *      processedAt: // value for 'processedAt'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useUpdateChargeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateChargeMutation, UpdateChargeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateChargeMutation, UpdateChargeMutationVariables>(UpdateChargeDocument, options)
}
export type UpdateChargeMutationHookResult = ReturnType<typeof useUpdateChargeMutation>
export type UpdateChargeMutationResult = Apollo.MutationResult<UpdateChargeMutation>
export type UpdateChargeMutationOptions = Apollo.BaseMutationOptions<
  UpdateChargeMutation,
  UpdateChargeMutationVariables
>
export const PeopleQueriesDocument = gql`
  query PeopleQueries($isFavorite: Boolean, $isPreset: Boolean) {
    queries(isFavorite: $isFavorite, isPreset: $isPreset) {
      queries {
        ...QueryFragment
      }
      totalCount
    }
  }
  ${QueryFragmentFragmentDoc}
`

/**
 * __usePeopleQueriesQuery__
 *
 * To run a query within a React component, call `usePeopleQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleQueriesQuery({
 *   variables: {
 *      isFavorite: // value for 'isFavorite'
 *      isPreset: // value for 'isPreset'
 *   },
 * });
 */
export function usePeopleQueriesQuery(
  baseOptions?: Apollo.QueryHookOptions<PeopleQueriesQuery, PeopleQueriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PeopleQueriesQuery, PeopleQueriesQueryVariables>(PeopleQueriesDocument, options)
}
export function usePeopleQueriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PeopleQueriesQuery, PeopleQueriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PeopleQueriesQuery, PeopleQueriesQueryVariables>(PeopleQueriesDocument, options)
}
export type PeopleQueriesQueryHookResult = ReturnType<typeof usePeopleQueriesQuery>
export type PeopleQueriesLazyQueryHookResult = ReturnType<typeof usePeopleQueriesLazyQuery>
export type PeopleQueriesQueryResult = Apollo.QueryResult<PeopleQueriesQuery, PeopleQueriesQueryVariables>
export const FormPartsQueryDocument = gql`
  query FormPartsQuery {
    formParts
  }
`

/**
 * __useFormPartsQueryQuery__
 *
 * To run a query within a React component, call `useFormPartsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormPartsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormPartsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormPartsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<FormPartsQueryQuery, FormPartsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FormPartsQueryQuery, FormPartsQueryQueryVariables>(FormPartsQueryDocument, options)
}
export function useFormPartsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FormPartsQueryQuery, FormPartsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FormPartsQueryQuery, FormPartsQueryQueryVariables>(FormPartsQueryDocument, options)
}
export type FormPartsQueryQueryHookResult = ReturnType<typeof useFormPartsQueryQuery>
export type FormPartsQueryLazyQueryHookResult = ReturnType<typeof useFormPartsQueryLazyQuery>
export type FormPartsQueryQueryResult = Apollo.QueryResult<FormPartsQueryQuery, FormPartsQueryQueryVariables>
export const PeopleQueryDocument = gql`
  query PeopleQuery($id: ID!) {
    query(id: $id) {
      ...QueryFragment
    }
  }
  ${QueryFragmentFragmentDoc}
`

/**
 * __usePeopleQueryQuery__
 *
 * To run a query within a React component, call `usePeopleQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePeopleQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePeopleQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePeopleQueryQuery(baseOptions: Apollo.QueryHookOptions<PeopleQueryQuery, PeopleQueryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PeopleQueryQuery, PeopleQueryQueryVariables>(PeopleQueryDocument, options)
}
export function usePeopleQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PeopleQueryQuery, PeopleQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PeopleQueryQuery, PeopleQueryQueryVariables>(PeopleQueryDocument, options)
}
export type PeopleQueryQueryHookResult = ReturnType<typeof usePeopleQueryQuery>
export type PeopleQueryLazyQueryHookResult = ReturnType<typeof usePeopleQueryLazyQuery>
export type PeopleQueryQueryResult = Apollo.QueryResult<PeopleQueryQuery, PeopleQueryQueryVariables>
export const CreatePersonQueryDocument = gql`
  mutation CreatePersonQuery($query: QueryAttributes!) {
    createQuery(query: $query) {
      id
    }
  }
`
export type CreatePersonQueryMutationFn = Apollo.MutationFunction<
  CreatePersonQueryMutation,
  CreatePersonQueryMutationVariables
>

/**
 * __useCreatePersonQueryMutation__
 *
 * To run a mutation, you first call `useCreatePersonQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonQueryMutation, { data, loading, error }] = useCreatePersonQueryMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCreatePersonQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePersonQueryMutation, CreatePersonQueryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePersonQueryMutation, CreatePersonQueryMutationVariables>(
    CreatePersonQueryDocument,
    options
  )
}
export type CreatePersonQueryMutationHookResult = ReturnType<typeof useCreatePersonQueryMutation>
export type CreatePersonQueryMutationResult = Apollo.MutationResult<CreatePersonQueryMutation>
export type CreatePersonQueryMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonQueryMutation,
  CreatePersonQueryMutationVariables
>
export const UpdatePersonQueryDocument = gql`
  mutation UpdatePersonQuery($id: ID!, $query: QueryAttributes!) {
    updateQuery(id: $id, query: $query) {
      ...QueryFragment
    }
  }
  ${QueryFragmentFragmentDoc}
`
export type UpdatePersonQueryMutationFn = Apollo.MutationFunction<
  UpdatePersonQueryMutation,
  UpdatePersonQueryMutationVariables
>

/**
 * __useUpdatePersonQueryMutation__
 *
 * To run a mutation, you first call `useUpdatePersonQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonQueryMutation, { data, loading, error }] = useUpdatePersonQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useUpdatePersonQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonQueryMutation, UpdatePersonQueryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePersonQueryMutation, UpdatePersonQueryMutationVariables>(
    UpdatePersonQueryDocument,
    options
  )
}
export type UpdatePersonQueryMutationHookResult = ReturnType<typeof useUpdatePersonQueryMutation>
export type UpdatePersonQueryMutationResult = Apollo.MutationResult<UpdatePersonQueryMutation>
export type UpdatePersonQueryMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonQueryMutation,
  UpdatePersonQueryMutationVariables
>
export const DeletePersonQueryDocument = gql`
  mutation DeletePersonQuery($id: ID!) {
    deleteQuery(id: $id) {
      id
    }
  }
`
export type DeletePersonQueryMutationFn = Apollo.MutationFunction<
  DeletePersonQueryMutation,
  DeletePersonQueryMutationVariables
>

/**
 * __useDeletePersonQueryMutation__
 *
 * To run a mutation, you first call `useDeletePersonQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonQueryMutation, { data, loading, error }] = useDeletePersonQueryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonQueryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePersonQueryMutation, DeletePersonQueryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePersonQueryMutation, DeletePersonQueryMutationVariables>(
    DeletePersonQueryDocument,
    options
  )
}
export type DeletePersonQueryMutationHookResult = ReturnType<typeof useDeletePersonQueryMutation>
export type DeletePersonQueryMutationResult = Apollo.MutationResult<DeletePersonQueryMutation>
export type DeletePersonQueryMutationOptions = Apollo.BaseMutationOptions<
  DeletePersonQueryMutation,
  DeletePersonQueryMutationVariables
>
export const CurrentPersonDocument = gql`
  query CurrentPerson {
    currentPerson {
      id
      isAgent
      canToggleDataMasking
    }
  }
`

/**
 * __useCurrentPersonQuery__
 *
 * To run a query within a React component, call `useCurrentPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentPersonQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentPersonQuery, CurrentPersonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentPersonQuery, CurrentPersonQueryVariables>(CurrentPersonDocument, options)
}
export function useCurrentPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentPersonQuery, CurrentPersonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentPersonQuery, CurrentPersonQueryVariables>(CurrentPersonDocument, options)
}
export type CurrentPersonQueryHookResult = ReturnType<typeof useCurrentPersonQuery>
export type CurrentPersonLazyQueryHookResult = ReturnType<typeof useCurrentPersonLazyQuery>
export type CurrentPersonQueryResult = Apollo.QueryResult<CurrentPersonQuery, CurrentPersonQueryVariables>
export const GetPeopleDocument = gql`
  query GetPeople(
    $searchStr: String
    $queryId: ID
    $queryPreset: String
    $familyId: Int
    $page: Int
    $perPage: Int
    $sortBy: String
    $asc: Boolean
  ) {
    people(
      searchStr: $searchStr
      queryId: $queryId
      queryPreset: $queryPreset
      familyId: $familyId
      page: $page
      perPage: $perPage
      sortBy: $sortBy
      asc: $asc
    ) {
      people {
        globalRoles @client {
          parish
          monetary
          staff
          admin
          inactiveStatus
          family
        }
        inOrgRoles @client {
          parish
          monetary
          staff
          admin
          inactiveStatus
          family
        }
        ...PersonFragment
      }
      totalCount
    }
  }
  ${PersonFragmentFragmentDoc}
`

/**
 * __useGetPeopleQuery__
 *
 * To run a query within a React component, call `useGetPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleQuery({
 *   variables: {
 *      searchStr: // value for 'searchStr'
 *      queryId: // value for 'queryId'
 *      queryPreset: // value for 'queryPreset'
 *      familyId: // value for 'familyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortBy: // value for 'sortBy'
 *      asc: // value for 'asc'
 *   },
 * });
 */
export function useGetPeopleQuery(baseOptions?: Apollo.QueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options)
}
export function useGetPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleQuery, GetPeopleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPeopleQuery, GetPeopleQueryVariables>(GetPeopleDocument, options)
}
export type GetPeopleQueryHookResult = ReturnType<typeof useGetPeopleQuery>
export type GetPeopleLazyQueryHookResult = ReturnType<typeof useGetPeopleLazyQuery>
export type GetPeopleQueryResult = Apollo.QueryResult<GetPeopleQuery, GetPeopleQueryVariables>
export const GetPersonDocument = gql`
  query GetPerson($id: ID!) {
    person(id: $id) {
      globalRoles @client {
        parish
        monetary
        staff
        admin
        inactiveStatus
        family
      }
      inOrgRoles @client {
        parish
        monetary
        staff
        admin
        inactiveStatus
        family
      }
      isAuthor @client
      isAdmin @client
      isSubscriber @client
      isTeamMember @client
      isOnlineMember @client
      isMemberRegistered @client
      isNonPrimaryParish @client
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options)
}
export function useGetPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options)
}
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>
export type GetPersonQueryResult = Apollo.QueryResult<GetPersonQuery, GetPersonQueryVariables>
export const DeletePersonDocument = gql`
  mutation DeletePerson($id: ID!) {
    deletePerson(id: $id) {
      id
    }
  }
`
export type DeletePersonMutationFn = Apollo.MutationFunction<DeletePersonMutation, DeletePersonMutationVariables>

/**
 * __useDeletePersonMutation__
 *
 * To run a mutation, you first call `useDeletePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePersonMutation, { data, loading, error }] = useDeletePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePersonMutation, DeletePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePersonMutation, DeletePersonMutationVariables>(DeletePersonDocument, options)
}
export type DeletePersonMutationHookResult = ReturnType<typeof useDeletePersonMutation>
export type DeletePersonMutationResult = Apollo.MutationResult<DeletePersonMutation>
export type DeletePersonMutationOptions = Apollo.BaseMutationOptions<
  DeletePersonMutation,
  DeletePersonMutationVariables
>
export const CreatePersonDocument = gql`
  mutation CreatePerson($person: PersonAttributes!) {
    createPerson(person: $person) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`
export type CreatePersonMutationFn = Apollo.MutationFunction<CreatePersonMutation, CreatePersonMutationVariables>

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      person: // value for 'person'
 *   },
 * });
 */
export function useCreatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePersonMutation, CreatePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePersonMutation, CreatePersonMutationVariables>(CreatePersonDocument, options)
}
export type CreatePersonMutationHookResult = ReturnType<typeof useCreatePersonMutation>
export type CreatePersonMutationResult = Apollo.MutationResult<CreatePersonMutation>
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonMutation,
  CreatePersonMutationVariables
>
export const UpdatePersonDocument = gql`
  mutation UpdatePerson($id: ID!, $person: PersonAttributes!) {
    person(id: $id, person: $person) {
      ...PersonFragment
    }
  }
  ${PersonFragmentFragmentDoc}
`
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      person: // value for 'person'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, options)
}
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>
export const PersonAvatarDocument = gql`
  query PersonAvatar($id: ID!, $geometry: String = "500x500", $crop: Boolean = true) {
    person(id: $id) {
      id
      avatarPhoto: memberPhoto(geometry: $geometry, crop: $crop) {
        id
        src
        position {
          x
          y
        }
      }
      authorPhoto(geometry: $geometry, crop: $crop) {
        id
        src
        position {
          x
          y
        }
      }
    }
  }
`

/**
 * __usePersonAvatarQuery__
 *
 * To run a query within a React component, call `usePersonAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonAvatarQuery({
 *   variables: {
 *      id: // value for 'id'
 *      geometry: // value for 'geometry'
 *      crop: // value for 'crop'
 *   },
 * });
 */
export function usePersonAvatarQuery(
  baseOptions: Apollo.QueryHookOptions<PersonAvatarQuery, PersonAvatarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PersonAvatarQuery, PersonAvatarQueryVariables>(PersonAvatarDocument, options)
}
export function usePersonAvatarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PersonAvatarQuery, PersonAvatarQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PersonAvatarQuery, PersonAvatarQueryVariables>(PersonAvatarDocument, options)
}
export type PersonAvatarQueryHookResult = ReturnType<typeof usePersonAvatarQuery>
export type PersonAvatarLazyQueryHookResult = ReturnType<typeof usePersonAvatarLazyQuery>
export type PersonAvatarQueryResult = Apollo.QueryResult<PersonAvatarQuery, PersonAvatarQueryVariables>
export const GetReportActionsDocument = gql`
  query GetReportActions(
    $fromDate: String!
    $toDate: String
    $metricsFor: String
    $category: String
    $action: String
    $label: String
    $personId: ID
  ) {
    reportActions(
      fromDate: $fromDate
      toDate: $toDate
      metricsFor: $metricsFor
      category: $category
      action: $action
      label: $label
      personId: $personId
    ) {
      totalCount
      totalSum
      metrics {
        fields {
          field
          value
          rawVal
        }
        count
        sum
      }
    }
  }
`

/**
 * __useGetReportActionsQuery__
 *
 * To run a query within a React component, call `useGetReportActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportActionsQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      metricsFor: // value for 'metricsFor'
 *      category: // value for 'category'
 *      action: // value for 'action'
 *      label: // value for 'label'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetReportActionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportActionsQuery, GetReportActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetReportActionsQuery, GetReportActionsQueryVariables>(GetReportActionsDocument, options)
}
export function useGetReportActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportActionsQuery, GetReportActionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetReportActionsQuery, GetReportActionsQueryVariables>(GetReportActionsDocument, options)
}
export type GetReportActionsQueryHookResult = ReturnType<typeof useGetReportActionsQuery>
export type GetReportActionsLazyQueryHookResult = ReturnType<typeof useGetReportActionsLazyQuery>
export type GetReportActionsQueryResult = Apollo.QueryResult<GetReportActionsQuery, GetReportActionsQueryVariables>
export const GetSacramentalRecordsDocument = gql`
  query GetSacramentalRecords($personId: ID, $recordType: String) {
    sacramentalRecords(personId: $personId, recordType: $recordType) {
      records {
        ...RecordFragment
      }
      totalCount
    }
  }
  ${RecordFragmentFragmentDoc}
`

/**
 * __useGetSacramentalRecordsQuery__
 *
 * To run a query within a React component, call `useGetSacramentalRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSacramentalRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSacramentalRecordsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      recordType: // value for 'recordType'
 *   },
 * });
 */
export function useGetSacramentalRecordsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSacramentalRecordsQuery, GetSacramentalRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSacramentalRecordsQuery, GetSacramentalRecordsQueryVariables>(
    GetSacramentalRecordsDocument,
    options
  )
}
export function useGetSacramentalRecordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSacramentalRecordsQuery, GetSacramentalRecordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSacramentalRecordsQuery, GetSacramentalRecordsQueryVariables>(
    GetSacramentalRecordsDocument,
    options
  )
}
export type GetSacramentalRecordsQueryHookResult = ReturnType<typeof useGetSacramentalRecordsQuery>
export type GetSacramentalRecordsLazyQueryHookResult = ReturnType<typeof useGetSacramentalRecordsLazyQuery>
export type GetSacramentalRecordsQueryResult = Apollo.QueryResult<
  GetSacramentalRecordsQuery,
  GetSacramentalRecordsQueryVariables
>
export const GetSacramentalRecordDocument = gql`
  query GetSacramentalRecord($id: ID!) {
    sacramentalRecord(id: $id) {
      ...RecordFragment
    }
  }
  ${RecordFragmentFragmentDoc}
`

/**
 * __useGetSacramentalRecordQuery__
 *
 * To run a query within a React component, call `useGetSacramentalRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSacramentalRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSacramentalRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSacramentalRecordQuery(
  baseOptions: Apollo.QueryHookOptions<GetSacramentalRecordQuery, GetSacramentalRecordQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSacramentalRecordQuery, GetSacramentalRecordQueryVariables>(
    GetSacramentalRecordDocument,
    options
  )
}
export function useGetSacramentalRecordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSacramentalRecordQuery, GetSacramentalRecordQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSacramentalRecordQuery, GetSacramentalRecordQueryVariables>(
    GetSacramentalRecordDocument,
    options
  )
}
export type GetSacramentalRecordQueryHookResult = ReturnType<typeof useGetSacramentalRecordQuery>
export type GetSacramentalRecordLazyQueryHookResult = ReturnType<typeof useGetSacramentalRecordLazyQuery>
export type GetSacramentalRecordQueryResult = Apollo.QueryResult<
  GetSacramentalRecordQuery,
  GetSacramentalRecordQueryVariables
>
export const CreateSacramentalRecordDocument = gql`
  mutation CreateSacramentalRecord($record: RecordAttributes!) {
    createSacramentalRecord(record: $record) {
      ...RecordFragment
    }
  }
  ${RecordFragmentFragmentDoc}
`
export type CreateSacramentalRecordMutationFn = Apollo.MutationFunction<
  CreateSacramentalRecordMutation,
  CreateSacramentalRecordMutationVariables
>

/**
 * __useCreateSacramentalRecordMutation__
 *
 * To run a mutation, you first call `useCreateSacramentalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSacramentalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSacramentalRecordMutation, { data, loading, error }] = useCreateSacramentalRecordMutation({
 *   variables: {
 *      record: // value for 'record'
 *   },
 * });
 */
export function useCreateSacramentalRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSacramentalRecordMutation, CreateSacramentalRecordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSacramentalRecordMutation, CreateSacramentalRecordMutationVariables>(
    CreateSacramentalRecordDocument,
    options
  )
}
export type CreateSacramentalRecordMutationHookResult = ReturnType<typeof useCreateSacramentalRecordMutation>
export type CreateSacramentalRecordMutationResult = Apollo.MutationResult<CreateSacramentalRecordMutation>
export type CreateSacramentalRecordMutationOptions = Apollo.BaseMutationOptions<
  CreateSacramentalRecordMutation,
  CreateSacramentalRecordMutationVariables
>
export const UpdateSacramentalRecordDocument = gql`
  mutation UpdateSacramentalRecord($id: ID!, $record: RecordAttributes!) {
    sacramentalRecord(id: $id, record: $record) {
      ...RecordFragment
    }
  }
  ${RecordFragmentFragmentDoc}
`
export type UpdateSacramentalRecordMutationFn = Apollo.MutationFunction<
  UpdateSacramentalRecordMutation,
  UpdateSacramentalRecordMutationVariables
>

/**
 * __useUpdateSacramentalRecordMutation__
 *
 * To run a mutation, you first call `useUpdateSacramentalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSacramentalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSacramentalRecordMutation, { data, loading, error }] = useUpdateSacramentalRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      record: // value for 'record'
 *   },
 * });
 */
export function useUpdateSacramentalRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSacramentalRecordMutation, UpdateSacramentalRecordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSacramentalRecordMutation, UpdateSacramentalRecordMutationVariables>(
    UpdateSacramentalRecordDocument,
    options
  )
}
export type UpdateSacramentalRecordMutationHookResult = ReturnType<typeof useUpdateSacramentalRecordMutation>
export type UpdateSacramentalRecordMutationResult = Apollo.MutationResult<UpdateSacramentalRecordMutation>
export type UpdateSacramentalRecordMutationOptions = Apollo.BaseMutationOptions<
  UpdateSacramentalRecordMutation,
  UpdateSacramentalRecordMutationVariables
>
export const DeleteSacramentalRecordDocument = gql`
  mutation DeleteSacramentalRecord($id: ID!) {
    deleteSacramentalRecord(id: $id) {
      id
    }
  }
`
export type DeleteSacramentalRecordMutationFn = Apollo.MutationFunction<
  DeleteSacramentalRecordMutation,
  DeleteSacramentalRecordMutationVariables
>

/**
 * __useDeleteSacramentalRecordMutation__
 *
 * To run a mutation, you first call `useDeleteSacramentalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSacramentalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSacramentalRecordMutation, { data, loading, error }] = useDeleteSacramentalRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSacramentalRecordMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSacramentalRecordMutation, DeleteSacramentalRecordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSacramentalRecordMutation, DeleteSacramentalRecordMutationVariables>(
    DeleteSacramentalRecordDocument,
    options
  )
}
export type DeleteSacramentalRecordMutationHookResult = ReturnType<typeof useDeleteSacramentalRecordMutation>
export type DeleteSacramentalRecordMutationResult = Apollo.MutationResult<DeleteSacramentalRecordMutation>
export type DeleteSacramentalRecordMutationOptions = Apollo.BaseMutationOptions<
  DeleteSacramentalRecordMutation,
  DeleteSacramentalRecordMutationVariables
>
