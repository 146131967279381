import React, { useEffect, useReducer } from "react";

// react-bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";

// custom components
import ThemeContext, { themes } from "../theme"
import { dup } from '../utils'
import NewServiceDropdown from "../service/new-service-dropdown";
import ServiceEditorModal from "../service-editor/service-editor-modal"
import ViewModeNav from "./view-mode-nav";
import ScheduleContainer from "./schedule-container"
import ScheduleNinja from "./schedule-ninja"
import ServiceModsList from "../service-mod/service-mods-list"
import Service from "../service/service"
import OccurrencesList from "../service/occurrences-list"
import scheduleReducer, { ScheduleDispatch } from "./schedule-reducer"

export default function ScheduleEditor ({ appTarget }) {
  var orgData = JSON.parse(appTarget.dataset.orgData)
  var scheduleAttrs = JSON.parse(appTarget.dataset.scheduleAttrs)
  var input_elem = document.querySelector(scheduleAttrs.input_elem_selector)

  var initialState = Service.processIncomingData(Object.assign(
    {services: {}, service_mods: {}},
    JSON.parse(input_elem.value || "{}"), // scheduleData
    {transient: {view_mode: 'schedule'}}
  ))

  const [scheduleData, dispatch] = useReducer(scheduleReducer, initialState);
  var viewModes = ['schedule', 'occurrences']

  if (scheduleAttrs.schedule_type != "Regular") {
    viewModes.unshift('settings')
  }

  useEffect(
    () => {document.getElementById("schedule-settings-container").style.display = (scheduleData.transient.view_mode == 'settings' ? "block" : "none")},
    [scheduleData.transient.view_mode]
  );

  useEffect(
    () => {
      var saveData = dup(scheduleData)
      delete saveData.transient
      input_elem.value = JSON.stringify(saveData)
    },
    [scheduleData.transient.trigger_save]
  );

  const themeAttrs          = Object.assign({}, themes['editor_default'], {global_vars: orgData.global_vars})
  orgData['current'] = {data: scheduleData, attrs: scheduleAttrs} // with updated state
  var scheduleNinja = new ScheduleNinja({orgData: orgData, themeAttrs: themeAttrs})

  const editServiceModUid   = scheduleData.transient.edit_service_mod_uid
  const editMod             = scheduleNinja.getServiceMod(editServiceModUid)
  const editServiceModAttrs = scheduleData.transient.edit_service_mod_attrs || (editMod ? editMod.attrs : null)
  const editServiceUid      = editServiceModAttrs ? editServiceModAttrs.service_uid : scheduleData.transient.edit_service_uid
  const editService         = scheduleNinja.getService(editServiceUid       || 0)
  const editServiceAttrs    = scheduleData.transient.edit_service_attrs     || (editService ? editService.attrs : null)

  return (
    <ThemeContext.Provider value={themeAttrs}>
      <ScheduleDispatch.Provider value={dispatch}>
        <Container className='pt-4'>
          <div>
            <Row>
              <NewServiceDropdown />
            </Row>
            <Row>
              <ViewModeNav className="mr-4" itemKeys={viewModes} activeKey={scheduleData.transient.view_mode}
                onChange={(viewMode) => dispatch({type: 'set_view_mode', view_mode: viewMode})}
              />
            </Row>
            <Row>
              <Col md={12}>
                <Tab.Container id='schedule-tabs' activeKey={scheduleData.transient.view_mode}>
                  <Tab.Content>
                    <Tab.Pane eventKey='settings'>{null /* this collapses to nothing and shows the rails form below this */}</Tab.Pane>
                    <Tab.Pane eventKey='schedule'>
                      <ScheduleContainer {...scheduleNinja.componentProps('current')} />
                      <ServiceModsList   serviceMods={scheduleNinja.serviceMods()} />
                    </Tab.Pane>
                    <Tab.Pane eventKey='occurrences'>
                      <OccurrencesList scheduleNinja={scheduleNinja} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </div>
        </Container>

        <ServiceEditorModal serviceAttrs={editServiceAttrs} serviceModAttrs={editServiceModAttrs} onHide={() => dispatch({type: 'close_editor_modal'})} scheduleType={scheduleAttrs.schedule_type} />

      </ScheduleDispatch.Provider>
    </ThemeContext.Provider>
  );
}
