import React from 'react';

const pledgeReducer = (state = {}, action) => {
  var newState = Object.assign({}, state);
  var focus_amount_key = newState.has_custom_total ? 'pledge_cents' : 'transaction_cents'

  switch (action.type) {
    case 'update_amount':
      newState['input_cents'] = Math.round(action.value * 100)
      newState['external_cents'] = undefined // AmountField is now taking over owning the amount
      break
    case 'set_external_cents':
      newState['external_cents'] = action.value
      break
    case 'update_frequency':
      newState['frequency'] = action.value
      break
    case 'update_payments':
      newState['payments_choice'] = action.value
      break
    case 'update_quantity':
      newState['quantity'] = action.value
      break
    case 'set_ui_context_attrs':
      newState = Object.assign(newState, action.value)
      break
  }

  var payments = 1 // used when there is no campaign, just ongoing

  if (newState.campaign_length_months != null) {
    var frequency_divisors = {month: 1, quarter: 3, halfyear: 6, year: 12}
    newState['max_payments'] = Math.floor(newState.campaign_length_months / (frequency_divisors[newState.frequency] || 1)) + 1
    if (!newState['has_custom_payments'] || !newState['payments_choice'] || newState['payments_choice'] < 2 || newState['payments_choice'] > newState['max_payments']) {
      newState['payments_choice'] = newState['max_payments']
    }

    payments = newState['payments'] = newState.frequency == 'once' ? 1 : (newState['payments_choice'] || 1)
  }

  var item_cents = Math.floor(parseFloat(newState['item_price']) * 100)
  // input_cents overrides for payments (means it was set by a 'kind-transaction_amount' form part)
  var input_cents = (newState.input_cents || (newState['is_donation'] ? 0 : item_cents))
  newState[focus_amount_key] = ((input_cents * (newState.quantity || 1)) + newState.add_amount_cents) * (newState.multiply_amount_cents || 1)

  // Calculate the 'other' cents field (transaction_cents <=> pledge_cents)
  if (newState['has_custom_total']) {
    newState['transaction_cents'] = Math.floor((newState['pledge_cents'] * 1.0) / payments)
  }
  else {
    newState['pledge_cents'] = (newState['transaction_cents'] || 0) * payments
  }

  return newState
}

export const PledgeUiDispatch = React.createContext(null)

export const pledgeUiReducer = (state = {}, action) => {
  return {
    pledgeAttrs: pledgeReducer(state['pledgeAttrs'], action),
  }
}