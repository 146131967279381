import { Box, Card, Heading, Spacer } from "@glasscanvas/elephantkit"
import { useContext } from "react"
import {
  ColoredBackgroundScrollEffect,
  ColoredWrapper,
  DEFAULTS,
  HeroSectionProps,
  OutlineButton,
} from "../../components"
import { SectionContext } from "../../section_context"
import { FormattedMessage } from "react-intl"

export function WideImageLayout({ title = DEFAULTS.TITLE, buttonPath = "/welcome", buttonTextKey = "nav.im_new", color }: Partial<HeroSectionProps>) {
  const { featuredImage, featuredVideo } = useContext(SectionContext)

  const textWrapperWidth = 700

  return (
    <ColoredWrapper
      contrastType="dark"
      css={{
        background: `$${color}`,
        position: "relative",
        padding: 0,
        "@tabletPortrait": { marginBottom: "200px", padding: 0 },
      }}
    >
      {featuredVideo ? (
        <Box
          css={{
            width: "100%",
            maxHeight: "75vh",
            minHeight: 400,
            objectFit: "cover",
            display: "block",
          }}
          as="video"
          autoPlay
          loop
          muted
          playsInline
          poster={featuredImage}
        >
          <source src={featuredVideo} type="video/mp4" />
        </Box>
      ) : (
        <Box
          css={{
            width: "100%",
            maxHeight: "75vh",
            minHeight: 400,
            height: "100vh",
            objectFit: "cover",
          }}
          as="img"
          src={featuredImage}
        />
      )}
      <Box
        css={{
          width: "100%",
          paddingInline: "$2",
          display: "flex",
          justifyContent: "center",

          "@tabletPortrait": {
            position: "absolute",
            transform: "translateY(50%)",
            bottom: "0",
            paddingInline: "$5",
          },
        }}
      >
        <Card
          css={{
            padding: "$5",
            width: "100%",
            maxWidth: textWrapperWidth,
            backgroundColor: "$background",
            textAlign: "center",

            "@tabletPortrait": {
              padding: "$10",
            },
          }}
        >
          <Heading
            as="h1"
            size="title2"
            css={{
              lineHeight: 1.5,
              fontWeight: "regular",
              fontFamily: "$serif",
              fontSize: "$title2",

              "@tabletPortrait": {
                fontSize: "$title1",
              },
            }}
          >
            {title}
          </Heading>
          <Spacer size="$10" />
          <OutlineButton href={buttonPath} contrastType="dark">
            <FormattedMessage id={buttonTextKey} defaultMessage="I'm New" />
          </OutlineButton>
        </Card>
      </Box>
    </ColoredWrapper>
  )
}
