import { FormattedMessage } from 'react-intl'
import { TranslatedFrequencyText, PledgeAttrsType, PledgeUiParamsType } from './pledge_ui_legacy'

type PledgeDescriptorSummaryProps = {
  pledgeParams: PledgeUiParamsType
  pledgeAttrs: PledgeAttrsType
}

export const PledgeDescriptorSummary = (props: PledgeDescriptorSummaryProps) => {
  console.log(props);
  const meta = props.pledgeParams.frequency_slugs[props.pledgeAttrs.frequency || 'once']

  return (
    <div className="form-group summary-text py-2">
      <span className="summary-text-frequency">
        <FormattedMessage id="refinery.payments.you_will_be_charged" defaultMessage="You will be charged " />
        &nbsp;<span className="h4">${(props.pledgeAttrs.transaction_cents || 0) / 100.0}</span>&nbsp;
        <span>
          <TranslatedFrequencyText id={meta[1]} />
        </span>
        {props.pledgeAttrs.payments && props.pledgeAttrs.frequency != 'once' ?
          ` for ${meta[2] * props.pledgeAttrs.payments} ${meta[3]} totaling $${(props.pledgeAttrs.pledge_cents || 0) / 100.0}`
        : null}
      </span>
    </div>
  )
}