import {
  Alert,
  Avatar,
  BirthdayIcon,
  Box,
  Button,
  CalendarIcon,
  ChurchIcon,
  EnvelopeIcon,
  FacebookIcon,
  GraphStatsIcon,
  HStack,
  IconProps,
  InstagramIcon,
  Link,
  LocationPinIcon,
  NetworkWwwIcon,
  NewspaperFoldBoldIcon,
  PhoneIcon,
  Spacer,
  Text,
  TwitterIcon,
  UserCheckIcon,
  UserIcon,
  VStack,
} from "@glasscanvas/elephantkit"
import { isEmpty, startCase, trim } from "lodash"
import { ReactChild } from "react"
import { FormattedMessage } from "react-intl"
import { rmsSectionsData, SectionMeta, selectKeys, transformValues } from "../base"
import { Person } from "../graphql"
import { AttributeLayout, nameFormatter, useAttributeLayouts, roleFormatter } from "../shared"
import { PersonFieldKeys } from "./fields_data"
import { PERSON_FIELDS_DATA } from "./index"
import { usePerson } from "../shared"

/** These are the custom layouts per layout grouping */
const attributeLayouts = {
  name: { component: NameLayout, title: "Full Name" },
  address: { component: AddressLayout, title: "Address" },
  phone: { component: PhoneLayout, title: "Phone" },
  email: { component: EmailLayout, title: "Email" },
  status: { component: StatusLayout, title: "Status" },
}

const fieldLayouts = {
  image_id: { component: AvatarLayout },
}

const attributeGroups: Partial<Record<PersonFieldKeys, keyof typeof attributeLayouts>> = {
  // Name
  firstName: "name",
  lastName: "name",
  memberTitle: "name",
  memberSuffix: "name",

  // Address
  addressAddress: "address",
  addressAddress2: "address",
  addressCity: "address",
  addressStateProvince: "address",
  addressCountry: "address",
  addressPostalCode: "address",

  // Phone
  memberHomePhone: "phone",
  memberDoNotCall: "phone",
  memberCellPhone: "phone",

  // Email
  email: "email",
  memberDoNotEmail: "email",

  // Status
  inOrgRoles: "status",
  globalRoles: "status",
}

type PersonAttributeProps = {
  setOpen: (open: boolean) => void
  sectionMeta: SectionMeta
}
export function PersonAttributeDisplay({ setOpen, sectionMeta }: PersonAttributeProps) {
  const person = usePerson()
  const values = transformValues(selectKeys(person, sectionMeta.subSections), PERSON_FIELDS_DATA, true)
  const sections = useAttributeLayouts({
    keys: sectionMeta.subSections,
    meta: PERSON_FIELDS_DATA,
    groups: attributeGroups,
    layouts: { ...attributeLayouts, ...fieldLayouts },
    hiddenAttributes: Object.keys(sectionMeta.hiddenValues ?? {}),
    values,
    defaultLayout: DefaultAttributeLayout,
  })

  if (!person) return null

  var present = !isEmpty(Object.values(values).join(""))
  if (sectionMeta.fallback?.presentCheckAttr) {
    present = person[sectionMeta.fallback?.presentCheckAttr]
  }

  if (!present) {
    return (
      <VStack align="center" justify="center" css={{ padding: "$5 $3" }}>
        <Button onPress={() => setOpen(true)} color="secondary">
          {sectionMeta.fallback?.title ?? `Add Information to ${sectionMeta?.navTitle}`}
        </Button>
      </VStack>
    )
  }

  return (
    <VStack space="large" css={{ paddingBlock: "$4" }}>
      {Object.entries(sections).map(([key, { component: Component, ...section }]) => (
        <Component key={key} slug={key} setOpen={setOpen} {...section} />
      ))}
    </VStack>
  )
}

const iconMap: Partial<Record<PersonFieldKeys, (props: IconProps) => JSX.Element>> = {
  email: EnvelopeIcon,
  memberBirthday: BirthdayIcon,
  memberDateMarried: CalendarIcon,
  memberGender: UserIcon,
  memberReligion: ChurchIcon,
  memberFacebookUrl: FacebookIcon,
  memberTwitterUrl: TwitterIcon,
  memberWebsiteUrl: NetworkWwwIcon,
  memberInstagramUrl: InstagramIcon,
  authorBio: NewspaperFoldBoldIcon,
  orgAccountStatus: GraphStatsIcon,
}

type PersonAttributeLayoutProps = {
  slug: PersonFieldKeys
  value: PersonFieldKeys
  title: string
  setOpen: (open: boolean) => void
}
function DefaultAttributeLayout({ slug, title, value, ...props }: PersonAttributeLayoutProps) {
  return (
    <AttributeLayoutBase
      when_blank={attributesWithAddLinkFallback.includes(slug) ? "showAddLink" : "hide"}
      icon={iconMap[slug as string]}
      content={value}
      label={PERSON_FIELDS_DATA[slug]?.label || title}
      {...props}
    />
  )
}

const attributesWithAddLinkFallback: PersonFieldKeys[] = [
  "memberGender",
  "memberBirthday",
  "memberMaritalStatus",
  "memberSchoolName",
  "teamMemberJobTitle",
  "authorBio",
]

type AttributeLayoutProps = {
  content: ReactChild
  label: string
  icon?: (props: IconProps) => JSX.Element
  setOpen: (open: boolean) => void
  when_blank?: "showAddLink" | "hide"
}

/** Most layouts inherit from this structure. Be sure to  */
function AttributeLayoutBase({
  content,
  label,
  setOpen,
  icon: Icon,
  when_blank = "showAddLink",
}: AttributeLayoutProps) {
  if (isEmpty(content)) {
    switch (when_blank) {
      case "hide":
        return null
      case "showAddLink":
        content = <Link size="caption1" onClick={() => setOpen?.(true)}>{`Add ${label}`}</Link>
    }
  }

  return (
    <Box>
      <HStack align="center" justify="start" space="none">
        {Icon ? (
          <Box css={{ marginRight: "$2" }}>
            <Icon size="$5" css={{ color: "$secondary" }} />
          </Box>
        ) : (
          <Box css={{ marginRight: "$2" }}>
            <Spacer size="$5" />
          </Box>
        )}
        <VStack space="small">
          <Text size="body">{content}</Text>
          <Text size="caption1" css={{ color: "$secondary" }}>
            {label}
          </Text>
        </VStack>
      </HStack>
    </Box>
  )
}

// MARK: Special Layouts
type PersonAttributeCustomLayoutProps = {
  slug: keyof typeof rmsSectionsData
  title: string
  values: Record<PersonFieldKeys, string>
  context?: AttributeLayout["context"]
  setOpen: (open: boolean) => void
}

function AddressLayout({ title, values, ...props }: PersonAttributeCustomLayoutProps) {
  const empty = isEmpty(Object.values(values).join(""))
  const address = [
    `${startCase(values?.addressAddress)} ${startCase(values?.addressAddress2)}`,
    `${startCase(values?.addressCity)} ${startCase(
      values?.addressStateProvince
    )} ${values?.addressPostalCode?.toUpperCase()}`,
    startCase(values?.addressCountry),
  ]

  const AddressLines = (
    <VStack space="small">
      {address
        .filter(Boolean)
        .map(trim)
        .map((addressLine) => (
          <Text key={addressLine}>{addressLine}</Text>
        ))}
    </VStack>
  )

  return <AttributeLayoutBase content={empty ? "" : AddressLines} label={title} icon={LocationPinIcon} {...props} />
}

function PhoneLayout({ title, values, ...props }: PersonAttributeCustomLayoutProps) {
  if (!values.memberHomePhone && !values.memberCellPhone) return null

  return (
    <Box>
      <HStack align="center">
        <VStack space="large">
          {values.memberHomePhone && (
            <AttributeLayoutBase {...props} content={values.memberHomePhone} label="Home Phone" icon={PhoneIcon} />
          )}
          {values.memberCellPhone && (
            <AttributeLayoutBase
              {...props}
              content={values.memberCellPhone}
              label="Cell Phone"
              icon={!values.memberHomePhone && PhoneIcon}
            />
          )}
        </VStack>
        {values?.memberDoNotCall && (
          <Alert title="Do not call" intent="danger" appearance="minimal" css={{ fontSize: "$caption1" }}>
            <FormattedMessage
              id="do_not_call_description"
              defaultMessage="Parishioner has requested not to be called"
            />
          </Alert>
        )}
      </HStack>
    </Box>
  )
}

function EmailLayout({ title, values, ...props }: PersonAttributeCustomLayoutProps) {
  if (!values.email) return null

  return (
    <Box>
      <HStack align="center">
        <AttributeLayoutBase {...props} content={values.email} label={title} icon={iconMap.email} />
        {values?.memberDoNotEmail && (
          <Alert title="Do not call" intent="danger" appearance="minimal" css={{ fontSize: "$caption1" }}>
            <FormattedMessage
              id="do_not_call_description"
              defaultMessage="Parishioner has requested not to be emailed"
            />
          </Alert>
        )}
      </HStack>
    </Box>
  )
}

function NameLayout({ values, title, ...props }: PersonAttributeCustomLayoutProps) {
  const fullName = nameFormatter(values as unknown as Person)

  return <AttributeLayoutBase {...props} content={fullName} label={title} icon={UserIcon} />
}

function StatusLayout({ context, values, title, ...props }: PersonAttributeCustomLayoutProps) {
  const role = roleFormatter(values as unknown as Person)

  return <AttributeLayoutBase {...props} content={role} when_blank="hide" label="Roles" icon={UserCheckIcon} />
}

function AvatarLayout({ values, context, title, ...props }: PersonAttributeCustomLayoutProps) {
  const fullName = nameFormatter(context.values as unknown as Person, { suffix: false, title: false })

  title = title.replace(/id/gi, "")

  return (
    <AttributeLayoutBase
      {...props}
      content={<Avatar src={context?.values?.authorPhoto?.src ?? context?.values?.memberPhoto?.src} name={fullName} />}
      label={title}
    />
  )
}
