import { Card, Heading, Text, VStack } from "@glasscanvas/elephantkit"
import type { ComponentProps } from "react"
import { Link } from "react-router-dom"

type ListCardProps = {
  title: string
  description?: string | string[]
  icon?: string
  to?: ComponentProps<Link>["to"]
}

/**
 * These cards are used in the giving/activity lists
 * @see https://projects.invisionapp.com/d/#/console/11503276/421795677/preview
 */
export function ListCard({ title, description, icon, to, ...props }: ListCardProps) {
  if (Array.isArray(description)) {
    description = description.filter(Boolean).join(" • ")
  }

  return (
    <Card border css={{ padding: "$5", backgroundColor: "$background" }}>
      <VStack space="medium">
        <Heading as="span" size="subHeadline">
          {title}
        </Heading>

        <Text size="caption1" css={{ color: "$secondary" }}>
          {description}
        </Text>
        
        {props.children}
      </VStack>
    </Card>
  )
}
