import { formbuilderPartsToQbFields, initQueryToRules, qbQueryToSearchkick } from "./utils"
import ReactOnRails from "react-on-rails"

// Register React Component
import QueryBuilderComponent from "./people_filter/query_builder_component"

ReactOnRails.register({ QueryBuilderComponent })

export * from "./people_filter"
export { formbuilderPartsToQbFields, qbQueryToSearchkick, initQueryToRules }
