import React, { useEffect } from "react"
import * as Sentry from "@sentry/browser"
import { EmptyState, useToaster } from "@glasscanvas/elephantkit"

export function handleGenericError(errorObj) {
  if (!__DEV__ && SENTRY_PUBLIC_DSN) {
    Sentry.init({ dsn: SENTRY_PUBLIC_DSN })
    Sentry.captureException(JSON.stringify(errorObj))
  }

  document.dispatchEvent(new CustomEvent("tilma-error-occurred", { bubbles: true, detail: errorObj }))
}

function ErrorDisplayer({ children, renderError }) {
  const toaster = useToaster()

  useEffect(() => {
    function errorEventListener(e) {
      console.error(e)
      toaster.add({
        intent: "danger",
        title: e.detail?.title || "Error",
        description: e.detail?.message || "Something went wrong",
      })
    }

    document.addEventListener("tilma-error-occurred", errorEventListener, false)

    return function cleanup() {
      document.removeEventListener("tilma-error-occurred", errorEventListener, false)
    }
  }, [])

  return (
    <>
      {renderError ? (
        <EmptyState title="Something went wrong" description="Something went wrong rendering the page" />
      ) : (
        children
      )}
    </>
  )
}

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { renderError: false }
  }

  static getDerivedStateFromError(error) {
    return { renderError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
    handleGenericError({ title: "Render error", message: "Unable to render", error: error, details: errorInfo })
  }

  render() {
    return <ErrorDisplayer renderError={this.state.renderError}>{this.props.children}</ErrorDisplayer>
  }
}
