import { Box, Card, HStack, styled, Text } from "@glasscanvas/elephantkit"
import { startCase } from "lodash-es"
import { useEffect, useState } from "react"
import ReactOnRails from "react-on-rails"
import * as yup from "yup"

type ColorPickerProps = {
  value?: string
  name?: string
  colors: string[]
  canUseCustom?: boolean
  /** Optionally set the size of the color swatches  */
  swatchSize?: number
  onChange?: (value: string) => void
}

const ColorSwatch = styled("div", Card, {
  size: "$$swatchSize",
  borderRadius: "$2",
  border: "3px solid white",
  fontSize: "$caption2",
  transition: "$normal",
  userSelect: "none",
  cursor: "pointer",
  minSize: "$$swatchSize",

  [`${Text}`]: {
    fontWeight: "$medium",
    background: "$background",
    padding: "$1",
    borderTopLeftRadius: "$1",
    bottom: "0",
    right: "0",
    position: "absolute",
  },

  variants: {
    active: {
      true: {
        "&, &:hover": { boxShadow: "$shadows$3, 0 0 0 2px $colors$primary" },
      },
    },
  },
})

export function ColorPicker({
  colors,
  name,
  value: initialValue,
  canUseCustom,
  onChange,
  swatchSize = 15,
}: ColorPickerProps) {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  }, [value])

  return (
    <HStack align="center" wrap css={{ $$swatchSize: `$sizes$${swatchSize}` }}>
      {colors.map((color) => (
        <ColorSwatch
          active={value === color}
          key={color}
          elevation={2}
          hoverElevation={3}
          color={color}
          css={{ backgroundColor: `var(--gc-colors-${color}, color)` }}
          onClick={() => setValue(color)}
        >
          <Text size="extraSmall" css={{ whiteSpace: "nowrap" }}>
            {startCase(color)}
          </Text>
        </ColorSwatch>
      ))}
      {canUseCustom && <CustomColorInput active={!colors.includes(value)} onChange={setValue} value={value} />}
      <input type="hidden" name={name} value={value} />
    </HStack>
  )
}

const customColorValidation = yup.string().matches(/[0-9a-f]{6}/gi)

function CustomColorInput({
  onChange,
  value,
  active,
}: {
  onChange: (value: string) => void
  value: string
  active: boolean
}) {
  const [inputValue, setInputValue] = useState(customColorValidation.isValidSync(value) ? value : "")

  return (
    <Box>
      <ColorSwatch
        as="input"
        type="color"
        elevation={2}
        hoverElevation={3}
        active={active}
        value={inputValue}
        onChange={(e) => {
          if (customColorValidation.isValidSync(e.currentTarget.value)) {
            onChange(e.currentTarget.value)
          }
          setInputValue(e.currentTarget.value)
        }}
        css={{
          padding: "0",
          "&::-webkit-color-swatch-wrapper": {
            background: "linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)",
            padding: 0,
            borderRadius: "$1",
          },
          "&::-webkit-color-swatch": {
            borderRadius: "$1",
            border: "none",
            backgroundColor: customColorValidation.isValidSync(value) ? undefined : "transparent !important",
          },
          size: "$$swatchSize",
        }}
      />
      <Text
        size="extraSmall"
        css={{
          position: "absolute",
          bottom: "$1",
          right: "calc($1 / 2)",
          fontWeight: "$medium",
          background: "$background",
          padding: "$1",
          borderTopLeftRadius: "$1",
        }}
      >
        Custom
      </Text>
    </Box>
  )
}

ReactOnRails.register({ ColorPicker })
