import { Button, useToaster } from "@glasscanvas/elephantkit"
import { ReactNode } from "react"
import { useParams } from "react-router"
import { RmsSectionProps } from "../base"
import { useGetPersonQuery, useUpdatePersonMutation } from "../graphql"
import { PersonAttrsSection } from "./person_attrs_section"
import { usePerson } from "../shared"

export function TeamMemberSection(props: RmsSectionProps) {
  const [updatePerson] = useUpdatePersonMutation()
  const toaster = useToaster()

  const person = usePerson()
  const present = person[props.sectionMeta.fallback.presentCheckAttr]
  var yieldBottom: ReactNode = null
  if (present) {
    yieldBottom = (
      <Button
        onClick={async () => {
          try {
            await updatePerson({ variables: { id: person.id, person: { isTeamMember: false } } })
            toaster.add({
              title: "Deleted",
              description: `Team member info was deleted successfully`,
              intent: "success",
            })
          } catch (error) {
            toaster.add({
              title: "Server Error",
              description: `There was an issue deleting team member info`,
              intent: "danger",
            })
          }
        }}
      >
        Delete team member info
      </Button>
    )
  }
  return <PersonAttrsSection yieldBottom={yieldBottom} {...props} />
}
