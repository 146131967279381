import CanvasForms     from 'glass/modules/forms'
import {validateAndScroll} from '@formvalidations'

const _file = 'PaydocPaymentForm';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

var PaydocPaymentForm = (function($){
  function onError($form) {
    CanvasForms.setPreprocessesPending($form, 'card-to-token', 'pending');
    CanvasForms.resetSubmit($form)
  }

  function paydockFormData($form) {
    var result = {};
    $form.find('input[data-paydock]').each(function() {
      result[$(this).data('paydock')] = $(this).val();
    });
    return result;
  }

  $(document).on('content-ready', _double_include ? null : function (e, element) {
    var $form = $(element).find('.payment-form').first();

    if ($form.length > 0) {
      var $pubkey_inp = $('#paydock-public-key');
      if ($pubkey_inp.length == 0 || !$pubkey_inp.val() || $pubkey_inp.val().length == 0) {
        CanvasForms.insertErrors($form, 'This form won\'t work, paydock public key has not been set', null);
        return;
      }

      Paydock.setSandbox(!production);
      Paydock.setPublicKey($pubkey_inp.val());
      CanvasForms.setPreprocessesPending($form, 'card-to-token', 'pending');

      $form.on('form-before-submit', function (e) {
        if (CanvasForms.preprocessesPending($form, 'card-to-token') == 'pending') {
          CanvasForms.setPreprocessesPending($form, 'card-to-token', 'processing');
          CanvasForms.disableSubmit($form);

          Paydock.createToken(paydockFormData($form), function (token, status) {
            $form.find('[name="paydockToken"]').remove();
            $form.append($('<input type="hidden" name="paydockToken" />').val(token));
            CanvasForms.setPreprocessesPending($form, 'card-to-token', false);
            CanvasForms.resetSubmit($form);
            if (CanvasForms.preprocessesPendingCount($form) == 0) {
              CanvasForms.triggerSave($form); // nothing else is pending, re-submit. forms.js may be able to trigger this itself
            }
          },
          function (res, status) {
            CanvasForms.insertErrors($form, "There was an error processing your payment");
            onError($form);
          });
        }
      });

      $form.on('form-on-error', function (e) {
        onError($form);
      });

      $form.on('complete', function (e) {
        CanvasForms.setPreprocessesPending($form, 'card-to-token', 'pending');
      });
    }
  });

  // Return API for other modules
  return {};
})(jQuery);