import { BasicSection, RmsSectionProps } from "../base"
import { PersonAttrsSection, PersonChunksSection, PersonPublicProfileSection, PersonRailsSection } from "./index"
import { PersonFamilySection } from "./person_family_section"

export function PersonProfileSection(props: RmsSectionProps) {
  return (
    <BasicSection
      {...props}
      subComponents={{
        general: PersonChunksSection,
        faith: PersonRailsSection,
        "disciple-info": PersonAttrsSection,
        "parish-registration": PersonRailsSection,
        "custom-fields": PersonRailsSection,
        "family-info": PersonFamilySection,
        "public-profile": PersonPublicProfileSection,
      }}
    />
  )
}
