import { Button, Heading, Paragraph, StackPage, VStack } from "@glasscanvas/elephantkit"
import { Link } from "react-router-dom"

export const MonthlyDonorPromptScreen = (props) => {
  return (
    <StackPage>
      <VStack>
        <Heading size="subHeadline">Become a Monthly Donor</Heading>
        <Paragraph size="caption1">
          Will you consider becoming one of our valued monthly donors by converting your <b>$100</b> contribution into a
          monthly gift?
        </Paragraph>
        <Button round as={Link} to="/donation-type">
          Continue
        </Button>
      </VStack>
    </StackPage>
  )
}
