import { Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { PeopleTable, PeopleFilterBar } from "../people_index/index"

export function Families() {
  return (
    <Fragment>
      <Helmet title="Families" />
      <PeopleFilterBar />
      <PeopleTable columns={["family", "primary contact", "address", "city"]} />
    </Fragment>
  )
}
