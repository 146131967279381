import { assign, createMachine, Interpreter, StatesConfig } from "xstate"
import { PeopleQuery } from "@people"

export type QueryBuilderEvent =
  | { type: "CREATE_NEW" }
  | { type: "EDIT"; query: Partial<PeopleQuery> }
  | { type: "CANCEL" }
  | { type: "CLEAR" }
  | { type: "CLOSE" }
  | { type: "SAVE" }
  | { type: "SAVE_FOR_LATER" }
  | { type: "IDLE" }
  | { type: "APPLY"; id: string; query?: Partial<PeopleQuery> }
  | { type: "DELETE" }
export type QueryBuilderContext = { query?: Partial<PeopleQuery>; showSuccessMessage?: boolean }

const editingOrCreating: StatesConfig<QueryBuilderContext, any, QueryBuilderEvent> = {
  idle: {
    on: {
      SAVE_FOR_LATER: "savingForLater",
      APPLY: { target: "idle", actions: ["applyFilter"] },
    },
  },
  savingForLater: {
    on: {
      SAVE: { actions: ["saveFilter"] },
      CANCEL: "idle",
    },
  },
}

export const queryBuilderMachine = createMachine<QueryBuilderContext, QueryBuilderEvent>({
  id: "people_query_builder",
  initial: "index",
  context: {},
  states: {
    index: {
      on: {
        CREATE_NEW: "new",
        EDIT: {
          target: "edit",
          actions: assign({
            query: (_, { query }) => query,
            showSuccessMessage: (_, { query }) => !(Number(query?.id) > 0),
          }),
        },
        DELETE: { actions: ["deleteFilter"] },
        CLOSE: { actions: ["close"] },
        CLEAR: { actions: ["clearFilter", "close"] },
        APPLY: { actions: ["applyFilter", "close"] },
      },
    },

    edit: {
      on: {
        CANCEL: {
          target: "index",
          actions: assign({
            query: () => undefined,
            showSuccessMessage: () => undefined,
          }),
        },
        CLOSE: { target: "index", actions: ["close"] },
        DELETE: "index",
      },
    },

    new: {
      initial: "idle",

      on: {
        CANCEL: {
          target: "index",
          actions: assign({
            query: () => undefined,
            showSuccessMessage: () => undefined,
          }),
        },
        CLOSE: { target: "index", actions: ["close"] },
        APPLY: { actions: ["applyFilter"] },
      },

      states: editingOrCreating,
    },
  },
})

export type QueryBuilderService = Interpreter<
  QueryBuilderContext,
  any,
  QueryBuilderEvent,
  {
    value: any
    context: QueryBuilderContext
  }
>
