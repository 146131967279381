import { StackRouter, VStack } from "@glasscanvas/elephantkit"
import { ModuleBase } from "@shared"
import { PledgeUiLegacy } from "@pledgeuilegacy"
import { RailsContext } from "react-on-rails/node_package/lib/types"
import { Route } from "react-router-dom"
import { AmountScreen, ConfirmationScreen, DonationTypeScreen, ThankYouScreen } from "."

export const PledgeUi = ({}: any, context: RailsContext) => {
  if (context.featureFlags.new_giving_ui) {
    return () => (
      <ModuleBase context={context}>
        <VStack css={{ maxWidth: "350px" }}>
          <StackRouter>
            <Route start exact title="Make a Secure Donation" path="/" component={AmountScreen} />
            <Route title="Donation Type" path="/donation-type" component={DonationTypeScreen} />
            <Route title="Confirmation" path="/confirmation" component={ConfirmationScreen} />
            <Route title="Thank You" path="/thank-you" component={ThankYouScreen} />
          </StackRouter>
        </VStack>
      </ModuleBase>
    )
  } else {
    return () => <PledgeUiLegacy />
  }
}
