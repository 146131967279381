import React from 'react';
import classNames from 'classnames'

class StringInput extends React.Component {
  constructor(props) {
    super(props);
  }
  onChange = (e) => {
    this.props.setSetting(this.props.id, e.target.value, this.props.options);
  }
  componentDidMount = () => {
    if (this.focus_elem && this.props.autofocus) {
      this.focus_elem.focus();
    }
  }
  render() {
    var props = this.props;
    var inputProps = {
      type: props.type ? props.type : "text",
      id: props.html_id,
      className: "form-control " + props.additional_classes,
      placeholder: props.label,
      onChange: this.onChange,
      value: props.val || '',
      ref: (elem) => {this.focus_elem = elem}
    };

    var classes = {};
    classes['py-2'] = props.in_group;
    classes['border-b'] = props.in_group;
    classes['has-error'] = props.invalid || (props.invalid_settings && props.invalid_settings.includes(props.id));

    return (
      <div className={classNames('form-group', 'form-row', 'mx-4', classes)} data-file={"------------------- string-input.jsx -------------------"}>
        {props.textarea ?
          <textarea {...inputProps} /> :
          <input    {...inputProps} />
        }
        <label htmlFor={props.html_id} className="placeholder-label">
          <span className="placeholder-label-text">{props.label}</span>
          <span className="error-text">Your {props.label} is required.</span>
        </label>
        {props.help_text ?
          <small className="form-text text-muted">{props.help_text}</small> : null
        }
      </div>
    );
  }
}

export default StringInput;
