import { Box, Heading, HStack, Pane, Text, VStack } from "@glasscanvas/elephantkit"
import { Helmet } from "react-helmet-async"
import { useLocation } from "react-router-dom"
import { BasicSection, getSectionMeta, NavigatableSubsections, SectionMeta } from "../base"
import { FourOhFour } from "../base/four_oh_four"
import { PersonChargesSection, PersonGivingSection } from "../giving"
import { PersonSacramentalRecordsSection } from "@sacramentalrecords"
import { useGetPersonQuery, usePersonAvatarQuery } from "../graphql"
import { PeopleTable } from "../people_index"
import { Loading, nameFormatter, usePersonId } from "../shared"
import { roleFormatter } from "../shared/role_formatter"
import { PersonAttrsSection, PersonChunksSection, PersonNav, PersonProfileSection, PersonRailsSection } from "./index"
import { PersonAvatar } from "./person_avatar"

export function PersonBaseSection() {
  const slug = "person-base"
  const personId = usePersonId()
  const { state } = useLocation<{ fromIndex?: boolean }>()

  const { loading, data, error } = useGetPersonQuery({ variables: { id: personId } })
  const { data: avatarData } = usePersonAvatarQuery({
    fetchPolicy: "network-only",
    variables: { id: personId, crop: false, geometry: "700x" },
  })

  if (error) return <FourOhFour />
  if (!data) return null

  const sectionMeta: SectionMeta = getSectionMeta(slug, data?.person)
  const { memberPhoto } = data?.person ?? {}
  const fullName = nameFormatter(data?.person)
  const avatarName = nameFormatter(data?.person, { title: false, suffix: false })
  const personInfo = roleFormatter(data?.person, {
    birthday: true,
    id: true,
    include: { globalRoles: ["family"] },
    limit: { globalRoles: 1, inOrgRoles: 1 },
  })

  return (
    <>
      <Helmet titleTemplate={`People | ${fullName} - %s`} />
      <HStack space="none" css={{ backgroundColor: "$groupedBackground" }}>
        {state?.fromIndex && <PeopleTable queryId="active" compact />}
        {loading ? (
          <Loading />
        ) : (
          <Box css={{ flexGrow: 1 }}>
            <Pane css={{ backgroundColor: "$background" }}>
              <HStack css={{ padding: "$6", paddingBottom: "0" }} align="center">
                <PersonAvatar
                  name="memberPhotoId"
                  fullPhoto={avatarData?.person?.avatarPhoto}
                  previewPhoto={memberPhoto}
                  personId={personId}
                  fullName={avatarName}
                />
                <VStack css={{ paddingLeft: "$2" }}>
                  <Heading size="title2" css={{ fontWeight: "$bold" }}>
                    {fullName}
                  </Heading>
                  <Text size="caption2" css={{ color: "$secondary" }} title={roleFormatter(data?.person)}>
                    {personInfo}
                  </Text>
                </VStack>
              </HStack>
              <PersonNav subSections={sectionMeta.subSections} />
            </Pane>

            <NavigatableSubsections
              slug={slug}
              subComponents={{
                personal: PersonProfileSection,
                account: BasicSection,
                "admin-access": PersonAttrsSection,
                permissions: PersonRailsSection,
                "platform-access": PersonChunksSection,
                "two-factor-info": PersonAttrsSection,
                "org-accounts": PersonRailsSection,
                password: PersonRailsSection,
                administrative: BasicSection,
                status: PersonAttrsSection,
                "sacramental-records": PersonSacramentalRecordsSection,
                notes: PersonRailsSection,
                onboarding: PersonAttrsSection,
                "custom-attributes": PersonRailsSection,
                activity: BasicSection,
                "form-submissions": PersonRailsSection,
                "event-registrations": PersonRailsSection,
                "giving-history": PersonChargesSection,
                subscriptions: PersonRailsSection,
                giving: PersonGivingSection,
              }}
            />
          </Box>
        )}
      </HStack>
    </>
  )
}
