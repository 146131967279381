import React from "react";

// react-bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

// custom inputs
import ServiceEditor from "./service-editor";
import ServiceModEditor from "../service-mod/service-mod-editor";

// .modal-50w defined in app/views/themes/parish/_stylesheets/config/_bootstrap-overrides.scss
export default (props) => {
  // filter out unecessary props
  const { serviceAttrs, serviceModAttrs } = props;

  return (
    <Modal
      show={!!serviceAttrs}
      onHide={props.onHide}
      aria-labelledby='mass-times-editor'
      centered
      dialogClassName='modal-50w'
    >
      <Modal.Header closeButton>
        <Modal.Title id='mass-times-editor'>Add {serviceAttrs ? serviceAttrs.type : 'Mass'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {serviceModAttrs ?
          <ServiceModEditor serviceAttrs={serviceAttrs} serviceModAttrs={serviceModAttrs} /> :
          <ServiceEditor    serviceAttrs={serviceAttrs} scheduleType={props.scheduleType} />
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant='light' className='mr'>
          Cancel
        </Button>
        <Form.Group id='formDeleteMass'>
          <Form.Check type='checkbox' label='Delete Mass' />
        </Form.Group>
      </Modal.Footer>
    </Modal>
  );
}

