import { Fragment, useContext } from 'react';
import { PledgeUiDispatch } from "./reducer"
import { Dropdown } from "@glassets"
import { TranslatedFrequencyText } from './pledge_ui_legacy';
import { useIntl } from "react-intl"

type FrequencySelectProps = {
  frequencyOptions: string[]
  frequencySlugs: object
  frequency: string
}

export const FrequencySelect = (props: FrequencySelectProps) => {
  const dispatch = useContext(PledgeUiDispatch)
  const { formatMessage } = useIntl()

  return (
    <div className="form-group">
      {props.frequencyOptions.length == 2 ? (
        <div className="btn-group btn-group-toggle d-flex frequency-selector" role="group">
          {props.frequencyOptions.map((frequencyOption) => (
            <button
              key={frequencyOption}
              className={`btn btn-outline-primary ${props.frequency == frequencyOption ? "active" : ""}`}
              onClick={(e) => {
                dispatch({ type: "update_frequency", value: frequencyOption })
              }}
            >
              <i className="fa fa-check-circle fa-lg mr-2"></i>
              <TranslatedFrequencyText id={props.frequencySlugs[frequencyOption][0]} />
            </button>
          ))}
        </div>
      ) : props.frequencyOptions.length > 2 ? (
        <Fragment>
          <label className="placeholder-label">Payment Frequency</label>
          <Dropdown
            id="frequency-choice-dropdown"
            val={props.frequency}
            options={props.frequencyOptions.map((frequencyOption) => {
              return {
                value: frequencyOption,
                text: formatMessage({ id: props.frequencySlugs[frequencyOption][0] }),
              }
            })}
            setSetting={(id, val) => {
              dispatch({ type: "update_frequency", value: val })
            }}
          />
        </Fragment>
      ) : null}
    </div>
  )
}