import { MutationFunctionOptions } from "@apollo/client"
import {
  AddIcon,
  Button,
  FormikCheckboxField,
  FormikForm,
  FormikTextField,
  HStack,
  Pane,
  QueryBuilder,
  RedoIcon,
  TrashIcon,
  useToaster,
  VStack,
} from "@glasscanvas/elephantkit"
import {
  CreatePersonQueryMutation,
  Exact,
  PeopleQueriesDocument,
  PeopleQueriesQuery,
  PeopleQuery,
  QueryAttributes,
  useCreatePersonQueryMutation,
  useFormPartsQueryQuery,
  usePeopleQueryLazyQuery,
  useUpdatePersonQueryMutation,
} from "@people"
import {
  formbuilderPartsToQbFields as formBuilderPartsToQueryBuilderFields,
  initQueryToRules,
  qbQueryToSearchkick,
} from "@querybuilder"
import { Loading, safelyParseJSON } from "@shared"
import { useActor } from "@xstate/react"
import { Formik } from "formik"
import { motion } from "framer-motion"
import { pick } from "lodash-es"
import { useEffect } from "react"
import { Utils } from "react-awesome-query-builder"
import { FormattedMessage, useIntl } from "react-intl"
import { useHistory, useLocation } from "react-router-dom"
import { boolean, object, string, TypeOf } from "yup"
import { QueryBuilderChildProps, sectionVariants } from "./people_query_builder"
import { useDeletePersonQueryHook } from "./people_query_hooks"

// MARK: - Edit Query
const saveFilterValidationSchema = object().shape({
  id: string().notRequired(),
  descriptor: string().nullable().notRequired(),
  jsonTree: string().defined(),
  searchkickWhere: string().defined(),
  humanReadable: string().notRequired(),
  isFavorite: boolean().notRequired(),
  isOneTime: boolean().notRequired(),
})
type SaveFilterValues = TypeOf<typeof saveFilterValidationSchema>
export function EditQuery({ service, close }: QueryBuilderChildProps) {
  const [state, send] = useActor(service)
  const { search, ...location } = useLocation()
  const { replace } = useHistory()
  const urlParams = new URLSearchParams(search)
  const toaster = useToaster()
  const [createPersonQuery, { loading: loadingCreatePerson }] = useCreatePersonQueryMutation()
  const intl = useIntl()
  const [updatePersonQuery, { loading: updateLoading }] = useUpdatePersonQueryMutation()
  const queryId = state.matches("edit") ? state.context.query?.id : urlParams.get("query_id")

  const { data: formPartsData, loading: formPartsLoading } = useFormPartsQueryQuery()
  const [callPeopleQuery, { data: queryData, called: peopleQueryCalled, loading: currentQueryLoading }] =
    usePeopleQueryLazyQuery()
  const query = queryData?.query ?? state.context.query ?? {}

  const fields = formBuilderPartsToQueryBuilderFields(safelyParseJSON(formPartsData?.formParts, []))
  const deletePersonQuery = useDeletePersonQueryHook(queryData?.query)

  useEffect(() => {
    if (!peopleQueryCalled && !!queryId && state.matches("edit")) {
      callPeopleQuery({ variables: { id: queryId } })
    }
  }, [peopleQueryCalled, queryId])

  if (formPartsLoading || currentQueryLoading || (queryId && !queryData && state.matches("edit"))) return <Loading />

  async function savePeopleFilter({ id, ...values }: SaveFilterValues) {
    try {
      const apolloParams: MutationFunctionOptions<
        CreatePersonQueryMutation,
        Exact<{ id?: string; query: QueryAttributes }>
      > = {
        variables: {
          id,
          query: { ...values, isOneTime: !values.isFavorite },
        },
        refetchQueries: ["PeopleQueries", "GetPeople"],
        update: (cache, { data, errors }) => {
          if (!errors || (errors.length === 0 && Number(queryId) > 0)) {
            toaster.add({
              intent: "success",
              title: intl.formatMessage({ id: "filter.filter_saved", defaultMessage: "Filter Saved" }),
              description: intl.formatMessage(
                { id: "filter.filter_updated_description", defaultMessage: "{descriptor} saved successfully" },
                { descriptor: values.descriptor }
              ),
            })

            urlParams.set("query_id", data?.createQuery?.id || queryId)
            replace({ ...location, search: urlParams.toString() })
            close()
          }

          if (state.matches("new")) {
            const isPreset = state.context.query?.preset ?? false

            const queries = cache.readQuery<PeopleQueriesQuery>({
              query: PeopleQueriesDocument,
              variables: { isPreset },
            }) ?? { queries: { queries: [] as PeopleQuery[] } }

            cache.writeQuery({
              query: PeopleQueriesDocument,
              variables: { isPreset },
              data: {
                ...queries,
                queries: { ...queries.queries, queries: [...queries.queries.queries, data.createQuery] },
              },
            })
          }
        },
      }

      if (state.matches("new")) {
        await createPersonQuery(apolloParams)
      } else {
        await updatePersonQuery(apolloParams as any)
      }
    } catch (error) {
      console.error(error)
      toaster.add({
        intent: "danger",
        title: intl.formatMessage({ id: "filter.error", defaultMessage: "Error" }),
        description: intl.formatMessage({
          id: "filter.filter_update_error",
          defaultMessage: "Something went wrong saving this filter",
        }),
      })
    }
  }

  const initQuery = safelyParseJSON(queryData?.query?.initQuery, {
    [Object.keys(fields)?.[0] ?? null]: ["text", "equal", null],
  })

  const rules = initQueryToRules(initQuery)

  const initialValues = pick(query, Object.keys(saveFilterValidationSchema.fields)) as SaveFilterValues

  if (state.context.query?.preset) {
    // duplicating a preset, we start with a blank descriptor instead of adding the " (Copied)"
    delete initialValues.descriptor
  }

  return (
    <VStack
      as={motion.div}
      variants={sectionVariants}
      initial="enter"
      animate="center"
      exit="exit"
      custom={1}
      transition={{
        x: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 },
      }}
      css={{ paddingTop: "$3" }}
    >
      <Formik<SaveFilterValues>
        validationSchema={saveFilterValidationSchema}
        initialValues={initialValues}
        onSubmit={savePeopleFilter}
      >
        {({ isSubmitting, isValid, submitForm, setFieldValue }) => (
          <FormikForm>
            <QueryBuilder
              fields={fields}
              settings={{ showNot: false }}
              initialValue={safelyParseJSON(queryData?.query?.jsonTree, {
                id: Utils.uuid(),
                type: "group",
                properties: {
                  conjunction: "AND",
                },
                children1: rules,
              })}
              onChange={({ jsonTree, config, immutableTree }) => {
                setFieldValue("jsonTree", JSON.stringify(jsonTree))
                const searchKickWhere = qbQueryToSearchkick(Utils.jsonLogicFormat(immutableTree, config)["logic"])
                setFieldValue("searchkickWhere", searchKickWhere ? JSON.stringify(searchKickWhere) : undefined)
                setFieldValue("humanReadable", Utils.queryString(immutableTree, config, true))
              }}
            />
            <VStack space="large">
              <Pane borderBottom css={{ paddingBlock: "$3" }} />
              <motion.div animate={{ y: 0, opacity: 1 }} initial={{ y: 15, opacity: 0 }}>
                <VStack>
                  <HStack align="center">
                    <Button loading={loadingCreatePerson} onPress={submitForm}>
                      <FormattedMessage id="filter.apply_filter" defaultMessage="Apply Filter" />
                    </Button>
                    {state.matches("new.idle") && (
                      <Button
                        appearance="outline"
                        onClick={() => {
                          send("SAVE_FOR_LATER")
                          setFieldValue("isOneTime", false)
                        }}
                      >
                        <FormattedMessage id="filter.save_filter" defaultMessage="Save this Filter" />
                      </Button>
                    )}
                    {["new.savingForLater", "edit"].some(state.matches) && (
                      <Button
                        appearance="minimal"
                        onClick={() => {
                          send("CANCEL")
                          setFieldValue("isOneTime", true)
                        }}
                      >
                        <FormattedMessage id="filter.cancel_save_filter" defaultMessage="Cancel" />
                      </Button>
                    )}
                  </HStack>
                  {["edit", "new.savingForLater"].some(state.matches) && (
                    <>
                      <FormikCheckboxField
                        name="isFavorite"
                        label={intl.formatMessage({ id: "filter.is_favorite", defaultMessage: "Favorite" })}
                      />
                      <FormikTextField
                        name="descriptor"
                        label={intl.formatMessage({
                          id: "filter.save_filter_title",
                          defaultMessage: "Name this filter for future use",
                        })}
                        placeholder={intl.formatMessage({ id: "filter.name_filter", defaultMessage: "Filter Name" })}
                      />
                      <HStack>
                        <Button
                          appearance="outline"
                          iconBefore={AddIcon}
                          loading={isSubmitting}
                          disabled={!isValid || isSubmitting}
                          onPress={submitForm}
                        >
                          {state.matches("edit") ? (
                            <FormattedMessage id="filter.save_as_new_filter" defaultMessage="Save as New" />
                          ) : (
                            <FormattedMessage id="filter.save_filter" defaultMessage="Save this Filter" />
                          )}
                        </Button>
                        {!query.preset && (
                          <>
                            <Button
                              appearance="outline"
                              iconBefore={RedoIcon}
                              loading={updateLoading}
                              onPress={submitForm}
                              disabled={!isValid || isSubmitting}
                            >
                              <FormattedMessage id="filter.update_filter" defaultMessage="Update" />
                            </Button>
                            <Button
                              intent="danger"
                              appearance="outline"
                              iconBefore={TrashIcon}
                              disabled={isSubmitting}
                              onPress={async () => {
                                await deletePersonQuery()
                                send("DELETE")
                              }}
                            >
                              <FormattedMessage id="filter.delete_filter" defaultMessage="Delete" />
                            </Button>
                          </>
                        )}
                      </HStack>
                    </>
                  )}
                </VStack>
              </motion.div>
            </VStack>
          </FormikForm>
        )}
      </Formik>
    </VStack>
  )
}
