import { ImageMetadata, ImageUpload, useToaster } from "@glasscanvas/elephantkit"
import { safelyParseJSON } from "@shared"
import { useState } from "react"
import { SectionEditorReturnType } from "../hooks/useSectionEditor"
import { getImage } from "./ImageCollage"
import { HeroSectionProps } from "./index"

export function ImageUploader({
  index,
  section,
}: {
  index: string
  section: SectionEditorReturnType<HeroSectionProps>
}) {
  const imageId = section.getProp("hero", index)
  const toaster = useToaster()
  const [metadata, setMetadata] = useState<ImageMetadata>({ alt: "" })

  return (
    <ImageUpload
      metadata={metadata}
      onMetadataChange={setMetadata}
      focusPosition={safelyParseJSON(section.getProp("hero", `${index}Position`), { x: 0, y: 0 })}
      onFocusPositionChange={async (focusPosition) => {
        try {
          const formData = new FormData()
          formData.append("image[x]", String(focusPosition.x))
          formData.append("image[y]", String(focusPosition.y))

          await fetch(`/admin/images/${imageId}`, {
            method: "PATCH",
            body: formData,
          }).then((res) => res.json())

          section.setProp("hero", { [`${index}Position`]: JSON.stringify(focusPosition) })
        } catch (error) {
          toaster.add({ intent: "danger", title: "Error setting focus" })
        }
      }}
      allowEditing
      allowfocusEditing
      onChange={async (files) => {
        const file = files?.[0]

        if (!file) {
          section.setProp("hero", { [index]: undefined })
          return
        }

        const formData = new FormData()
        formData.append("image[image]", file, file.name)

        try {
          const result = await fetch(`/admin/images`, { method: "POST", body: formData }).then((res) => res.json())
          section.setProp("hero", { [index]: String(result.image_id) })
        } catch (error) {
          toaster.add({ intent: "danger", title: "Error uploading image." })
          console.error(error)
        }
      }}
      css={{ size: "auto", aspectRatio: "1/1" }}
      initialFiles={imageId ? [{ preview: `/image?id=${encodeURIComponent(imageId)}&crop=false` }] : []}
    />
  )
}
