import React, { useContext, Fragment } from "react"

// bootstrap
import Card from "react-bootstrap/Card"

// custom
import { ScheduleDispatch } from "../schedule/schedule-reducer"
import ServiceRowActionBtn from "../service/service-row-action-btn"

export default ({ serviceMod }) => {
  const dispatch = useContext(ScheduleDispatch)

  var rowActions = []

  function rowActionClick(actionType) {
    dispatch({type: actionType, serviceModUid: serviceMod.uid})
  }

  rowActions.push({
    btnText: 'Edit',
    btnIconClasses: "fa fa-edit",
    actionType: 'edit_service_mod',
  })
  rowActions.push({
    btnText: 'Delete',
    btnIconClasses: "fa fa-trash",
    actionType: 'delete_service_mod',
  })

  const serviceRowActionButtons = rowActions.map((actionMeta) => <ServiceRowActionBtn {...actionMeta} key={actionMeta.actionType} onClick={rowActionClick} />)

  return (
    <Fragment>
      <Card className='flex-row mb-3'>
        <div className='p-3'>
          {serviceMod.noteText('editor')}
        </div>

        <div className='d-flex align-items-center ml-auto pr-3'>
          <div className='dropdown'>
            <div className='dropdown-arrow independent-arrow'></div>
            <button name="button" type="button" className="btn btn-outline-default circle-icon shrink-0 border-0">
              <i className='fa fa-dots fa-sm' aria-hidden='true'></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right border-0-last">
              {serviceRowActionButtons}
            </div>
          </div>
        </div>
      </Card>
    </Fragment>
  )
}
