import { startCase } from "lodash-es"
import { safelyParseJSON } from "@shared"
import type { Person } from "../graphql"

export const rmsSectionsData = {
  // base route: /admin/people can go to /index, /families, /staff, /admin, /person/:id, /person/new
  "person-base": {},
  personal: {},
  general: {},
  faith: {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-faith-container",
  },
  "custom-fields": {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-customfields-container",
  },
  "public-profile": {
    navType: "left",
  },
  account: {},
  "platform-access": {},
  "admin-access": {
    subSections: ["email", "isAdmin"],
    hiddenValues: {
      isMember: true,
      isAuthUser: true,
    },
  },
  permissions: {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-permissions-container",
    fallback: {
      presentCheckAttr: "isAdmin",
      title: "Admin Permissions Not Enabled",
      description: "Click below to give this user admin access",
      actionTitle: "Grant Admin Access",
      actionTo: "platform-access",
    },
  },
  "parish-registration": {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-parish-registration-container",
  },
  "org-accounts": {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-org-accounts-container",
  },
  password: {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-password-reset-container",
  },
  administrative: {},
  "sacramental-records": {},
  notes: {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-notes-container",
  },
  "custom-attributes": {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-customattrs-container",
  },
  activity: {},
  "form-submissions": {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-formsubmissions-container",
  },
  "ministry-contact": {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-ministrycontact-container",
  },
  "event-registrations": {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-eventregistrations-container",
  },
  subscriptions: {
    ajaxUrl: "/admin/people/:person_id/edit?only_rms_pieces=true",
    domSelector: ".rms-rails-subscriptions-container",
  },
  giving: {},
  pledges: {
    navTitle: "Recurring",
  },
  charges: {
    navTitle: "History",
  },
  status: {
    subSections: ["orgAccountStatus", "globalRoles", "inOrgRoles"],
    fallback: { presentCheckAttr: "inOrgRoles" },
  },
  "contact-info": {
    subSections: [
      "memberTitle",
      "firstName",
      "lastName",
      "memberSuffix",
      "memberNamePreference",
      "email",
      "memberDoNotEmail",
      "memberHomePhone",
      "memberCellPhone",
      "memberDoNotCall",
      "addressAddress",
      "addressAddress2",
      "addressCity",
      "addressStateProvince",
      "addressPostalCode",
      "addressCountry",
    ],
    hiddenValues: {
      isMember: true
    },
  },
  "family-info": {
    // subSections: ["memberFamilyRole"],
  },
  "basic-info": {
    subSections: [
      // "memberPhotoId",
      "memberGender",
      "memberBirthday",
      "memberDateMarried",
      "memberReligion",
      "memberReligionOther",
      "memberBaptised",
      "memberConfirmed",
      "memberFirstCommunion",
      "memberMaritalStatus",
      "memberChurchWhereMarried",
      "memberCityWhereMarried",
      "memberCountryWhereMarried",
      "memberHaveChildren",
      "memberSchoolName",
      "memberSchoolGrade",
      "memberOccupation",
      "financialProfileEnvelopeNumber",
    ],
    hiddenValues: {
      isMember: true,
    },
  },
  "social-info": {
    subSections: ["memberWebsiteUrl", "memberFacebookUrl", "memberTwitterUrl", "memberInstagramUrl"],
    hiddenValues: {
      isMember: true,
    },
    props: {
      editBtnText: "Add Social Links",
    },
    fallback: { title: "Add Social Links" },
  },
  onboarding: {
    subSections: ["financialProfileCurrentPaymentMethod", "financialProfileWeeklyDollarsHistoricalEstimate"],
    hiddenValues: {
      isMember: true,
    },
  },
  "disciple-info": {
    subSections: [
      "memberMissionaryDiscipleCommitment",
      "memberTrainingCommitment",
      "memberRunSeriesCommitment",
      "memberPrayerCommitment",
      "memberSiteTraining",
      "memberAlphaTraining",
      "memberDiscoveryTraining",
      "memberHostingAlpha",
      "memberHostingDiscovery",
    ],
    hiddenValues: {
      isMember: true,
    },
  },
  "team-member-info": {
    navTitle: "Contact Page",
    props: {
      layout: "inline",
    },
    subSections: [
      "teamMemberJobTitle",
      "teamMemberCustomJobTitle",
      "teamMemberDisplayEmail",
      "teamMemberBio",
      "teamMemberDisplayMember",
    ],
    hiddenValues: {
      isTeamMember: true,
    },
    fallback: { title: "List on the Contact Page", presentCheckAttr: "isTeamMember" },
  },
  // "two-factor-info": {
  //   navTitle: "Two Factor Authentication",
  //   props: {
  //     layout: "inline",
  //   },
  //   subSections: [
  //     "authUserTwoFactorEnabled",
  //     "authUserCustomTwoFactorAuthNumber",
  //     "authUserEnforceTwoFactor",
  //   ],
  //   hiddenValues: {
  //     isAuthUser: true,
  //   },
  // },
  "author-info": {
    navTitle: "Author Profile",
    props: {
      layout: "inline",
    },
    subSections: [
      // Hidden because it's not saving correctly in the backend
      // "authorPhoto",
      // "authorPhotoId",
      "authorBio",
      "authorIsContentProvider",
      "authorAnonymous",
      "authorTwitterHandle",
      "authorWebsiteUrl",
      "authorFacebookUrl",
      "authorInstagramUrl",
    ],
    hiddenValues: {
      isAuthor: true,
    },
    fallback: { title: "Create an Author Profile", presentCheckAttr: "isAuthor" },
  },
}

export type SectionMeta = {
  navType: string
  navTitle?: string
  props?: Record<string, any>
  subSections: Array<keyof typeof rmsSectionsData>
  ajaxUrl?: string
  domSelector?: string
  // Values that are not shown in the form but are put on the form
  hiddenValues?: Record<string, boolean>
  // Set a fallback to display a component in lieu of empty section data
  fallback?: {
    presentCheckAttr: string
    title: string
    description: string
    actionTitle: string
    actionHref: string
    actionTo: string
  }
}

export function getSectionMeta(slug: string, person: Person = null): SectionMeta {

  return {
    navType: "top",
    navTitle: startCase(slug),
    subSections: [],
    props: {},
    hiddenValues: {},
    ...(rmsSectionsData[slug] ?? {}),
    ...(safelyParseJSON(person?.rmsSectionsData, {})?.[slug] ?? {}),
  }
}
