import { Button, DownloadSquareIcon, UsersIcon, HStack, Tooltip, Box } from '@glasscanvas/elephantkit'
import { useHistory, useLocation } from 'react-router-dom'
import { FilterBar } from '../shared'

export function PeopleFilterBar({ compact }: { compact?: boolean }) {
  compact ||= false
  const { search, ...location } = useLocation()
  const { replace } = useHistory()

  const urlParams = new URLSearchParams(search)

  return (
    <Box css={{ paddingBlock: !compact && '$1' }}>
      <FilterBar
        initialSearchString={urlParams.get('search') ?? undefined}
        onChange={(searchStr: string) => {
          searchStr ? urlParams.set('search', searchStr) : urlParams.delete('search')
          replace({ ...location, search: urlParams.toString() })
        }}
        searchPlaceholder="Search People..."
        compact={compact}
        tableActions={TableActions}
      />
    </Box>
  )
}

function TableActions() {
  const TOOLTIP_PLACEMENT = 'bottom-center'
  const { search } = useLocation()
  const urlParams = new URLSearchParams(search)
  const queryId = urlParams.get('query_id')

  return (
    <HStack align="center">
      <Tooltip content="Re-order Team Members" placement={TOOLTIP_PLACEMENT}>
        <Button size="large" icon={UsersIcon} appearance="minimal" as="a" href="/admin/team_members" />
      </Tooltip>
      <Tooltip content="Export People" placement={TOOLTIP_PLACEMENT}>
        <Button
          size="large"
          icon={DownloadSquareIcon}
          appearance="minimal"
          as="a"
          href={`/admin/people/download_member${queryId ? `?query_id=${queryId}` : ''}`}
        />
      </Tooltip>
    </HStack>
  )
}
