import { Card, EmptyState, LockIcon } from "@glasscanvas/elephantkit"
import type { ComponentProps } from "react"

export function AccessDenied({title, description, illustration, ...props}: ComponentProps<typeof EmptyState>) {
  return (
    <Card
      border
      elevation={1}
      css={{
        backgroundColor: "$background",
        padding: "$9",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <EmptyState
        title={title || "Access denied"}
        description={description || "You do not have access to this information."}
        illustration={illustration || <LockIcon size="40px" />}
        {...props}
      />
    </Card>
  )
}
