import { VStack } from "@glasscanvas/elephantkit"
import { getSectionMeta, RmsSectionProps } from "../base"
import { PersonAttrsSection } from "../person"
import { usePerson } from "../shared"

export function PersonChunksSection(props: RmsSectionProps) {
  const person = usePerson()
  return (
    <VStack align="center" space="large" css={{ padding: "0 $5", width: "100%" }}>
      {props.sectionMeta.subSections.map((subSlug) => {
        const ChunkComponent = props.subComponents[subSlug] ?? PersonAttrsSection
        return <ChunkComponent key={subSlug} slug={subSlug} sectionMeta={getSectionMeta(subSlug, person)} />
      })}
    </VStack>
  )
}
