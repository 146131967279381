import { Box, Heading, Paragraph, VStack } from "@glasscanvas/elephantkit"

export function FourOhFour() {
  return (
    <Box css={{ display: "flex", minHeight: "80vh", width: "100%", alignItems: "center", justifyContent: "center" }}>
      <VStack align="center" css={{ width: "100%", maxWidth: "500px", textAlign: "center" }}>
        <Heading size="extraLargeTitle">Not Found</Heading>
        <Paragraph>The page you were looking for doesn't exist.</Paragraph>
        <Paragraph>You may have mistyped the address or the page may have moved.</Paragraph>
      </VStack>
    </Box>
  )
}
