const _file = 'FormPrereqs';
const _double_include = $(window).data('included-' + _file);
$(window).data('included-' + _file, true);

// Hides/shows element B on a form based on the current value of element A.
// To use this function, 
//
// USAGE:
// 1) When element A is a dropdown (select):
// Put data-prereq-selector=<the selector for element A> on element B
// Either add data-prereq-vals = <value of element A that causes element B to be shown>
// Or data-prereq-vals-except = <value of element A that causes element B to be hidden>
//
// 2) When element A is a checkbox:
// Put data-prereq-selector=<the selector for element A> on element B
// Add data-prereq-vals-bool = true, and data-prereq-vals = <true or false, whichever value causes element B to be shown>
//
// 3) When element A is a set of radio buttons:
// Note: In this case you want the selector to be the selector of a parent div for the radio buttons, not any of the inputs themselves
// Add data-prereq-vals-radio = <field name> and data-prereq-vals = <the option(s) which cause element B to be shown>
// Then put data-prereq-selector = ".radio-options-parent" This function will automatically add that class to the parent div of the radio buttons

var FormPrereqs = (function($){
  $(document).on('content-ready', _double_include ? null : function (e, element) {

    var $prereqFields = $(element).find('[data-prereq-selector]')
    $prereqFields.each(function() {
      var $field = $(this);
      if ($field.data('prereq-vals') == 'undefined' && $field.data('prereq-vals-except') == 'undefined') {
        console.log("ERROR: An element with a data-prereq-selector attr is missing its data-prereq-vals attr");
        return;
      }

      var neg = typeof $field.data('prereq-vals') == 'undefined';

      if ($field.data('prereq-vals-bool')) {
        $field.data('prereq-vals-bool-val', $field.data('prereq-vals' + (neg ? '-except' : '')));
      } else {
        $field.data('prereq-vals-array', $field.data('prereq-vals' + (neg ? '-except' : '')).toString().split(";"));
      }

      $($field.data('prereq-selector')).on('change', function(e) {
        var incl;
        if ($field.data('prereq-vals-bool')) {
          incl = $field.data('prereq-vals-bool-val') == $(this).prop('checked');
        } else if ($field.data('prereq-vals-radio')) {
          $selectedOption = $($field.data('prereq-selector') + ':checked')
          incl = $field.data('prereq-vals-array').includes($selectedOption.val())
        } else {
          incl = $field.data('prereq-vals-array').includes($(this).val());
          incl = incl || ( $field.data('prereq-vals-array').includes("##any##") && $(this).val() != "" );
        }

        if (neg == incl) {
          $field.addClass('d-none');
        }
        else {
          $field.removeClass('d-none');
        }
      });
      $($field.data('prereq-selector')).each(function() {
        $(this).change();
      });
    });
  });

  // Return API for other modules
  return {};
})(jQuery);
