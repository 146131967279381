import React, { Fragment } from "react";
import dayjs from "dayjs";

const MASS_TYPES = {
  regular:       {scheduleType: 'All',                      label: 'Regular'},
  christmas_eve: {scheduleType: 'Christmas', day_delta: -1, label: 'Christmas Eve & Midnight Mass'},
  christmas_day: {scheduleType: 'Christmas', day_delta:  0, label: 'Christmas'},
  new_years_eve: {scheduleType: 'Christmas', day_delta:  6, label: 'New Year\'s Eve'},
  new_years_day: {scheduleType: 'Christmas', day_delta:  7, label: 'New Year\'s Day'},
  ash_wed:       {scheduleType: 'Easter', day_delta: -46, label: 'Ash Wednesday'},
  palm_sun:      {scheduleType: 'Easter', day_delta:  -7, label: 'Palm Sunday'},
  holy_thur:     {scheduleType: 'Easter', day_delta:  -3, label: 'Holy Thursday'},
  good_fri:      {scheduleType: 'Easter', day_delta:  -2, label: 'Good Friday'},
  easter_vigil:  {scheduleType: 'Easter', day_delta:  -1, label: 'Easter Vigil'},
  easter_sun:    {scheduleType: 'Easter', day_delta:   0, label: 'Easter Sunday'}
  // ascension:     {scheduleType: 'Easter', day_delta:  39, label: 'Ascension Sunday'},
  // pentecost:     {scheduleType: 'Easter', day_delta:  49, label: 'Pentecost Sunday'},
  // trinity:       {scheduleType: 'Easter', day_delta:  56, label: 'Trinity Sunday'},
  // corpus_chisti: {scheduleType: 'Easter', day_delta:  60, label: 'Corpus Christi'},
}

export const specialMassTypeToStr = (massType, themeAttrs) => {
  const meta = MASS_TYPES[massType]
  return meta && themeAttrs.global_vars ? `${meta.label} - ${dayjs(themeAttrs.global_vars[`date_of_${meta.scheduleType.toLowerCase()}`]).utc().add(meta.day_delta, 'day').format("dddd, MMMM D")}` : null
}

export default (props) => {
  if (props.scheduleType) {
    return (
      <Fragment>
        {Object.entries(MASS_TYPES).map(([k, meta]) => ![props.scheduleType, 'All'].includes(meta.scheduleType) ? null : <option key={k} value={k}>{meta.label}</option>)}
      </Fragment>
    )
  }
};
