import { HeroSectionProps } from "../sections/HeroSection"
import { Box } from "@glasscanvas/elephantkit"

export const getImage = (path: string, size: string = "600x", { position }: { position?: string } = {}) => {
  if (String(path).match("static")) {
    return `/image?file=${encodeURIComponent(path)}&size=${encodeURIComponent(size)}`
  } else {
    return `/image?id=${encodeURIComponent(path)}&size=${encodeURIComponent(size)}${
      position ? `&position=${encodeURIComponent(position)}` : ""
    }`
  }
}

export function ImageCollage({
  image0 = "static/hero1.jpg",
  image1 = "static/hero2.jpg",
  image2 = "static/hero3.jpg",
  image3 = "static/hero4.jpg",
  image0Position,
  image1Position,
  image2Position,
  image3Position,
}: Partial<HeroSectionProps>) {
  return (
    <Box css={{ width: "100%", margin: "0 auto", maxWidth: 700 }}>
      <svg
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 1324 1291"
      >
        <g transform="scale(.94465 .87515)">
          <path fill="none" d="M0 0H1400.91V1475.1H0z"></path>
          <clipPath id="_clip1">
            <path d="M0 0H1400.91V1475.1H0z"></path>
          </clipPath>
          <g clipPath="url(#_clip1)">
            <use
              width="854"
              height="428"
              transform="matrix(1.2954 0 0 1.39826 159.69 785.186)"
              xlinkHref="#_Image2"
            ></use>
            <g transform="matrix(2.5908 0 0 2.82363 711.53 488.595) translate(-213 -107)">
              <clipPath id="_clip3">
                <path d="M0 0H426V214H0z"></path>
              </clipPath>
              <g clipPath="url(#_clip3)">
                <g transform="rotate(90 745.5 -187.5)">
                  <g>
                    <g>
                      <path
                        fill="none"
                        stroke="#E0A961"
                        strokeWidth="2"
                        d="M1146 557V133c-117.08 0-212 94.916-212 212s94.92 212 212 212z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g transform="matrix(.55376 0 0 .60715 -148.836 261.876)">
              <path
                fill="#EBEBEB"
                fillOpacity="0"
                d="M1093.14 336.131c0-51.936-42.83-94.101-95.581-94.101H375.863c-52.754 0-95.583 42.165-95.583 94.101v993.409c0 51.94 42.829 94.1 95.583 94.1h621.696c52.751 0 95.581-42.16 95.581-94.1V336.131z"
              ></path>
              <clipPath id="_clip4">
                <path d="M1093.14 336.131c0-51.936-42.83-94.101-95.581-94.101H375.863c-52.754 0-95.583 42.165-95.583 94.101v993.409c0 51.94 42.829 94.1 95.583 94.1h621.696c52.751 0 95.581-42.16 95.581-94.1V336.131z"></path>
              </clipPath>
              <g clipPath="url(#_clip4)">
                <use
                  width="428"
                  height="628"
                  x="255"
                  y="359"
                  transform="matrix(1.91166 0 0 1.88201 -209.104 -436.027)"
                  xlinkHref="#_Image5"
                ></use>
              </g>
            </g>
            <g transform="matrix(.8465 0 0 .6468 475.568 -152.135)">
              <path
                fill="#EBEBEB"
                fillOpacity="0"
                d="M1093.14 330.361c0-48.751-28.02-88.331-62.53-88.331H342.808c-34.51 0-62.528 39.58-62.528 88.331V1335.31c0 48.75 28.018 88.33 62.528 88.33h687.802c34.51 0 62.53-39.58 62.53-88.33V330.361z"
              ></path>
              <clipPath id="_clip6">
                <path d="M1093.14 330.361c0-48.751-28.02-88.331-62.53-88.331H342.808c-34.51 0-62.528 39.58-62.528 88.331V1335.31c0 48.75 28.018 88.33 62.528 88.33h687.802c34.51 0 62.53-39.58 62.53-88.33V330.361z"></path>
              </clipPath>
              <g clipPath="url(#_clip6)">
                <use
                  width="652"
                  height="668"
                  x="928"
                  y="5"
                  transform="matrix(1.25056 0 0 1.76663 -881.486 229.912)"
                  xlinkHref="#_Image7"
                ></use>
              </g>
            </g>
            <g transform="matrix(.85088 0 0 .94949 189.335 -22.09)">
              <path
                fill="#EBEBEB"
                fillOpacity="0"
                d="M1093.14 302.202c0-33.21-27.87-60.172-62.21-60.172H342.486c-34.332 0-62.206 26.962-62.206 60.172V1363.47c0 33.21 27.874 60.17 62.206 60.17h688.444c34.34 0 62.21-26.96 62.21-60.17V302.202z"
              ></path>
              <clipPath id="_clip8">
                <path d="M1093.14 302.202c0-33.21-27.87-60.172-62.21-60.172H342.486c-34.332 0-62.206 26.962-62.206 60.172V1363.47c0 33.21 27.874 60.17 62.206 60.17h688.444c34.34 0 62.21-26.96 62.21-60.17V302.202z"></path>
              </clipPath>
              <g clipPath="url(#_clip8)">
                <use
                  width="656"
                  height="984"
                  x="403"
                  y="181"
                  transform="matrix(1.24412 0 0 1.20345 -223.141 22.831)"
                  xlinkHref="#_Image9"
                ></use>
              </g>
            </g>
            <g transform="matrix(.45387 0 0 .43919 141.211 849.848)">
              <path
                fill="#EBEBEB"
                fillOpacity="0"
                d="M1093.14 372.117c0-71.797-52.25-130.087-116.618-130.087H396.899c-64.364 0-116.619 58.29-116.619 130.087v921.443c0 71.79 52.255 130.08 116.619 130.08h579.623c64.368 0 116.618-58.29 116.618-130.08V372.117z"
              ></path>
              <clipPath id="_clip10">
                <path d="M1093.14 372.117c0-71.797-52.25-130.087-116.618-130.087H396.899c-64.364 0-116.619 58.29-116.619 130.087v921.443c0 71.79 52.255 130.08 116.619 130.08h579.623c64.368 0 116.618-58.29 116.618-130.08V372.117z"></path>
              </clipPath>
              <g clipPath="url(#_clip10)">
                <use
                  width="351"
                  height="456"
                  x="253"
                  y="1091"
                  transform="matrix(2.33239 0 0 2.60175 -313.288 -2598.66)"
                  xlinkHref="#_Image11"
                ></use>
              </g>
            </g>
            <g transform="matrix(2.50993 0 0 2.70924 968.761 1287.35) translate(-104.5 -53)">
              <clipPath id="_clip12">
                <path d="M0 0H209V106H0z"></path>
              </clipPath>
              <g clipPath="url(#_clip12)">
                <g transform="matrix(0 1 1 0 -531.75 -1088.25)">
                  <g>
                    <g>
                      <path
                        fill="none"
                        stroke="#E0A961"
                        strokeWidth="2"
                        d="M1089.75 739.75c57.16 0 103.5-46.339 103.5-103.5s-46.34-103.5-103.5-103.5v207z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <defs>
          <image id="_Image2" width="854" height="428" href={getImage("static/hero_oval.png", "854x428#")}></image>

          <image
            id="_Image5"
            width="426"
            height="628"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image0, "426x628#", { position: image0Position })}
          ></image>
          <image
            id="_Image7"
            width="650"
            height="669"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image3, "650x669#", { position: image3Position })}
          ></image>
          <image
            id="_Image9"
            width="671"
            height="1007"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image1, "672x1007#", { position: image1Position })}
          ></image>
          <image
            id="_Image11"
            width="365"
            height="465"
            preserveAspectRatio="xMidYMid slice"
            href={getImage(image2, "365x465#", { position: image2Position })}
          ></image>
        </defs>
      </svg>
    </Box>
  )
}
