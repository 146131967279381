import React from "react"
import uuid from "react-uuid"

// custom
import { dup } from '../utils'
import Service from "../service/service";
import ServiceMod from "../service-mod/service-mod";
import servicesReducer from "../service/services-reducer"
import serviceModsReducer from "../service-mod/service-mods-reducer"

const transientReducer = (state = {}, action) => {
  var new_state

  switch (action.type) {
    case 'add_service_mod':
      new_state = dup(state)
      var serviceMod = new ServiceMod(action.serviceModAttrs)
      new_state['edit_service_mod_attrs'] = dup(serviceMod.attrs)
      break
    case 'edit_service_mod':
      new_state = dup(state)
      new_state['edit_service_mod_uid'] = action.serviceModUid
      break
    case 'add_service':
      new_state = dup(state)
      var service = new Service(action.serviceAttrs)
      new_state['edit_service_attrs'] = dup(service.attrs)
      break
    case 'edit_service':
      new_state = dup(state)
      new_state['edit_service_uid'] = action.serviceUid
      break
    case 'close_editor_modal':
      new_state = dup(state)
      new_state['edit_service_mod_attrs'] = null
      new_state['edit_service_mod_uid'] = null
      new_state['edit_service_uid'] = null
      new_state['edit_service_attrs'] = null
      break
    case 'set_view_mode':
      new_state = dup(state)
      new_state['view_mode'] = action.view_mode
      break
    default: // this assumes we want to save on ALL other actions (currently true, but that may change)
      new_state = dup(state)
      new_state['trigger_save'] = (new_state['trigger_save'] || 0) + 1
      break
  }

  return new_state
}

export const ScheduleDispatch = React.createContext(null)

export default (state = {}, action) => {
  return {
    services:   servicesReducer( state['services'],   action),
    service_mods: serviceModsReducer( state['service_mods'], action),
    transient:  transientReducer(state['transient'],  action)
  }
}

