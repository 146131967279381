import {
  Box,
  Button,
  Card,
  FormikCheckboxField,
  FormikForm,
  FormikTextField,
  Heading,
  HStack,
  VStack,
} from '@glasscanvas/elephantkit'
import { Formik } from 'formik'
import { merge, pick } from 'lodash-es'
import { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { useCreatePersonMutation } from '../graphql'
import { useAttributeLayouts } from '../shared'
import { PERSON_FIELDS_DATA } from './fields_data'
import {
  attributeGroups,
  attributeLayouts,
  fieldDictionary,
  generateSchema,
  renderFormElements,
} from './person_attribute_form'

const NEW_PERSON_FIELDS = pick(PERSON_FIELDS_DATA, [
  'memberTitle',
  'firstName',
  'lastName',
  'memberSuffix',
  'email',
  'memberDoNotEmail',
])

export function PersonNewSection() {
  const history = useHistory()
  const [createPerson] = useCreatePersonMutation()

  const fieldSections = useAttributeLayouts({
    keys: Object.keys(NEW_PERSON_FIELDS),
    meta: NEW_PERSON_FIELDS,
    layouts: merge(fieldDictionary, attributeLayouts),
    groups: attributeGroups,
    values: {},
    defaultLayout: FormikTextField,
  })

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        ...generateSchema(NEW_PERSON_FIELDS),
        isAuthUser: yup.boolean(),
        email: yup.string().when('isAdmin', {
          is: true,
          then: yup.string().email().required('Email is required to create a Tilma admin account'),
        }),
      }),
    [NEW_PERSON_FIELDS]
  )

  return (
    <>
      <Helmet title="New Person" />
      <Box
        css={{
          backgroundColor: '$groupedBackground',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '$14',
          minHeight: 'calc(100vh - 75px)',
        }}
      >
        <Formik
          initialValues={{}}
          onSubmit={async function createPersonFunction(values, { setSubmitting }) {
            if (values['email']) {
              values = { ...values, isAuthUser: true }
            }
            await createPerson({
              variables: {
                person: { isMember: true, ...values },
              },
              refetchQueries: ['GetPeople'],
              update: (_, { data: { createPerson } }) => {
                history.push(`/person/${createPerson.id}`, { fromNewPerson: true })
              },
            })
            setSubmitting(false)
          }}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, errors, values, touched }) => (
            <FormikForm>
              <Card
                elevation={1}
                css={{ backgroundColor: '$background', padding: '$9', width: '100%', maxWidth: '650px' }}
              >
                <VStack space="large">
                  <Heading>
                    <FormattedMessage id="rms.section.new-person" defaultMessage="New person" />
                  </Heading>
                  {renderFormElements(fieldSections)}

                  <Heading size="subHeadline" css={{ paddingTop: '$4' }}>
                    <FormattedMessage id="rms.new-person.person-attributes" defaultMessage="Attributes" />
                  </Heading>
                  <Box
                    css={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '$3' }}
                  >
                    <FormikCheckboxField label="Team member" name="isTeamMember" />
                    <FormikCheckboxField label="Author" name="isAuthor" />
                    <FormikCheckboxField label="Site admin" name="isAdmin" />
                    {/* <Checkbox label="Send an invite email" name="getInviteEmail" /> */}
                  </Box>
                  <HStack space="medium" css={{ paddingTop: '$3' }}>
                    <Button type="submit" color="primary" loading={isSubmitting}>
                      Save
                    </Button>
                    <Button onPress={() => history.goBack()} appearance="minimal">
                      Cancel
                    </Button>
                  </HStack>
                </VStack>
              </Card>
            </FormikForm>
          )}
        </Formik>
      </Box>
    </>
  )
}
