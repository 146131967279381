import { styled, Button, ChevronLeftIcon, CloseIcon, Heading, HStack, VStack } from "@glasscanvas/elephantkit"
import { useMachine } from "@xstate/react"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect } from "react"
import { JsonTree } from "react-awesome-query-builder"
import { FormattedMessage } from "react-intl"
import { useHistory, useLocation } from "react-router-dom"
import { PeopleQuery, usePeopleQueryQuery } from "@people"
import { QueryIndex } from "./people_query_index"
import {
  QueryBuilderContext,
  QueryBuilderEvent,
  queryBuilderMachine,
  QueryBuilderService,
} from "./people_query_machine"
import { EditQuery } from "./people_query_form"
import { noop } from "lodash-es"

// MARK: Motion Config
const titleVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "15px" : "-15px",
      opacity: 0,
    }
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? "15px" : "-15px",
      opacity: 0,
    }
  },
}

export const sectionVariants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    }
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? "100%" : "-100%",
      opacity: 0,
    }
  },
}

// MARK - Query Builder (Entry)

type PeopleQueryBuilderProps = {
  /** Let the query builder close its surrounding modal/sheet internally */
  close?: () => void
  queryId?: string
  useParams?: boolean
  onApply?: (query: PeopleQuery) => void
  onClear?: () => void
}

export default function PeopleQueryBuilder({
  close = noop,
  onApply = noop,
  onClear = noop,
  useParams = true,
  queryId: initialQueryId,
}: PeopleQueryBuilderProps) {
  const { search, ...location } = useLocation()
  const urlParams = new URLSearchParams(search)
  const queryId = initialQueryId ?? urlParams.get("query_id")
  const history = useHistory()

  function clearFilter() {
    if (useParams) {
      const newParams = new URLSearchParams(urlParams)
      newParams.delete("query_id")

      history.replace({ ...location, search: newParams.toString() })
    }

    onClear()
  }

  function applyFilter(_: QueryBuilderContext, event: QueryBuilderEvent) {
    if (event.type !== "APPLY") return
    if (useParams) {
      urlParams.set("query_id", event.id)
      history.replace({ ...location, search: urlParams.toString() })
    }
    onApply(event?.query)
  }

  const [state, send, service] = useMachine(queryBuilderMachine, {
    devTools: __DEV__,
    context: {},
    actions: {
      close,
      clearFilter,
      applyFilter,
    },
  })

  const { data: exitingQueryData } = usePeopleQueryQuery({ variables: { id: queryId }, skip: !queryId })

  useEffect(() => {
    if (exitingQueryData?.query?.id) {
      send("EDIT", { query: exitingQueryData?.query })
      return
    }
  }, [exitingQueryData?.query?.id])

  return (
    <VStack>
      <HStack align="center" justify="between" css={{ position: "relative" }}>
        <AnimatePresence initial={false}>
          {state.matches("index") ? (
            <Heading
              key="title"
              transition={{
                x: { type: "spring", stiffness: 500, damping: 30 },
                opacity: { duration: 0.15 },
              }}
              variants={titleVariants}
              initial="enter"
              animate="center"
              exit="exit"
              custom={-1}
              as={motion.h5}
            >
              Filters
            </Heading>
          ) : (
            <Button
              key="button"
              transition={{
                x: { type: "spring", stiffness: 500, damping: 30 },
                opacity: { duration: 0.15 },
              }}
              as={motion.button}
              variants={titleVariants}
              initial="enter"
              animate="center"
              exit="exit"
              custom={1}
              appearance="minimal"
              iconBefore={ChevronLeftIcon}
              onClick={() => send("CANCEL")}
            >
              <FormattedMessage id="filter.back_to_filters" defaultMessage="Back to Filters" />
            </Button>
          )}
        </AnimatePresence>
        <HStack>
          <AnimatePresence initial={false}>
            {state.matches("index") && (
              <Button
                key="newfilterbutton"
                as={motion.button}
                css={{ marginLeft: "auto" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => send("CREATE_NEW")}
              >
                <FormattedMessage id="filter.new_filter" defaultMessage="New Filter" />
              </Button>
            )}
            {state.matches("index") && queryId && (
              <Button
                key="clearfilterbutton"
                as={motion.button}
                css={{ marginLeft: "auto" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={() => send("CLEAR")}
                appearance="outline"
              >
                <FormattedMessage id="filter.clear_filter" defaultMessage="Clear Filter" />
              </Button>
            )}
          </AnimatePresence>
          <Button iconBefore={CloseIcon} onClick={() => send("CLOSE")} appearance="outline">
            Close
          </Button>
        </HStack>
      </HStack>
      <AnimatePresence initial={false}>
        {state.matches("index") && <QueryIndex service={service} close={close} />}
        {["edit", "new"].some(state.matches) && <EditQuery service={service} close={close} />}
      </AnimatePresence>
    </VStack>
  )
}

export type QueryBuilderChildProps = {
  service: QueryBuilderService
  initQuery?: JsonTree
  id?: string
  close?: () => void
  query?: PeopleQuery
}

export const FilterButton = styled(Button, {
  flexGrow: 1,
  whiteSpace: "nowrap",

  variants: {
    compact: {
      true: {
        width: "300px",
      },
      false: {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
    },

    hasFilter: {
      true: {
        backgroundColor: "$primary",
        color: "$white",
      },
      false: {
        backgroundColor: "$gray5",
        color: "$gray1",
        "&:hover": {
          color: "$label",
        },
      },
    },
  },
})
